import React from 'react';
import { Images } from '@/utils/Images';
import Image from '@/js/components/Image/Image';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { AccountTypes, OnboardingFlowTypes, PageTranslation } from '@/utils/Enums';
import { isMobile } from 'react-device-detect';
import { ScreenSizes } from '@/utils/ScreenSizes';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { useWindowSize } from '@/js/hooks/useWindowSize/useWindowSize';
import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';
import { OnboardingOutletContextType, talentOnboardingSteps, companyOnboardingSteps, blokOwnerOnboardingSteps, resetOnboardingLocalStorage, eventOnboardingSteps } from '../constants/onbardingConstants';
import userStore from '../../users/store/v2/UserStore';
import LocalStorage from '@/js/helpers/localStorage';
import OnboardingBottom from './OnboardingBottom';
import { __ } from '@/utils/Trans';

export default function OnboardingSelectAccountType() {
	const [selectedCard, setSelectedCard] = React.useState<typeof onboardingItems[0] | null>(null);

	const navigate = useNavigate();
	const { width } = useWindowSize()
	const { i18n } = useTranslation();
	const [searchParams] = useSearchParams()

	const { setMailActivationSucces, handleChangeStep } = useOutletContext<OnboardingOutletContextType>();

	const isFlowTrue = searchParams.has('flow')
	const isEventFlow = String(searchParams.get('flow')) === OnboardingFlowTypes.Event

	const handleOnCardClick = (item: typeof onboardingItems[0]) => {
		setMailActivationSucces(false)

		if (item.id === selectedCard?.id) {
			setSelectedCard(null);
			return;
		}

		setSelectedCard(item);
	}

	const handleSetCustomOnboardingSteps = () => {
		if (isEventFlow) {
			LocalStorage.set('onboardingSteps', eventOnboardingSteps)
		}
	}

	const handleOnContinueClick = () => {
		setMailActivationSucces(false)
		resetOnboardingLocalStorage()

		LocalStorage.remove('onboardingSteps')
		LocalStorage.set('onboardingSelectedAccountType', selectedCard?.type)

		if (isFlowTrue) {
			handleSetCustomOnboardingSteps()
			handleChangeStep()
			return
		}

		switch (selectedCard?.type) {
			case AccountTypes.BlokOwner:
				LocalStorage.set('onboardingSteps', blokOwnerOnboardingSteps)
				break;
			case AccountTypes.CompanyOwner:
				LocalStorage.set('onboardingSteps', companyOnboardingSteps)
				break;
			case AccountTypes.Talent:
				LocalStorage.set('onboardingSteps', talentOnboardingSteps)
				break;
			default:
				break;
		}

		handleChangeStep()
	}

	React.useEffect(() => {
		if (searchParams.has('referralType')) {
			navigate({
				search: window.location.search,
				pathname: userStore.getRouteByReferral()
			});
		}
	}, [navigate, searchParams])

	const isEnglish = i18n.language === PageTranslation.EN;

	const onboardingItems = React.useMemo(() => {
		return [
			{
				id: 1,
				icon: "🎯",
				title: "OnboardingPage.CardOne.Title",
				type: AccountTypes.BlokOwner,
				description: "OnboardingPage.CardOne.Description",
				showcase: [
					{
						title: "OnboardingPage.CardOne.Showcase.Items.Title",
						description: "OnboardingPage.CardOne.Showcase.ItemOne.Description",
						image: isEnglish ? Images.BlokOwnerOnbardingPreviewOneEN : Images.BlokOwnerOnbardingPreviewOneTR
					},
					{
						title: "OnboardingPage.CardOne.Showcase.Items.Title",
						description: "OnboardingPage.CardOne.Showcase.ItemTwo.Description",
						image: isEnglish ? Images.BlokOwnerOnbardingPreviewTwoEN : Images.BlokOwnerOnbardingPreviewTwoTR
					},
					{
						title: "OnboardingPage.CardOne.Showcase.Items.Title",
						description: "OnboardingPage.CardOne.Showcase.ItemThree.Description",
						image: isEnglish ? Images.BlokOwnerOnbardingPreviewThreeEN : Images.BlokOwnerOnbardingPreviewThreeTR
					}
				]
			},
			{
				id: 2,
				icon: "💼",
				title: "OnboardingPage.CardTwo.Title",
				type: AccountTypes.CompanyOwner,
				description: "OnboardingPage.CardTwo.Description",
				showcase: [
					{
						title: "OnboardingPage.CardTwo.Showcase.Items.Title",
						description: "OnboardingPage.CardTwo.Showcase.ItemOne.Description",
						image: Images.CompanyOnboardingPreviewOne
					},
					{
						title: "OnboardingPage.CardTwo.Showcase.Items.Title",
						description: "OnboardingPage.CardTwo.Showcase.ItemTwo.Description",
						image: isEnglish ? Images.CompanyOnboardingPreviewTwoEN : Images.CompanyOnboardingPreviewTwoTR
					},
					{
						title: "OnboardingPage.CardTwo.Showcase.Items.Title",
						description: "OnboardingPage.CardTwo.Showcase.ItemThree.Description",
						image: isEnglish ? Images.CompanyOnboardingPreviewThreeEN : Images.CompanyOnboardingPreviewThreeTR
					}
				]
			},
			{
				id: 3,
				icon: "👩‍💻",
				title: "OnboardingPage.CardThree.Title",
				type: AccountTypes.Talent,
				description: "OnboardingPage.CardThree.Description",
				showcase: [
					{
						title: "OnboardingPage.CardThree.Showcase.Items.Title",
						description: "OnboardingPage.CardThree.Showcase.ItemOne.Description",
						image: Images.TalentOnboardingPreviewOne
					},
					{
						title: "OnboardingPage.CardThree.Showcase.Items.Title",
						description: "OnboardingPage.CardThree.Showcase.ItemTwo.Description",
						image: isEnglish ? Images.TalentOnboardingPreviewThreeEN : Images.TalentOnboardingPreviewThreeTR
					},
					{
						title: "OnboardingPage.CardThree.Showcase.Items.Title",
						description: "OnboardingPage.CardThree.Showcase.ItemThree.Description",
						image: Images.TalentOnboardingPreviewTwo
					},
				]
			},
		];
	}, [isEnglish])

	const handleSetLocalstorageData = React.useCallback(() => {
		const accountTypeObject = LocalStorage.get('onboardingSelectedAccountType') as AccountTypes

		switch (accountTypeObject) {
			case AccountTypes.BlokOwner:
				setSelectedCard(onboardingItems[0]);
				break;
			case AccountTypes.CompanyOwner:
				setSelectedCard(onboardingItems[1]);
				break;
			case AccountTypes.Talent:
				setSelectedCard(onboardingItems[2]);
				break;
			default:
				break;
		}
	}, [onboardingItems])

	const handleGetOnboardingItems = () => {
		if (isFlowTrue && isEventFlow) {
			return onboardingItems.slice(1)
		}

		if (searchParams.has('redirect')) {
			const redirect = String(searchParams.get('redirect'))
			if (redirect &&  redirect.includes('bloks')) {
				return onboardingItems.slice(1)
			}
		}

		return onboardingItems
	}

	React.useEffect(() => {
		handleSetLocalstorageData()
	}, [])

	const slideShow = !!selectedCard?.id && (
		<AnimatePresence>
			<motion.div
				transition={{ type: 'tween' }}
				animate={{ x: 0, opacity: 1, display: "flex" }}
				initial={{ x: !isMobile ? '100%' : 0, opacity: 0, display: "none" }}
				className={"p-7 bg-shade-background relative h-full w-full justify-center flex items-center xl:justify-start xl:w-auto xl:px-10 xl:pt-0 xl:pb-10"}
			>
				<CarouselProvider
					infinite
					isPlaying
					interval={3000}
					totalSlides={3}
					visibleSlides={1}
					naturalSlideHeight={400}
					playDirection={'forward'}
					naturalSlideWidth={width <= ScreenSizes.XXLarge ? 300 : 400}
					className={'w-full h-full flex flex-col items-center justify-center'}
				>
					<Slider className={'w-full h-full flex flex-col justify-center'}>
						{selectedCard.showcase.map((item, index) => (
							<Slide index={index} key={index} >
								<div className={'card-panel flex flex-col items-center justify-center gap-3 h-full w-full '}>
									<div>
										<h4 className={"text-header-semibold-h7 text-shade-black text-center"}>
											{__(item.title)}
										</h4>
										<p className={"text-body-regular-b5 text-shade-black text-center mt-4"}>
											{__(item.description)}
										</p>
									</div>
									<Image
										src={item.image}
										height={width <= ScreenSizes.XXLarge ? undefined : 400}
										className={'max-w-lg max-h-[350px] 2xl:max-h-[400px] w-full'}
									/>
								</div>
							</Slide>
						))}
					</Slider>
					<DotGroup totalSlides={3} className={'flex justify-center gap-[6px]'} />
				</CarouselProvider>
			</motion.div>
		</AnimatePresence>
	)

	const continueButton = (
		<OnboardingBottom
			hasBackButton={false}
			disabled={!selectedCard?.id}
			onClick={handleOnContinueClick}
		/>
	)

	return (
		<div className={'flex flex-col xl:overflow-x-hidden'}>
			<div className="onboarding-full-height flex flex-col xl:flex-row">
				<div className={`flex flex-col w-full items-center justify-center gap-8 px-4 py-6 xl:gap-2 2xl:gap-10 xl:p-0 ${!!selectedCard ? "xl:w-10/12" : "w-full"}`}>
					<div className={"flex flex-col items-center"}>
						<h1 className={"text-shade-black text-header-bold-h8 xl:text-header-extrabold-h6 2xl:text-header-bold-h5"}>
							{__("OnboardingPage.SelectAccountType.Title")}
						</h1>
						<span className={"text-shade-black text-body-regular-b5 -mt-1 2xl:mt-3"}>
							{__("OnboardingPage.SelectAccountType.Subtitle")}
						</span>
					</div>
					<div className='max-w-lg flex flex-col gap-4 w-full xl:gap-4 2xl:gap-6'>
						{handleGetOnboardingItems().map(item => (
							<div
								key={item.id}
								onClick={() => handleOnCardClick(item)}
								className={`flex items-center gap-6 h-32 px-4 w-full rounded-20 border-2 border-neutral-300 cursor-pointer transition md:h-28 2xl:h-32 hover:shadow-md md:py-7 md:px-10 ${item.id === selectedCard?.id && "border-primary-500 bg-primary-50"}`}
							>
								<span className={"text-header-extrabold-h5 lg:text-header-extrabold-h4"}>
									{item.icon}
								</span>
								<div className={"flex flex-col gap-[10px]"}>
									<h3 className={`text-body-semibold-b6 lg:text-body-semibold-b5 text-shade-black ${item.id === selectedCard?.id && "!text-primary-700"}`}>
										{__(item.title)}
									</h3>
									<p className={"text-body-regular-b6 lg:text-body-regular-b5 text-shade-black"}>
										{__(item.description)}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className={"block h-full xl:hidden"}>
					{slideShow}
				</div>
				{!!selectedCard?.id && (
					<div className={"block xl:hidden"}>
						{continueButton}
					</div>
				)}
				<div className={`hidden w-full ${!!selectedCard && 'xl:w-1/2 xl:block'}`}>
					{slideShow}
				</div>
			</div>
			<div className={"hidden xl:block"}>
				{continueButton}
			</div>
		</div>
	)
}
