import React from 'react';
import talentStore from "../../../talent/store/TalentStore";
import SelectBox, { ISelectBoxResult } from "@/js/components/SelectBox/SelectBox";
import { __ } from "@/utils/Trans";
import { Images } from "@/utils/Images";
import { useOutletContext } from 'react-router-dom';
import { OnboardingOutletContextType } from '../../constants/onbardingConstants';
import OnboardingBottom from '../OnboardingBottom';
import { useQuery } from '@tanstack/react-query';
import locationStore from '@/js/modules/locations/store/LocationStore';
import { useSearchParams } from 'react-router-dom';
import { isJoinBlokFlow } from '../../utils/OnboardingHelper';

const _cities = [
  { name: "İstanbul", icon: Images.TurkeyIcon },
  { name: "Ankara", icon: Images.TurkeyIcon },
  { name: "Londra", icon: Images.EnglandIcon },
  { name: "Berlin", icon: Images.GermanyIcon },
  { name: "Dubai", icon: Images.UnitedArabEmiratesIcon },
  { name: "Doha", icon: Images.QatarIcon },
  { name: "New York, NY", icon: Images.UnitedStatesIcon },
  { name: "San Francisco, CA", icon: Images.UnitedStatesIcon },
  { name: "Riyad", icon: Images.UnitedArabEmiratesIcon },
  { name: "Tokyo", icon: Images.JapanIcon },
  { name: "Pekin", icon: Images.ChinaIcon },
  { name: "Sidney", icon: Images.AustraliaIcon },
  { name: "Singapur", icon: Images.SingaporeIcon },
  { name: "Hong Kong", icon: Images.HongKongIcon }
];

export default function OnboardingTalentLocation() {
  const [disabled, setDisabled] = React.useState(false);
  const [selectedCity, setSelectedCity] = React.useState('');
  const [searchParams] = useSearchParams()

  const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()


  const handleOnContinueClick = () => {
    handleChangeStep()
  }

  const _handleCityClick = (cityName: string) => {
    setDisabled(selectedCity !== cityName)
    setSelectedCity((selectedCity === cityName) ? '' : cityName);
  };

  const handleLocationChange = (e: ISelectBoxResult) => {
    talentStore.set("locationId", e.value)
    setSelectedCity(e.value);
  }

  useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      await locationStore.fetchLocations()
      return true
    }
  })

  return (
    <div className="flex flex-col justify-center items-center">
      <div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} max-w-[950px] w-full`}>
        <div className='flex flex-col h-full justify-start items-start gap-6 w-full px-4 py-10 md:justify-center md:gap-10 md:p-0 md:items-center'>
          <h2 className="text-header-bold-h8 md:text-header-bold-h5 ">
            {__('OnboardingPage.TalentSelectLocation.Title')}
          </h2>
          <SelectBox
            inputName={"locationId"}
            name={"locationId"}
            isDisabled={disabled}
            classes={'w-full max-w-[750px]'}
            data={talentStore.locations()}
            onChange={e => handleLocationChange(e)}
            error={talentStore.state.errors}
            value={talentStore.state.form.locationId}
            placeholder={__('OnboardingPage.TalentSelectLocation.SearchPlaceholder')}
          />
          {/* <div className="flex flex-wrap md:justify-center md:items-center justify-items-start items-start gap-4">
            {chunkArray(cities, isMobile ? 2 : 4)?.map((row, rowIndex) => (
              <div key={rowIndex} className="flex flex-wrap gap-4">
                {row.map(city => (
                  <SelectionBox
                    rounded
                    key={city.name}
                    isSelected={selectedCity === city.name}
                    onClick={() => handleCityClick(city.name)}
                    classes={"!p-4 !inline-flex !items-center !gap-2.5 !w-auto"}
                  >
                    <div className="w-6 h-6 relative">
                      <img src={city.icon} />
                    </div>
                    <div className="text-base font-normal leading-normal">
                      {city.name}
                    </div>
                  </SelectionBox>
                ))}
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <OnboardingBottom
        onClick={handleOnContinueClick}
        disabled={!talentStore.state.form.locationId}
      />
    </div>
  );
}
