import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import Tooltip from '../Tooltip/Tooltip'
import { __ } from '@/utils/Trans'
import { TooltipPlacement } from '../Tooltip/TooltipTypes'
import CopyIconV2 from '@/assets/icons/CopyIconV2'

interface ICopyButton {
	value: string,
	customText?: string | React.ReactNode;
	customIcon?: React.ReactNode;
	classes?: string;
}

const CopyButton = (props: ICopyButton) => {
	const [isCopied, setIsCopied] = React.useState(false)

	const handleOnCopy = () => {
		setIsCopied(true)

		setTimeout(() => {
			setIsCopied(false)
		}, 2000)
	}

	return (
		<CopyToClipboard onCopy={handleOnCopy} text={props.value}>
			<span className={`cursor-pointer text-gray-400 group-focus-within:border-gray-400 sm:p-2 transition duration-150 ease-in-out ${props.classes}`}>
				<Tooltip
					content={isCopied ? __('InviteTalentModal.Copied') : __('InviteTalentModal.Copy')}
					name={"CopyBlokLink"}
					placement={TooltipPlacement.Top}
				>
					{props.customText || props.customIcon || <CopyIconV2 />}
				</Tooltip>
			</span>
		</CopyToClipboard>
	)
}

export default CopyButton
