
const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
            <path
                fill="#26874E"
                fillRule="evenodd"
                d="M8.89 5.221a4 4 0 100 8 4 4 0 000-8zm-6 4a6 6 0 1110.891 3.477l4.817 4.816a1 1 0 11-1.415 1.414l-4.816-4.816a6 6 0 01-9.477-4.89z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default SearchIcon
