import { UIState } from '@/utils/Enums';

export const borderColors = {
	[UIState.Warning]: 'border-orange-400',
	[UIState.Error]: '!border-error-400',
	[UIState.Success]: 'border-success-600',
	[UIState.Info]: 'border-blue-400',
	[UIState.Default]: 'border-shade-stroke',
};

export const textColors = {
	[UIState.Warning]: 'text-orange-400',
	[UIState.Error]: '!text-error-400',
	[UIState.Success]: 'text-success-600',
	[UIState.Info]: 'text-blue-400',
	[UIState.Default]: 'text-shade-black',
};
