import { ReactSVG } from "react-svg";
import {Icons} from "@/utils/Icons";
import {BaseComponentProps} from "@/utils/BaseComponentProps";

interface IIcon extends BaseComponentProps {
  name: Icons,
  width?: string,
  height?: string,
  color?: string,
};

function Icon(props: IIcon) {
  const { width = '21', height = '21' } = props;
  return <ReactSVG
    wrapper="div"
    className={props.color}
    src={`/assets/icons/${props.name}.svg`}
    beforeInjection={svg => {
      svg.setAttribute('style', `width: ${width}px; height: ${height}px;`);
    }}
  />;
}

export default Icon;
