export default function UploadIcon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="25"
         fill="none"
         viewBox="0 0 24 25"
      >
         <path
            fill="#8A47F6"
            d="M22 13.5a1 1 0 00-1 1v4.213a2.79 2.79 0 01-2.787 2.787H5.787A2.79 2.79 0 013 18.713V14.5a1 1 0 10-2 0v4.213A4.792 4.792 0 005.787 23.5h12.426A4.792 4.792 0 0023 18.713V14.5a1 1 0 00-1-1z"
         ></path>
         <path
            fill="#8A47F6"
            d="M6.707 9.207L11 4.914V17.5a1 1 0 002 0V4.914l4.293 4.293a1 1 0 001.414-1.414l-6-6a1 1 0 00-1.414 0l-6 6a1 1 0 001.414 1.414z"
         ></path>
      </svg>
   )
}