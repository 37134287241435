
import { Icons } from '@/utils/Icons'
import LogOutButton from '../author/LogOutButton'
import TopBarDropdownMenuItem from '../dropdown/TopNavBarDropdownMenuItem'
import TopNavBarChangeLanguage from '../TopNavBarChangeLanguage'
import { ScreenModes } from '@/utils/Enums'

interface ITopBarMobileMenuDropdown extends React.PropsWithChildren {
    items: {
        to: string,
        name: string,
        translation: string
        icon: Icons
    }[]
}

const TopBarMobileMenuDropdown = (props: ITopBarMobileMenuDropdown) => {
    return (
        <div className={"mt-3 space-y-1"}>
            {props.items.map(item => (
                <TopBarDropdownMenuItem
                    key={item.name}
                    item={item}
                    classes={"flex gap-3 hover:bg-gray-50 flex-row-reverse px-4 py-2 justify-end text-base font-medium text-gray-500 hover:text-gray-800 sm:px-6"}
                />
            ))}
            <div className={"flex items-center justify-between"}>
                <div className={"sm:px-2 hover:bg-gray-50"}><LogOutButton /></div>
                <span className='px-6'>
                    <TopNavBarChangeLanguage screenMode={ScreenModes.Mobile} />
                </span>
            </div>
        </div>
    )
}

export default TopBarMobileMenuDropdown
