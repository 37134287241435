import Icon from "@/js/components/Icon/Icon"
import { Icons } from "@/utils/Icons"
import { __ } from "@/utils/Trans"

const TopBarSearchNoResult = () => {
    return (
        <div className="absolute flex flex-col items-center w-full px-6 pt-3 -mt-3 text-center bg-white border border-t-0 border-gray-200 rounded-md rounded-t-none shadow w rounded-b-md text -sm py-14 sm:px-14">
            <Icon name={Icons.Info} />
            <p className="mt-4 font-semibold text-gray-700">
                {__('TopBarNavigationSearchBox.NoResultsFound')}
            </p>
            <p className="mt-2 font-medium leading-5 text-gray-500">
                {__('TopBarNavigationSearchBox.NoResultsFoundDescription')}
            </p>
        </div>
    )
}

export default TopBarSearchNoResult