const LinkedInIconV2 = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2990_1051)">
        <path d="M17.925 17.2837C22.0255 13.3296 22.0255 6.91892 17.925 2.9649C13.8246 -0.989124 7.17638 -0.989124 3.07592 2.9649C-1.02455 6.91892 -1.02455 13.3296 3.07592 17.2837C7.17638 21.2377 13.8246 21.2377 17.925 17.2837Z" fill="#0172B1"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.1782 9.17242C11.2281 9.11891 11.2503 9.10285 11.267 9.08145C11.9718 8.08607 12.9818 7.79709 14.1584 7.96834C15.518 8.1717 16.3893 9.0868 16.6113 10.4889C16.6613 10.8207 16.689 11.1578 16.689 11.495C16.689 12.8917 16.6835 14.2884 16.6946 15.6852C16.6946 15.8404 16.6557 15.8885 16.4892 15.8885C15.7289 15.8778 14.9686 15.8778 14.2139 15.8885C14.0474 15.8885 14.003 15.8457 14.0085 15.6852C14.0141 14.3794 14.0141 13.079 14.0085 11.7732C14.0085 11.4789 13.9863 11.1846 13.9031 10.8956C13.6256 9.97515 12.5434 9.65406 11.7665 10.2641C11.3502 10.5959 11.1837 11.0294 11.1893 11.5431C11.1948 12.774 11.1893 13.9995 11.1893 15.2303C11.1893 15.3908 11.1782 15.5514 11.1948 15.7119C11.2059 15.8511 11.1449 15.8778 11.0172 15.8778C10.2458 15.8725 9.46888 15.8725 8.69748 15.8778C8.55874 15.8778 8.50879 15.8511 8.50879 15.7012C8.51989 14.5935 8.52544 13.4857 8.52544 12.378C8.52544 11.0133 8.52544 9.6487 8.51434 8.28943C8.51434 8.13959 8.54764 8.09678 8.70303 8.09678C9.47443 8.10748 10.2514 8.10213 11.0228 8.09678C11.1615 8.09678 11.2004 8.13424 11.1948 8.26802C11.1726 8.557 11.1782 8.83528 11.1782 9.17242Z" fill="#FEFEFE"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.02101 11.998C7.02101 13.2288 7.02101 14.4543 7.02656 15.6852C7.02656 15.8404 6.99326 15.8939 6.82122 15.8885C6.05536 15.8778 5.28951 15.8832 4.52365 15.8885C4.37936 15.8885 4.33496 15.8564 4.33496 15.7119C4.34051 13.2342 4.34051 10.7565 4.33496 8.27873C4.33496 8.15029 4.36826 8.10748 4.507 8.10748C5.28951 8.11283 6.06646 8.11283 6.84897 8.10748C7.00436 8.10748 7.02656 8.161 7.02656 8.29478C7.02101 9.52562 7.02101 10.7618 7.02101 11.998Z" fill="#FEFEFE"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.70027 4.36145C6.72696 4.36145 7.39292 5.14812 7.15428 6.06857C6.97114 6.76426 6.22194 7.16563 5.36174 7.02649C4.44604 6.882 3.93547 6.02576 4.274 5.19093C4.48489 4.67184 5.0232 4.36145 5.70027 4.36145Z" fill="#FEFEFE"/>
      </g>
      <defs>
        <clipPath id="clip0_2990_1051">
          <rect width="21" height="20.25" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default LinkedInIconV2;
