import Cookies from 'js-cookie';

const cookies = {
   getCookie: (name: string) => {
      return Cookies.get(name);
   },
   setCookie: (name: string, value: string, days: number, path = '/') => {
      Cookies.set(name, value, { expires: days, path });
   },
   deleteCookie: (name: string) => {
      Cookies.remove(name);
   }
}

export default cookies;
