
export enum ValidationCodes {
    IsNotEmpty = 'isNotEmpty',
    IsEmail = 'isEmail',
    IsUrl = 'isUrl',
    IsString = 'isString',
    IsNumber = 'isNumber',
    isAlreadyExists = 'isAlreadyExists',
    isWrongCredentials = 'isWrongCredentials',
    matches = 'matches',
    Match = 'Match',
    isTokenExpired = 'isTokenExpired',
    IsTrueBoolean = 'IsTrueBoolean',
    IsBiggerThan = 'isBiggerThan',
    minLength = 'minLength',
    maxLength = 'maxLength',
    TheYearShouldBeBiggerThan = 'TheYearShouldBeBiggerThan',
    TheYearShouldBeLessThanOrEqual = 'TheYearShouldBeLessThanOrEqual',
    EmailMinLength = 'EmailMinLength',
    ArrayMinSize = 'arrayMinSize',
    DefaultTitleMaxLength = 'DefaultTitleMaxLength',
		ArrayMinThreeSize = 'ArrayMinThreeSize',
    ArrayNotEmpty = 'ArrayNotEmpty',
    ArrayMaxTenSize = 'ArrayMaxTenSize',
    ArrayMaxFiveSize = 'ArrayMaxFiveSize',
    IsUrlStartsWith = 'IsUrlStartsWith',
    Forbidden = '403',
    IsPasswordMatches = 'IsPasswordMatches',
    ValidateSkillExperience = 'ValidateSkillExperience',
    HasThreeRequiredSkill = 'HasThreeRequiredSkill',
    HasDuplicate = 'HasDuplicate',
    isTotalBiggerThanFive = 'isTotalBiggerThanFive',
    minJobPostRewardAmount = 'minJobPostRewardAmount',
    ValidateUnpublishReason = 'ValidateUnpublishReason',
    isAlreadyReferred = 'isAlreadyReferred',
    onlyTalentType = 'onlyTalentType',
    MaxOneHundredThousand = 'MaxOneHundredThousand',

}

export enum ValidationMessages {
    isNotEmpty = 'Validations.IsNotEmpty',
    TheYearShouldBeBiggerThan = 'Validations.TheYearShouldBeBiggerThan',
    TheYearShouldBeLessThanOrEqual = 'Validations.TheYearShouldBeLessThanOrEqual',
    minLength = 'Validations.MinLength',
    maxLength = 'Validations.MaxLength',
    isBiggerThan = 'Validations.isBiggerThan',
    isEmail = 'Validations.IsEmail',
    isUrl = 'Validations.IsUrl',
    isString = 'Validations.IsString',
    isPositive = 'Validations.IsPositive',
    isNumber = 'Validations.IsNumber',
    isAlreadyExists = 'Validations.AlreadyExists',
    onlyTalentType = 'Validations.OnlyTalentType',
    isAlreadyReferred = 'Validations.AlreadyReferred',
    isWrongCredentials = 'Validations.NotFound',
    matches = 'Validations.Matches',
    EmailShouldBeValid = 'Validations.EmailShouldBeValid',
    Match = 'Validations.PasswordConfirmation.Match',
    isTokenExpired = 'Validations.EmailAndTokenExpired',
    IsTrueBoolean = 'Validations.IsTrueBoolean',
    EmailMinLength = 'Validations.EmailMinLength',
    arrayMinSize = 'Validations.ArrayMinSize',
    DefaultTitleMaxLength = 'Validations.DefaultTitleMaxLength',
    ArrayMinThreeSize = 'Validations.ArrayMinThreeSize',
    ArrayNotEmpty = 'Validations.ArrayNotEmpty',
    ArrayMaxTenSize = 'Validations.ArrayMaxTenSize',
    ArrayMaxFiveSize = 'Validations.ArrayMaxFiveSize',
    IsUrlStartsWith = 'Validations.IsUrlStartsWith',
    IsPasswordMatches = 'Validations.Password',
    ValidateSkillExperience = 'Validations.ValidateSkillExperience',
    ValidateEmailsArray = 'Validations.ValidateEmailsArray',
    HasThreeRequiredSkill = 'Validations.HasThreeRequiredSkill',
    HasDuplicate = 'Validations.HasDuplicate',
    isTotalBiggerThanFive = 'Validations.isTotalBiggerThanFive',
    minJobPostRewardAmount = 'Validations.minJobPostRewardAmount',
    ValidateUnpublishReason = 'Validations.ValidateUnpublishReason',
    MaxOneHundredThousand = 'Validations.MaxOneHundredThousand'
}
