import BaseStore from '@/js/core/BaseStore';
import {IJobStoreState} from './UserTypes';
import UserService from '@/js/modules/users/services/UserService';
import {AccountTypes, ReferralTypes} from '@/utils/Enums';
import {PageRoutes} from '@/utils/PageRoutes';
import {errorHandler} from '@/js/helpers/errorHandler';

const UserStore = {
    accountType: '',
    user: '',
    createPasswordPage: {
        password: '',
        token: '',
        hasExplicitConsent: false,
        hasTalentUserAgreement: false,
        hasNotificationPermission: false,
    },
};

class UserStores extends BaseStore<IJobStoreState, UserService> {
    initializeStore() {
        return this.useStore();
    }

    async fetchAccountTypeForPerks(): Promise<string> {
        const result = await this.service.fetchAccountType();
        return result.data?.result?.accountType;
    }

    async isEmailExists(email: string): Promise<boolean> {
        try {
            const result = await this.service.isEmailExists(email);

            return result.data.result;
        } catch {
            return Promise.resolve(false);
        }
    }

    async verify(token: string): Promise<boolean> {
        try {
            this.state.isLoading = true;

            const result = await this.service.verify({token});

            return result.data.result;
        } catch (error: any) {
            const errors = error.response.data.validations?.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.field]: curr.code,
                }),
                {}
            );

            if (Object.keys(errors!).length) {
                this.state.hasError = true;
                this.state.errors = errors;
            }

            return Promise.resolve(false);
        } finally {
            this.state.isLoading = false;
        }
    }

    async fetchAccountType() {
        try {
            const result = await this.service.fetchAccountType();
            this.state.accountType = result.data.result.accountType;

            return Promise.resolve(this.state.accountType);
        } catch {
            this.state.accountType = '';
            return Promise.resolve();
        }
    }

    async fetchMe() {
        try {
            const result = await this.service.me();
            this.state.user = result.data.result;

            return result.data.result;
        } catch {
            return Promise.resolve();
        }
    }

    async updateLanguage(payload) {
        try {
            const result = await this.service.updateLanguage(payload);

            return Promise.resolve(result.data.result);
        } catch {
            return Promise.resolve();
        }
    }

    async resendVerificationEmail(token?) {
        try {
            await this.service.resendVerificationEmail(token);
        } catch {
            return Promise.resolve();
        }
    }

    async updatePassword(token: string) {
        try {
            const result = await this.service.updatePassword({
                ...this.state.createPasswordPage,
                token,
            });

            return result.data.result;
        } catch (error) {
            this.goToForbidden(error);
            const errorMessages = errorHandler(error);

            if (Object.keys(errorMessages!).length) {
                this.state.hasError = true;
                this.state.errors = errorMessages;
            }
        }
    }

    async getInviteInfo(id: string) {
        try {
            const result = await this.service.getUserInviteInfo(id);

            return result.data.result;
        } catch (error) {
            return;
        }
    }

    async sendImportRequest(req: any) {
        try {
            const result = await this.service.sendImportRequest(req);

            return result.data.result;
        } catch (error) {
            //this.goToForbidden(error)
            const errorMessages = errorHandler(error);

            if (Object.keys(errorMessages!).length) {
                this.state.hasError = true;
                this.state.errors = errorMessages;
            }
        }
    }

    async sendUpdateImportRequest(req: any) {
        try {
            const result = await this.service.updateImportRequest(req);

            return result.data.result;
        } catch (error) {
            //this.goToForbidden(error)
            const errorMessages = errorHandler(error);

            if (Object.keys(errorMessages!).length) {
                this.state.hasError = true;
                this.state.errors = errorMessages;
            }
        }
    }

    async fetchUsersAndPaywallPaymentsInfo(blokId: string) {
        try {
            const response = await this.service.fetchUsersAndPaywallPaymentsInfo(blokId);
    
            const users = response.data.result;
            console.log(users);
    
            return users;
        } catch (error) {
            console.error(error);
            return;
        }
    }

    isVisitor() {
        return !this.state.accountType;
    }

    isCompanyOwnerAccountType() {
        return this.state.accountType === AccountTypes.CompanyOwner;
    }

    isTalentAccountType() {
        return this.state.accountType === AccountTypes.Talent;
    }

    isBlokOwnerAccountType() {
        return this.state.accountType === AccountTypes.BlokOwner;
    }

    getAccountType() {
        return this.state.accountType as AccountTypes;
    }

    getRedirectPagesByAccountType() {
        if (this.isTalentAccountType()) {
          	return PageRoutes.UserFeedPage;
        } else if (this.isBlokOwnerAccountType()) {
          	return PageRoutes.MyBlokPage;
        } else if (this.isCompanyOwnerAccountType()) {
            return PageRoutes.DashboardRewardJobs;
        }

        return PageRoutes.Index;
    }

    getRedirectPagesByAccountTypeNavBar() {
        if (this.isTalentAccountType()) {
          	return PageRoutes.UserFeedPage;
        } else if (this.isBlokOwnerAccountType()) {
            return PageRoutes.MyBlokPage;
        } else if (this.isCompanyOwnerAccountType()) {
            return PageRoutes.DashboardRewardJobs;
        }

        return PageRoutes.Index;
    }

    getRouteByReferral() {
        const params = new URLSearchParams(window.location.search as string);
        const referralType = params.get('referralType') as ReferralTypes;

        switch (referralType) {
            case ReferralTypes.Company:
                return PageRoutes.Company;
            case ReferralTypes.Talent:
                return PageRoutes.TalentCreate;
            case ReferralTypes.Blok:
                return PageRoutes.BlokOwnerCreate;
            default:
                return PageRoutes.Onboarding;
        }
    }
}

export default new UserStores(UserStore, UserService);
