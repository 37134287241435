import { useOutletContext, useSearchParams } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import { __ } from "@/utils/Trans";
import OnboardingBottom from "../OnboardingBottom";
import TextBox from "@/js/components/TextBox/TextBox";
import companyStore from "@/js/modules/company/store/CompanyStore";
import { InputType } from "@/js/components/Input/InputType";
import { defaultTitleMaxLength } from "@/utils/Regex";
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";

export default function OnboardingCompanyName() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>();
  const [searchParams] = useSearchParams()

	const handleOnContinueClick = () => {

		if (companyStore.state.form.title.length > defaultTitleMaxLength) {
			companyStore.state.errors = {
				...companyStore.state.errors,
				title: "Validations.DefaultTitleMaxLength"
			}
			return
		}

		handleChangeStep();
	};

	return (
		<div className="flex flex-col justify-center items-center ">
			<div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} max-w-2xl w-full flex-col justify-start items-center gap-8 xl:gap-10 inline-flex md:justify-center xl:p-0 py-10 px-4`}>
				<h1 className="text-header-bold-h8 w-full text-shade-black md:text-header-bold-h5 md:w-auto md:text-center">
					{__("OnboardingPage.CompanyName.Title")}
				</h1>
				<div className={"w-full flex flex-col gap-5"}>
					<TextBox name={"title"} classes={"w-full"}>
						<TextBox.Label>
							{__('CompanyPage.CompanyName')}
						</TextBox.Label>
						<TextBox.Input
							name={"title"}
							error={companyStore.state.errors}
							attr={{
								name: "title",
								type: InputType.Text,
								value: companyStore.state.form.title,
								onChange: e => companyStore.set('title', e.target.value),
								placeholder: __('CompanyPage.CompanyNamePlaceholder')
							}}
						/>
					</TextBox>
				</div>
			</div>
			<OnboardingBottom
				isLoading={companyStore.state.isSaving}
				onClick={handleOnContinueClick}
				disabled={!companyStore.state.form.title}
			/>
		</div>
	);
}
