import BaseStore from "@/js/core/BaseStore";
import { IRegionsStore } from "./RegionTypes";
import RegionService from "../services/RegionService";

const regionStore = {
  regions: [],
}

class RegionsStore extends BaseStore<IRegionsStore, RegionService> {
  async fetchRegions() {
    const { data } = await this.service.fetchRegions();
    this.state.regions = data.result;
  }
}

export default new RegionsStore(regionStore, RegionService);