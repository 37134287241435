import React from 'react'
import { useLocation } from 'react-router-dom'
import TopBarNavigationItem from '../TopNavBarMenuItem'
import { PageRoutes } from '@/utils/PageRoutes'
interface ITopBarMobileMenuNavigation extends React.PropsWithChildren {
    items: {
        to: string,
        name: string,
        translation: string
    }[]
}


const TopBarMobileMenuNavigation = (props: ITopBarMobileMenuNavigation) => {
    const { pathname } = useLocation()

    const activeClassName = `block py-2 pl-3 pr-4 text-base font-semibold rounded-md bg-gray-50 sm:pl-5 sm:pr-6 ${pathname === PageRoutes.LandingForCommunities ? 'text-violet-500' : 'text-primary-500'}`
    const inActiveClassName = "block py-2 pl-3 pr-4 text-base font-medium text-gray-500 rounded-md hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
    const getClassNames = (isActive: boolean) => {
        if (isActive) return activeClassName
        else return inActiveClassName
    }

    return (
        <div className={"pt-2 pb-3 space-y-1 px-2"}>
            {props.items.map(item => (
                <TopBarNavigationItem
                    key={item.name}
                    item={item}
                    getClassNames={getClassNames}
                />
            ))}
        </div>
    )
}

export default TopBarMobileMenuNavigation