import { useOutletContext } from "react-router-dom"
import { OnboardingOutletContextType } from "../../constants/onbardingConstants"
import OnboardingBottom from "../OnboardingBottom"
import { PhoneInput } from 'react-international-phone';
import { __ } from "@/utils/Trans";
import blokOwnerStore from "@/js/modules/bloks/store/BlokOwnerStore";
import AddSocialMediaLinkInfo from "@/js/modules/socialMedias/components/AddSocialMediaLinkInfo";
import { useQuery } from "@tanstack/react-query";
import socialAccountStore from "@/js/modules/socialMedias/store/SocialAccountStore";
import blokStore from "@/js/modules/bloks/store/BlokStore";
import TextBox from "@/js/components/TextBox/TextBox";
import { validateSocialUrls } from "@/js/helpers/validateSocialUrls";
import clsx from "clsx";

export default function OnboardingBlokOwnerContact() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()

	const handleContinueClick = () => {
		blokOwnerStore.resetErrors()

		const errors = validateSocialUrls(blokOwnerStore.state.form.ownerSocialAccounts || [])
		blokOwnerStore.state.errors = errors

		if (!!Object.keys(errors).length) return;

		handleChangeStep()
	}

	const handleSocialMedia = (data) => {
		blokOwnerStore.set('ownerSocialAccounts', data)
		blokOwnerStore.resetSocialMediaErrors();
	}

	useQuery({
		queryKey: ['socialMediaProviders'],
		queryFn: async () => {
			await socialAccountStore.fetchSocialAccounts()
			return true
		}
	})

	return (
		<div className="flex flex-col justify-center items-center">
			<div
				className={clsx("py-10 px-4 flex flex-col justify-start items-center gap-10 max-w-2xl w-full xl:gap-10 xl:p-0 xl:justify-center", {
					'onboarding-full-height': !(blokOwnerStore.state.form.ownerSocialAccounts.length > 3)
				})}
			>
				<div className={"flex flex-col gap-4"}>
					<h1 className={"text-header-bold-h8 md:text-header-bold-h5 text-shade-black self-start md:self-center"}>
						{__("OnboardingPage.BlokOwnerContact.Title")}
					</h1>
					<p className={"text-body-regular-b5 text-shade-black text-start md:text-center"}>
						{__("OnboardingPage.BlokOwnerContact.Description")}
					</p>
				</div>
				<div className={"w-full flex flex-col gap-10"}>
					<div>
						<TextBox.Label>
							{__('LandingCommon.ContactLayout.PhoneNumber')}
						</TextBox.Label>
						<PhoneInput
							defaultCountry={"tr"}
							name={'phoneNumber'}
							value={blokOwnerStore.state.form.gsmNumber}
							onChange={(value) => blokOwnerStore.set('gsmNumber', value)}
							placeholder={__('LandingCommon.ContactLayout.PhoneNumber')}
							className={'mt-[10px] flex items-center rounded-lg border focus-within:border-primary-500 transition ease-in-out duration-150 focus:ring-0 focus:outline-none border-lavender-web-2 p-3 w-full '}
						/>
					</div>
					<div className={clsx({ 'pb-12': (blokOwnerStore.state.form.ownerSocialAccounts.length > 3) })}>
						<AddSocialMediaLinkInfo
							labelKey={"OnboardingPage.BlokOwnerContact.Socials"}
							onChange={handleSocialMedia}
							providers={blokStore.providers()}
							errors={blokOwnerStore.state.errors}
							socialAccounts={blokOwnerStore.state.form.ownerSocialAccounts}
						/>
					</div>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={!blokOwnerStore.state.form.gsmNumber?.length}
			/>
		</div>
	)
}
