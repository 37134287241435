import Modal from '@/js/components/Modal/Modal.tsx';
import {SocialAccountNames} from '@/js/modules/socialMedias/store/SocialAccountTypes.ts';
import WhatsAppIcon from '@/assets/icons/WhatsAppIcon.tsx';
import TwitterXIcon from '@/assets/icons/TwitterXIcon.tsx';
import TelegramIcon from '@/assets/icons/TelegramIcon.tsx';
import EmailIconV2 from '@/assets/icons/EmailIconV2.tsx';
import LinkedInIconV2 from '@/assets/icons/LinkedInIconV2.tsx';
import InputWithCopy from '@/js/modules/bloks/components/InputWithCopy';
import Image from '@/js/components/Image/Image';
interface ISocialMediaShareModal {
    modalTitle: string;
    modalDescription?: string;
    shareText: string;
    shareTextWithUrl: string;
    url: string;
    onClose: () => void;
    entityInfo?: {
        imageUrl: string;
        title: string;
        subtitle: string;
    };
    showTitle?: boolean;
    showIcon?: boolean;
    type: 'share' | 'invite';
}

const socialMedias = [
    {type: SocialAccountNames.Email, icon: <EmailIconV2 />},
    {type: SocialAccountNames.LinkedIn, icon: <LinkedInIconV2 />},
    {type: SocialAccountNames.Whatsapp, icon: <WhatsAppIcon />},
    {type: SocialAccountNames.Twitter, icon: <TwitterXIcon />},
    {type: SocialAccountNames.Telegram, icon: <TelegramIcon />},
];

export default function SocialMediaShareModal(props: ISocialMediaShareModal) {
    const {showTitle = false} = props;
    const {showIcon = true} = props;

    const handleShareButton = (type: SocialAccountNames) => {
        if (type === SocialAccountNames.Email) {
            return window.open(`mailto:?subject=&body=${encodeURI(props.shareTextWithUrl)}`, '_blank');
        } else if (type === SocialAccountNames.LinkedIn) {
            return window.open(
                `https://www.linkedin.com/feed/?shareActive&mini=true&text=${encodeURI(props.shareTextWithUrl)}`,
                '_blank'
            );
        } else if (type === SocialAccountNames.Whatsapp) {
            return window.open(`https://api.whatsapp.com/send?text=${encodeURI(props.shareTextWithUrl)}`, '_blank');
        } else if (type === SocialAccountNames.Twitter) {
            return window.open(`https://twitter.com/share?text=${encodeURI(props.shareTextWithUrl)}`, '_blank');
        } else if (type === SocialAccountNames.Telegram) {
            return window.open(`https://t.me/share/url?url=${encodeURI(props.url)}&text=${props.shareText}`, '_blank');
        }
    };

    return (
        <Modal closeClasses={'!mb-0'} onClose={props.onClose} name={'socialMediaShareModal'}>
            <div className={'flex flex-col gap-[30px]'}>
                {props.entityInfo && (
                    <div className="flex items-center gap-6">
                        <Image
                            width={72}
                            height={72}
                            src={props.entityInfo.imageUrl}
                            alt={props.entityInfo.title}
                            className={'rounded-[10px] border border-shade-stroke'}
                        />
                        <div>
                            <p className="text-body-semibold-b2 text-shade-black break-words">
                                {props.entityInfo.title}
                            </p>
                            <span className="text-body-regular-b4 text-shade-black">{props.entityInfo.subtitle}</span>
                        </div>
                    </div>
                )}
                {showTitle && (
                    <div className="flex flex-col justify-center items-center flex-wrap">
                        {showIcon && (
                            <p
                                className={
                                    'bg-primary-50 text-gray-700 text-center rounded-full w-20 h-20 flex justify-center items-center text-[30px] mb-8'
                                }
                            >
                                🔗
                            </p>
                        )}
                        <p className={'text-2xl font-semibold text-shade-black text-center'}>{props.modalTitle}</p>
                        <p className={'text-body-regular-b5 text-shade-black text-center mt-4'}>
                            {props.modalDescription}
                        </p>
                    </div>
                )}
                <div className={'flex justify-center items-center gap-6 flex-wrap'}>
                    {socialMedias.map((socialMedia) => (
                        <button
                            key={socialMedia.type}
                            onClick={() => handleShareButton(socialMedia.type)}
                            className={'w-14 h-14 bg-neutral-50 rounded-full flex justify-center items-center'}
                        >
                            {socialMedia.icon}
                        </button>
                    ))}
                </div>
                {props.type === 'invite' && props.modalTitle && (
                    <p className="text-center text-body-regular-b5 text-shade-black">{props.modalTitle}</p>
                )}
                <span className={'border-b border-shade-stroke w-full'} />
                <InputWithCopy value={props.url} />
            </div>
        </Modal>
    );
}
