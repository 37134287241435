import UserService from './UserService';
import { create } from 'zustand';
import { Action, IAuthResponse, IResult, State } from './UserStore.types';
import { errorHandler } from '@/js/helpers/errorHandler';
import LocalStorage from '@/js/helpers/localStorage';

const userService = new UserService();

const inititalState: State = {
	user: {
		id: '',
		firstName: '',
		lastName: '',
		email: '',
		username: '',
		hasAgreements: false,
		hasNotificationPermission: false,
		isOnboardingCompleted: true,
		isDetailsCompleted: true,
		emailVerifiedAt: new Date(),
		isRegisterationCompleted: false,
		language: null,
		title: '',
	},
	errors: {},
	isLoading: false,
	hasError: false,
};

const useUserStore = create<State & Action>((set, get) => ({
	...inititalState,

	setErrors(errors: { [key: string]: string; }) {
		set({ errors });
	},
	async me() {
		try {
			set({ isLoading: true, hasError: false, errors: {} });

			const { data }: IAuthResponse = await userService.me();

			if (data.isSuccess) {
				const user: IResult = data.result;
				set({ user });
				LocalStorage.set('user', JSON.stringify(user));

				return user;
			}

		} catch (error) {
			const errorMessages = errorHandler(error);
			if (Object.keys(errorMessages!).length) {
				set({ hasError: true, errors: errorMessages });
			}
		} finally {
			set({ isLoading: false });
		}
	},
}));


export default useUserStore;
