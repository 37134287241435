import React from 'react'
import { Images } from '@/utils/Images';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Routes } from 'react-router-dom';
import Image from '@/js/components/Image/Image';
import AuthRoutes from '@/js/router/Routes/AuthRoutes';
import AdminRoutes from '@/js/router/Routes/AdminRoutes';
import PublicRoutes from '@/js/router/Routes/PublicRoutes';
import ErrorBoundary from '@/js/components/ErrorBoundary/ErrorBoundary';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const fallback = (
	<div className={'flex justify-center items-center h-screen animate-pulse'}>
		<Image
			width={100}
			height={100}
			effect={'opacity'}
			src={Images.DWSLogoLetter}
		/>
	</div>
)

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0,
			refetchOnWindowFocus: false,
		}
	}
})

export default function Router() {
	const helmetContext = {};
	const oAuthClientId = String(process.env.VITE_GOOGLE_CLIENT_ID);

	return (
		<HelmetProvider context={helmetContext}>
			<BrowserRouter>
				<Toaster />
				<ErrorBoundary>
					<React.Suspense fallback={fallback}>
						<GoogleOAuthProvider clientId={oAuthClientId}>
							<QueryClientProvider client={queryClient}>
								<Routes>
									{AdminRoutes}
									{PublicRoutes}
									{AuthRoutes}
								</Routes>
							</QueryClientProvider>
						</GoogleOAuthProvider>
					</React.Suspense>
				</ErrorBoundary>
			</BrowserRouter>
		</HelmetProvider>
	);
}
