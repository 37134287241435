import PlusIcon from '@/assets/icons/PlusIcon'
import Button from '@/js/components/Buttons/Button'
import { PageRoutes } from '@/utils/PageRoutes'
import { __ } from '@/utils/Trans'
import { useNavigate } from 'react-router-dom'

interface INewNoticeButton {
    hasBorder?: boolean
    isVisible?: boolean
}

const NewNoticeButton = (props: INewNoticeButton) => {
    const { hasBorder = true, isVisible = false } = props

    const navigate = useNavigate()

    const handleNewNoticeClick = () => navigate(PageRoutes.CreateJobSelectionPage)

    return (
        <div className={`items-center flex-shrink-0 ${isVisible ? 'hidden' : ''} h-full ${hasBorder ? 'border-l border-gray-100 px-7' : ''} md:flex`}>
            <Button attrs={{ onClick: handleNewNoticeClick }} classes={"flex items-center justify-center w-full h-12"} name={"TopBarButton"}>
                <div className={"flex gap-1 items-center"}>
                    <Button.Icon>
                        <PlusIcon />
                    </Button.Icon>
                    <p className={"font-bold text-base leading-4 tracking-wide"}>
                        {__('TopBar.NewNoticeButton')}
                    </p>
                </div>
            </Button>
        </div>
    )
}

export default NewNoticeButton
