import React from 'react'
import './SelectBox.css';
import slugify from "@/js/helpers/slugify";
import { __ } from "@/utils/Trans";
import { Icons } from "@/utils/Icons";
import { UISize, UIState } from "@/utils/Enums";
import Select, { ControlProps, MenuListProps, ValueContainerProps, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { BaseComponentProps } from "@/utils/BaseComponentProps";
import { textColors } from "../TextBox/constants/textBoxConstants";
import { TooltipPlacement } from "@/js/components/Tooltip/TooltipTypes";
import { truncateString } from "@/js/helpers/truncateString";
import { useIsomorphicLayoutEffect } from "@/js/hooks/useIsomorphicLayoutEffect/useIsomorphicLayoutEffect";
import { useWindowSize } from "@/js/hooks/useWindowSize/useWindowSize";
import { ScreenSizes } from "@/utils/ScreenSizes";
import { renderToString } from 'react-dom/server'
import Icon from "@/js/components/Icon/Icon";
import Tooltip from "@/js/components/Tooltip/Tooltip";
import List from 'rc-virtual-list';
import './SelectBox.css';
import CheckIconV4 from "@/assets/icons/CheckIconV4";
import Badge from "../Badge/Badge";
import MagnifyGlassIcon from "@/assets/icons/MagnifyGlassIcon";

export interface ISelectBoxResult {
	label: string,
	value: string;
	__isNew__?: boolean;
}

interface ISelectBox extends BaseComponentProps {
	label?: string,
	isDisabled?: boolean,
	canBeMultiple?: boolean;
	canSearchable?: boolean;
	placeholder?: string,
	canCreatable?: boolean,
	classes?: string;
	inputName?: string;
	stateType?: UIState;
	isValueNew?: boolean;
	maxLength?: number;
	isClearable?: boolean;
	isOptional?: boolean;
	hasSearchIcon?: boolean;
	customIcon?: JSX.Element;
	customValueContainer?: boolean;
	customMenu?: boolean;
	customOption?: boolean;
	hideSelectedOptions?: boolean;
	hideOptionMessage?: boolean;
	value?: string | string[],
	onChange?: (e: any, m: any) => void;
	error?: { [key: string]: string; };
	data: { label: string, value: string; }[],
	customData?: { label: string, value: string; }[],
	isListVirtualized?: boolean;
	showTooltip?: boolean;
	showTooltipText?: string;
}

export default function SelectBox(props: ISelectBox) {
	const { width } = useWindowSize()
	const [isLabelsTruncated, setIsLabelTruncated] = React.useState(false);
	const [tooltipPlacement] = React.useState<TooltipPlacement>(TooltipPlacement.Right);

	const {
		canSearchable = true,
		canBeMultiple = false,
		isDisabled = false,
		canCreatable = false,
		maxLength = 50,
		isOptional = false,
		isClearable = false,
		isValueNew = false,
		hasSearchIcon = false,
		customIcon = <MagnifyGlassIcon />,
		customValueContainer = false,
		customMenu = false,
		customOption = false,
		hideSelectedOptions = false,
		isListVirtualized = false,
		placeholder = __('SelectBox.PlaceHolder'),
		stateType = UIState.Default,
		showTooltip = false,
	} = props;

	const hasError = props.error?.[props.name!];
	const Component = canCreatable ? CreatableSelect : Select;

	const getStringify = (option: ISelectBoxResult) => {
		if (typeof option.label === 'object') {
			return slugify(renderToString(option?.label))
		}

		if (typeof option.label === 'string') {
			return slugify(option?.label)
		}

		return '';
	}

	let model: any = {}

	if (canBeMultiple) {
		const array = props.value as string[];

		if (props.data) {
			model = array?.map(v => props.data?.find(d => d?.value === v))

			model = model?.map((v: ISelectBoxResult) => {
				return {
					value: v?.value,
					label: isLabelsTruncated ? truncateString(v?.label, 20) : v?.label
				}
			})
		}
		if (props.customData) {
			model = array?.map(v => props.customData?.find(d => d?.value === v))

			model = model?.map((v: ISelectBoxResult) => {
				return {
					value: v?.value,
					label: isLabelsTruncated ? truncateString(v?.label, 20) : v?.label
				}
			})
		}
	} else {
		model = props.data.find(d => d.value === props.value) ||
			isValueNew && { label: props.value, value: props.value }
	}

	const getNoOptionsMessage = (inputValue: string) => {
		if (props.hideOptionMessage) {
			return null;
		}

		return inputValue ? __('SelectBox.NoOption') : null;
	}

	useIsomorphicLayoutEffect(() => {
		if (width <= ScreenSizes.Small) {
			setIsLabelTruncated(true)
		} else {
			setIsLabelTruncated(false)
		}
	}, [width])

	return (
		<div className={`${props.classes}`} >
			{props.label && (
				<label className={"flex items-center gap-1 mb-2 text-shade-black text-body-semibold-b5 dark:text-gray-400"}>
					{props.label}
					{showTooltip && (
						<Tooltip
							classes={"info-tooltip"}
							name={'talent-highlights-info-tooltip'}
							placement={tooltipPlacement}
							content={props.showTooltipText ? props.showTooltipText : __("SelectBoxSkills.InfoTooltip")}
						>
							<Icon name={Icons.Info} />
						</Tooltip>
					)}
					{isOptional && (
						<span className="ml-[10px]">
							<Badge type={UIState.Success} size={UISize.Sm}>
								{__("Optional")}
							</Badge>
						</span>
					)}
				</label>
			)}
			<Component
				value={model}
				options={props.data}
				name={props.inputName}
				isMulti={canBeMultiple}
				isDisabled={isDisabled}
				placeholder={placeholder}
				isClearable={isClearable}
				classNamePrefix={"select"}
				isSearchable={canSearchable}
				onChange={e => props.onChange!(e, props.inputName)}
				formatCreateLabel={inputValue => `${inputValue}`}
				className={`select-box ${stateType} ${hasError && 'has-error'}`}
				noOptionsMessage={props => getNoOptionsMessage(props.inputValue)}
				hideSelectedOptions = {hideSelectedOptions}
				filterOption={(data, input) => getStringify(data).includes(slugify(input))}
				components={{
					MenuList: isListVirtualized ? CustomMenuList : customMenu ? CustomMenuListV2 : components.MenuList,
					Control: hasSearchIcon ? (props) => <CustomControl {...props} customIcon={customIcon} /> : components.Control,
					ValueContainer: customValueContainer ? (props) => <CustomValueContainer {...props} placeholder={placeholder} /> : components.ValueContainer,
					Option: customOption ? CustomOption : components.Option,
				}}
				onInputChange={inputValue => inputValue.length === maxLength ? inputValue.slice(0, -1) : inputValue}
			/>
			{props.error?.[props.name!] && (
				<p className={`${textColors[stateType]} absolute text-sm mt-1 font-medium`}>
					{<span className={"text-red-400"}>{__(props.error[props.name!])}</span>}
				</p>
			)}
		</div>
	);
}

interface CustomControlProps extends ControlProps<any, boolean> {
  customIcon?: JSX.Element;
}
const CustomControl: React.FC<CustomControlProps> = ({ customIcon, ...props }) => {
  return (
    <components.Control {...props}>
      <div className="flex items-center w-full gap-[10px]">
        {customIcon}
        {props.children}
      </div>
    </components.Control>
  );
};



const CustomMenuList = (props: MenuListProps) => {
	return (
		<components.MenuList {...props}>
			<List
				height={200}
				itemKey={'key'}
				itemHeight={30}
				data={props.children as []}
			>
				{(item, index) => (
					<React.Fragment key={index}>
						{item}
					</React.Fragment>
				)}
			</List>
		</components.MenuList>
	)
}

const CustomMenuListV2 = (props) => {
	return (
		<components.MenuList {...props}>
			<div style={{ padding: '10px' }}>
				<input
					type="text"
					placeholder="Search..."
					value={props.selectProps.inputValue}
					onChange={(e) => props.selectProps.onInputChange(e.target.value, { action: 'input-change' })}
					style={{ width: '100%' }}
				/>
			</div>
			{props.children}
		</components.MenuList>
	);
};

interface CustomValueContainerProps extends ValueContainerProps<unknown, boolean> {
	placeholder: string;
}


const CustomValueContainer = ({ children, placeholder, ...props }: CustomValueContainerProps) => {
	const count = props.getValue().length;

	return (
		<components.ValueContainer {...props}>
			<div className="flex flex-row items-center whitespace-nowrap">
			<div>
				{count > 0 ? (
					<span>
						{placeholder} <span className="text-primary-500">{count}</span>
					</span>
				) : (
					placeholder
				)}
			</div>
				{children && children[1]}
			</div>
		</components.ValueContainer>
	);
};


const CustomOption = (props) => {
	const { data, isSelected, innerRef } = props;
	return (
		<components.Option {...props}>
			<div ref={innerRef} {...props.innerProps} className="flex items-center justify-between">
				<span>{data.label}</span>
				{isSelected ? <CheckIconV4 /> : null}
			</div>
		</components.Option>
	);
};
