import React from 'react'

export const useToggleOptions = <T,>(initialOptions: T[], options: { selectCount: number }) => {
   const [selectedOptions, setSelectedOptions] = React.useState(initialOptions);

   const toggleOption = (optionId: T) => {
      if (selectedOptions.length === options.selectCount && !selectedOptions.includes(optionId)) {
         return;
      }

      setSelectedOptions(prevSelected =>
         prevSelected.includes(optionId)
            ? prevSelected.filter(id => id !== optionId)
            : [...prevSelected, optionId]
      );
   };

   const resetOptions = () => {
      setSelectedOptions(initialOptions);
   };

   return { selectedOptions, toggleOption, resetOptions, setSelectedOptions };
};