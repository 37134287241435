import CheckBox from "@/js/components/CheckBox/CheckBox";
import TextBox from "@/js/components/TextBox/TextBox";
import talentStore from "@/js/modules/talent/store/TalentStore";
import { __ } from "@/utils/Trans";
import { useOutletContext } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import OnboardingBottom from "../OnboardingBottom";
import React from 'react'
import { defaultTitleMaxLength } from "@/utils/Regex";
import { useSearchParams } from 'react-router-dom';
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";
import SelectBox from "@/js/components/SelectBox/SelectBox";
import positionStore from "@/js/modules/positions/store/PositionStore";
import { useQuery } from "@tanstack/react-query";

export default function OnboardingTalentTitle() {
	talentStore.initializeStore();
	const [errors, setErrors] = React.useState({})
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()
	const [searchParams] = useSearchParams()

	const handleContinueClick = () => {
		setErrors({})

		if (talentStore.state.form.lastWorkedCompany.length > defaultTitleMaxLength) {
			setErrors({
				lastWorkedCompany: talentStore.state.form.lastWorkedCompany.length > defaultTitleMaxLength
					? "Validations.DefaultTitleMaxLength"
					: ""
			})
			return
		}

		handleChangeStep()
	}

	const handlePositionChange = (e: { __isNew__?: boolean, value: string, label: string }) => {
		const position = { id: e.value, isNew: false }

		if (e.__isNew__) {
			position.isNew = true
			positionStore.addPosition(e)
			talentStore.set("positionId", position)
			return
		}

		position.isNew = false
		talentStore.set("positionId", position)
	}

	useQuery({
		queryKey: ['positions'],
		queryFn: async () => {
		  await positionStore.fetchPositions()
		  return true
		}
	  })

	return (
		<div className="flex flex-col justify-center items-center">
			<div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} py-10 px-4 flex flex-col justify-start items-center gap-7 max-w-2xl w-full xl:gap-10 xl:p-0 xl:justify-center`}>
				<h1 className={"text-header-bold-h8 md:text-header-bold-h5 text-shade-black self-start md:self-auto"}>
					{__("OnboardingPage.TalentTitle.Title")}
				</h1>
				<div className={"w-full flex flex-col justify-center gap-6"}>
					<SelectBox
						canCreatable
						canSearchable
						maxLength={50}
						data={talentStore.positions()}
						label={__("OnboardingPage.TitleInputLabel")}
						name={"positionId"}
						error={talentStore.state.errors}
						onChange={handlePositionChange}
						value={talentStore.state.form.positionId?.id}
						inputName={"positionId"}
						placeholder={__("OnboardingPage.TitleInputPlaceholder")}
						isDisabled={talentStore.state.form.isEmployed}
					/>
					<TextBox name={"txt_LastEmployedCompany"}>
						<TextBox.Label>
							{__("CreateTalentPage.LastEmployedCompany")}
						</TextBox.Label>
						<TextBox.Input
							name={"lastWorkedCompany"}
							error={errors}
							attr={{
								disabled: talentStore.state.form.isEmployed,
								onChange: e => {
									setErrors({})
									talentStore.set("lastWorkedCompany", e.target.value)
								},
								name: "lastWorkedCompany",
								value: talentStore.state.form.lastWorkedCompany,
								maxLength: 200,
								placeholder: __("CreateTalentPage.LastEmployedCompanyPlaceholder")
							}}
						/>
					</TextBox>
				</div>
				<div className={"self-start"}>
					<CheckBox className={"flex-row-reverse"}>
						<CheckBox.Label htmlFor={"isEmployed"}>
							<span className={"text-body-regular-b5 text-shade-black"}>
								{__("CreateTalentPage.NotEmployed")}
							</span>
						</CheckBox.Label>
						<CheckBox.Input
							id={"isEmployed"}
							name={"isEmployed"}
							attrs={{
								type: "checkbox",
								name: "isEmployed",
								checked: talentStore.state.form.isEmployed,
								onChange: e => talentStore.set("isEmployed", e.target.checked),
							}}
						/>
					</CheckBox>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={
					(
						talentStore.state.form.isEmployed === false && (
							!talentStore.state.form.positionId ||
							!talentStore.state.form.lastWorkedCompany
						)
					) &&
					!talentStore.state.form.isEmployed
				}
			/>
		</div>
	)
}
