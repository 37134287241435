import { PageRoutes } from '@/utils/PageRoutes'
import { Outlet, useLocation } from 'react-router-dom'
import CookieConsentBanner from '@/js/modules/landing/components/CookieConsentBanner'
import PublicNavbar from '@/js/modules/landing/components/PublicNavbar'
import LandingFooter from '@/js/modules/landing/components/LandingFooter'
import clsx from 'clsx'
import blokTabStore from '@/js/modules/bloks/store/BlokTabStore'
interface ILandingLayout {
	hideFooter?: boolean
}

export const landingMenuItems = [
	{
		to: PageRoutes.LandingForCompanies,
		name: "For Employers",
		translation: "TopBar.Landing.ForEmployers",
		openInNewTab: false
	},
	{
		to: PageRoutes.LandingForCommunities,
		name: "For Communities",
		translation: "TopBar.Landing.ForCommunities",
		openInNewTab: false
	},
	{
		to: PageRoutes.DWSPlus,
		name: "DWS+",
		translation: "TopBar.Landing.DWS+",
		openInNewTab: false
	},
]

const landingRoutes = [
	PageRoutes.Index,
	PageRoutes.LandingForCommunities,
	PageRoutes.LandingForCompanies,
	PageRoutes.FAQ,
	PageRoutes.RequestDemo,
	PageRoutes.Contact,
	PageRoutes.LandingPageForTalents,
	PageRoutes.AboutUs,
	PageRoutes.DWSPlus
]

export default function LandingLayout(props: ILandingLayout) {
	const location = useLocation()
	const isBlokPages = location.pathname.includes('blok');

	const isLandingPages = landingRoutes.some(route => route.includes(location.pathname as PageRoutes))

	return (
		<>
			<div className={`${!isBlokPages && 'min-h-screen overflow-x-hidden'}`}>
				<header className={`w-full ${isBlokPages && 'sticky top-0 z-20'}`}>
					<PublicNavbar />
				</header>
				<main
					className={clsx({
						'bg-gray-50': isBlokPages,
						'max-w-screen-2xl mx-auto': blokTabStore.isVisitor() && !isLandingPages,
					})}
				>
					<Outlet />
				</main>
				{props.hideFooter || <LandingFooter />}
			</div>
			<CookieConsentBanner />
		</>
	)
}
