import { tailwindColors } from "@/plugins/tailwind";
import React from "react";

function JobServiceIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none">
      <circle cx="17.5" cy="17.5" r="17.5" fill={tailwindColors['secondary']['600']}></circle>
      <path
        fill="#fff"
        d="M18.55 20.5h-2.1c-.577 0-1.05-.45-1.05-1H8.06v4c0 1.1.945 2 2.1 2h14.69c1.155 0 2.1-.9 2.1-2v-4H19.6c0 .55-.473 1-1.05 1zm7.35-9h-4.2c0-2.21-1.88-4-4.2-4-2.32 0-4.2 1.79-4.2 4H9.1c-1.155 0-2.1.9-2.1 2v3c0 1.11.934 2 2.1 2h6.3v-1c0-.55.473-1 1.05-1h2.1c.578 0 1.05.45 1.05 1v1h6.3c1.155 0 2.1-.9 2.1-2v-3c0-1.1-.945-2-2.1-2zm-10.5 0c0-1.1.945-2 2.1-2 1.155 0 2.1.9 2.1 2h-4.21.01z"
      ></path>
    </svg>
  );
}

export default JobServiceIcon;
