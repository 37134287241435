const PencilIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      width="21"
      height="21"
      fill="currentColor"
      viewBox="0 0 21 21"
    >
      <g fill="currentColor" clipPath="url(#clip0_1043_32889)">
        <path d="M4.848 13.328l-.827 3.577a.9.9 0 00.87 1.09.93.93 0 00.188 0l3.59-.828 6.896-6.884-3.848-3.848-6.87 6.893zM17.733 6.836l-2.568-2.573a.9.9 0 00-1.271 0l-1.428 1.43 3.844 3.853 1.428-1.431a.902.902 0 00-.005-1.279z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1043_32889">
          <path fill="#fff" d="M0 0H21V21H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default PencilIcon;
