import BaseService from "@/js/core/BaseService";
import { BlokEndpoints } from "@/js/modules/bloks/services/BlokEndpoints";
import { JobEndpoints } from "@/js/modules/jobs/services/JobEndpoints";

export default class BlokJobPostService extends BaseService {
  async fetchBloksByJobPost(jobPostId) {
    return this.api().get(BlokEndpoints.FetchBloksByJobPost.replace(':id', jobPostId));
  }
  async updateBloksByJobPostId(jobPostId, payload) {
    return this.api().put(JobEndpoints.UpdateBloksByJobPostId.replace(':id', jobPostId), payload);
  }
}
