import { BaseComponentProps } from '@/utils/BaseComponentProps';
import React from 'react';
import { UIState } from '@/utils/Enums';
import { InputType } from '@/js/components/Input/InputType';

interface IInput extends BaseComponentProps {
    id: string,
    attrs: React.InputHTMLAttributes<HTMLInputElement>,
    stateType?: UIState,
    error?: { [key: string]: string; };
};


function CheckBox({ children, className = '' }: { children: React.ReactNode; className?: string }) {
    return <div className={`${className} flex items-center gap-2`}>{children}</div>;
}

function Label({ children, htmlFor }: { children: React.ReactNode; htmlFor: string; }) {
    return <label className={"text-sm font-medium leading-4 text-gray-500 cursor-pointer"} htmlFor={htmlFor}>
        {children}
    </label>;
}

function Input(props: IInput) {
    return <input
        {...props.attrs}
        id={props.id}
        type={InputType.CheckBox}
        className={`${props.error?.[props.attrs.name!] ? 'border-red-400' : 'border-shade-stroke'} w-5 h-5 text-violet-500 cursor-pointer border-shade-stroke rounded focus:ring-transparent`}
    />;

}

CheckBox.Input = Input;
CheckBox.Label = Label;


export default CheckBox;
