import BaseStore from "@/js/core/BaseStore";
import { ISectorStore } from "@/js/modules/sectors/store/SectorTypes";
import SectorService from "@/js/modules/sectors/services/SectorService";

const sectorStore = {
  sectors: [],
}

class SectorsStore extends BaseStore<ISectorStore, SectorService> {
  async fetchSectors() {
    const { data } = await this.service.fetchSectors();
    this.state.sectors = data.result;
  }

	sectors() {
		const sectors = this.state.sectors;
		return this.optionsMapper(sectors);
	}

	optionsMapper(options) {
		return options.map(option => {
			return { label: option.name, value: option.id };
		});
	}
}

export default new SectorsStore(sectorStore, SectorService);
