import { UIState } from '@/utils/Enums';
import { __ } from '@/utils/Trans';
import React from 'react'
import { textColors } from '../TextBox/constants/textBoxConstants';

interface CustomErrorProps extends React.PropsWithChildren {
    name: string,
    stateType?: UIState,
    error?: { [key: string]: string; };
    customErrorMessage?: string;
    classes?: string;
}

const CustomError = (props: CustomErrorProps) => {
    const { stateType = UIState.Default } = props;

    const errorMessage = props.customErrorMessage || __(props?.error![props.name!]) || '';

    return (
        <>
            {props.error?.[props.name!] && (
                <p className={`${textColors[stateType]} text-sm absolute mt-1 font-medium ${props.classes}`}>
                    {<span className={"text-error-400"}>{errorMessage}</span>}
                </p>
            )}
        </>
    )
}

export default CustomError
