import { Environments } from "@/utils/Enums";

if (process.env.NODE_ENV === Environments.Production) {
   const script = document.createElement('script');

   script.innerHTML = `
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      (function () {
         var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
         s1.async = true;
         s1.src = 'https://embed.tawk.to/6482fc77cc26a871b0218927/1h2fqkd5s';
         s1.charset = 'UTF-8';
         s1.setAttribute('crossorigin', '*');
         s0.parentNode.insertBefore(s1, s0);
      })();
   `
   document.head.appendChild(script)
}
