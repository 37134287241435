import React from 'react'
import './TopBarSearch.css'
import { Combobox, Transition } from '@headlessui/react'
import Icon from '@/js/components/Icon/Icon'
import { Icons } from '@/utils/Icons'
import { __ } from '@/utils/Trans'
import { InputType } from '@/js/components/Input/InputType'
import TopBarSearchNoResult from './TopBarSearchNoResult'
import SearchIcon from '@/assets/icons/SearchIcon'

const classNames = (...classes: (string | boolean)[]) => {
    return classes.filter(Boolean).join(' ')
}

const TopBarSearch = () => {
    const [query, setQuery] = React.useState('')

    const handleResetQuery = () => setQuery('')

    const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value)
    }

    const items = [
        { id: 1, name: 'Workflow Inc.', category: __('TopBarNavigationSearchBox.Notices'), url: '#', icon: Icons.Megaphone },
        { id: 2, name: 'Workflow Inc.', category: __('TopBarNavigationSearchBox.Notices'), url: '#', icon: Icons.Megaphone },
        { id: 3, name: 'Workflow Inc.', category: __('TopBarNavigationSearchBox.Notices'), url: '#', icon: Icons.Megaphone },
        { id: 4, name: 'Workflow Inc.', category: __('TopBarNavigationSearchBox.MyCandidates'), url: '#', icon: Icons.CandidateZoom },
        { id: 5, name: 'Workflow Inc.', category: __('TopBarNavigationSearchBox.MyCandidates'), url: '#', icon: Icons.CandidateZoom },
        { id: 6, name: 'Workflow Inc.', category: __('TopBarNavigationSearchBox.MyCandidates'), url: '#', icon: Icons.CandidateZoom },
        { id: 7, name: 'Workflow Inc.', category: __('TopBarNavigationSearchBox.Companies'), url: '#', icon: Icons.Building },
        { id: 8, name: 'Workflow Inc.', category: __('TopBarNavigationSearchBox.Companies'), url: '#', icon: Icons.Building },
        { id: 9, name: 'Workflow Inc.', category: __('TopBarNavigationSearchBox.Companies'), url: '#', icon: Icons.Building },
    ]

    const filteredItems = query === '' ? [] : items.filter((item) => {
        return item.name.toLowerCase().includes(query.toLowerCase())
    })

    const groups = filteredItems.reduce((groups, item) => {
        return { ...groups, [item.category]: [...(groups[item.category] || []), item] }
    }, {})

    return (
        <Combobox className={"w-full max-w-full md:max-w-[350px]"} as={'div'}>
            <div className={"relative z-40"}>
                <div className={"w-full ml-2 lg:ml-0"}>
                    <div className={"relative"}>
                        <div className={"absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"}>
                            <SearchIcon />
                        </div>
                        <Combobox.Input
                            name={"search"}
                            value={query}
                            onChange={handleQueryChange}
                            onBlur={handleResetQuery}
                            type={InputType.Search}
                            autoComplete={"off"}
                            className={`w-full text-gray-700 transition-all h-12 duration-200 ease-in-out py-2 pl-10 pr-3 text-sm placeholder-gray-200 bg-white border border-gray-200 rounded-xl focus:border-gray-200 focus:outline-none focus:ring-0 focus:ring-gray-200 sm:text-sm`}
                            placeholder={__("TopBar.SearchPlaceholder")}
                        />
                        <Transition.Root as={React.Fragment} afterLeave={handleResetQuery} appear>
                            <Transition.Child
                                as={'div'}
                                enter={"ease-out duration-200"}
                                enterFrom={"opacity-0 scale-95"}
                                enterTo={"opacity-100 scale-100"}
                                leave={"ease-in duration-200"}
                                leaveFrom={"opacity-100 scale-100"}
                                leaveTo={"opacity-0 scale-95"}
                            >
                                {filteredItems.length > 0 && (
                                    <Combobox.Options static className="absolute w-full pt-3 -mt-3 space-y-2 overflow-y-auto bg-white border border-t-0 border-gray-200 shadow rounded-b-xl scroll-pt-11 scroll-pb-2">
                                        {Object.entries(groups).map(([category, items]: any[], idx) => (
                                            <li key={category} >
                                                <h2 className="bg-gray-50 py-2.5 gap-2 flex items-center px-4 text-xs font-semibold text-gray-500">
                                                    <Icon name={items[idx].icon} color="text-gray-200" />
                                                    {category}
                                                </h2>
                                                <ul className="mt-2 text-sm text-gray-800">
                                                    {items.map((item: any) => (
                                                        <Combobox.Option
                                                            key={item.id}
                                                            value={item}
                                                            className={({ active }) =>
                                                                classNames('cursor-pointer text-gray-700  select-none px-4 py-2', active && 'bg-violet-500/90 text-white')
                                                            }
                                                        >
                                                            {item.name}
                                                        </Combobox.Option>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </Combobox.Options>
                                )}
                                {query !== '' && filteredItems.length === 0 && <TopBarSearchNoResult />}
                            </Transition.Child>
                        </Transition.Root>
                    </div>
                </div>
            </div>
        </Combobox>
    )
}

export default TopBarSearch
