import React from 'react';
import { BlokOwnerInterests } from "@/utils/Enums";
import { useOutletContext } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import { useToggleOptions } from "../../hooks/useToggleOptions/useToggleOptions";
import { Trans } from "react-i18next";
import { __ } from "@/utils/Trans";
import SelectionBox from "../SelectionBox";
import OnboardingBottom from "../OnboardingBottom";
import blokStore from "@/js/modules/bloks/store/BlokStore";

const blokOwnerOptionsData = [
	{ id: BlokOwnerInterests.SupportCommunityCareerGrowth, label: "OnboardingPage.BlokOwnerSelectInterest.FirstOption" },
	{ id: BlokOwnerInterests.GrowCommunity, label: "OnboardingPage.BlokOwnerSelectInterest.SecondOption" },
	{ id: BlokOwnerInterests.SellService, label: "OnboardingPage.BlokOwnerSelectInterest.ThirdOption" },
	{ id: BlokOwnerInterests.ReferTalentToJobs, label: "OnboardingPage.BlokOwnerSelectInterest.FourthOption" },
	{ id: BlokOwnerInterests.ShareConent, label: "OnboardingPage.BlokOwnerSelectInterest.FifthOption" },
	{ id: BlokOwnerInterests.CreateEvent, label: "OnboardingPage.BlokOwnerSelectInterest.SixthOption" }
];

export default function OnboardingBlokOwnerInterests() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()
	const { selectedOptions, toggleOption } = useToggleOptions<string>([], { selectCount: 2 });

	const handleOnContinueClick = () => {
		blokStore.set('interests', selectedOptions)
		handleChangeStep()
	}

	const handleSetLocalstorageData = React.useCallback(() => {
		const interests = blokStore.state.form.interests;

		if (interests) {
			Object.values<string>(interests)
				.filter(item => typeof item === 'string' && item.trim() !== '')
				.forEach(item => {
					toggleOption(item);
				});
		}
	}, [])

	React.useEffect(() => {
		handleSetLocalstorageData()
	}, [handleSetLocalstorageData])

	return (
		<div className="flex flex-col justify-center items-center ">
			<div className={`px-4 flex flex-col justify-start items-center gap-8 max-w-2xl w-full xl:gap-10 xl:p-0 xl:justify-center`}>
				<div className="onboarding-full-height w-full flex flex-col">
					<div className="no-scrollbar overflow-y-auto py-10 w-full">
						<div className="flex flex-col items-start w-full gap-[10px] md:gap-4 md:items-center">
							<h1 className={"text-header-bold-h8 text-shade-black w-full md:text-center md:text-header-bold-h5"}>
								{__("OnboardingPage.BlokOwnerSelectInterest.Title")}
							</h1>
							<p className="text-center text-body-regular-b5">
								<Trans
									i18nKey={'OnboardingPage.BlowOwnerSelectInterest.Subtitle'}
									components={{
										bold: <span className={"text-violet-500 text-body-bold-b5"} />,
									}}
								/>
							</p>
						</div>
						<div className="flex flex-wrap md:justify-center md:items-center justify-items-start items-start gap-4 pt-10">
							{blokOwnerOptionsData.map(option => (
								<SelectionBox
									rounded
									key={option.id}
									onClick={() => toggleOption(option.id)}
									isSelected={selectedOptions.includes(option.id)}
									classes={"!p-4 !inline-flex !items-center !gap-2.5 !w-auto"}
								>
									{__(option.label)}
								</SelectionBox>
							))}
						</div>
					</div>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleOnContinueClick}
				disabled={selectedOptions.length < 2}
			/>
		</div>
	);
}
