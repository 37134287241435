import { useOutletContext, useSearchParams } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import { __ } from "@/utils/Trans";
import OnboardingBottom from "../OnboardingBottom";
import TextArea from "@/js/components/TextArea/TextArea";
import SelectBox from "@/js/components/SelectBox/SelectBox";
import companyStore from "@/js/modules/company/store/CompanyStore";
import AddSocialMediaLinkInfo from "@/js/modules/socialMedias/components/AddSocialMediaLinkInfo";
import { useQuery } from "@tanstack/react-query";
import socialAccountStore from "@/js/modules/socialMedias/store/SocialAccountStore";
import { validateSocialUrls } from "@/js/helpers/validateSocialUrls";
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";

export default function OnboardingCompanyInfo() {
  const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>();
  const [searchParams] = useSearchParams()

  const handleOnContinueClick = () => {
    companyStore.resetErrors()

    const errors = validateSocialUrls(companyStore.state.form.socialAccounts)
    companyStore.state.errors = errors

    if (!!Object.keys(errors).length) return;

    handleChangeStep();
  };

  const handleOnSocialMediaChange = (e: unknown) => {
    companyStore.set("socialAccounts", e);
    companyStore.resetSocialMediaErrors();
  };

  useQuery({
    queryKey: ['social-accounts'],
    queryFn: async () => {
      await socialAccountStore.fetchSocialAccounts()
      return true
    }
  })

  return (
    <div className="flex flex-col justify-center items-center ">
      <div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} no-scrollbar max-w-2xl w-full overflow-y-auto py-10 px-4 md:justify-center`}>
        <h1 className="text-header-bold-h8 w-full text-shade-black md:text-header-bold-h5 md:w-auto md:text-center">
          {__("OnboardingPage.CompanyInfo.Title")}
        </h1>
        <div className="flex flex-col justify-center items-center">
          <div className="w-full pt-6 md:pt-10">
            <TextArea
              attrs={{
                name: 'description',
                maxLength: 1000,
                onChange: e => companyStore.set('description', e.target.value),
                value: companyStore.state.form.description,
              }}
              rowCount={5}
              name={"description"}
              label={__('CompanyPage.About')}
              placeholder={__('CompanyPage.AboutPlaceholder')}
              error={companyStore.state.errors}
            />
          </div>
          <SelectBox
            name={"foundingDate"}
            inputName={"foundingDate"}
            error={companyStore.state.errors}
            data={companyStore.createYears()}
            label={__('CompanyPage.Founded')}
            classes={'w-full pb-6 md:pb-10 md:pt-8'}
            value={companyStore.state.form.foundingDate}
            onChange={e => companyStore.set('foundingDate', e.value)}
            placeholder={__('CompanyPage.FoundedPlaceholder')}
          />
          <div className="w-full">
            <AddSocialMediaLinkInfo
              errors={companyStore.state.errors}
              socialAccounts={companyStore.state.form.socialAccounts}
              onChange={handleOnSocialMediaChange}
              providers={companyStore.getSocialMediaProviders()}
            />
          </div>
        </div>
      </div>
      <OnboardingBottom
        onClick={handleOnContinueClick}
        disabled={!companyStore.state.form.description || !companyStore.state.form.foundingDate}
      />
    </div>
  );
}
