import { useToggle } from "@/js/hooks/useToggle/useToggle"
import TopBarMobileMenu from "./mobile/TopBarMobileMenu"
import TopBarMobileMenuButton from "./mobile/TopBarMobileMenuButton"
import React from 'react'
import TopNavBarNotification from "./TopNavBarNotification"
import TopBarSearch from "./search/TopBarSearch"
import { Icons } from "@/utils/Icons"
import { useWindowSize } from "@/js/hooks/useWindowSize/useWindowSize"
import { ScreenSizes } from "@/utils/ScreenSizes"
import TopNavBarMenu from "./TopNavBarMenu"
import { useGetChildByDisplayName } from "@/js/hooks/useGetChildByDisplayName/useGetChildByDisplayName"
import Author from "./author/Author"
import DWSLogo from "@/js/components/DWS/DWSLogo"
import { useLocation, useNavigate } from "react-router-dom";
import { ScreenModes } from "@/utils/Enums"
import TopNavBarChangeLanguage from "./TopNavBarChangeLanguage"
import { useOnClickOutside } from "@/js/hooks/useOnClickOutside/useOnClickOutside"
import clsx from "clsx"
import NotificationBar from "@/js/components/NotificationBar/NotificationBar"
import MessageIcon from "@/assets/icons/MessageIcon"
import { PageRoutes } from "@/utils/PageRoutes"

interface ITopBar extends React.PropsWithChildren {
	children?: React.ReactNode
	isSearchable?: boolean
	hasNotifications?: boolean
	hasMessages?: boolean
	useLong?: boolean
	useOnlyLogo?: boolean
	hasTranslation?: boolean
	forceRedirect?: boolean
}

const TopNavBar = (props: ITopBar) => {
	const { children, isSearchable = true, hasNotifications = true, forceRedirect = false } = props

	const ref = React.useRef<HTMLDivElement>(null)

	const location = useLocation();
	const { width } = useWindowSize()
	const navigate = useNavigate();

	const [isMobileMenuOpen, toggleIsMobileMenuOpen, setIsMobileMenuOpen] = useToggle(false)

	const { ActionSection, AuthorSection, MenuSection } = useGetChildByDisplayName(children,
		["MenuSection", "ActionSection", "AuthorSection"]
	)

	useOnClickOutside(ref, () => setIsMobileMenuOpen(false))

	React.useEffect(() => {
		if (width >= ScreenSizes.XLarge) {
			setIsMobileMenuOpen(false)
		}
	}, [width, setIsMobileMenuOpen])

	React.useEffect(() => {
		setIsMobileMenuOpen(false)
		window.scrollTo(0, 0);
	}, [location.pathname, setIsMobileMenuOpen]);

	return (
		<>
			<nav
				ref={ref}
				className={clsx(
					`bg-white border-b border-gray-100 z-50 print:hidden px-4`,
					{
						"sticky top-0 z-20": location.pathname.includes('post'),
						"max-w-screen-2xl mx-auto": !location.pathname.includes('onboarding'),
					}
				)}
			>
				<div className={"max-w-full mx-auto"}>
					<div className={"flex items-center justify-between h-24"}>
						<div className={"flex items-center w-full gap-0 lg:gap-6 flex-1"}>
							<div className={"flex items-center flex-shrink-0"}>
								<DWSLogo forceRedirect={forceRedirect} useLong={props.useLong || false} />
							</div>
							{isSearchable && <TopBarSearch />}
						</div>
						<div className={"flex h-full"}>
							<div className={"hidden xl:flex h-full items-center"}>
								{MenuSection}
								{ActionSection}
							</div>
							{props.hasTranslation && (
								<TopNavBarChangeLanguage screenMode={ScreenModes.Desktop} />
							)}
							{!props.useOnlyLogo && (
								<>
									<button onClick={() => navigate(PageRoutes.MessagesPage)} type={"button"} className={"flex items-center xl:hidden"}>
										<MessageIcon hasMessages={props.hasMessages} />
									</button>
									<TopBarMobileMenuButton
										isMobileMenuOpen={isMobileMenuOpen}
										toggleMobileMenu={toggleIsMobileMenuOpen} />
								</>
							)}
							<div className={"hidden xl:flex md:flex-shrink-0 md:items-center"}>
								{hasNotifications && <TopNavBarNotification />}
								{AuthorSection}
							</div>
						</div>
					</div>
				</div>
				{isMobileMenuOpen && (
					<TopBarMobileMenu
						hasNotifications={hasNotifications}
						actionButton={ActionSection?.props?.children}
						menuItems={MenuSection?.props?.menuItems || []}
						dropdownItems={AuthorSection?.props?.dropdownItems || []}
					/>
				)}
			</nav>
			<NotificationBar />
		</>
	)
}

interface IMenuSection extends React.PropsWithChildren {
	menuItems: {
		to: string,
		name: string,
		translation: string
		openInNewTab?: boolean
		forceRedirect?: boolean
		element?: React.ReactNode | JSX.Element
	}[]
}

const MenuSection = (props: IMenuSection) => {
	return <TopNavBarMenu items={props.menuItems} />;
}

interface IActionSection extends React.PropsWithChildren { }

const ActionSection = (props: IActionSection) => {
	return <div className={'ml-6 h-full'}>{props.children}</div>
}

interface IUserSection {
	hasMessages?: boolean;
	dropdownItems?: {
		to: string,
		name: string,
		translation: string,
		icon: Icons,
		type?: string;
	}[]
}

const AuthorSection = (props: IUserSection) => {
	const { dropdownItems = [] } = props
	return <Author dropdownItems={dropdownItems} hasMessages={props.hasMessages} />
}

MenuSection.displayName = "MenuSection"
ActionSection.displayName = "ActionSection"
AuthorSection.displayName = "AuthorSection"

TopNavBar.MenuSection = MenuSection
TopNavBar.ActionSection = ActionSection
TopNavBar.AuthorSection = AuthorSection

export default TopNavBar
