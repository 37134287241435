export default function MedalDoodle() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="36"
         height="36"
         fill="none"
         viewBox="0 0 36 36"
      >
         <path
            fill="#D64751"
            d="M10.647 15.564L2.785 30.376l7.14-.66L13.38 36l7.862-14.812-10.595-5.623z"
         ></path>
         <path
            fill="#AB3941"
            d="M11.547 31.686c.22-.474-.01-1.04-.472-1.285l-.452-.241a.968.968 0 01.93-1.697l1.28.68c.395.209.89.057 1.094-.34l.031-.062a.763.763 0 00-.294-1.075l-3.824-2.03c-.466-.247-.695-.811-.478-1.286a.97.97 0 011.333-.446l3.548 1.883c.395.21.89.057 1.1-.338l.02-.052.005-.01a.85.85 0 00-.35-1.157l-1.99-1.055c-.466-.248-.694-.812-.473-1.293a.974.974 0 011.332-.445l2.198 1.167c.411.218.902.036 1.114-.378l.006-.011.006-.012c.224-.408.1-.917-.312-1.134l-2.372-1.259c-.46-.244-.688-.808-.471-1.283a.97.97 0 011.333-.445l5.85 3.105-7.862 14.812-2.166-3.938a.92.92 0 00.336-.375z"
         ></path>
         <path
            fill="#AB3941"
            d="M25.353 15.564l7.862 14.812-7.14-.66L22.62 36l-7.862-14.812 10.595-5.623z"
         ></path>
         <path
            fill="#D64751"
            d="M24.453 31.686c-.22-.474.01-1.04.471-1.285l.453-.241a.968.968 0 00-.93-1.697l-1.28.68a.807.807 0 01-1.094-.34l-.031-.062a.763.763 0 01.294-1.075l3.825-2.031c.466-.248.695-.812.478-1.287a.97.97 0 00-1.333-.445l-3.548 1.883a.816.816 0 01-1.1-.338l-.02-.052-.005-.01a.85.85 0 01.35-1.157l1.99-1.055c.466-.248.694-.812.474-1.293a.974.974 0 00-1.333-.445l-2.2 1.17c-.411.217-.902.035-1.115-.38l-.005-.01-.006-.012c-.224-.408-.1-.917.312-1.134l2.371-1.259c.46-.244.69-.808.472-1.283a.97.97 0 00-1.333-.445l-5.85 3.105L22.622 36l2.166-3.938a.937.937 0 01-.335-.376z"
         ></path>
         <path
            fill="#FFCC5B"
            d="M18 26.272c7.255 0 13.136-5.881 13.136-13.136C31.136 5.881 25.255 0 18 0 10.745 0 4.864 5.881 4.864 13.136c0 7.255 5.881 13.136 13.136 13.136z"
         ></path>
         <path
            fill="#FDBC4B"
            d="M19.782 11.629h-8.325a1.183 1.183 0 01-1.178-1.178l.009-.082v-.018A1.23 1.23 0 0111.52 9.11h5.77c.764 0 1.44-.566 1.485-1.331a1.41 1.41 0 00-1.404-1.476h-6.112c-.674 0-1.178-.566-1.17-1.242v-.018-.018c-.008-.674.495-1.241 1.17-1.241h6.396c.755 0 1.43-.566 1.475-1.322A1.405 1.405 0 0017.727.986h-4.684c-4.775 1.969-8.14 6.664-8.14 12.15 0 5.486 3.365 10.18 8.141 12.147h5.947c.755 0 1.43-.566 1.475-1.323a1.404 1.404 0 00-1.403-1.474l-6.765-.001a1.4 1.4 0 01-1.398-1.476c.04-.756.72-1.325 1.477-1.325h2.622a1.403 1.403 0 001.364-1.399c0-.773-.63-1.403-1.404-1.403h-3.975c-.648 0-1.179-.53-1.17-1.179v-.099a1.107 1.107 0 011.107-1.178h8.78c.764 0 1.44-.566 1.484-1.323a1.406 1.406 0 00-1.403-1.474z"
         ></path>
         <path
            fill="#FFDB70"
            d="M18 22.512c-5.17 0-9.377-4.207-9.377-9.377 0-5.17 4.206-9.376 9.377-9.376 5.17 0 9.377 4.207 9.377 9.377 0 5.17-4.206 9.376-9.377 9.376z"
         ></path>
         <path
            fill="#fff"
            d="M27.377 13.136c0-4.988-3.92-9.068-8.839-9.35h-.873c-.577 0-1.091.432-1.126 1.009a1.073 1.073 0 001.07 1.125l5.16.001c.609 0 1.098.51 1.066 1.126-.03.577-.548 1.01-1.127 1.01h-2a1.07 1.07 0 00-1.04 1.068c0 .59.48 1.07 1.07 1.07h3.033c.494 0 .898.405.891.898v.075a.844.844 0 01-.844.898h-6.696c-.583 0-1.097.432-1.132 1.008a1.073 1.073 0 001.07 1.126h6.348c.494 0 .898.405.898.898l-.006.062v.013a.939.939 0 01-.94.947h-4.401c-.583 0-1.097.432-1.132 1.015-.027.61.459 1.125 1.07 1.125h4.66c.515 0 .9.433.892.947v.027c.007.514-.377.947-.891.947H18.68c-.576 0-1.09.432-1.126 1.008a1.072 1.072 0 001.07 1.126h1.287c4.258-.887 7.465-4.666 7.465-9.179z"
            opacity="0.2"
         ></path>
         <path
            fill="#EC9922"
            d="M24.546 11.06h-4.56l-1.411-4.338c-.181-.558-.97-.558-1.151 0l-1.41 4.337h-4.56c-.587 0-.83.751-.356 1.096l3.69 2.68-1.41 4.338c-.18.558.457 1.021.932.677L18 17.169l3.69 2.68c.474.345 1.112-.119.931-.676l-1.409-4.337 3.69-2.681c.473-.345.23-1.096-.356-1.096z"
         ></path>
      </svg>
   )
}