import { useOutletContext, useSearchParams } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import { __ } from "@/utils/Trans";
import OnboardingBottom from "../OnboardingBottom";
import SelectBox from "@/js/components/SelectBox/SelectBox";
import companyStore from "@/js/modules/company/store/CompanyStore";
import { useQuery } from "@tanstack/react-query";
import sectorStore from "@/js/modules/sectors/store/SectorStore";
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";

export default function OnboardingCompanyIndustry() {
  const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>();
  const [searchParams] = useSearchParams()

  const handleOnContinueClick = () => {
    handleChangeStep();
  };

  useQuery({
    queryKey: ["sectors"],
    queryFn: async () => {
      await sectorStore.fetchSectors()
      return true
    },
  })

  return (
    <div className="flex flex-col justify-center items-center ">
      <div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} max-w-2xl w-full flex-col justify-start items-center gap-8 xl:gap-10 inline-flex md:justify-center xl:p-0 py-10 px-4`}>
        <h1 className="text-header-bold-h8 w-full text-shade-black md:text-header-bold-h5 md:w-auto md:text-center">
          {__("OnboardingPage.CompanyIndustry.Title")}
        </h1>
        <div className="self-stretch h-12 flex-col justify-start items-start gap-2.5 flex">
          <SelectBox
            maxLength={35}
            name={"sectorId"}
            inputName={"sectorId"}
            error={companyStore.state.errors}
            value={companyStore.state.form.sectorId}
            classes={'w-full'}
            canSearchable={true}
            onChange={e => companyStore.set('sectorId', e.value)}
            data={companyStore.getSectors()}
            label={__('CompanyPage.Sector')}
            placeholder={__('CompanyPage.SectorPlaceholder')}
          />
        </div>
      </div>
      <OnboardingBottom
        onClick={handleOnContinueClick}
        disabled={!companyStore.state.form.sectorId}
      />
    </div>
  );
}
