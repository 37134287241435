
const BellIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
            <g clipPath="url(#a)">
                <path
                    fill="#7F8B9F"
                    d="M10.001 21.5c1.015 0 1.846-.83 1.846-1.846H8.155a1.846 1.846 0 001.846 1.846zm5.537-5.539v-4.615c0-2.834-1.513-5.206-4.153-5.834v-.627c0-.767-.618-1.385-1.384-1.385-.766 0-1.384.618-1.384 1.385v.627c-2.649.628-4.153 2.991-4.153 5.834v4.615l-1.19 1.191c-.582.582-.176 1.579.646 1.579h12.153c.822 0 1.237-.997.656-1.579l-1.19-1.19z"
                ></path>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h21v21H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default BellIcon
