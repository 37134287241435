import BaseStore from "@/js/core/BaseStore";
import { IExpertiseStore } from "./ExpertiseTypes";
import ExpertiseService from "../services/ExpertiseService";

const expertiseStore = {
	expertises: [],
}

class ExpertisesStore extends BaseStore<IExpertiseStore, ExpertiseService> {
	async fetchExpertises() {
		const { data } = await this.service.fetchExpertises();
		this.state.expertises = data.result;
	}
}

export default new ExpertisesStore(expertiseStore, ExpertiseService);
