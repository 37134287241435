export const validateSocialUrls = (socialAccounts) => {
	const validPatterns = ['https://', 'http://']
	const supportedPlatforms = ['Spotify', 'Reddit', 'Twitch', 'Behance', 'Dribbble', 'Medium', 'Discord', 'Gitlab', 'Github', 'Youtube', 'Telegram', 'Whatsapp', 'Slack', 'Facebook', 'Instagram', 'Twitter', 'LinkedIn'];

	return socialAccounts.reduce((acc, account, index) => {
		if (!validPatterns.some(pattern => account.url.startsWith(pattern))) {
			return {
				...acc,
				[`url-${index}`]: "Validations.IsUrlStartsWith"
			}
		}

		const platform = supportedPlatforms.find(platform => account.url.includes(platform.toLowerCase()));
		if (platform) {
			if (!account.url.includes(platform.toLowerCase() + '.com')) {
				return {
					...acc,
					[`url-${index}`]: "Validations.IsPlatformUrl"
				};
			}
		}


		return acc
	}, {})
}
