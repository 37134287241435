import BaseStore from "@/js/core/BaseStore";
import { IAdminStore } from "@/js/modules/admin/store/AdminTypes";
import AdminService from "@/js/modules/admin/services/AdminService";
import { RowStates } from "@/js/core/enums/RowStates";
import { AccountTypes, DwsStatus, UIState } from '@/utils/Enums';
import { __ } from "@/utils/Trans";
import rewardJobsStore from "@/js/modules/jobs/store/RewardJobsStore";
import dayjs from "dayjs";
import slugify from '@/js/helpers/slugify';
import { BlokTalentStates } from "../pages/AdminBlokTalentListPage";
import { BlokCompaniesStates } from "../pages/AdminBlokCompaniesListPage";
import { AdminUserListFilter } from "../pages/AdminUserListPage";

const adminStore: IAdminStore = {
  users: [],
  bloks: [],
  jobPosts: [],
  jobPostsBloks: [],
  blogPosts: [],
  blokTalents: [],
  blokCompanies: [],
  jobPostTalents: [],
  companies: [],
}

class AdminStore extends BaseStore<IAdminStore, AdminService> {
  initializeStore() {
    return this.useStore();
  }

  async fetchJobPostTalents() {
    try {
      this.state.isLoading = true;
      const response = await this.service.fetchJobPostTalents();
      this.state.jobPostTalents = response.data.result;
    } finally {
      this.state.isLoading = false;
    }
  }

  async fetchCompanies() {
    try {
      this.state.isLoading = true;
      const response = await this.service.fetchCompanies();
      this.state.companies = response.data.result;
    } finally {
      this.state.isLoading = false;
    }
  }

  async fetchBloks(state: string) {
    try {
      this.state.isLoading = true;
      const response = await this.service.fetchBloks(state);
      this.state.bloks = response.data.result;
    } finally {
      this.state.isLoading = false;
    }
  }

  async fetchUsers(accountType: string, state: string) {

    if (accountType === AdminUserListFilter.All) accountType = ''
    if (state === AdminUserListFilter.All) state = ''

    try {
      this.state.isLoading = true;
      const response = await this.service.fetchUsers(accountType, state);
      this.state.users = response.data.result;
    } finally {
      this.state.isLoading = false;
    }
  }

  async fetchJobPosts(state: string) {
    try {
      this.state.isLoading = true;
      const response = await this.service.fetchJobPosts(state);
      this.state.jobPosts = response.data.result;
    } finally {
      this.state.isLoading = false;
    }
  }

  async fetchBlokTalents(state) {
    if (state === BlokTalentStates.All) state = ''

    try {
      this.state.isLoading = true;
      const response = await this.service.fetchBlokTalents(state);
      this.state.blokTalents = response.data.result;
    } finally {
      this.state.isLoading = false;
    }
  }

  async fetchJobPostsBloks() {
    try {
      this.state.isLoading = true;
      const response = await this.service.fetchJobPostsBloks();
      this.state.jobPostsBloks = response.data.result;
    } finally {
      this.state.isLoading = false;
    }
  }

  async fetchBlokCompanies(state) {
    if (state === BlokCompaniesStates.All) state = ''

    try {
      this.state.isLoading = true;
      const response = await this.service.fetchBlokCompanies(state);
      this.state.blokCompanies = response.data.result;
    } finally {
      this.state.isLoading = false;
    }
  }

  async updateJobPostTalentDwsStatus(id: string, dwsStatus: DwsStatus) {
    await this.service.updateJobPostTalentDwsStatus(id, { dwsStatus });
    await this.fetchJobPostTalents();
  }

  async updateDwsNoteById(id: string, dwsNote: string) {
    this.state.isLoading = true;
    try {
      await this.service.updateDwsNoteById(id, { dwsNote });
      await this.fetchJobPostTalents();
    } finally {
      this.state.isLoading = false;
    }
  }

  async updateCompanyCreateJobPostStatus(id: string, canCreateJobPost: boolean) {
    await this.service.updateCompanyCreateJobPostStatus(id, { canCreateJobPost });
  }

  async updateCompanyState(id: string, isDisabled: boolean) {
		const state = isDisabled ? RowStates.Unpublished : RowStates.Approved
    await this.service.updateCompanyState(id, { state });
  }

  async updateBlokState(id, state) {
    await this.service.updateBlokState(id, { state });
    await this.fetchBloks(state);
  }

  async updateJobPostState(id, state) {
    await this.service.updateJobPostState(id, { state });
    await this.fetchJobPosts(state);
  }

  async updateJobPostBlokState(jobPostId, blokId, state) {
    await this.service.updateJobPostBlokState(jobPostId, blokId, { state });
    await this.fetchJobPostsBloks();
  }

	async updateOrCreateBlokCategory( blokId, initialValueId, category) {
		if(initialValueId){
			await this.service.updateBlokCategory(initialValueId, { category });
		}else{
			await this.service.createBlokCategory({ blokId, category });
		}
  }

	async updateOrCreateBlokOwnerType( blokId, initialValueId, ownerType) {
		if(initialValueId){
			await this.service.updateBlokOwnerType(initialValueId, { ownerType });
		}else{
			await this.service.createBlokOwnerType({ blokId, ownerType });
		}
  }

  async checkIsAdmin() {
    try {
      const response = await this.service.checkIsAdmin();

      return response?.data?.result || false;
    } catch {
      return false;
    }
  }

  filterByState(data, state) {
    return data.filter((d) => d.state === state);
  }

  searchInUsers(user, value) {
    return user.id.includes(slugify(value)) || slugify(user.email).includes(slugify(value)) || slugify(`${user.firstName} ${user.lastName}`).includes(slugify(value));
  }

  filterByAccountTypeAndSearch(data, accountType, value) {
    if (accountType) {
      return data.filter((d) => d.accountType === accountType && this.searchInUsers(d, value));
    }

    return data.filter((d) => this.searchInUsers(d, value));
  }

  getBlokTalentStatus(state: RowStates) {
    switch (state) {
      case RowStates.Approved:
        return { text: __('AdminListPage.Approved'), type: UIState.Success }
      case RowStates.Pending:
        return { text: __('AdminListPage.Pending'), type: UIState.Warning }
      case RowStates.Draft:
        return { text: __("AdminListPage.Examined"), type: UIState.Info }
      default:
        return { text: '-', type: UIState.Info }
    }
  }

  getDwsStatus(status: DwsStatus) {
    switch (status) {
      case DwsStatus.Negative:
        return { text: __('DwsStatus.Negative'), type: UIState.Danger }
      case DwsStatus.Interviewing:
        return { text: __('DwsStatus.Interviewing'), type: UIState.Warning }
      case DwsStatus.NegativeAfterInterview:
        return { text: __("DwsStatus.NegativeAfterInterview"), type: UIState.Info }
      case DwsStatus.Offer:
        return { text: __("DwsStatus.Offer"), type: UIState.Success }
      case DwsStatus.OfferRejected:
        return { text: __("DwsStatus.OfferRejected"), type: UIState.Danger }
      case DwsStatus.Hired:
        return { text: __("DwsStatus.Hired"), type: UIState.Success }
      default:
        return { text: '-', type: UIState.Info }
    }
  }

  searchInJobPostTalents(jobPostTalent, value: string) {
    return jobPostTalent?.id?.includes(slugify(value)) || slugify(jobPostTalent?.companyName).includes(slugify(value)) || slugify(jobPostTalent?.jobPostName).includes(slugify(value)) || slugify(jobPostTalent?.blokName).includes(slugify(value)) || slugify(jobPostTalent?.blokOwnerFullName).includes(slugify(value))
  }

  filterJobPostTalentsBySearch(data, searchValue: string) {
    return data?.filter(d => this.searchInJobPostTalents(d, searchValue));
  }

  searchInBlokTalents(blokTalent, value: string) {
    return blokTalent.id.includes(slugify(value)) || slugify(blokTalent.email).includes(slugify(value)) || slugify(blokTalent.lastName).includes(slugify(value)) || slugify(blokTalent.blokName).includes(slugify(value)) || slugify(blokTalent.firstName).includes(slugify(value));
  }

  filterBlokTalentsBySearch(data, searchValue: string) {
    return data?.filter(d => this.searchInBlokTalents(d, searchValue));
  }

  searchInBlokCompanies(blokCompany, value: string) {
    return blokCompany.id.includes(slugify(value)) || slugify(blokCompany.email).includes(slugify(value)) || slugify(blokCompany.blokName).includes(slugify(value)) || slugify(blokCompany.companyTitle).includes(slugify(value));
  }

  filterBlokCompaniesBySearch(data, searchValue: string) {
    return data?.filter(d => this.searchInBlokCompanies(d, searchValue));
  }

  getTabType(tab, selectedTab) {
    return tab === selectedTab ? UIState.Active : UIState.Default;
  }

  getStatus(state) {
    if (state === RowStates.Approved || state === RowStates.Published) {
      return { text: __('AdminBlokListPage.ApprovedStatus'), type: UIState.Success }
    } else if (state === RowStates.Pending) {
      return { text: __('AdminBlokListPage.PendingStatus'), type: UIState.Warning }
    } else if (state === RowStates.Declined) {
      return { text: __('AdminBlokListPage.DeclinedStatus'), type: UIState.Danger }
    } else if (state === RowStates.Unpublished) {
      return { text: __('AdminBlokListPage.UnpublishedStatus'), type: UIState.Danger }
    } else if (state === RowStates.Draft) {
      return { text: __('AdminUserListPage.Draft'), type: UIState.Idle }
    }

    return { text: '-', type: UIState.Info }
  }

  getAccountType(accountType) {
    if (accountType === AccountTypes.Talent) {
      return { text: __('AdminUserListPage.Talent'), type: UIState.Danger }
    } else if (accountType === AccountTypes.BlokOwner) {
      return { text: __('AdminUserListPage.BlokOwner'), type: UIState.Warning }
    } else if (accountType === AccountTypes.CompanyOwner) {
      return { text: __('AdminUserListPage.CompanyOwner'), type: UIState.Info }
    }

    return { text: __('AdminUserListPage.DidNotChoose'), type: UIState.Default }
  }

  getReward(price) {
    return rewardJobsStore.getReward(price);
  }

  getDateByFormat(date) {
    if (!date) return __('AdminSidebar.NoDate');

    return dayjs(date).format('DD/MM/YYYY HH:mm');
  }

  getDate(data, tab) {
    let date;

    if (tab === RowStates.Approved || tab === RowStates.Published) {
      date = data.approvedDate;
    } else if (tab === RowStates.Pending) {
      date = data.applicationDate;
    } else if (tab === RowStates.Declined) {
      date = data.declinedDate;
    } else if (tab === RowStates.Unpublished) {
      date = dayjs(data.approvedDate).add(30, 'day')
    }

    if (!date) return __('AdminSidebar.NoDate');

    return dayjs(date).format('DD/MM/YYYY HH:mm');
  }
}

export default new AdminStore(adminStore, AdminService) as AdminStore;
