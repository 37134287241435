export enum Images {
    BlokOwner = '/assets/images/onboarding/blok-owner/blok-owner-layout.svg',
    CompanyWeAreHiring = '/assets/images/onboarding/company/company-we-are-hiring.svg',
    EmptyCompanyLogo = '/assets/images/onboarding/company/empty-profile.svg',
    EmptyProfileLogo = '/assets/images/talent/empty-profile-logo.svg',
    EmptyBlokLogo = '/assets/images/bloks/empty-blok-logo.svg',
    BlokTalentHidden = '/assets/images/bloks/blok-talent-hidden.webp',
    EmployerDescription = '/assets/images/onboarding/employer-description.svg',
    BlokManagerDescription = '/assets/images/onboarding/blok-manager-description.svg',
    TalentDescription = '/assets/images/onboarding/talent-description.svg',
    CreateBlokDescription = '/assets/images/onboarding/blok-owner/create-blok-description.svg',
    ListNoticeDescription = '/assets/images/onboarding/blok-owner/list-notice-description.svg',
    Reward = '/assets/images/job/reward.webp',
    DWSLogo = '/assets/images/logo/dws-logo.webp',
    DWSBetaLogo = '/assets/images/logo/dws-beta-logo.webp',
    Signup = '/assets/images/signup/signup-doodle.svg',
    Spinner = '/assets/icons/spinner.svg',
    Verified = '/assets/images/verification/verified.svg',
    Failed = '/assets/images/verification/failed.svg',
    Timeout = '/assets/images/verification/timeout.svg',
    NotFound = '/assets/images/not-found/not-found.webp',
    Forbidden = '/assets/images/forbidden/forbidden.webp',
    JobReward = '/assets/images/job/job-reward.webp',
    PublishJobReward = '/assets/images/job/publish-job-reward.webp',
    JobBlok = '/assets/images/job/job-blok.webp',
    PublishJobBlok = '/assets/images/job/publish-job-blok.webp',
    DWSLogoLetter = '/assets/images/logo/dws-logo-letter.svg',
    JobsLoading = '/assets/images/job/jobs-loading.svg',
    JobsPreviewLoading = '/assets/images/job/jobs-preview-loading.svg',
    JobFormLoading = '/assets/images/job/job-form-loading.svg',
    JobFormDetailsLoading = '/assets/images/job/job-form-details-loading.svg',
    JobsPageEmptyStateLoading = '/assets/images/job/jobs-page-empty-state-loading.svg',
    JobRewardLoading = '/assets/images/job/job-reward-loading.svg',
    JobVerification = '/assets/images/job/job-verification.webp',
    JobSelectBlok = '/assets/images/job/job-select-blok.webp',
    CertifiedEmblem = '/assets/images/footer/emblem.webp',
    CommunitiesJumbatron = '/assets/images/landing/communities/communities-jumbatron.webp',
    CommunitiesJumbatronEN = '/assets/images/landing/communities/communities-jumbatron-en.webp',
    CommunitiesJumbatronMobile = '/assets/images/landing/communities/communities-jumbatron-mobile.webp',
    CompaniesJumbatron = '/assets/images/landing/companies/companies-jumbatron.webp',
    CompaniesJumbatronMobile = '/assets/images/landing/companies/companies-jumbatron-mobile.webp',
    RewardedNotice = '/assets/images/landing/communities/rewarded-notice.webp',
    RewardedNoticeEN = '/assets/images/landing/communities/rewarded-notice-en.webp',
    SmartAlgorithm = '/assets/images/landing/companies/smart-algorithm.webp',
    FasterPairing = '/assets/images/landing/companies/faster-pairing.webp',
    CorrectCommunity = '/assets/images/landing/companies/correct-community.webp',
    HowItWorksOne = '/assets/images/landing/communities/how-it-works-1.webp',
    HowItWorksOneEN = '/assets/images/landing/communities/how-it-works-1-en.webp',
    HowItWorksTwo = '/assets/images/landing/communities/how-it-works-2.webp',
    HowItWorksTwoEN = '/assets/images/landing/communities/how-it-works-2-en.webp',
    HowItWorksThree = '/assets/images/landing/communities/how-it-works-3.webp',
    HowItWorksThreeEN = '/assets/images/landing/communities/how-it-works-3-en.webp',
    HowItWorksFour = '/assets/images/landing/communities/how-it-works-4.webp',
    HowItWorksFourEN = '/assets/images/landing/communities/how-it-works-4-en.webp',
    MockCardOne = '/assets/images/landing/communities/mock-card-1.webp',
    MockCardTwo = '/assets/images/landing/communities/mock-card-2.webp',
    MockCardThree = '/assets/images/landing/communities/mock-card-3.webp',
    MockCardFour = '/assets/images/landing/communities/mock-card-4.webp',
    MockCardFive = '/assets/images/landing/communities/mock-card-5.webp',
    MockCardSix = '/assets/images/landing/communities/mock-card-6.webp',
    MockCardSeven = '/assets/images/landing/communities/mock-card-7.webp',
    MockCardEight = '/assets/images/landing/communities/mock-card-8.webp',
    MockCardNine = '/assets/images/landing/communities/mock-card-9.webp',
    MockCardTen = '/assets/images/landing/communities/mock-card-10.webp',
    MockCardEleven = '/assets/images/landing/communities/mock-card-11.webp',
    MockCardTwelve = '/assets/images/landing/communities/mock-card-12.webp',
    MockCardThirteen = '/assets/images/landing/communities/mock-card-13.webp',
    MockCardFourteen = '/assets/images/landing/communities/mock-card-14.webp',
    MockCardFifteen = '/assets/images/landing/communities/mock-card-15.webp',
    MockCardSixteen = '/assets/images/landing/communities/mock-card-16.webp',
    MockCardSeventeen = '/assets/images/landing/communities/mock-card-17.webp',
    MockCardEighteen = '/assets/images/landing/communities/mock-card-18.webp',
    MockCardNineteen = '/assets/images/landing/communities/mock-card-19.webp',
    MockCardTwenty = '/assets/images/landing/communities/mock-card-20.webp',
    MockCardTwentyOne = '/assets/images/landing/communities/mock-card-21.webp',
    MockCardTwentyTwo = '/assets/images/landing/communities/mock-card-22.webp',
    MockCardTwentyThree = '/assets/images/landing/communities/mock-card-23.webp',
    MockCardTwentyFour = '/assets/images/landing/communities/mock-card-24.webp',
    MockCardTwentyFive = '/assets/images/landing/communities/mock-card-25.webp',
    MockCardTwentySix = '/assets/images/landing/communities/mock-card-26.webp',
    MockCardTwentySeven = '/assets/images/landing/communities/mock-card-27.webp',
    MockCardTwentyEight = '/assets/images/landing/communities/mock-card-28.webp',
    MockCardTwentyNine = '/assets/images/landing/communities/mock-card-29.webp',
    MockCardThirty = '/assets/images/landing/communities/mock-card-30.webp',
    MockCardThirtyOne = '/assets/images/landing/communities/mock-card-31.webp',
    MockCardThirtyTwo = '/assets/images/landing/communities/mock-card-32.webp',
    MockCardThirtyThree = '/assets/images/landing/communities/mock-card-33.webp',
    MockCardThirtyFour = '/assets/images/landing/communities/mock-card-34.webp',
    MockCardThirtyFive = '/assets/images/landing/communities/mock-card-35.webp',
    CompaniesDwsPlusOne = '/assets/images/landing/companies/dwsPlus-1.webp',
    CompaniesDwsPlusTwo = '/assets/images/landing/companies/dwsPlus-2.webp',
    CompaniesDwsPlusThree = '/assets/images/landing/companies/dwsPlus-3.webp',
    CommunitiesDwsPlusOne = '/assets/images/landing/communities/dwsPlus-1.webp',
    CommunitiesDwsPlusTwo = '/assets/images/landing/communities/dwsPlus-2.webp',
    CommunitiesDwsPlusThree = '/assets/images/landing/communities/dwsPlus-3.webp',
    BlokVerify = '/assets/images/bloks/verify.svg',
    JoinBlok = '/assets/images/job/join-blok.webp',
    SuggestionSuccess = '/assets/images/job/suggestion-success.webp',
    EmptyAvatar = '/assets/icons/empty-avatar.svg',
    AboutUsImage = '/assets/images/about/about-us-image.webp',
    WhatIsBlok = '/assets/images/landingV2/what-is-blok.webp',
    WhatIsBlokEN = '/assets/images/landingV2/what-is-blok-en.webp',
    SectionThreeBG = '/assets/images/landingV2/section-three-bg.webp',
    ShowCaseCardOneTR = '/assets/images/landingV2/showcase-card-1-tr.webp',
    ShowCaseCardOneEN = '/assets/images/landingV2/showcase-card-1-en.webp',
    ShowCaseCardTwoTR = '/assets/images/landingV2/showcase-card-2-tr.webp',
    ShowCaseCardTwoEN = '/assets/images/landingV2/showcase-card-2-en.webp',
    ShowCaseCardThreeTR = '/assets/images/landingV2/showcase-card-3-tr.webp',
    ShowCaseCardThreeEN = '/assets/images/landingV2/showcase-card-3-en.webp',
    ShowCaseCardFourTR = '/assets/images/landingV2/showcase-card-4-tr.webp',
    ShowCaseCardFourEN = '/assets/images/landingV2/showcase-card-4-en.webp',
    ShowCaseCardFive = '/assets/images/landingV2/showcase-card-5.webp',
    AvatarGroup = '/assets/images/landingV2/avatar-group.webp',
    DemoImage = '/assets/images/landingV2/demo-img.webp',
    RocketEmoji = '/assets/images/landingV2/rocket-emoji.webp',
    SuitcaseEmoji = '/assets/images/landingV2/suitcase-emoji.webp',
    TestimonialBG = '/assets/images/landingV2/testimonial-bg.webp',
    TestimonialBGMobile = '/assets/images/landingV2/testimonial-bg-mobile.webp',
    SectionThreeImg = '/assets/images/landingV2/section-three-img.webp',
    SectionThreeImgEN = '/assets/images/landingV2/section-three-img-en.webp',
    ContactImage = '/assets/images/contact/contact.webp',
    ContactUkImage = '/assets/images/contact/uk.webp',
    ContactTrImage = '/assets/images/contact/turkey.webp',
    TalentsLandingPeopleOne = '/assets/images/landingV2/talents-landing-people-1.webp',
    TalentsLandingPeopleTwo = '/assets/images/landingV2/talents-landing-people-2.webp',
    TalentCommunityCardOne = '/assets/images/landingV2/talent-community-card-1.webp',
    TalentCommunityCardTwo = '/assets/images/landingV2/talent-community-card-2.webp',
    TalentCommunityCardTwoEN = '/assets/images/landingV2/talent-community-card-2-en.webp',
    TalentCommunityCardThree = '/assets/images/landingV2/talent-community-card-3.webp',
    TalentCommunityCardThreeEN = '/assets/images/landingV2/talent-community-card-3-en.webp',
    TalentCommunityCardFour = '/assets/images/landingV2/talent-community-card-4.webp',
    TalentCommunityCardFive = '/assets/images/landingV2/talent-community-card-5.webp',
    TalentCommunityCardFiveEN = '/assets/images/landingV2/talent-community-card-5-en.webp',
    TalentCommunityCardFiveMobileTR = '/assets/images/landingV2/talent-community-card-5-mobile-tr.webp',
    TalentCommunityCardFiveMobileEN = '/assets/images/landingV2/talent-community-card-5-mobile-en.webp',
    InstagramIcon = '/assets/icons/instagram.webp',
    ContactImageEn = '/assets/images/contact/contact_en.webp',
    ContactImageTr = '/assets/images/contact/contact_tr.webp',
    ContactLocationOne = '/assets/images/contact/location_one.svg',
    ContactLocationTwo = '/assets/images/contact/location_two.svg',
    CompanyLogoOne = '/assets/images/landingV2/companies/e-bebek.webp',
    CompanyLogoTwo = '/assets/images/landingV2/companies/global-bilgi.webp',
    CompanyLogoThree = '/assets/images/landingV2/companies/osevio.webp',
    CompanyLogoFour = '/assets/images/landingV2/companies/bottobo-robotics.webp',
    CompanyLogoFive = '/assets/images/landingV2/companies/bella-maison.webp',
    CompanyLogoSix = '/assets/images/landingV2/companies/appcent.webp',
    CompanyLogoSeven = '/assets/images/landingV2/companies/asset.webp',
    CompanyLogoEight = '/assets/images/landingV2/companies/esinbad.webp',
    CompanyLogoNine = '/assets/images/landingV2/companies/metro.webp',
    CompanyLogoPrimaryOne = '/assets/images/landingV2/companies/e-bebek-primary.webp',
    CompanyLogoPrimaryTwo = '/assets/images/landingV2/companies/global-bilgi-primary.webp',
    CompanyLogoPrimaryThree = '/assets/images/landingV2/companies/bottobo-robotics-primary.webp',
    CompanyLogoPrimaryFour = '/assets/images/landingV2/companies/bella-maison-primary.webp',
    CompanyLogoPrimaryFive = '/assets/images/landingV2/companies/appcent-primary.webp',
    CompanyLogoSecondaryOne = '/assets/images/landingV2/companies/e-bebek-secondary.webp',
    CompanyLogoSecondaryTwo = '/assets/images/landingV2/companies/global-bilgi-secondary.webp',
    CompanyLogoSecondaryThree = '/assets/images/landingV2/companies/bottobo-secondary.webp',
    CompanyLogoSecondaryFour = '/assets/images/landingV2/companies/bella-maison-secondary.webp',
    CompanyLogoSecondaryFive = '/assets/images/landingV2/companies/appcent-secondary.webp',
    ContactEmailIcon = '/assets/images/contact/email_icon.svg',
    BlokImage1 = '/assets/images/landingV2/bloks/blokImage1.webp',
    BlokImage2 = '/assets/images/landingV2/bloks/blokImage2.webp',
    BlokImage3 = '/assets/images/landingV2/bloks/blokImage3.webp',
    BlokImage4 = '/assets/images/landingV2/bloks/blokImage4.webp',
    BlokImage5 = '/assets/images/landingV2/bloks/blokImage5.webp',
    BlokImage6 = '/assets/images/landingV2/bloks/blokImage6.webp',
    BlokImage7 = '/assets/images/landingV2/bloks/blokImage7.webp',
    BlokImage8 = '/assets/images/landingV2/bloks/blokImage8.webp',
    BlokImage9 = '/assets/images/landingV2/bloks/blokImage9.webp',
    BlokImage10 = '/assets/images/landingV2/bloks/blokImage10.webp',
    BlokImage11 = '/assets/images/landingV2/bloks/blokImage11.webp',
    BlokImage12 = '/assets/images/landingV2/bloks/blokImage12.webp',
    BlokImage13 = '/assets/images/landingV2/bloks/blokImage13.webp',
    BlokImage14 = '/assets/images/landingV2/bloks/blokImage14.webp',
    BlokImage15 = '/assets/images/landingV2/bloks/blokImage15.webp',
    BlokImage16 = '/assets/images/landingV2/bloks/blokImage16.webp',
    BlokImageIhkib = '/assets/images/landingV2/bloks/blokImageIhkib.webp',
    BlokImageKeiken = '/assets/images/landingV2/bloks/blokImageKeiken.webp',
    BlokImageDevnot = '/assets/images/landingV2/bloks/blokImageDevnot.webp',
    BlokImageVestelVentures = '/assets/images/landingV2/bloks/blokImageVestelVentures.webp',
    BlokLogo1 = '/assets/images/landingV2/bloks/YTULogo.webp',
    BlokLogo2 = '/assets/images/landingV2/bloks/IHKIBLogo.webp',
    BlokLogo3 = '/assets/images/landingV2/bloks/UretkenLogo.webp',
    BlokLogo4 = '/assets/images/landingV2/bloks/FolksLogo.webp',
    BlokLogo5 = '/assets/images/landingV2/bloks/TJCLogo.webp',
    BlokLogo6 = '/assets/images/landingV2/bloks/IOCLogo.webp',
    BlokLogo7 = '/assets/images/landingV2/bloks/TalentSpotLogo.webp',
    BlokImageArya = '/assets/images/landingV2/bloks/blokImageArya.webp',
    BlokAvatar2 = '/assets/images/landingV2/avatar/avatar-zt.webp',
    BlokAvatar4 = '/assets/images/landingV2/avatar/avatar-js.webp',
    BlokAvatar8 = '/assets/images/landingV2/avatar/avatar-üü.webp',
    BlokAvatar10 = '/assets/images/landingV2/avatar/avatar-tty.webp',
    BlokAvatar11 = '/assets/images/landingV2/avatar/avatar-et.webp',
    BlokAvatar12 = '/assets/images/landingV2/avatar/avatar-tjc.webp',
    BlokAvatar13 = '/assets/images/landingV2/avatar/avatar-ioc.webp',
    BlokAvatar14 = '/assets/images/landingV2/avatar/avatar-ytu.webp',
    BlokAvatar15 = '/assets/images/landingV2/avatar/avatar-ihkib2.webp',
    BlokAvatar16 = '/assets/images/landingV2/avatar/avatar-ue.webp',
    BlokAvatar17 = '/assets/images/landingV2/avatar/avatar-folk.webp',
    BlokAvatar18 = '/assets/images/landingV2/avatar/avatar-tjc2.webp',
    BlokAvatarIhkib = '/assets/images/landingV2/avatar/avatar-ihkib.webp',
    BlokAvatarCube = '/assets/images/landingV2/avatar/avatar-cf.webp',
    BlogImage1 = '/assets/images/landingV2/blog/blogImage1.webp',
    BlogImage2 = '/assets/images/landingV2/blog/blogImage2.webp',
    BlogImage3 = '/assets/images/landingV2/blog/blogImage3.webp',
    TestimonialCardImage1 = '/assets/images/landingV2/avatar/testimonial-card1.webp',
    TestimonialCardImage2 = '/assets/images/landingV2/avatar/testimonial-card2.webp',
    TestimonialCardImage3 = '/assets/images/landingV2/avatar/testimonial-card3.webp',
    TestimonialCardImage4 = '/assets/images/landingV2/avatar/testimonial-card4.webp',
    TestimonialCardImage5 = '/assets/images/landingV2/avatar/testimonial-card5.webp',
    TestimonialCardImage6 = '/assets/images/landingV2/avatar/testimonial-card6.webp',
    TestimonialCardImage7 = '/assets/images/landingV2/avatar/testimonial-card7.webp',
    TestimonialCardImage8 = '/assets/images/landingV2/avatar/testimonial-card8.webp',
    TestimonialCardImage9 = '/assets/images/landingV2/avatar/testimonial-card9.webp',
    SavingsIcon = '/assets/images/landingV2/companies/savings-icon.webp',
    EventIcon = '/assets/images/landingV2/companies/event-icon.webp',
    ArticlesIcon = '/assets/images/landingV2/companies/articles-icon.webp',
    GroupTasksIcon = '/assets/images/landingV2/companies/group-tasks-icon.webp',
    RewardedJobsImageTr = '/assets/images/landingV2/rewarded-jobs-image-tr.webp',
    RewardedJobsImageMobileTr = '/assets/images/landingV2/rewarded-jobs-image-mobile-tr.webp',
    RewardedJobsImageMobileEn = '/assets/images/landingV2/rewarded-jobs-image-mobile-en.webp',
    RewardedJobsImageEn = '/assets/images/landingV2/rewarded-jobs-image-en.webp',
    JobMatchingEn = '/assets/images/landingV2/job-matching-en.webp',
    JobMatchingTr = '/assets/images/landingV2/job-matching-tr.webp',
    JobMatchingBackground = '/assets/images/landingV2/job-matching-bg.webp',
    JobMatchingMobileTr = '/assets/images/landingV2/job-matching-mobile-tr.webp',
    JobMatchingMobileEn = '/assets/images/landingV2/job-matching-mobile-en.webp',
    CompanyLogoWhiteOne = '/assets/images/landingV2/companies/e-bebek-white.webp',
    CompanyLogoWhiteTwo = '/assets/images/landingV2/companies/global-bilgi-white.webp',
    CompanyLogoWhiteThree = '/assets/images/landingV2/companies/bottobo-robotics-white.webp',
    CompanyLogoWhiteFour = '/assets/images/landingV2/companies/bella-maison-white.webp',
    CompanyLogoWhiteFive = '/assets/images/landingV2/companies/appcent-white.webp',
    TalentsLandingCommentBG = '/assets/images/landingV2/talents-landing-comment-bg.webp',
    TalentsLandingCommentBgEN = '/assets/images/landingV2/talents-landing-comment-bg-en.webp',
    TalentCommentEmojiOne = '/assets/images/landingV2/talent-comment-emoji-1.webp',
    TalentCommentEmojiTwo = '/assets/images/landingV2/talent-comment-emoji-2.webp',
    WelcomePageDoodle = '/assets/images/auth/welcome-page-doodle.webp',
    TalentOnboardingPreviewOne = '/assets/images/onboarding/talent/preview-1.webp',
    TalentOnboardingPreviewOneEN = '/assets/images/onboarding/talent/preview-1-en.webp',
    TalentOnboardingPreviewTwo = '/assets/images/onboarding/talent/preview-2.webp',
    TalentOnboardingPreviewThreeTR = '/assets/images/onboarding/talent/preview-3-tr.webp',
    TalentOnboardingPreviewThreeEN = '/assets/images/onboarding/talent/preview-3-en.webp',
    CompanyOnboardingPreviewOne = '/assets/images/onboarding/company/preview-1.webp',
    CompanyOnboardingPreviewTwoEN = '/assets/images/onboarding/company/preview-2-en.webp',
    CompanyOnboardingPreviewTwoTR = '/assets/images/onboarding/company/preview-2-tr.webp',
    CompanyOnboardingPreviewThreeTR = '/assets/images/onboarding/company/preview-3-tr.webp',
    CompanyOnboardingPreviewThreeEN = '/assets/images/onboarding/company/preview-3-en.webp',
    BlokOwnerOnbardingPreviewOneEN = '/assets/images/onboarding/blok-owner/preview-1-en.webp',
    BlokOwnerOnbardingPreviewOneTR = '/assets/images/onboarding/blok-owner/preview-1-tr.webp',
    BlokOwnerOnbardingPreviewTwoEN = '/assets/images/onboarding/blok-owner/preview-2-en.webp',
    BlokOwnerOnbardingPreviewTwoTR = '/assets/images/onboarding/blok-owner/preview-2-tr.webp',
    BlokOwnerOnbardingPreviewThreeEN = '/assets/images/onboarding/blok-owner/preview-3-en.webp',
    BlokOwnerOnbardingPreviewThreeTR = '/assets/images/onboarding/blok-owner/preview-3-tr.webp',
    TurkeyIcon = '/assets/images/onboarding/turkey.webp',
    EnglandIcon = '/assets/images/onboarding/england.webp',
    GermanyIcon = '/assets/images/onboarding/germany.webp',
    QatarIcon = '/assets/images/onboarding/qatar.webp',
    UnitedStatesIcon = '/assets/images/onboarding/united-states.webp',
    UnitedArabEmiratesIcon = '/assets/images/onboarding/united-arab-emirates.webp',
    JapanIcon = '/assets/images/onboarding/japan.webp',
    ChinaIcon = '/assets/images/onboarding/china.webp',
    AustraliaIcon = '/assets/images/onboarding/australia.webp',
    SingaporeIcon = '/assets/images/onboarding/singapore.webp',
    HongKongIcon = '/assets/images/onboarding/hong-kong.webp',
    TalentOnboardingLastStepGIF = '/assets/images/onboarding/talent/talent-onboarding-last-step.gif',
    Clear = '/assets/icons/clear.svg',
    CompanyOnboardingLastStepTR = '/assets/images/onboarding/company/company-onboarding-last-step-tr.webp',
    CompanyOnboardingLastStepEN = '/assets/images/onboarding/company/company-onboarding-last-step-en.webp',
    BlokOwnerOnboardingLastStep = '/assets/images/onboarding/blok-owner/last-step.webp',
    DefaultBlokPackageImage = '/assets/images/bloks/default-blok-package-image.webp',
    BlokApprovalSent = '/assets/images/bloks/blok-approval-sent.webp',
    PaymentSuccess = '/assets/images/payment/success.webp',
    CompanyOnboardingBanner = '/assets/images/company/CompanyOnboardingBanner.webp',
    TalentOnboardingBanner = '/assets/images/talent/TalentOnboardingBanner.webp',
    DefaultPerkImage = '/assets/images/perks/default_perk.webp',
    PerkNotMember = '/assets/images/perks/perks_member.webp',
    PerkMemberPending = '/assets/images/perks/perks_member_pending.webp',
    BlokTalentPageVisitor = '/assets/images/bloks/blok-talent-page-visitor.webp',
    AiProfileStepOneImageOne = '/assets/images/talent/ai-step1-img1.webp',
    AiProfileStepOneImageTwo = '/assets/images/talent/ai-step1-img2.webp',
    AiProfileStepOneImageThree = '/assets/images/talent/ai-step1-img3.webp',
    AiProfileStepOneImageFour = '/assets/images/talent/ai-step1-img4.webp',
    AiProfileStepTwoImageOne = '/assets/images/talent/ai-step2-img1.webp',
    AiProfileStepTwoImageTwo = '/assets/images/talent/ai-step2-img2.webp',
    AiProfileStepTwoImageThree = '/assets/images/talent/ai-step2-img3.webp',
    AiProfileStepTwoImageFour = '/assets/images/talent/ai-step2-img4.webp',
    AiProfileStepTwoImageFive = '/assets/images/talent/ai-step2-img5.webp',
    AiProfileStepTwoImageSix = '/assets/images/talent/ai-step2-img6.webp',
    AiProfileStepThreeImageOne = '/assets/images/talent/ai-step3-img1.webp',
    AiProfileStepThreeImageTwo = '/assets/images/talent/ai-step3-img2.webp',
    AiProfileStepThreeImageThree = '/assets/images/talent/ai-step3-img3.webp',
    AiProfileStepThreeImageFour = '/assets/images/talent/ai-step3-img4.webp',
    AiProfileStepThreeImageFive = '/assets/images/talent/ai-step3-img5.webp',
    AiProfileStepThreeImageSix = '/assets/images/talent/ai-step3-img6.webp',
    AiProfileStepFourMockPhoto = '/assets/images/talent/ai-step4-mock.webp',
}
