const SearchIconV2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor">
      <path fill="currentColor"
            d="m20.171 18.594-3.776-3.765a8.797 8.797 0 0 0 1.877-5.443 8.886 8.886 0 1 0-8.886 8.886 8.797 8.797 0 0 0 5.443-1.877l3.765 3.776a1.11 1.11 0 0 0 1.577 0 1.11 1.11 0 0 0 0-1.577ZM2.721 9.386a6.665 6.665 0 1 1 13.33 0 6.665 6.665 0 0 1-13.33 0Z" />
    </svg>
  );
};

export default SearchIconV2;
