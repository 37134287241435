
const IyzicoIcon = () => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="38"
         height="23"
         fill="none"
         viewBox="0 0 38 23"
      >
         <path
            fill="#1E64FF"
            fillRule="evenodd"
            d="M4.227 3.69a.927.927 0 011.237.382L7.432 7.79 9.4 4.072a.927.927 0 011.238-.383.89.89 0 01.39 1.213l-4.185 7.91a.919.919 0 01-.774.483.922.922 0 01-.464-.1.89.89 0 01-.39-1.213l1.183-2.24-2.562-4.84a.89.89 0 01.391-1.213zm22.014-.221a3.73 3.73 0 012.626 1.065c.359.352.359.92 0 1.272a.93.93 0 01-1.298 0 1.886 1.886 0 00-1.328-.54c-.502 0-.974.192-1.33.54a1.811 1.811 0 000 2.603c.356.348.828.54 1.33.54.502 0 .973-.192 1.328-.54a.93.93 0 011.298 0c.359.352.359.92 0 1.272a3.73 3.73 0 01-2.626 1.065 3.73 3.73 0 01-2.627-1.065 3.578 3.578 0 01-1.087-2.573 3.58 3.58 0 011.087-2.574 3.73 3.73 0 012.627-1.065zm7.42 0c2.047 0 3.713 1.632 3.713 3.639 0 2.006-1.666 3.638-3.714 3.638-2.047 0-3.714-1.632-3.714-3.638 0-2.007 1.666-3.64 3.714-3.64zm-31.934.119c.506 0 .917.402.917.899v5.257a.908.908 0 01-.917.9.909.909 0 01-.918-.9V4.487c0-.497.411-.9.918-.9zm15.476 0a.927.927 0 01.621.21c.389.319.439.886.114 1.267l-3.239 3.78h2.536c.506 0 .917.403.917.9 0 .495-.41.898-.917.898H12.73a.926.926 0 01-.59-.21.886.886 0 01-.112-1.266l3.238-3.782H13.15a.908.908 0 01-.917-.898c0-.497.411-.9.917-.9h4.054zm3.204 0c.506 0 .917.402.917.899v5.257c0 .497-.41.9-.917.9a.909.909 0 01-.918-.9V4.487c0-.497.41-.9.918-.9zM33.66 5.267c-1.036 0-1.88.825-1.88 1.84 0 1.016.844 1.842 1.88 1.842s1.88-.826 1.88-1.841c0-1.016-.844-1.841-1.88-1.841zM1.727.725c.56 0 1.014.445 1.014.993 0 .549-.455.994-1.014.994-.56 0-1.014-.445-1.014-.994 0-.548.454-.993 1.014-.993zm18.68 0c.56 0 1.013.445 1.013.993 0 .549-.454.994-1.013.994-.56 0-1.014-.445-1.014-.994 0-.548.453-.993 1.014-.993z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#495057"
            fillRule="evenodd"
            d="M14.656 16.064c1.552 0 3.086 1.304 3.086 3.188 0 1.885-1.534 3.188-3.086 3.188-1.552 0-3.087-1.303-3.087-3.188 0-1.884 1.535-3.188 3.087-3.188zm7.752-.062c.257 0 .48.215.48.473v5.482c0 .26-.223.474-.48.474a.475.475 0 01-.47-.474v-.482c-.062.402-.612.964-1.455.964-.984 0-2.163-.767-2.163-2.383 0-1.59 1.188-2.375 2.163-2.375.657 0 1.224.34 1.455.857v-.357c0-.027 0-.063.009-.09v-1.616c0-.259.213-.473.461-.473zM1.094 17.708c.195 0 .364.17.364.375v3.964a.376.376 0 01-.364.384.378.378 0 01-.372-.384v-3.964c0-.206.168-.375.372-.375zm1.72-1.706c.196 0 .365.17.365.366v5.679a.38.38 0 01-.364.375.382.382 0 01-.373-.375v-5.679c0-.196.169-.366.373-.366zm3.434 1.679c1.01 0 2.225.803 2.225 2.25 0 .285-.168.42-.47.42l-3.263-.018c.08 1 .842 1.473 1.605 1.473a1.62 1.62 0 001.028-.34c.134-.089.214-.133.31-.133.17 0 .276.116.276.276 0 .098-.044.197-.195.33-.355.313-.878.483-1.437.483-1.135 0-2.35-.75-2.35-2.357 0-1.607 1.26-2.384 2.27-2.384zm19.735 0c1.01 0 2.226.803 2.226 2.223 0 .366-.204.51-.585.51l-2.971-.01c.097.83.744 1.241 1.454 1.241.364 0 .612-.09.887-.259.15-.08.248-.143.372-.143.204 0 .329.143.329.34 0 .116-.045.24-.24.401-.337.277-.825.438-1.384.438-1.152 0-2.385-.741-2.385-2.357 0-1.607 1.286-2.384 2.297-2.384zm-5.349.812c-.647 0-1.375.51-1.375 1.563 0 1.062.728 1.571 1.375 1.571.604 0 1.348-.464 1.348-1.57 0-1.117-.744-1.564-1.348-1.564zm-5.978-1.518c-1.082 0-2.084.92-2.084 2.277 0 1.357 1.002 2.268 2.084 2.268s2.084-.91 2.084-2.268c0-1.357-.993-2.277-2.084-2.277zm-8.408 1.358c-.657 0-1.429.446-1.508 1.446h3.015c-.08-1-.843-1.446-1.507-1.446zm19.735.17c-.586 0-1.25.374-1.33 1.223h2.642c-.08-.849-.718-1.224-1.312-1.224zm-24.889-2.59c.195 0 .373.17.373.375a.382.382 0 01-.373.375.384.384 0 01-.381-.375c0-.206.177-.375.381-.375zm14.653-1.473c.283 0 .514.232.514.509a.515.515 0 01-1.029 0c0-.277.23-.51.515-.51zm-2.182 0c.284 0 .514.232.514.509a.514.514 0 01-1.028 0c0-.277.23-.51.514-.51z"
            clipRule="evenodd"
         ></path>
      </svg>
   )
}

export default IyzicoIcon