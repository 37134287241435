import React from 'react'
import { useEventListener } from "../useEventListener/useEventListener";
import { useIsomorphicLayoutEffect } from '../useIsomorphicLayoutEffect/useIsomorphicLayoutEffect';

export const useWindowSize = () => {
    const [size, setSize] = React.useState({ width: 0, height: 0 });

    const handleResize = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    useEventListener('resize', handleResize)

    useIsomorphicLayoutEffect(() => {
        handleResize()
    }, [])

    return size;
};