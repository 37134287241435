import BaseService from "@/js/core/BaseService";
import { SubscriptionEndpoints } from "@/js/modules/subscriptions/services/SubscriptionEndpoints.ts";
import { PackageEndpoints } from "../../packages/store/PackageEndpoints";

export default class SubscriptionService extends BaseService {
  async fetchPlansBy(paymentInterval) {
    return this.api().get(SubscriptionEndpoints.FetchPlansBy.replace(':paymentInterval', paymentInterval));
  }

  async fetchPlanByName(planName: string) {
    return this.api().get(SubscriptionEndpoints.FetchPlanByName.replace(':planName', planName));
  }

  async createPlanSubscription(payload) {
    return this.api().post(SubscriptionEndpoints.CreatePlanSubscription, payload);
  }

  async upgradePlanSubscription(payload) {
    return this.api().post(SubscriptionEndpoints.UpgradePlanSubscription, payload);
  }

  async cancelSubscription() {
    return this.api().post(SubscriptionEndpoints.CancelPlanSubscription);
  }

  async canUseServiceFeature() {
    return this.api().get(SubscriptionEndpoints.CanUseServiceFeature);
  }

  async fetchPlanById(id) {
    return this.api().get(SubscriptionEndpoints.FetchPlanById.replace(':id', id));
  }

  async fetchPlanByLabel(label) {
    return this.api().get(SubscriptionEndpoints.FetchPlanByLabel.replace(':label', label));
  }

  async fetchPlanSubscriptionStatus() {
    return this.api().get(SubscriptionEndpoints.FetchPlanSubscriptionStatus);
  }

	async fetchPlanSubscriptionStatusById(id: string) {
    return this.api().get(SubscriptionEndpoints.FetchPlanSubscriptionStatusById.replace(':userId', id));
  }

	async CreateDefaultPlan(payload) {
    return this.api().post(SubscriptionEndpoints.CreateDefaultPlan, payload);
  }

  async fetchPlanSubscriptionUsage() {
    return this.api().get(PackageEndpoints.FetchPackageCount);
  }

  async fetchPlanSubscriptionHistory() {
    return this.api().get(SubscriptionEndpoints.FetchPlanSubscriptionHistory);
  }

  async fetchAllPlans() {
    return this.api().get(SubscriptionEndpoints.FetchAllPlans);
  }
}
