import BaseStore from "@/js/core/BaseStore";
import { IJobTypeStore } from "@/js/modules/jobTypes/store/JobTypeTypes";
import JobTypeService from "@/js/modules/jobTypes/services/JobTypeService";
import { JobTypes } from "@/utils/Enums";

const jobTypeStore = {
  jobTypes: [],
}

class JobTypesStore extends BaseStore<IJobTypeStore, JobTypeService> {
  async fetchJobTypes() {
    const { data } = await this.service.fetchJobTypes();
    this.state.jobTypes = data.result;
  }

  mapOptionsForOnboarding(): { name: string, value: string }[] {
    return this.state?.jobTypes.map(item => {
      switch (item.languages[0].en) {
        case JobTypes.FullTime:
          return { name: `⏰ ${item.name}`, value: item.id }
        case JobTypes.Intern:
          return { name: `🎓 ${item.name}`, value: item.id }
        case JobTypes.PartTime:
          return { name: `⏳ ${item.name}`, value: item.id }
        case JobTypes.ProjectBased:
          return { name: `👩‍💻 ${item.name}`, value: item.id }
        default:
          return { name: item.name, value: item.id }
      }
    })
  }
}

export default new JobTypesStore(jobTypeStore, JobTypeService);