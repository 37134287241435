import CheckBox from '../../../../components/CheckBox/CheckBox'
import { Trans } from 'react-i18next'
import { BaseComponentProps } from '@/utils/BaseComponentProps'
import { ContractRoutes } from '../../services/ContractRoutes'
import React from 'react'
import { UIState } from '@/utils/Enums'
import { useContract } from '../../hooks/useContract'

interface IContractCheckbox extends BaseComponentProps {
   to: ContractRoutes
   label: string
   stateType?: UIState
   labelClass?: string
   error?: { [key: string]: string }
   checked?: boolean
   onChecked: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ContractCheckbox = (props: IContractCheckbox) => {
   const { to, label, name, stateType, labelClass, error, onChecked, checked = false } = props

   const getLinkToContract = useContract()

   return (
      <CheckBox className={"flex-row-reverse"}>
         <CheckBox.Label htmlFor={name}>
            <span className={labelClass}>
               <Trans
                  i18nKey={label}
                  components={{ Link: getLinkToContract(to) }}
               />
            </span>
         </CheckBox.Label>
         <CheckBox.Input
            id={name}
            name={name}
            error={error}
            stateType={stateType}
            attrs={{
               name,
               checked,
               onChange: e => onChecked(e),
            }}
         />
      </CheckBox>
   )
}

export default ContractCheckbox
