import { LazyLoadImage, LazyLoadImageProps } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';


interface IImage extends LazyLoadImageProps { }

const Image = (props: IImage) => {
    const { effect = 'opacity' } = props

    return <LazyLoadImage effect={effect} {...props} />
}

export default Image