export default function LeftIcon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="16"
         height="16"
         fill="none"
         viewBox="0 0 16 16"
      >
         <g clipPath="url(#clip0_7754_5341)">
            <path
               fill="#8A47F6"
               fillRule="evenodd"
               d="M10.467 1.51a.56.56 0 010 .858L5.459 8l5.008 5.632a.56.56 0 010 .857.745.745 0 01-.965 0L4.333 8.714a1.01 1.01 0 01-.246-.327.912.912 0 010-.774 1.01 1.01 0 01.246-.327L9.502 1.51a.745.745 0 01.965 0z"
               clipRule="evenodd"
            ></path>
         </g>
         <defs>
            <clipPath id="clip0_7754_5341">
               <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
         </defs>
      </svg>
   )
}