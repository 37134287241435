import { AxiosError } from "axios";
import { ValidationCodes, ValidationMessages } from "@/utils/Validations";
import { IAuthErrorResponse } from "../modules/auth/store/AuthStore.types";

export function errorHandler(err: unknown) {
    if (err instanceof AxiosError && err.response) {
        const { response }: IAuthErrorResponse = err;

        return response.data.validations?.reduce((acc, curr) => ({
            ...acc,
            [curr.field]: ValidationMessages[curr.code as ValidationCodes]
        }), {});
    }
}
