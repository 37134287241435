import { PageRoutes } from '@/utils/PageRoutes';

export default () => {
  if (![
    PageRoutes.NotFound,
    PageRoutes.Forbidden,
    PageRoutes.ForgotPassword,
    PageRoutes.ResetPassword,
    PageRoutes.Landing,
    PageRoutes.Contact,
    PageRoutes.RequestDemo,
    PageRoutes.LandingForCompanies,
    PageRoutes.LandingForCommunities,
    PageRoutes.FAQ,
    PageRoutes.Contract,
    PageRoutes.SignIn,
    PageRoutes.SignUp,
  ].includes(window.location.pathname as PageRoutes)) {
    return window.location.pathname
  }

  return ''
}
