export default function TurkishFlagIcon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         fill="none"
         viewBox="0 0 24 24"
      >
         <path fill="#D80027" d="M24 4H0v16h24V4z"></path>
         <path
            fill="#F0F0F0"
            d="M11.588 10.162l.825 1.137 1.336-.433L12.923 12l.825 1.136-1.336-.434-.826 1.135.001-1.404L10.252 12l1.336-.433v-1.404z"
         ></path>
         <path
            fill="#F0F0F0"
            d="M9.338 14.84a2.84 2.84 0 111.351-5.34 3.496 3.496 0 100 4.999 2.827 2.827 0 01-1.351.342z"
         ></path>
      </svg>
   )
}