const BlokIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="21"
            fill="currentColor"
            viewBox="0 0 22 21"
        >
            <g fill="currentColor" clipPath="url(#clip0_5784_39044)">
                <path d="M.47 8.246h2.677v-5.57h5.57V0H.47v8.246zM18.794 18.324h-5.57v2.677h8.247v-8.246h-2.677v5.57zM13.225 0v2.676h5.57v5.57h2.675V0h-8.245zM3.147 12.755H.47V21h8.246v-2.677h-5.57v-5.57zM9.406 8.089c0 .84.687 1.526 1.526 1.526a1.53 1.53 0 001.527-1.526 1.53 1.53 0 00-1.527-1.527A1.53 1.53 0 009.406 8.09zm4.274 6.486h.343v-1.87c0-1.335-1.068-2.403-2.366-2.403h-1.373c-1.297 0-2.366 1.068-2.366 2.403v1.87h5.762zM7.69 11.333H6.544c-1.183 0-2.137.954-2.137 2.174v.306h2.938v-1.107c0-.496.153-.954.343-1.373zM7.194 10.723c.763 0 1.373-.61 1.373-1.374 0-.763-.61-1.412-1.373-1.412s-1.374.61-1.374 1.374c0 .763.61 1.412 1.374 1.412zM14.748 10.722c.763 0 1.373-.61 1.373-1.374 0-.763-.61-1.373-1.373-1.373s-1.374.61-1.374 1.373.61 1.374 1.374 1.374zM15.396 11.332h-1.183c.23.42.344.877.344 1.373v1.107h2.976v-.305c0-1.221-.954-2.175-2.137-2.175z"></path>
            </g>
            <defs>
                <clipPath id="clip0_5784_39044">
                    <path fill="#fff" d="M0 0H21V21H0z" transform="translate(.47)"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default BlokIcon
