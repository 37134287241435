import Button from '@/js/components/Buttons/Button'
import { PageRoutes } from "@/utils/PageRoutes";
import { __ } from '@/utils/Trans';
import { useNavigate } from "react-router-dom";
import userStore from "@/js/modules/users/store/v2/UserStore";
import { useLogout } from "@/js/hooks/useLogout/useLogout";
import setRedirectForAuth from '@/js/helpers/setRedirectForAuth';

interface ILandingTopNavButton {
   forceRedirect?: boolean
}

const LandingTopNavButton = ({ forceRedirect = false }: ILandingTopNavButton) => {
   const navigate = useNavigate();
   const handleLogout = useLogout();

   const handleLoginClick = async () => {
      const result = await userStore.fetchMe();

      if (result && !result.emailVerifiedAt) {
         return handleLogout();
      }

      const redirectUrl = setRedirectForAuth();

      const isBlogPages = location.href.includes('blog')


      if (isBlogPages) {
         window.location.href = `${String(process.env.VITE_FE_URL)}${PageRoutes.SignIn}`
      } else {
         navigate({
            pathname: PageRoutes.SignIn,
            search: redirectUrl ? `?redirect=${redirectUrl}` : '',
         })
      }
   }

   return (
      <div className={`items-center flex-shrink-0 h-full md:flex`}>
         <Button attrs={{ onClick: handleLoginClick }} classes={"flex items-center justify-center w-full h-12"} name={"TopBarButton"}>
            <p className={"font-bold text-base leading-4 tracking-wide"}>
               {__('TopBar.Landing.Login')}
            </p>
         </Button>
      </div>
   )
}

export default LandingTopNavButton
