import React from 'react';
import axios from 'axios';
import { PageTranslation } from '@/utils/Enums';

export enum AwsClientEndpoints {
	UploadFile = '/awsclients/upload',
	UploadTranslations = '/awsclients/translations/:language',
	GetTranslations = '/awsclients/translations/:language'
}

export const useFileUpload = () => {
	const [isLoading, setIsLoading] = React.useState(false);

	const uploadFile = React.useCallback(async (file: File) => {
		setIsLoading(true);

		const formData = new FormData();
		formData.append('file', file);

		const { data: url } = await axios.post(AwsClientEndpoints.UploadFile, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})

		setIsLoading(false);

		return url
	}, [])

	const handleFileChange = React.useCallback(async (newFile: File | null) => {
		if (!newFile) return null;
		return await uploadFile(newFile)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const uploadTranslations = async (jsonFile: Partial<Object>, fileName: PageTranslation) => {
		setIsLoading(true);
		try {
			const { data } = await axios.put<boolean>(
				AwsClientEndpoints.UploadTranslations.replace(':language', fileName),
				{ jsonObject: jsonFile },
			)

			return data
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoading(false);
		}
	}

	const getTranslations = async (fileName: PageTranslation): Promise<object | undefined> => {
		setIsLoading(true);
		try {
			const { data } = await axios.get<object>(AwsClientEndpoints.GetTranslations.replace(':language', fileName))

			return data
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoading(false);
		}
	}

	return {
		isLoading,
		uploadFile,
		getTranslations,
		handleFileChange,
		uploadTranslations,
	}
}
