import { Link } from "react-router-dom"
import { ContractRoutes } from "../services/ContractRoutes"
import { PageRoutes } from "@/utils/PageRoutes"

export const useContract = () => {
   const getLinkToContract = (contract: ContractRoutes, classes?: string) => {
      return (
         <Link
            target={'_blank'}
            className={`text-violet-500 ${classes}`}
            to={PageRoutes.Contract.replace(':name', contract)}
         />
      )
   }

   return getLinkToContract
}