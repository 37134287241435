import { nanoid } from 'nanoid'

const createImage = (url: string): Promise<HTMLImageElement> => {
   return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
   });
}

const rotateSize = (width: number, height: number, rotation: number): { width: number; height: number } => {
   const rotRad = (rotation * Math.PI) / 180;

   return {
      width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
   };
}

export const getCroppedImage = async (imageSrc: string, pixelCrop: any, rotation = 0, flip = { horizontal: false, vertical: false }): Promise<File | null> => {
   const image = await createImage(imageSrc);
   const canvas = document.createElement('canvas');
   const ctx = canvas.getContext('2d');

   if (!ctx) return null;

   const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

   canvas.width = bBoxWidth;
   canvas.height = bBoxHeight;

   ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
   ctx.rotate((rotation * Math.PI) / 180);
   ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
   ctx.translate(-image.width / 2, -image.height / 2);

   ctx.drawImage(image, 0, 0);

   const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

   canvas.width = pixelCrop.width;
   canvas.height = pixelCrop.height;

   ctx.putImageData(data, 0, 0);

   const blob = await new Promise<Blob | null>(resolve => {
      canvas.toBlob(file => resolve(file), 'image/png');
   });

   if (!blob) return null;

   const b = blob as any;

   b.lastModifiedDate = new Date();
   b.name = nanoid();

   return <File>blob;
}