const SettingsIcon = () => {
	return (
		<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.24961 4.69999C5.24961 4.12009 4.77951 3.64999 4.19961 3.64999C3.61971 3.64999 3.14961 4.12009 3.14961 4.69999V12.3309C2.52192 12.694 2.09961 13.3727 2.09961 14.15C2.09961 14.9273 2.52192 15.606 3.14961 15.9691V17.3C3.14961 17.8799 3.61971 18.35 4.19961 18.35C4.77951 18.35 5.24961 17.8799 5.24961 17.3V15.9691C5.8773 15.606 6.29961 14.9273 6.29961 14.15C6.29961 13.3727 5.8773 12.694 5.24961 12.3309V4.69999Z"
				fill="#7F8B9F"
			/>
			<path
				d="M11.5496 4.69999C11.5496 4.12009 11.0795 3.64999 10.4996 3.64999C9.91971 3.64999 9.44961 4.12009 9.44961 4.69999V6.03094C8.82192 6.39404 8.39961 7.0727 8.39961 7.84999C8.39961 8.62729 8.82192 9.30595 9.44961 9.66905V17.3C9.44961 17.8799 9.91971 18.35 10.4996 18.35C11.0795 18.35 11.5496 17.8799 11.5496 17.3V9.66905C12.1773 9.30595 12.5996 8.62729 12.5996 7.84999C12.5996 7.0727 12.1773 6.39404 11.5496 6.03094V4.69999Z"
				fill="#7F8B9F"
			/>
			<path
				d="M16.7996 3.64999C17.3795 3.64999 17.8496 4.12009 17.8496 4.69999V12.3309C18.4773 12.694 18.8996 13.3727 18.8996 14.15C18.8996 14.9273 18.4773 15.606 17.8496 15.9691V17.3C17.8496 17.8799 17.3795 18.35 16.7996 18.35C16.2197 18.35 15.7496 17.8799 15.7496 17.3V15.9691C15.1219 15.606 14.6996 14.9273 14.6996 14.15C14.6996 13.3727 15.1219 12.694 15.7496 12.3309V4.69999C15.7496 4.12009 16.2197 3.64999 16.7996 3.64999Z"
				fill="#7F8B9F"
			/>
		</svg>
	);
};

export default SettingsIcon;
