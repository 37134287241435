
const TroyIcon = () => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="45"
         height="21"
         fill="none"
         viewBox="0 0 45 21"
      >
         <path
            fill="#323E48"
            fillRule="evenodd"
            d="M1.802 17.972h-.3s-.075 0-.075.075v.075h-.15v-.15c0-.075.075-.15.15-.15h.975c.075 0 .15 0 .075.15v.15h-.15v-.15h-.375l-.3 1.35h-.15l.3-1.35z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#323E48"
            d="M2.552 18.422l-.15-.075v-.15h.15c.075 0 .15 0 .075.15l-.075.525c0 .15 0 .3.15.3.225 0 .45-.225.45-.45l.075-.525h.225l-.225.9v.075h.075v.15h-.075c-.075 0-.15-.075-.075-.15v-.075-.075a.506.506 0 01-.45.3c-.3 0-.375-.15-.3-.375l.15-.525zm.3-.6h.15l-.075.225h-.075v-.225zm.3 0h.15v.225h-.15v-.225z"
         ></path>
         <path
            fill="#323E48"
            fillRule="evenodd"
            d="M3.753 18.422l-.075-.075v-.15h.15c.075 0 .15 0 .075.15v.15c.075-.15.225-.3.375-.3h.075v.15h-.075c-.15 0-.3.15-.375.3 0 .075-.075.15-.075.225l-.075.45h-.15l.15-.9zM4.577 18.047c0-.075 0-.075 0 0l-.15-.075v-.15h.15c.075 0 .15 0 .075.15l-.075.675h.075c.075 0 .075 0 .15-.075l.375-.375h.15l-.375.45-.075.075.075.075.075.375s0 .075.075.075h.075v.15h-.15c-.075 0-.15 0-.15-.075l-.15-.525c0-.075-.075-.075-.075-.075h-.075l-.15.6h-.15l.3-1.275z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#323E48"
            d="M5.777 18.422s0-.075-.075-.075h-.225l.075-.15h.375c.225 0 .3 0 .225.15l-.45.75s0 .075.075.075h.225l-.075.15h-.3c-.225 0-.3-.075-.225-.15l.375-.75zm.3-.6h.3l-.075.225h-.3l.075-.225z"
         ></path>
         <path
            fill="#323E48"
            fillRule="evenodd"
            d="M5.852 19.622c.075 0 .15-.075.225-.15l.075-.15-.15-.9s0-.075-.075-.075v-.15h.075c.075 0 .15 0 .15.075l.15.675v.15s0-.075.075-.15l.375-.675c.075-.075.075-.075.15-.075h.075v.15s-.075 0-.075.075l-.75 1.125a.573.573 0 01-.375.15c-.15 0-.15-.075-.15-.075l.075-.075s0 .075.15.075z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#323E48"
            d="M7.577 18.197c.3 0 .45.225.375.525v.075h-.825c-.075.225.075.375.3.375.225 0 .375-.15.375-.15l.075.075s-.15.15-.45.15c-.3 0-.525-.225-.45-.525.075-.3.3-.525.6-.525zm.225.45c.075-.225-.075-.3-.225-.3-.15 0-.375.15-.45.3h.675z"
         ></path>
         <path
            fill="#323E48"
            fillRule="evenodd"
            d="M8.328 17.822h.15l-.15.375h-.15l.15-.375zM8.628 18.422l-.075-.075v-.15h.15c.075 0 .15 0 .075.15v.15c.075-.075.225-.3.45-.3.3 0 .3.15.3.375l-.075.525v.075h.075v.15h-.15c-.075 0-.15-.075-.075-.15l.075-.525c0-.15.075-.3-.15-.3-.15 0-.375.15-.45.3 0 .075 0 .075-.075.15l-.075.525h-.15l.15-.9z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#323E48"
            d="M10.128 18.422s0-.075-.075-.075h-.225l.075-.15h.375c.225 0 .3 0 .225.15l-.375.75s0 .075.075.075h.225l-.075.15h-.375c-.225 0-.3-.075-.225-.15l.375-.75zm.3-.6h.3l-.075.15h-.3l.075-.15z"
         ></path>
         <path
            fill="#323E48"
            fillRule="evenodd"
            d="M10.427 18.422l-.15-.075v-.15h.15c.075 0 .15 0 .075.15v.15c.075-.075.3-.3.525-.3.225 0 .3.15.225.375l-.075.525v.075h.075v.15h-.15c-.075 0-.15-.075-.075-.15l.075-.525c0-.15.075-.3-.15-.3-.15 0-.375.15-.45.3 0 .075 0 .075-.075.15l-.15.525h-.075l.225-.9z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#323E48"
            d="M13.052 17.822c.45 0 .675.3.6.75-.075.45-.525.75-.9.75-.45 0-.675-.3-.6-.75a.89.89 0 01.9-.75zm-.375 1.35c.3 0 .675-.3.75-.6.075-.375-.15-.6-.45-.6-.3 0-.675.3-.75.6-.075.375.15.6.45.6zm.225-1.65h.15l-.075.15h-.15l.075-.15zm.3 0h.15l-.075.15h-.15l.075-.15zM14.327 18.196c.225 0 .3.225.3.225v-.075l.075-.375v-.075h-.075v-.15h.15c.075 0 .15 0 .075.15l-.225 1.125v.075h.075v.15h-.15c-.075 0-.15-.075-.075-.15v-.075s-.15.225-.375.225c-.3 0-.375-.225-.3-.525 0-.3.225-.525.525-.525zm.225.6c.075-.225 0-.45-.225-.45-.15 0-.375.15-.45.45-.075.225.075.45.225.45.15-.075.375-.15.45-.45zM15.603 18.197c.3 0 .375.225.375.45v.075h-.825c-.075.225.075.45.3.45.225 0 .375-.15.375-.15l.075.075s-.225.15-.45.15c-.3 0-.525-.225-.45-.525 0-.3.3-.525.6-.525zm.15.45c.075-.225-.075-.3-.225-.3-.15 0-.375.075-.45.3h.675z"
         ></path>
         <path
            fill="#323E48"
            fillRule="evenodd"
            d="M16.203 18.422c0-.075.075 0 0 0l-.15-.075v-.15h.15c.075 0 .15 0 .075.15v.15c.15-.15.375-.3.525-.3.225 0 .3.075.3.225.075-.075.225-.225.45-.225s.3.15.225.375l-.075.525v.075h.075v.15h-.15c-.075 0-.15-.075-.075-.15l.15-.525c.075-.15 0-.3-.15-.3s-.3.15-.375.3c0 .075-.075.075-.075.15l-.075.525h-.15l.15-.6c0-.15.075-.3-.15-.3-.15 0-.3.15-.375.3 0 .075-.075.075-.075.15l-.225.45h-.15l.15-.9z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#323E48"
            d="M18.677 18.197c.3 0 .375.225.375.45v.075h-.825c-.075.225.075.45.3.45.225 0 .375-.15.375-.15l.075.075s-.225.15-.45.15c-.3 0-.525-.225-.45-.525 0-.3.3-.525.6-.525zm.15.45c.075-.225-.075-.3-.225-.3-.15 0-.375.075-.45.3h.675z"
         ></path>
         <path
            fill="#323E48"
            fillRule="evenodd"
            d="M20.103 18.646l-.225-.675c0-.075-.075-.075-.075-.075v-.15h.075c.075 0 .15 0 .15.15l.15.375c0 .075.075.15.075.15l.15-.15.3-.3c.075-.075.15-.15.225-.15h.075v.15c-.075 0-.075 0-.075.075l-.525.675-.15.6h-.3l.15-.675z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#323E48"
            d="M21.377 18.197c.3 0 .525.225.45.525a.72.72 0 01-.675.525c-.3 0-.525-.225-.45-.525.075-.225.375-.525.675-.525zm-.225.975c.225 0 .45-.15.525-.45.075-.225-.075-.375-.3-.375-.225 0-.45.15-.525.375-.075.3.075.45.3.45zm.075-1.35h.15l-.075.15h-.15l.075-.15zm.3 0h.15v.15h-.15v-.15z"
         ></path>
         <path
            fill="#323E48"
            fillRule="evenodd"
            d="M22.128 18.422l-.15-.075v-.15h.224c.075 0 .15 0 .075.15v.15c.075-.075.226-.3.45-.3.226 0 .3.15.226.375l-.075.525s0 .075.075.075h.075v.15h-.15c-.076 0-.15-.075-.076-.15l.076-.525c0-.15.075-.3-.15-.3-.15 0-.376.15-.45.3-.075.075-.075.075-.075.15l-.075.525h-.15l.15-.9zM23.402 18.346h-.15v-.15h.15l.075-.3h.15l-.075.3h.3v.15h-.3l-.15.526c-.075.224.075.3.15.3h.075v.15h-.075c-.15 0-.375-.075-.3-.375l.15-.6z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#323E48"
            d="M24.527 18.197c.3 0 .375.225.375.45v.075h-.825c-.075.225.075.45.3.45.225 0 .375-.15.375-.15l.075.075s-.15.15-.45.15c-.3 0-.525-.225-.45-.525 0-.3.3-.525.6-.525zm.225.45c.075-.225-.075-.3-.225-.3-.15 0-.375.075-.45.3h.675z"
         ></path>
         <path
            fill="#323E48"
            fillRule="evenodd"
            d="M25.203 18.422l-.15-.075v-.15h.15c.075 0 .15 0 .075.15v.15c.15-.15.3-.3.45-.3.225 0 .3.075.3.225.075-.075.3-.225.45-.225.225 0 .3.15.225.45l-.075.525v.075h.075v.15h-.15c-.075 0-.15-.075-.075-.15l.075-.525c.075-.15 0-.3-.15-.3s-.3.15-.375.3c0 .075-.075.075-.075.15l-.075.525h-.15l.15-.6c0-.15.075-.3-.15-.3-.15 0-.3.15-.375.3 0 .075 0 .075-.075.15l-.075.525h-.15l.15-1.05z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#323E48"
            d="M27.378 18.422s0-.075-.075-.075h-.225l.075-.15h.375c.225 0 .3 0 .225.15l-.375.75s0 .075.075.075h.225l-.075.15h-.375c-.225 0-.3-.075-.225-.15l.375-.75zm.3-.6h.3l-.075.15h-.3l.075-.15z"
         ></path>
         <path
            fill="#323E48"
            fillRule="evenodd"
            d="M40.625 3.703c-.6 0-1.275.375-1.575.975l-2.85 6.525-1.05-6.525c-.15-.6-.525-.975-1.2-.975h-3.675l3.15 10.875c.075.225.075.45 0 .675-.15.9-.975 1.65-1.875 1.65h-2.025c-.525 0-.825.3-1.05 1.05l-.45 2.7h3.6c1.875 0 4.05-.9 5.55-3.525l7.2-13.425h-3.75zM5.225.402c1.05 0 1.575.45 1.425 1.5l-.3 1.725h2.475L8.3 6.702H5.75l-.675 3.825c-.225 1.35 1.05 1.5 1.875 1.5h.375l-.6 3.375c-.15 0-.375.075-.75.075-1.875 0-5.4-.525-4.725-4.425L2 6.702H.125L.65 3.627h1.8l.6-3.225h2.175z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#00ADBB"
            fillRule="evenodd"
            d="M26.068 3.773l-.675 3.6c.75.375 1.35 1.2 1.35 2.1 0 1.2-.9 2.25-2.1 2.4l-.6 3.6h.375c3.3 0 6-2.7 6-6-.075-2.625-1.875-4.95-4.35-5.7zM23.218 11.649c-.75-.375-1.35-1.2-1.35-2.1 0-1.2.9-2.25 2.1-2.4l.675-3.6h-.375c-3.3 0-6 2.7-6 6 0 2.7 1.8 4.95 4.275 5.7l.675-3.6z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#323E48"
            fillRule="evenodd"
            d="M10.25 3.703h2.1c1.05 0 1.575.45 1.425 1.5l-.225 1.275c.825-1.575 2.475-2.925 4.275-2.925.225 0 .45.075.45.075l-.675 3.9s-.3-.075-.825-.075c-.975 0-2.55.3-3.45 2.025-.225.45-.375.975-.45 1.575l-.75 4.35H8.15l2.1-11.7z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#323E48"
            d="M44.375 15.927c0 .375-.15.675-.375.9a1.242 1.242 0 01-.9.375c-.375 0-.675-.15-.9-.375a1.242 1.242 0 01-.375-.9c0-.375.15-.675.375-.9.225-.225.525-.375.9-.375s.675.15.9.375c.225.225.375.525.375.9zm-.225 0c0-.3-.075-.525-.3-.75-.225-.225-.45-.3-.75-.3s-.525.075-.75.3c-.225.225-.3.45-.3.75s.075.525.3.75c.225.225.45.3.75.3s.525-.075.75-.3c.225-.225.3-.45.3-.75zm-.525.675H43.4l-.3-.6h-.225v.6h-.225v-1.35h.45c.15 0 .225.075.3.15.075.075.15.15.15.3 0 .225-.075.3-.3.375l.375.525zm-.225-.975c0-.075 0-.15-.075-.15s-.15-.075-.225-.075h-.225v.45h.225c.075 0 .15 0 .225-.075 0 0 .075-.075.075-.15z"
         ></path>
      </svg>
   )
}

export default TroyIcon