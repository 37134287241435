export enum UserEndpoints {
  UserEmailVerify = '/users/email-verify',
  FetchAccountType = '/users/account-type',
  Me = '/users/me',
  UpdateLanguage = '/users/update/language',
  ResendVerificationEmail = '/users/email/resend',
  UpdatePassword = '/auth/update-password',
  IsEmailExists = '/users/email-exists/:email',
	getUserInviteInfo = '/userimports',
	InsertUserInviteInfo = '/talents/create-user',
	UpdateUserInviteInfo = '/talents/update-user',
}
