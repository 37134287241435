import LocalStorage from "@/js/helpers/localStorage";
import { PageRoutes } from "@/utils/PageRoutes";
import { Outlet, useLocation, Navigate } from "react-router-dom";

function GuestGuard() {
    const location = useLocation();

    if (LocalStorage.has('accessToken')) {
        return (
            <Navigate
                to={{ pathname: PageRoutes.Dashboard, search: window.location.search }}
                state={{ from: location }}
                replace
            />
        );
    }

    return <Outlet />
}

export default GuestGuard;
