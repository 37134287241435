import React, { PropsWithChildren } from "react";
import { __ } from "@/utils/Trans";
import { borderColors, textColors } from "./constants/textBoxConstants";
import Icon from "../Icon/Icon";
import { Icons } from "@/utils/Icons";
import { UISize, UIState } from "@/utils/Enums";
import { BaseComponentProps } from "@/utils/BaseComponentProps";
import { maxInputLength, minInputLength } from "@/js/modules/auth/constants/InputConfig";
import SearchIconV2 from "@/assets/icons/SearchIconV2";
import Badge from "../Badge/Badge";
import clsx from "clsx";

interface ITextBox extends BaseComponentProps {
	children?: React.ReactNode;
	classes?: string;
	isOptional?: boolean;
}

let isOptional = false;

function TextBox(props: PropsWithChildren<ITextBox>) {
	isOptional = props.isOptional || false;

	return (
		<div className={props.classes}>
			{props.children}
		</div>
	)
}

interface ILabel {
	children: React.ReactNode;
	classes?: string;
}

const Label = (props: ILabel) => {
	return (
		<label className={`flex items-center gap-[10px] text-shade-black text-body-semibold-b5 ${props.classes ? props.classes : ""}`}>
			{props.children}
			{isOptional && (
				<Badge size={UISize.Sm} type={UIState.Success}>
					{__('Optional')}
				</Badge>
			)}
		</label>
	);
};

interface IInput extends BaseComponentProps {
	classes?: string | null,
	attr: React.InputHTMLAttributes<HTMLInputElement>,
	stateType?: UIState,
	error?: { [key: string]: string; };
	message?: string;
	inputRef?: React.RefObject<HTMLInputElement>;
	searchable?: boolean;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
}

const Input = (props: IInput) => {
	const { stateType = UIState.Default } = props;

	const error = props.error?.[props.attr.name!];

	return (
		<>
			<div
				className={clsx(
					`mt-1 flex items-center transition duration-150 ease-in-out outline-none input-wrapper relative`,
					{
						'border-error-400': error,
						'border-shade-stroke': !error,
						'mt-[10px]': isOptional,
						[borderColors[props.stateType!]]: true
					}
				)}
			>
				{props.searchable && (
					<p className={"text-gray-200 pr-2"}>
						<SearchIconV2 />
					</p>
				)}
				{props.startIcon && (
					<p className={"text-gray-200 pr-2"}>
						{props.startIcon}
					</p>
				)}
				<input
					{...props.attr}
					ref={props?.inputRef}
					maxLength={props?.attr?.maxLength || maxInputLength}
					minLength={minInputLength}
					className={clsx(
						'text-label-regular-l5 h-[48px] rounded-lg border hover:border-primary-50 border-shade-stroke p-3 block placeholder:text-label-regular-l5 ${textColors[stateType]} placeholder:text-neutral-500 w-full focus:border-primary-500 focus:ring-0 focus:outline-none transition ease-in-out duration-150 disabled:border-shade-stroke disabled:bg-shade-stroke disabled:text-neutral-100',
						{ [props.classes as string]: props.classes },
						{ [borderColors[props.stateType!]]: error }
					)}
				/>
				{props.endIcon && (
					<p className={"text-gray-200 absolute top-1/2 transform -translate-y-1/2 right-0"}>
						{props.endIcon}
					</p>
				)}
				{stateType === UIState.Success && (
					<Icon width='29' height='24' name={Icons.Check} />
				)}
			</div>
			{props.message && (
				<p className={`${textColors[UIState.Success]} text-sm absolute mt-1 font-medium`}>
					{props.message}
				</p>
			)}
			{error && (
				<p className={`${textColors[stateType]} text-sm mt-1 font-medium`}>
					{<span className={"text-error-400"}>
						{__(error)}
					</span>}
				</p>
			)}
		</>
	);
};
TextBox.Label = Label;
TextBox.Input = Input;

export default TextBox;
