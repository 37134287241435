import EmptyAvatar from '@/assets/icons/EmptyAvatar';
import React from 'react';
import Image from '../Image/Image';

type AvatarProps = {
    isRounded?: boolean;
    attrs: React.ImgHTMLAttributes<HTMLImageElement>;
    width?: string;
    height?: string;
    isLazyLoaded?: boolean;
};

const Avatar = (props: React.PropsWithChildren<AvatarProps>) => {
    const { isRounded, attrs, width = "w-20", height = "h-20" } = props;

    if (!attrs.src) {
        return <div className={"rounded-full bg-gray-50 p-2"}><EmptyAvatar /></div>
    }

    return props.isLazyLoaded
        ? <Image
            src={attrs.src}
            alt={attrs.alt}
            className={`${isRounded ? 'rounded-full' : 'rounded'} ${width} ${height}`}
        />
        : <img
            {...attrs}
            className={`${isRounded ? 'rounded-full' : 'rounded'} ${width} ${height}`}
        />
};

export default Avatar;
