import TopNavBar from '@/js/layouts/topbar/TopNavBar'
import NewNoticeButton from '@/js/layouts/topbar/actions/NewNoticeButton'
import { Icons } from '@/utils/Icons'
import React from 'react'
import userStore from "@/js/modules/users/store/v2/UserStore";
import { PageRoutes } from '@/utils/PageRoutes';
import { useIsomorphicLayoutEffect } from '@/js/hooks/useIsomorphicLayoutEffect/useIsomorphicLayoutEffect';
import { ScreenSizes } from '@/utils/ScreenSizes';
import { useWindowSize } from '@/js/hooks/useWindowSize/useWindowSize';
import useUserStore from '@/js/modules/users/store/useUserStore';
import blokPublicStore from "@/js/modules/bloks/store/BlokPublicStore.ts";
import subscriptionStore from "@/js/modules/subscriptions/store/SubscriptionStore.ts";
import MessageIndicator from '@/js/modules/chats/components/MessageIndicator';
import conversationStore from '@/js/modules/chats/store/ConversationStore/ConversationStore';
import { AccountTypes } from '@/utils/Enums';
import { useQuery } from '@tanstack/react-query';

const talentMenuItems = [
	{ to: PageRoutes.DiscoverBloksPage, name: "Bloks", translation: "TopBar.Bloks" }
]

const companyMenuItems = [
	{ to: PageRoutes.DiscoverBloksPage, name: "Bloks", translation: "TopBar.Bloks" },
	{ to: PageRoutes.DashboardRewardJobs, name: "My Notices", translation: "TopBar.JobPosts" },
	{ to: PageRoutes.MessagesPage, name: "Messages", translation: "TopBar.Messages", element: <></> },
]

const blokOwnerMenuItems = [
	{ to: PageRoutes.DiscoverBloksPage, name: "Bloks", translation: "TopBar.Bloks" },
	{ to: PageRoutes.BlokJobPostRewards, name: "Reward Jobs", translation: "TopBar.RewardJobTable" },
	{ to: PageRoutes.BlokJobPostReferrers, name: "Job Post Referrers", translation: "TopBar.TalentsThatRecommended" },
	{ to: PageRoutes.MyBlokPage, name: "My Blok", translation: "TopBar.MyBlok" },
	{ to: PageRoutes.MessagesPage, name: "Messages", translation: "TopBar.Messages", element: <></> },
]

const companyDropdownItems = [
	{ to: PageRoutes.MyCompanyPage, name: "My Company", translation: "TopBar.MyCompany", icon: Icons.Building },
	{ to: PageRoutes.Bloks, name: "Joined Bloks", translation: "TopBar.JoinedBloks", icon: Icons.Blok },
	{ to: PageRoutes.CompanyPaymentHistory, name: "Company Purchase History", translation: "TopBar.CompanyPaymentHistory", icon: Icons.Payment },
]

const blokOwnerDropdownItems = [
	{ to: PageRoutes.MyBlokPage, name: "My Blok", translation: "TopBar.MyBlok", icon: Icons.Blok },
	{ to: PageRoutes.BlokPaymentHistory, name: "Blok Purchase History", translation: "TopBar.BlokPaymentHistory", icon: Icons.Payment },
	{ to: PageRoutes.SubscriptionPlansPage, name: "Subscription Info Page", type: 'subscription', itemClasses: 'bg-pink-50', },
	{ to: PageRoutes.SubscriptionInfoPage, name: "Settings", translation: "TopBar.Settings", icon: Icons.SettingsV2 },
]

const talentDropdownItems = [
	{ to: PageRoutes.MyProfilePage, name: "My Profile", translation: "TopBar.MyProfile", icon: Icons.User },
	{ to: PageRoutes.Bloks, name: "Joined Bloks", translation: "TopBar.JoinedBloks", icon: Icons.Blok },
]

export default function RoleBasedNavbar() {
	blokPublicStore.initializeStore();
	conversationStore.initializeStore();
	subscriptionStore.initializeStore();

	const [menuItems, setMenuItems] = React.useState([] as any[])
	const [buttonHasBorder, setButtonHasBorder] = React.useState(true)
	const [dropdownItems, setDropdownItems] = React.useState([] as any[])

	const { width } = useWindowSize()
	const { user } = useUserStore();

	const handleSetSubscriptionIndicator = () => {
		const subsItem = dropdownItems.find(item => item?.type === 'subscription')

		if (subsItem) {
			subsItem.itemClasses =
				subscriptionStore.isPremiumPlan()
					? '!bg-purple-50 hover:!bg-purple-50'
					: subscriptionStore.isProPlan()
						? '!bg-violet-50 hover:!bg-violet-50'
						: 'bg-pink-50 hover:!bg-pink-50'
		}
	}

	const handleSetNotificationIndicator = (accountType: AccountTypes) => {
		setTimeout(async () => {
			const menuItems = accountType === AccountTypes.BlokOwner ? blokOwnerMenuItems : companyMenuItems;

			const messagesButton = menuItems.find(item => item.to === PageRoutes.MessagesPage);
			const hasNotifications = await conversationStore.fetchForNotifications();

			messagesButton && (messagesButton.element = hasNotifications ? <MessageIndicator /> : <></>);

			setMenuItems([...menuItems]);
		}, 200)
	}

	const setItems = React.useCallback(() => {
		if (userStore.isTalentAccountType()) {
			setMenuItems(talentMenuItems)
			setDropdownItems(talentDropdownItems)
		} else if (userStore.isBlokOwnerAccountType()) {
			setMenuItems(blokOwnerMenuItems)
			setDropdownItems(blokOwnerDropdownItems)
			handleSetNotificationIndicator(AccountTypes.BlokOwner)
			handleSetSubscriptionIndicator()
		} else if (userStore.isCompanyOwnerAccountType()) {
			setMenuItems(companyMenuItems)
			setDropdownItems(companyDropdownItems)
			handleSetNotificationIndicator(AccountTypes.CompanyOwner)
		}
	}, [])

	useIsomorphicLayoutEffect(() => {
		if (width < ScreenSizes.XLarge) {
			setButtonHasBorder(false)
		} else setButtonHasBorder(true)
	}, [width])

	useQuery({
		queryKey: ['navItems', user.isOnboardingCompleted, user.isDetailsCompleted],
		queryFn: () => {
			if (user.isOnboardingCompleted && user.isDetailsCompleted) {
				userStore.fetchAccountType().then(() => setItems())
			}
			return true
		}
	})

	useQuery({
		queryKey: ['subscriptionStatus', user.accountType, blokPublicStore.state.info.user.id],
		queryFn: async () => {
			if(user.accountType === AccountTypes.BlokOwner){
				const activePlan = await subscriptionStore.fetchPlanSubscriptionStatus()
				if(!activePlan){
					await subscriptionStore.CreateFreePlan()
				}
			} else if (blokPublicStore.state.info.user.id){
			  await subscriptionStore.fetchPlanSubscriptionStatusById(blokPublicStore.state.info.user.id)
			}
			return true
		}
	})

	// React.useEffect(() => {
	// 	if (user.isOnboardingCompleted && user.isDetailsCompleted) {
	// 		userStore.fetchAccountType().then(() => setItems())
	// 	}
	// }, [setItems, user.isDetailsCompleted, user.isOnboardingCompleted]);

	// React.useEffect(() => {
	//    (async () => await subscriptionStore.fetchPlanSubscriptionStatus())()
	// }, [])

	return (
		<TopNavBar hasNotifications={false} isSearchable={false}>
			<TopNavBar.MenuSection menuItems={menuItems} />
			<TopNavBar.ActionSection>
				{userStore.isCompanyOwnerAccountType() && (
					<NewNoticeButton hasBorder={buttonHasBorder} />
				)}
			</TopNavBar.ActionSection>
			<TopNavBar.AuthorSection dropdownItems={dropdownItems} />
		</TopNavBar>
	)
}
