
export const isJoinBlokFlow = (searchParams: URLSearchParams): boolean => {
	if (searchParams.has('redirect')) {
			const redirect = String(searchParams.get('redirect'));
			if (redirect && redirect.includes('bloks')) {
					return true;
			}
	}
	return false;
};
