const CopyUrlIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0439 8.95606C11.2249 8.13732 10.1142 7.67738 8.95605 7.67738C7.79794 7.67738 6.68723 8.13732 5.86815 8.95606L2.77926 12.044C1.96016 12.863 1.5 13.974 1.5 15.1324C1.5 16.2907 1.96016 17.4017 2.77926 18.2207C3.59835 19.0398 4.70928 19.5 5.86765 19.5C7.02603 19.5 8.13696 19.0398 8.95605 18.2207L10.5 16.6768" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.95605 12.0439C9.77513 12.8627 10.8858 13.3226 12.044 13.3226C13.2021 13.3226 14.3128 12.8627 15.1318 12.0439L18.2207 8.95605C19.0398 8.13696 19.5 7.02603 19.5 5.86765C19.5 4.70928 19.0398 3.59835 18.2207 2.77926C17.4017 1.96016 16.2907 1.5 15.1323 1.5C13.974 1.5 12.863 1.96016 12.044 2.77926L10.5 4.3232" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default CopyUrlIcon;
