export default function TrashIconV2() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="25"
			fill="currentColor"
			viewBox="0 0 24 25"
		>
			<path
				fill="currentColor"
				d="M19 7.5a1 1 0 00-1 1v11.191a1.92 1.92 0 01-2.01 1.809H8.01A1.92 1.92 0 016 19.691V8.5a1 1 0 00-2 0v11.191A3.918 3.918 0 008.01 23.5h7.98A3.918 3.918 0 0020 19.691V8.5a1 1 0 00-1-1zM20 4.5h-4v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2H4a1 1 0 000 2h16a1 1 0 100-2zm-10 0v-1h4v1h-4z"
			></path>
			<path
				fill="currentColor"
				d="M11 17.5v-7a1 1 0 00-2 0v7a1 1 0 102 0zM15 17.5v-7a1 1 0 00-2 0v7a1 1 0 002 0z"
			></path>
		</svg>
	)
}
