import { Icons } from "@/utils/Icons"
import { Transition } from "@headlessui/react"
import React from 'react'
import TopBarDropdownMenuItem from "./TopNavBarDropdownMenuItem"
import AuthorInfo from "../author/AuthorInfo"
import LogOutButton from "../author/LogOutButton"
import TopNavBarChangeLanguage from '@/js/layouts/topbar/TopNavBarChangeLanguage';
import { ScreenModes } from '@/utils/Enums';

interface ITopBarDropdownMenu extends React.PropsWithChildren {
    isDropdownOpen: boolean,
    items: {
        to: string,
        name: string,
        translation: string,
        icon: Icons
        itemClasses?: string;
        type?: string;
    }[]
}

const TopBarDropdownMenu = (props: ITopBarDropdownMenu) => {
    return (
        <Transition
            appear
            show={props.isDropdownOpen}
            as={React.Fragment}
            enter={"transition ease-out duration-100"}
            enterFrom={"transform opacity-0 scale-95"}
            enterTo={"transform opacity-100 scale-100"}
            leave={"transition ease-in duration-75"}
            leaveFrom={"transform opacity-100 scale-100"}
            leaveTo={"transform opacity-0 scale-95"}
        >
            <div tabIndex={-1} className={"absolute right-0 z-10 w-[13rem] py-1 mt-2 origin-top-right bg-white rounded-md shadow ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100"}>
                <AuthorInfo classes={"py-3 cursor-default"} />
                <div>
                    {props.items.map(item => (
                        <TopBarDropdownMenuItem
                            key={item.name}
                            item={item}
                            classes={`flex flex-row-reverse justify-end px-4 py-2 text-sm text-gray-700 gap-3 hover:bg-gray-50 ${item.itemClasses}`}
                        />
                    ))}
                </div>
                <LogOutButton />
                <div className={"py-2 w-full flex items-center justify-center"}>
                    <div className={"-ml-5"}>
                    <TopNavBarChangeLanguage screenMode={ScreenModes.Desktop} />
                    </div>
                </div>
            </div>
        </Transition>
    )
}
export default TopBarDropdownMenu
