import BaseStore from "@/js/core/BaseStore";
import JobService from "@/js/modules/jobs/services/JobService";
import { errorHandler } from "@/js/helpers/errorHandler";
import { IJobStoreState, JobPostApplicationTypes, JobPostPublishTypes } from "./JobTypes";
import benefitStore from "@/js/modules/benefits/store/BenefitStore";
import jobLevelStore from "@/js/modules/jobLevels/store/JobLevelStore";
import departmentStore from "@/js/modules/departments/store/DepartmentStore";
import locationTypeStore from "@/js/modules/locationTypes/store/LocationTypeStore";
import jobTypeStore from "@/js/modules/jobTypes/store/JobTypeStore";
import locationStore from "@/js/modules/locations/store/LocationStore";
import sectorStore from "@/js/modules/sectors/store/SectorStore";
import companyStore from "@/js/modules/company/store/CompanyStore";
import skillStore from "@/js/modules/skills/store/SkillStore";
import languageStore from "@/js/modules/languages/store/LanguageStore";
import educationDegreeStore from "@/js/modules/educationDegrees/store/EducationDegreeStore";
import { AccountTypes, State, UIState } from '@/utils/Enums';
import jobBlokStore from "@/js/modules/jobs/store/JobBlokStore";
import { __ } from "@/utils/Trans";
import { RowStates } from "@/js/core/enums/RowStates";
import { PageRoutes } from "@/utils/PageRoutes";
import rewardJobsStore from "@/js/modules/jobs/store/RewardJobsStore";
import userStore from '@/js/modules/users/store/v2/UserStore';
import skillExperienceStore from "@/js/modules/skillExperiences/store/SkillExperienceStore";

const JobStore = {
	form: {
		description: "",
		minExperience: 0,
		maxExperience: 5,
		jobSkills: [],
		jobLanguages: [],
		jobEducationDegrees: [],
		state: "",
	},
	jobPost: {},
	jobPostState: '',
	jobPosts: [],
	applicationForm: {
		applicationType: '',
		applicationValue: JobPostApplicationTypes.Email,
	},
	rewardsJobPosts: [],
}

class JobStores extends BaseStore<IJobStoreState, JobService> {
	initializeStore() {
		return this.useStore();
	}
	benefits() {
		return this.optionsMapper(benefitStore.state.benefits);
	}
	sectors() {
		return this.optionsMapper(sectorStore.state.sectors);
	}
	jobLevels() {
		return this.optionsMapper(jobLevelStore.state.jobLevels);
	}
	departments() {
		return this.optionsMapper(departmentStore.state.departments);
	}
	locationTypes() {
		return this.optionsMapper(locationTypeStore.state.locationTypes);
	}
	jobTypes() {
		return this.optionsMapper(jobTypeStore.state.jobTypes);
	}
	jobSkills() {
		return this.optionsMapper(skillStore.state.skills);
	}
	locations() {
		const locations = locationStore.state.locations;

		let locationsWithCountryName = locations.map((item: any) => {
			if (item.name === item.countryName) {
				return { id: item.id, name: item.name };
			}

			return { id: item.id, name: `${item.name}, ${item.countryName}` };
		});

		locationsWithCountryName = locationsWithCountryName.sort(function (a, b) {
			return a.name.localeCompare(b.name, 'tr', { sensitivity: 'base' });
		});

		return this.optionsMapper(locationsWithCountryName);
	}
	company() {
		return companyStore.state.company;
	}
	selectedBloks() {
		return jobBlokStore.state.selectedBloks;
	}
	async fetchForSummary(id: string) {
		this.state.isLoading = true;

		try {
			await Promise.all([
				sectorStore.fetchSectors(),
				benefitStore.fetchBenefits(),
				jobLevelStore.fetchJobLevels(),
				departmentStore.fetchDepartments(),
				locationTypeStore.fetchLocationTypes(),
				jobTypeStore.fetchJobTypes(),
				locationStore.fetchLocations(),
				companyStore.fetchCompany()]
			);

			if (!!id) {
				return await this.service.fetchJobPost(id);
			}

		} catch {
			this.goToNotFound();
		} finally {
			this.state.isLoading = false;
		}
	}
	async fetchJobPost(id) {
		try {
			this.state.isLoading = true;
			const { data } = await this.service.fetchJobPost(id);

			this.state.jobPost = data.result;
			this.state.jobPostState = data.result.state;
			this.state.isLoading = false;

			return data;
		} catch {
			this.goToNotFound();
		}
	}
	async fetchForDetail(id: string) {
		try {
			this.state.isLoading = true;

			await Promise.all([
				skillStore.fetchSkills(),
				languageStore.fetchLanguages(),
				languageStore.fetchLanguageLevels(),
				educationDegreeStore.fetchEducationDegrees(),
				skillExperienceStore.fetchSkillExperiences(),
			]);

			if (!!id) {
				const response = await this.service.fetchJobPost(id);

				if (response?.data?.result) {
					const result = response.data.result;
					this.state.jobPostState = result.state;
					this.setFormData(result);
				}
			}
		} catch {
			this.goToNotFound();
		} finally {
			this.state.isLoading = false;
		}
	}

	setFormData(data) {
		this.state.form = {
			...data,
			description: data.description,
			minExperience: data.minExperience || 0,
			maxExperience: data.maxExperience || 5,
			jobSkills: data?.jobSkills?.sort((a, b) => Number(b.isRequired) - Number(a.isRequired)),
			jobLanguages: data.jobLanguages,
			jobEducationDegrees: data.jobEducationDegrees,
			state: data.state || '',
		}
	}

	async fetchJobPostsByCompany() {
		try {
			this.state.isLoading = true;
			const company = await companyStore.fetchCompany();
			const jobPostsResponse = await this.service.fetchJobPostsByCompany(company.id);
			this.state.jobPosts = jobPostsResponse.data.result || [];
		} finally {
			this.state.isLoading = false;
		}
	}
	async createJobPost(payload) {
		try {
			this.state.isSaving = true;

			return await this.service.createJobPost({
				...payload,
				candidateCountOfHire: parseInt(payload.candidateCountOfHire, 10),
				companyId: companyStore.state.company.id,
				bloks: this.selectedBloks()
			});
		} catch (error) {
			const errorMessages = errorHandler(error);
			if (Object.keys(errorMessages!).length) {
				this.state.hasError = true;
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}
	async updateJobPost(id, data) {
		try {
			this.state.isSaving = true;

			const payload = {
				name: data.name,
				jobLevelId: data.jobLevelId,
				canManageTeam: data.canManageTeam,
				minSalary: parseInt(data.minSalary, 10),
				maxSalary: parseInt(data.maxSalary, 10),
				canSalaryVisible: data.canSalaryVisible,
				jobTypeId: data.jobTypeId,
				companyId: companyStore.state.company.id,
				candidateCountOfHire: parseInt(data.candidateCountOfHire, 10),
				sectorId: data.sectorId,
				departmentId: data.departmentId,
				locationTypeId: data.locationTypeId,
				locationId: data.locationId,
				benefits: data.benefits,
				publishType: data.publishType,
			}

			return await this.service.updateJobPost(id, payload);
		} catch (error: any) {
			this.goToForbidden(error);
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.hasError = true;
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}
	async updateJobDetails(id, data) {
		try {
			this.state.isSaving = true;
			const payload = {
				...data,
				jobLanguages: data.jobLanguages.filter(language => !!language.id && !!language.levelId)
			}

			return await this.service.updateJobPostDetails(id, payload);
		} catch (error: any) {
			this.goToForbidden(error);
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.hasError = true;
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}
	async updateSettings(id, payload) {
		try {
			this.state.isSaving = true;
			return await this.service.updateSettings(id, payload);
		} catch (error: any) {
			this.goToForbidden(error);
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.hasError = true;
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	getPayload(saveAsDraft: boolean) {
		return {
			...this.state.form,
			saveAsDraft,
			jobSkills: this.state.form.jobSkills
				.filter(skill => skill.id !== "" || skill.skillExperience.id !== ""),
		};
	}

	async updateJobPostReward(id, payload) {
		try {
			this.state.isSaving = true;
			return await this.service.updateJobPostReward(id, payload);
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.hasError = true;
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}
	async updateApplicationType(id, payload) {
		try {
			this.state.isSaving = true;
			const result = await this.service.updateApplicationType(id, payload);

			return result;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}
	async fetchRewardsJobPosts() {
		this.state.isLoading = true;
		const accountType = await userStore.fetchAccountType();

		if (accountType !== AccountTypes.BlokOwner) {
			this.goToForbidden(true);

			return;
		}

		const { data } = await this.service.fetchAllRewardJobPosts();

		this.state.rewardsJobPosts = data?.result || [];
		this.state.isLoading = false;

		return data.result;
	}

	getReward(price) {
		return rewardJobsStore.getReward(price);
	}
	optionsMapper(options) {
		return options.map(option => { return { label: option.name, value: option.id } });
	}
	setErrors(errors: { [key: string]: string; }) {
		this.state.errors = errors;
	}
	isDraft(state) {
		return state === State.Draft;
	}
	isPublished(state) {
		return state === State.Published;
	}
	isUnpublished(state) {
		return state === State.UnPublished;
	}
	isApproved(state) {
		return state === RowStates.Approved;
	}
	isPending(state) {
		return state === State.Pending;
	}
	isDeclined(state) {
		return state === State.Declined;
	}
	filterJobPostByState(jobPosts, state) {
		return jobPosts.filter(jobPost => jobPost.state === state);
	}
	getAverageSalary(minSalary, maxSalary) {
		return ((parseInt(minSalary) + parseInt(maxSalary)) / 2).toString();
	}
	getStatus(state) {
		if (state === RowStates.Draft) {
			return { text: __('JobPost.Draft'), type: UIState.Info }
		} else if (state === RowStates.Published || state === RowStates.Approved) {
			return { text: __('JobPost.Published'), type: UIState.Success }
		} else if (state === RowStates.Pending) {
			return { text: __('JobPost.Pending'), type: UIState.Warning }
		} else if (state === RowStates.Unpublished) {
			return { text: __('JobPost.Unpublished'), type: UIState.Danger }
		} else if (state === RowStates.Declined) {
			return { text: __('JobPost.Declined'), type: UIState.Danger }
		}

		return { text: '', type: UIState.Default }
	}

	getFilteredJobPostBy(type, jobPosts) {
		return jobPosts.filter(jobPost => jobPost.state === type);
	}

	showSaveAsDraftButton(id, state) {
		return id && state === RowStates.Draft;
	}

	getDashboardRedirectLinkByPublishType(publishType) {
		return publishType === JobPostPublishTypes.Blok ? PageRoutes.DashboardBlokJobs : PageRoutes.DashboardRewardJobs;
	}

	async unpublishJobPost(jobPostId, payload) {
		try {
			this.state.isSaving = true;
			const result = await this.service.unpublishJobPost(jobPostId, { ...payload, state: RowStates.Unpublished });

			return result;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}
}

export default new JobStores(JobStore, JobService);
