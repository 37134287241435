import React from "react";
import { __ } from "@/utils/Trans";
import { UIState } from "@/utils/Enums";
import SelectBox, { ISelectBoxResult } from "@/js/components/SelectBox/SelectBox";
import skillStore from "../../skills/store/SkillStore";
import skillExperienceStore from "../store/SkillExperienceStore";
import TrashIconV2 from "@/assets/icons/TrashIconV2";

enum SkillType {
   Skill = 'skill',
   Experience = 'experience'
}

interface ISkill {
   label: string;
   value: string;
   isRequired?: boolean;
   __isNew__?: boolean;
   skillExperience: { id: string; name: string; languages: any[] }
}

interface IAddSkillWithExperienceLevel {
   hasRequiredSwitch?: boolean;
   onChange: (data: any) => void;
   errors: { [key: string]: string };
   title?: string;
   data: ISkill[]
   skillInputName: string;
}

export default function AddSkillWithExperienceLevelV2(props: IAddSkillWithExperienceLevel) {
   const [skills, setSkills] = React.useState<typeof props.data>(props.data);
   const [errors, setErrors] = React.useState<{ index: number, type: SkillType }[]>([]);

   const handleAddSkill = () => {
      setErrors([])

      const defaultOption = {
         label: '',
         value: '',
         skillExperience: { id: '', name: '', languages: [] }
      }

      const emptyInputs = skills.reduce((emptyIndexes: typeof errors, skill, index) => {
         if (!skill.value) {
            emptyIndexes.push({ index, type: SkillType.Skill });
         }

         if (!skill.skillExperience.id) {
            emptyIndexes.push({ index, type: SkillType.Experience });
         }

         return emptyIndexes;
      }, []);

      if (!!emptyInputs.length) {
         setErrors(emptyInputs)
         return;
      }

      setSkills([...skills, defaultOption])
   }

   const onChangeSkill = (data: ISkill, index: number) => {
      setErrors(errors.filter(error => error.index !== index))

      const newState = [...skills]

      if (data.__isNew__) {
         skillStore.addSkill(data)
      }

      newState[index].value = data.value
      newState[index].label = data.label
      newState[index]['isNew'] = data.__isNew__ || false
      newState[index].isRequired = newState[index].isRequired || false

      setSkills(newState)
   }


   const onChangeSkillExperience = (data: ISelectBoxResult, index: number) => {
      setErrors(errors.filter(error => error.index !== index))

      const newState = [...skills]

      newState[index].skillExperience = {
         id: data.value,
         name: data.label,
         languages: newState[index].skillExperience.languages
      }

      setSkills(newState)
   }

   const handleRemoveButtonClick = (inputIndex: number) => {
      setErrors([])
      setSkills(prevSkills => prevSkills.filter((_, index) => index !== inputIndex));
      props.onChange(skills)
   };

   const getSkills = (selectedSkillValue: string) => {
      return skillStore.optionsMapper()
         .filter(skill => {
            return !skills.map(addedSkill => addedSkill.value)
               .includes(skill.value) || selectedSkillValue === skill.value
         })
   }

   const getError = (index: number, type: SkillType) => {
      if (errors.some(error => error.index === index) && errors.some(error => error.type === type)) {
         return UIState.Error
      }

      return {} as UIState
   }

   React.useEffect(() => {
      if (props?.data?.length) {
         setSkills(props.data)
      } else {
         const defaultOption = {
            label: '',
            value: '',
            skillExperience: { id: '', name: '', languages: [] }
         }

         setSkills([defaultOption])
      }
   }, [props?.data]);

   React.useEffect(() => {
      props.onChange(skills)
   }, [skills.length]);

   return (
      <div className={`flex flex-col w-full gap-4`}>
         {skills?.map((skill, index) => (
            <div className={"flex items-center gap-[10px]"} key={skill.value}>
               <SelectBox
                  canCreatable
                  isListVirtualized
                  showTooltip
                  key={skill.value}
                  isValueNew={skill['isNew']}
                  classes={"w-[65%]"}
                  data={getSkills(skill?.value)}
                  stateType={getError(index, SkillType.Skill)}
                  onChange={data => onChangeSkill(data, index)}
                  inputName={props.skillInputName}
                  value={skill.value}
                  label={index === 0 ? __("AddSkillWithExperienceLevel.Skills") : undefined}
                  placeholder={__("AddSkillWithExperienceLevel.SkillPlaceholder")}
                  name={props.skillInputName}
               />
               <SelectBox
                  classes={"self-end w-[35%]"}
                  canSearchable={false}
                  key={skill.skillExperience.id}
                  data={skillExperienceStore.optionsMapper()}
                  stateType={getError(index, SkillType.Experience)}
                  name={props.skillInputName}
                  inputName={props.skillInputName}
                  onChange={data => onChangeSkillExperience(data, index)}
                  value={skill?.skillExperience?.id}
                  placeholder={__("AddSkillWithExperienceLevel.ExperienceLevelPlaceholder")}
               />
               {(skill.value || skill.skillExperience.id) && (
                  <button onClick={() => handleRemoveButtonClick(index)} className={`self-center ${index === 0 ? "mt-6" : ""}`}>
                     <TrashIconV2 />
                  </button>
               )}
            </div>
         ))}
         <div>
            {!!errors.length && (
               <p className="-mt-2 mb-4 text-body-regular-b6 text-error-400">
                  {__("AddSkillWithExperienceLevel.Error")}
               </p>
            )}
            <button onClick={handleAddSkill} className={"w-full border border-shade-stroke bg-shade-white rounded-lg py-3 px-4"} >
               <p className={"text-center text-body-semibold-b5 text-shade-black"}>
                  {__('AddSkillWithExperienceLevel.Add')}
               </p>
            </button>
         </div>
         {props.errors && props.errors[props.skillInputName] && (
            <div className={"text-red-400 text-sm font-medium "}>
               <p className="mt-1">{__(props.errors[props.skillInputName])}</p>
            </div>
         )}
      </div>
   )
}