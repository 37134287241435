import React from 'react';
import {PageRoutes} from '@/utils/PageRoutes';
import {Route} from 'react-router-dom';
import ContactLayout from '@/js/layouts/landing/ContactLayout';
import LandingLayout from '@/js/layouts/landing/LandingLayout';
import AuthLayoutForPublic from '@/js/layouts/auth/AuthLayoutForPublic';
import GuestGuard from '../Guards/GuestGuard';
import CommonLayout from '@/js/layouts/common/CommonLayout';
import {LinkedInCallback} from 'react-linkedin-login-oauth2';
import DemoLayout from '@/js/layouts/landing/DemoLayout';

// auth
const WelcomePage = React.lazy(() => import('@/js/modules/auth/pages/WelcomePage'));
const SignInPage = React.lazy(() => import('@/js/modules/auth/pages/SignInPage'));
const SignUpPage = React.lazy(() => import('@/js/modules/auth/pages/SignupPage'));
const ResetPasswordPage = React.lazy(() => import('@/js/modules/auth/pages/ResetPasswordPage'));
const ForgotPasswordPage = React.lazy(() => import('@/js/modules/auth/pages/ForgotPasswordPage'));
const VerificationScreen = React.lazy(() => import('@/js/modules/auth/pages/VerificationScreen'));
// other
const PostDetailPage = React.lazy(() => import('@/js/modules/posts/pages/PostDetailPage'));
const BlogsPage = React.lazy(() => import('@/js/modules/blog/pages/BlogsPage'));
const NotFoundPage = React.lazy(() => import('@/js/layouts/NotFound/NotFound'));
const ForbiddenPage = React.lazy(() => import('@/js/layouts/Forbidden/Forbidden'));
const CompanyPublicPage = React.lazy(() => import('@/js/modules/company/pages/CompanyPublicPage'));
// blok
const BlokPublicPage = React.lazy(() => import('@/js/modules/bloks/pages/BlokPublicPage'));
const RedirectBlokPublicPage = React.lazy(() => import('@/js/modules/bloks/pages/RedirectBlokPublicPage'));
const DiscoverBloksPage = React.lazy(() => import('@/js/modules/bloks/pages/DiscoverBloksPage'));
const PackageDetailPage = React.lazy(() => import('@/js/modules/packages/pages/PackageDetailPage'));
const PerkDetailPage = React.lazy(() => import('@/js/modules/perks/pages/PerkDetailPage'));
// event
const EventDetailPage = React.lazy(() => import('@/js/modules/events/pages/EventDetailPage'));
// landing
const CompaniesLanding = React.lazy(() => import('@/js/modules/landing/pages/CompaniesLanding'));
const TalentsLanding = React.lazy(() => import('@/js/modules/landing/pages/TalentsLanding'));
const CommunitiesLanding = React.lazy(() => import('@/js/modules/landing/pages/CommunitiesLanding'));
const FAQ = React.lazy(() => import('@/js/modules/landing/pages/FAQ'));
const ContactPage = React.lazy(() => import('@/js/modules/landing/pages/ContactPage'));
const RequestDemoPage = React.lazy(() => import('@/js/modules/landing/pages/RequestDemoPage'));
const ContractPage = React.lazy(() => import('@/js/modules/contracts/pages/ContractPage'));
const AboutUsPage = React.lazy(() => import('@/js/modules/landing/pages/AboutUsPage'));
const JobPostPage = React.lazy(() => import('@/js/modules/jobs/pages/JobPostPage'));
const PricingPage = React.lazy(() => import('@/js/modules/landing/pages/PricingPage'));

const PublicRoutes = (
    <>
        <Route path={PageRoutes.LinkedInCallback} element={<LinkedInCallback />} />

        <Route element={<LandingLayout />}>
            <Route path={PageRoutes.LandingForCompanies} element={<CompaniesLanding />} />
            <Route path={PageRoutes.LandingForCommunities} element={<CommunitiesLanding />} />
            <Route path={PageRoutes.LandingPageForTalents} element={<TalentsLanding />} />
            <Route path={PageRoutes.FAQ} element={<FAQ />} />
            <Route path={PageRoutes.AboutUs} element={<AboutUsPage />} />
            <Route path={PageRoutes.DWSPlus} element={<BlogsPage />} />
            <Route path={PageRoutes.Contract} element={<ContractPage />} />
            <Route path={PageRoutes.Pricing} element={<PricingPage />} />
        </Route>

        <Route path={PageRoutes.Landing} element={<ContactLayout />}>
            <Route path={PageRoutes.Contact} element={<ContactPage />} />
        </Route>

        <Route path={PageRoutes.Landing} element={<DemoLayout />}>
            <Route path={PageRoutes.RequestDemo} element={<RequestDemoPage />} />
        </Route>

        <Route path={PageRoutes.Verification} element={<VerificationScreen />} />

        <Route element={<GuestGuard />}>
            <Route element={<AuthLayoutForPublic />}>
                <Route path={PageRoutes.WelcomePage} element={<WelcomePage />} />
                <Route path={PageRoutes.ForgotPassword} element={<ForgotPasswordPage />} />
                <Route path={PageRoutes.SignIn} element={<SignInPage />} />
                <Route path={PageRoutes.SignUp} element={<SignUpPage />} />
                <Route path={PageRoutes.SignIn}>
                    <Route path={PageRoutes.ResetPassword} element={<ResetPasswordPage />} />
                </Route>
            </Route>
        </Route>

        <Route element={<CommonLayout />}>
            <Route path={PageRoutes.OldBlokPublicPage} element={<RedirectBlokPublicPage />} />
            <Route path={PageRoutes.BlokPublicPage} element={<BlokPublicPage />} />
            <Route path={PageRoutes.DiscoverBloks} element={<DiscoverBloksPage />} />
            <Route path={PageRoutes.CompanyPublicPage} element={<CompanyPublicPage />} />
            <Route path={PageRoutes.JobPostPage} element={<JobPostPage />} />
            <Route path={PageRoutes.PostDetailPage} element={<PostDetailPage />} />
            <Route path={PageRoutes.PackageDetailPage} element={<PackageDetailPage />} />
            <Route path={PageRoutes.EventDetailPage} element={<EventDetailPage />} />
            <Route path={PageRoutes.PerkDetailPage} element={<PerkDetailPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
        <Route path={PageRoutes.NotFound} element={<NotFoundPage />} />
        <Route path={PageRoutes.Forbidden} element={<ForbiddenPage />} />
    </>
);

export default PublicRoutes;
