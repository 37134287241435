export default function ChevronUpV2() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="21"
         fill="none"
         viewBox="0 0 20 21"
      >
         <path
            fill="#3A0087"
            fillRule="evenodd"
            d="M16.913 13.663a.938.938 0 01-1.326 0L10 8.076l-5.587 5.587a.938.938 0 01-1.326-1.326l6.25-6.25a.938.938 0 011.326 0l6.25 6.25a.938.938 0 010 1.326z"
            clipRule="evenodd"
         ></path>
      </svg>
   )
}