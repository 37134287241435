import BaseStore from "@/js/core/BaseStore";
import { IResponse } from '@/js/modules/dashboard/store/UserStore.types';
import StoryService from '@/js/modules/stories/services/StoryService';

const StoryStoreState = {
  stories: []
}

interface IState {
  stories: { type: string, url: string }[]
}

class StoryStore extends BaseStore<IState, StoryService> {
  initializeStore() {
    return this.useStore();
  }

  async create(payload): Promise<IResponse | any> {
    try {
      this.state.isLoading = true;

      return await this.service.create(payload);
    } catch (error: any) {
      const errors = error.response.data.validations?.reduce((acc, curr) => ({
        ...acc,
        [curr.field]: curr.code
      }), {});

      if (Object.keys(errors!).length) {
        this.state.hasError = true;
        this.state.errors = errors;
      }
    } finally {
      this.state.isLoading = false;
    }
  }

  async fetchByEntityType(entityId, entityType) {
    try {
      const result = await this.service.fetchByEntityType(entityId, entityType);
      this.state.stories = result.data.result;

      return Promise.resolve(this.state.stories);
    } catch {
      return Promise.resolve();
    }
  }

  async destroy(id) {
    try {
      await this.service.destroy(id);

      this.state.stories = [];

      return Promise.resolve(true)
    } catch {
      return Promise.resolve(false)
    }
  }
}

export default new StoryStore(StoryStoreState, StoryService);
