export const emailRegex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
export const onlyTextAndDotsRegex = new RegExp(/^[a-zA-ZğüşöçİĞÜŞÖÇ. ]*$/);
export const onlyNumberAndFourDigitsRegex = new RegExp(/^[0-9]*$/);
export const turkishPhoneNumberRegex = new RegExp(/^(\+?90|0)?[0-9]{0,10}$/);
export const validUrlRegex = new RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)
export const flexibleValidUrlRegex = new RegExp(/^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/)
export const linkedInPublicUrlRegex = new RegExp(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm)

export const defaultTitleMaxLength = 60 as const

export const validLinkedinUrlArray = [
	'https://www.linkedin.co',
	'https://linkedin.co',
	'https://linkedin.com',
	'www.linkedin.co',
	'linkedin.co',
	'linkedin.com'
]
