import TextBox from "@/js/components/TextBox/TextBox";
import OnboardingBottom from "../OnboardingBottom";
import { __ } from "@/utils/Trans";
import blokStore from "@/js/modules/bloks/store/BlokStore";
import TextArea from "@/js/components/TextArea/TextArea";
import AddSocialMediaLinkInfo from "@/js/modules/socialMedias/components/AddSocialMediaLinkInfo";
import { useQuery } from "@tanstack/react-query";
import socialAccountStore from "@/js/modules/socialMedias/store/SocialAccountStore";
import React from 'react'
import Skeleton from "react-loading-skeleton";
import ReadMore from "@/js/components/ReadMore/ReadMore";
import { useOutletContext } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import { validateSocialUrls } from "@/js/helpers/validateSocialUrls";
import MediaUploader from "@/js/components/MediaUploader/MediaUploader";
import { defaultTitleMaxLength } from "@/utils/Regex";

const maxDescriptionLength = 248

export default function OnboardingBlokOwnerDetails() {
	blokStore.initializeStore(blokStore.state)

	const [isImageLoading, setIsImageLoading] = React.useState(false)

	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()

	const handleContinueClick = () => {
		blokStore.resetErrors()

		const errors = validateSocialUrls(blokStore.state.form.socialAccounts)
		blokStore.state.errors = errors

		if (blokStore.state.form.name.length > defaultTitleMaxLength) {
			blokStore.state.errors = {
				...blokStore.state.errors,
				name: "Validations.DefaultTitleMaxLength"
			}
			return
		}

		if (!!Object.keys(errors).length) return;

		handleChangeStep()
	}

	const handleSocialMedia = (data) => {
		blokStore.set('socialAccounts', data)
		blokStore.resetSocialMediaErrors();
	}

	useQuery({
		queryKey: ['socialMediaProviders'],
		queryFn: async () => {
			await socialAccountStore.fetchSocialAccounts()
			return true
		}
	})

	const blok = blokStore.state.form || {}

	return (
		<div className="flex flex-col justify-center items-center">
			<div className="flex justify-end w-full onboarding-full-height xl:pt-[2%]">
				<div className="flex flex-col justify-start w-full xl:pt-6 xl:px-4 overflow-y-auto onboarding-scrollbar xl:p-0 xl:w-10/12 xl:justify-center xl:flex-row">
					<div className="px-4 py-6 w-full flex flex-col mx-auto gap-10 xl:h-auto xl:p-1 xl:max-w-xl ">
						<div>
							<h1 className={"text-header-bold-h8 text-shade-black text-left md:text-center md:text-header-bold-h5"}>
								{__("OnboardingPage.Details.Title")}
							</h1>
							<p className={"text-body-regular-b5 mt-4 text-shade-black md:text-center"}>
								{__("OnboardingPage.Details.Description")}
							</p>
						</div>
						<div className="flex flex-col gap-8">
							<MediaUploader
								imageSrc={blok.avatarUrl}
								label={"upload-blok-photo"}
								isUploadingImage={isImageLoading}
								title={__("OnboardingPage.Details.UploadProfileImage")}
								mediaInfo={__("OnboardingPage.Details.ImageMax")}
								onDeleteClick={() => blok.avatarUrl && blokStore.set('avatarUrl', '')}
								onChange={url => blokStore.set('avatarUrl', url)}
								setIsUploadingImage={setIsImageLoading}
							/>
							<TextBox name={'txt_blokName'}>
								<TextBox.Label>
									{__("CreateBlokStepForm.BlokName")}
								</TextBox.Label>
								<TextBox.Input
									name={'name'}
									error={blokStore.state.errors}
									attr={{
										name: 'name',
										maxLength: 100,
										value: blokStore.state.form.name,
										placeholder: __("CreateBlokStepForm.BlokNamePlaceholder"),
										onChange: e => blokStore.set('name', e.target.value),
									}}
								/>
							</TextBox>
						</div>
						<TextArea
							label={__("CreateBlokStepForm.About")}
							attrs={{
								onChange: (e) => blokStore.set('description', e.target.value),
								value: blokStore.state.form.description,
								name: 'description',
							}}
							error={blokStore.state.errors}
							rowCount={4}
							maxWordCount={1000}
							name={'description'}
							placeholder={__("CreateBlokStepForm.AboutPlaceholder")}
						/>
						<div>
							<AddSocialMediaLinkInfo
								labelKey="OnboardingPage.BlokSocials"
								onChange={handleSocialMedia}
								providers={blokStore.providers()}
								errors={blokStore.state.errors}
								socialAccounts={blokStore.state.form.socialAccounts}
							/>
						</div>
					</div>
				</div>
				<BlokPreview isImageLoading={isImageLoading} />
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={
					!blokStore.state.form.name ||
					!blokStore.state.form.description ||
					!blokStore.state.form.avatarUrl
				}
			/>
		</div>
	)
}

const BlokPreview = (props: { isImageLoading: boolean }) => {
	const blok = blokStore.state.form || {}
	return (
		<div className="hidden lg:block xl:w-1/2 ml-1">
			<div className="h-full overflow-y-auto onboarding-scrollbar shadow rounded-tl-2xl">
				<div className="max-h-52 pb-6 h-full relative overflow-x-clip">
					<div className="bg-gradient-to-b from-violet-100 to-slate-50 h-2/5 rounded-tl-2xl" />
					<div className="relative w-full h-[60%]">
						<div className="absolute left-8 bottom-4 h-36 w-36">
							{
								props.isImageLoading
									? <Skeleton className={"w-36 h-36 rounded-20 border-2 border-shade-stroke"} />
									: <img
										className={`h-36 w-36 object-cover rounded-20 border-2 border-shade-stroke bg-neutral-100`}
										src={blok.avatarUrl ? blok.avatarUrl : 'https://www.thecolorapi.com/id?format=svg&named=false&hex=F3F5F6'}
									/>
							}
						</div>
						<div className="absolute  left-[calc(176px+25px)] mt-3 flex flex-col gap-3 w-full">
							{blok.name ? (
								<p className={'text-body-bold-b5 text-shade-black break-words break-word max-w-lg'}>
									{blok.name}
								</p>
							) : <p className={"h-6 w-2/3 bg-neutral-100 rounded-3xl"} />}
							<p className={"h-5 w-1/3 bg-neutral-100 rounded-3xl"} />
							<div className="flex items-center gap-2">
								{blokStore.state.form.skills?.map(skill => (
									<span key={skill.value} className={"text-body-semibold-b8 uppercase whitespace-nowrap bg-primary-100 rounded-full py-1 px-2 text-primary-700"}>
										{skill.label}
									</span>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="bg-neutral-50 w-full h-full p-6 flex gap-[14px]">
					<div className="h-min rounded-2xl bg-white flex flex-col gap-8 py-5 px-4">
						{Array(5).fill(0).map((_, index) => (
							<div key={index} className="flex items-center gap-[10px]">
								<span className={`w-5 h-5 ${index === 1 ? "bg-primary-50" : "bg-neutral-100"} rounded-full`} />
								<span className={`w-16 h-4 ${index === 1 ? "bg-primary-50" : "bg-neutral-100"} rounded-full`} />
							</div>
						))}
					</div>
					<div className="h-min rounded-2xl bg-white py-5 px-6 w-full flex flex-col gap-8">
						<div className="flex flex-col gap-3">
							<p className="text-shade-black text-body-bold-b6">
								{__("CreateBlokStepForm.About")}
							</p>
							<div className="max-w-md whitespace-pre-wrap break-words text-body-regular-b7 text-shade-black">
								{blok.description
									? blok.description.length > maxDescriptionLength
										? <ReadMore limit={248}>{blok.description}</ReadMore>
										: blok.description
									: (
										<div className="flex flex-col gap-2">
											{Array(3).fill(0).map((_, index) => (
												<div key={index} className="h-3 bg-neutral-100 rounded-full w-full" />
											))}
										</div>
									)}
							</div>
						</div>
						<div className={"divide-y flex flex-col"}>
							<div className={"flex items-center justify-between w-full pb-4"}>
								<span className={"text-shade-black text-body-bold-b7"}>
									{__("OnboardingPage.BlokOwnerDetails.TotalOutreach")}
								</span>
								{blok.outreach ? (
									<p className={"text-body-regular-b7 text-shade-black"}>
										{blok.outreach}
									</p>
								) : <div className="h-3 bg-neutral-100 rounded-full w-20" />}
							</div>
							<div className={"flex items-center justify-between w-full py-4"}>
								<span className={"text-shade-black text-body-bold-b7 w-full"}>
									{__("OnboardingPage.BlokOwnerDetails.Location")}
								</span>
								{!!blok.locations.length ? (
									<span className={"text-body-regular-b7 text-shade-black text-right"}>
										{blok.locations.slice(0, 2).map(location => location.label).join(', ') + (blok.locations.length > 2 ? '...' : '')}
									</span>
								) : <div className="h-3 bg-neutral-100 rounded-full w-1/2" />}
							</div>
							<div className={"flex items-center justify-between w-full py-4"}>
								<span className={"text-shade-black text-body-bold-b7 w-full"}>
									{__("OnboardingPage.BlokOwnerDetails.CommunitySkills")}
								</span>
								{!!blok.skills.length
									? (
										<span className={"text-body-regular-b7 text-shade-black"}>
											{blok.skills.slice(0, 3).map(skill => skill.label).join(', ') + (blok.skills.length > 3 ? '...' : '')}

										</span>
									)
									: <div className="h-3 bg-neutral-100 rounded-full w-1/2" />}
							</div>
							<div className={"flex items-center justify-between w-full py-4"}>
								<span className={"text-shade-black text-body-bold-b7"}>
									{__("OnboardingPage.BlokOwnerDetails.Industry")}
								</span>
								<div className="h-3 bg-neutral-100 rounded-full w-1/2" />
							</div>
							<div className={"flex items-center justify-between w-full py-4"}>
								<span className={"text-shade-black text-body-bold-b7"}>
									{__("OnboardingPage.BlokOwnerDetails.ExperienceLevel")}
								</span>
								<div className="h-3 bg-neutral-100 rounded-full w-1/2" />
							</div>
							<div className={"flex items-start justify-between w-full py-4"}>
								<span className={"text-shade-black text-body-bold-b7"}>
									{__("OnboardingPage.BlokOwnerDetails.SocialMediaAccounts")}
								</span>
								{!!blok.socialAccounts.length
									? (
										<div className="flex gap-2">
											{blok.socialAccounts.map((account, index) => (
												blokStore.providers().find(provider => provider.id === account.id) && (
													<div key={index} className={"w-9 h-9 bg-neutral-100 rounded-full flex items-center justify-center"}>
														<img
															className={"w-5 h-5"}
															src={blokStore.providers().find(provider => provider.id === account.id).iconUrl}
														/>
													</div>
												)
											))}
										</div>
									) : (
										<div className="flex flex-col gap-2">
											<div className="flex gap-2">
												{Array(5).fill(0).map((_, index) => (
													<span key={index} className={"w-9 h-9 bg-neutral-100 rounded-full"} />
												))}
											</div>
											<div className="flex gap-2">
												{Array(5).fill(0).map((_, index) => (
													<span key={index} className={"w-9 h-9 bg-neutral-100 rounded-full"} />
												))}
											</div>
										</div>
									)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
