import BaseService from "@/js/core/BaseService";
import { StoryEndpoints } from "./StoryEndpoints";

export default class StoryService extends BaseService {
  async create(payload) {
    return this.api().post(StoryEndpoints.CreateStory, payload);
  }

  async fetchByEntityType(entityId, entityType) {
    return this.api().get(StoryEndpoints.FetchByEntityType.replace(':entityId', entityId).replace(':entityType', entityType));
  }

  async destroy(id) {
    return this.api().delete(StoryEndpoints.DeleteStory.replace(':id', id));
  }
}
