import BaseStore from "@/js/core/BaseStore";
import JobService from "@/js/modules/jobs/services/JobService";
import companyStore from "@/js/modules/company/store/CompanyStore";
import { PriceSymbols } from "@/utils/Enums";
import jobStore from "@/js/modules/jobs/store/JobStore";

interface IRewardJobsStore { }

const rewardJobsStore = {
	jobPosts: [],
};

class RewardJobsStore extends BaseStore<IRewardJobsStore, JobService> {
	initializeStore() {
		return this.useStore();
	}

	async fetchRewardJobPosts() {
		try {
			this.state.isLoading = true;
			await companyStore.fetchCompany();

			const { data } = await this.service.fetchRewardJobPosts(companyStore.state.company.id);
			this.state.jobPosts = data.result;

			return data.result;
		} finally {
			this.state.isLoading = false;
		}
	}

	getReward(price) {
		return `${new Intl.NumberFormat().format(price)}${PriceSymbols.TL}`.replace(',', '.');
	}

	getStatus(state) {
		return jobStore.getStatus(state);
	}

	getFilteredJobPostBy(type) {
		return jobStore.getFilteredJobPostBy(type, this.state.jobPosts);
	}

	isPublished(state) {
		return jobStore.isPublished(state);
	}

	isDraft(state) {
		return jobStore.isDraft(state);
	}

	isUnpublished(state) {
		return jobStore.isUnpublished(state);
	}

	isPending(state) {
		return jobStore.isPending(state);
	}

	isDeclined(state) {
		return jobStore.isDeclined(state);
	}
}

export default new RewardJobsStore(rewardJobsStore, JobService) as RewardJobsStore;
