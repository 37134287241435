import React from "react";
import { UISize, UIState } from "@/utils/Enums";

interface IBadge {
	children: React.ReactNode | string,
	type?: UIState,
	isClickable?: boolean,
	onClick?: () => void,
	size?: UISize,
	customClass?: string,
}

function Badge(props: IBadge) {
	const { type = UIState.Default, isClickable = false } = props;

	const getClassNames = () => {
		if (type === UIState.Active) {
			return 'bg-primary-500 text-white dark:bg-indigo-400/10 dark:ring-indigo-400/30 dark:text-indigo-400 dark:font-medium'
		} if (type === UIState.Default) {
			return 'text-gray-500 bg-gray-50 dark:bg-gray-400/10 dark:text-gray-400 dark:font-medium'
		} else if (type === UIState.Info) {
			return 'bg-blue-50 text-blue-500 dark:bg-blue-400/10 dark:text-blue-400 dark:font-medium'
		} else if (type === UIState.Success) {
			return 'bg-success-50 text-success-500 dark:bg-success-500/10 dark:text-green-400 dark:font-medium'
		} else if (type === UIState.Danger) {
			return 'bg-red-50 text-red-700 dark:bg-red-400/10 dark:text-red-400 dark:font-medium'
		} else if (type === UIState.Warning) {
			return 'bg-yellow-50 text-yellow-700 dark:bg-yellow-400/10 dark:text-yellow-500'
		} else if (type === UIState.Idle) {
			return 'text-primary-700 bg-primary-50 !text-label-semibold-l8 !rounded-full dark:bg-gray-400/10 dark:text-gray-400 dark:font-medium'
		} else if (type === UIState.UserProfile) {
			return 'text-neutral-500 bg-neutral-100 !text-label-semibold-l8 !rounded-full dark:bg-gray-400/10 dark:text-gray-400 dark:font-medium'
		} else if (type === UIState.WorkPreferences) {
			return 'text-shade-black bg-white border border-neutral-300 !text-sm rounded-[8px] dark:bg-gray-400/10 dark:text-gray-400 dark:font-medium'
		}

		return '';
	}

	const getSize = () => {
		if (props.size === UISize.Sm) {
			return 'px-2 py-1 text-sm'
		}

		return 'px-3 py-2'
	}

	return (
		<>
			{
				isClickable
					? (<button className={`${getClassNames()} ${getSize()} rounded-xl inline font-medium `} onClick={props.onClick}>{props.children}</button>)
					: (<div className={`${getClassNames()} ${getSize()} rounded-xl inline font-medium ${props.customClass}`}>{props.children}</div>)
			}
		</>
	)
}

export default Badge;
