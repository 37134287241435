/* eslint-disable react-hooks/exhaustive-deps */
import LocalStorage from "@/js/helpers/localStorage";
import { PageRoutes } from "@/utils/PageRoutes";
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { matchPath } from 'react-router';
import localStorage from '@/js/helpers/localStorage';
import ONBOARDING_ROUTES from '@/js/router/constants';
import React from 'react'
import useUserStore from "@/js/modules/dashboard/store/useUserStore";
import { useLogout } from "@/js/hooks/useLogout/useLogout";
import { useNavigateByAccountType } from "@/js/hooks/useNavigateByAccountType/useNavigateByAccountType";
import { setSentryUserId } from "@/plugins/sentry";

export default function AuthGuard() {
	const logout = useLogout();
	const navigate = useNavigate();
	const { me, isLoading } = useUserStore();
	const [searchParams] = useSearchParams();
	const { getRedirectRoute } = useNavigateByAccountType()

	const isTalentPages = !!matchPath(PageRoutes.TalentCreate, window.location.pathname) ||
		!!matchPath(PageRoutes.TalentUpdate, window.location.pathname) ||
		!!matchPath(PageRoutes.TalentUpdateDetails, window.location.pathname);

	const isBlokPages = !!matchPath(PageRoutes.BlokOwnerCreate, window.location.pathname) ||
		!!matchPath(PageRoutes.BlokOwnerEdit, window.location.pathname) ||
		!!matchPath(PageRoutes.BlokCreate, window.location.pathname) ||
		!!matchPath(PageRoutes.BlokEdit, window.location.pathname) ||
		!!matchPath(PageRoutes.BlokVerify, window.location.pathname) ||
		!!matchPath(PageRoutes.OldBlokPublicPage, window.location.pathname) ||
		!!matchPath(PageRoutes.BlokOwnerCreate, window.location.pathname)

	const isCompanyPages = !!matchPath(PageRoutes.Company, window.location.pathname)
		|| !!matchPath(PageRoutes.CompanyEdit, window.location.pathname)

	const completeRegisterationPage = !!matchPath(PageRoutes.CompleteRegistration, window.location.pathname)
	const completeCompanyRegisterationPage = !!matchPath(PageRoutes.CompleteCompanyRegistration, window.location.pathname)
	const isTalentProfile = !!matchPath(PageRoutes.TalentProfilePage, window.location.pathname)

	React.useEffect(() => {
		me().then(async (currentUser) => {
			const searchToken = searchParams.get('token')

			if (searchToken && completeRegisterationPage) {
				if (currentUser?.isRegisterationCompleted) {
					return navigate(await getRedirectRoute())
				}

				return logout(`${PageRoutes.CompleteRegistration}?token=${searchToken}`, false)
			}

			if (searchToken && completeCompanyRegisterationPage) {
				if (currentUser?.isRegisterationCompleted) {
					return navigate(await getRedirectRoute())
				}

				return logout(`${PageRoutes.CompleteCompanyRegistration}?token=${searchToken}&activateCompany=true`, false)
			}

			LocalStorage.set('user', JSON.stringify(currentUser));
			const user = JSON.parse(localStorage.get('user') as string);
			setSentryUserId(currentUser?.id);

			if (!LocalStorage.has('accessToken') || !localStorage.has('user')) {

				if (!isLoading) {
					return navigate({ pathname: PageRoutes.SignIn, search: `redirect=${window.location.pathname}${window.location.search}` })
				}

				return navigate({ pathname: PageRoutes.SignIn, search: window.location.search })
			}

			if (!user?.emailVerifiedAt && location.pathname !== PageRoutes.SignUpMailActivation) {
				return navigate({ pathname: PageRoutes.SignUpMailActivation, search: window.location.search })
			}

			if (!user?.emailVerifiedAt && location.pathname !== PageRoutes.SignUpMailActivation && ONBOARDING_ROUTES.includes(window.location.pathname as PageRoutes)) {
				return <Outlet />
			}

			if (user?.isDetailsCompleted && user?.emailVerifiedAt && !user?.isOnboardingCompleted && !window.location.pathname.includes('onboarding') && !isTalentPages && !isBlokPages && !isCompanyPages) {
				return navigate({ pathname: PageRoutes.Onboarding, search: window.location.search })
			}

			//if (!isLoading && user?.isDetailsCompleted && user?.isOnboardingCompleted && ONBOARDING_ROUTES.includes(window.location.pathname as PageRoutes)) {
				//return navigate({ pathname: PageRoutes.Dashboard, search: window.location.search })
			//}
		}).catch(() => {

			LocalStorage.remove('accessToken')
			LocalStorage.remove('user')
			setSentryUserId('')

			if (completeRegisterationPage || completeCompanyRegisterationPage || isTalentProfile) return;

			return navigate({
				pathname: PageRoutes.SignIn,
				search: `redirect=${window.location.pathname}${window.location.search}`
			})
		})
	}, [window.location.pathname])

	return <Outlet />
}
