import CurrencyInput from "react-currency-input-field";
import './PriceInput.css'
import { BaseComponentProps } from "@/utils/BaseComponentProps";
import { UIState } from "@/utils/Enums";
import { textColors } from "@/js/components/TextBox/constants/textBoxConstants";
import { __ } from "@/utils/Trans";
import Tooltip from "@/js/components/Tooltip/Tooltip.tsx";
import Icon from "@/js/components/Icon/Icon.tsx";
import { Icons } from "@/utils/Icons.ts";
import { TooltipPlacement } from "@/js/components/Tooltip/TooltipTypes.ts";

interface IPriceInput extends BaseComponentProps {
	label?: string;
	isDisabled?: boolean;
	placeholder?: string;
	value?: string | number;
	onChange?: (e: any, n: any) => void;
	suffix?: string;
	prefix?: string;
	inputName?: string;
	maxLength?: number;
	stateType?: UIState;
	min?: number;
	max?: number;
	step?: number;
	allowZero?: boolean;
	error?: { [key: string]: string; };
	disableGroupSeparators?: boolean;
	tooltipText?: string;
}

function PriceInput(props: IPriceInput) {
	const { stateType = UIState.Default } = props;
	const value = props.allowZero && props.value == 0 ? 0 : (props.value ? props.value.toString() : '');

	const handleOnValueChange = (value: any) => {
		if (props.min !== undefined && value < props.min) {
			return;
		}

		if (props.max !== undefined && value > props.max) {
			return;
		}

		if (!value) return props?.onChange!('', props.inputName);

		return props?.onChange!(parseInt(value, 10), props.inputName);
	}

	const hasError = props.error?.[props.name!];

	return (
		<>
			{props.label && <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
				{props.label}
				{props.tooltipText &&
					<div className={"ml-1"}>
						<Tooltip content={props.tooltipText} name={"rewardTooltip"} placement={TooltipPlacement.Right}>
							<Icon name={Icons.Info} />
						</Tooltip>
					</div>}
			</label>}
			<div className={`price-input ${stateType} ${hasError && 'error'}`}>
				<CurrencyInput
					id="price-input"
					name={props.inputName}
					placeholder={props.placeholder}
					allowDecimals={false}
					allowNegativeValue={false}
					suffix={props.suffix}
					value={value}
					step={props.step}
					max={props.max}
					min={props.min}
					prefix={props.prefix}
					disabled={props.isDisabled}
					decimalSeparator=","
					groupSeparator="."
					disableGroupSeparators={props.disableGroupSeparators}
					maxLength={props.maxLength}
					onValueChange={handleOnValueChange}
				/>
				{props.error?.[props.name!] && (
					<p className={`${textColors[stateType]} text-sm absolute mt-1 font-medium`}>
						{<span className={"text-red-400"}>{__(props.error[props.name!])}</span>}
					</p>
				)}
			</div>
		</>
	)
}

export default PriceInput;
