import Button from "@/js/components/Buttons/Button"
import CheckBox from "@/js/components/CheckBox/CheckBox"
import CustomError from "@/js/components/CustomError/CustomError"
import { InputType } from "@/js/components/Input/InputType"
import SelectBox from "@/js/components/SelectBox/SelectBox"
import TextBox from "@/js/components/TextBox/TextBox"
import LocalStorage from "@/js/helpers/localStorage"
import companyStore from "@/js/modules/company/store/CompanyStore"
import { CompanyContract } from "@/js/modules/onboarding/components/company/OnboardingCompanyLastStep"
import { TalentContract } from "@/js/modules/onboarding/components/talent/OnboardingTalentLastStep"
import sectorStore from "@/js/modules/sectors/store/SectorStore"
import talentStore from "@/js/modules/talent/store/TalentStore"
import { AccountTypes, UIState } from "@/utils/Enums"
import { __ } from "@/utils/Trans"
import { useQuery } from "@tanstack/react-query"
import React from 'react'
import { Trans } from "react-i18next"
import { PhoneInput } from "react-international-phone"
import { useSearchParams } from "react-router-dom"

export default function EventOnboardingPage() {
	const accountType = LocalStorage.get('onboardingSelectedAccountType') as AccountTypes

	return (
		<div className={"full-height flex flex-col items-center w-full bg-shade-background p-4 lg:p-0 lg:pb-4"}>
			<div className="max-w-3xl mt-8 w-full bg-shade-white p-4 rounded-20 lg:p-10">
				{accountType === AccountTypes.Talent && <TalentForm />}
				{accountType === AccountTypes.CompanyOwner && <CompanyForm />}
			</div>
		</div>
	)
}

function TalentForm() {
	talentStore.initializeStore()

	const [searchParams] = useSearchParams()

	const handleSubmit = async () => {
		const redirect = searchParams.get('redirect')
		const slug = String(redirect?.split('/').pop())

		const response = await talentStore.createForEvent(slug)

		if (response?.data.result && redirect) {
			return window.location.href = redirect
		}
	}

	return (
		<React.Fragment>
			<h1 className={"text-header-bold-h8 mb-4 text-shade-black self-start md:self-auto"}>
				{__("OnboardingPage.TalentTitle.Title")}
			</h1>
			<div className={"w-full flex flex-col justify-center gap-6"}>
				<TextBox name={"txt_Title"}>
					<TextBox.Label>
						{__("OnboardingPage.TitleInputLabel")}
					</TextBox.Label>
					<TextBox.Input
						name={"title"}
						error={talentStore.state.errors}
						attr={{
							onChange: e => talentStore.set("title", e.target.value),
							name: "title",
							value: talentStore.state.form.title,
							maxLength: 200,
							placeholder: __("OnboardingPage.TitleInputPlaceholder")
						}}
					/>
				</TextBox>
				<TextBox name={"txt_LastEmployedCompany"}>
					<TextBox.Label>
						{__("CreateTalentPage.LastEmployedCompany")}
					</TextBox.Label>
					<TextBox.Input
						name={"lastWorkedCompany"}
						error={talentStore.state.errors}
						attr={{
							disabled: talentStore.state.form.isEmployed,
							onChange: e => {
								talentStore.set("lastWorkedCompany", e.target.value)
							},
							name: "lastWorkedCompany",
							value: talentStore.state.form.lastWorkedCompany,
							maxLength: 200,
							placeholder: __("CreateTalentPage.LastEmployedCompanyPlaceholder")
						}}
					/>
				</TextBox>
			</div>
			<div className={"self-start"}>
				<CheckBox className={"mt-4"}>
					<CheckBox.Input
						id={"isEmployed"}
						name={"isEmployed"}
						error={talentStore.state.errors}
						attrs={{
							type: "checkbox",
							name: "isEmployed",
							checked: talentStore.state.form.isEmployed,
							onChange: e => talentStore.set("isEmployed", e.target.checked),
						}}
					/>
					<CheckBox.Label htmlFor={"isEmployed"}>
						<span className={"text-body-regular-b5 text-shade-black"}>
							{__("CreateTalentPage.NotEmployed")}
						</span>
					</CheckBox.Label>
				</CheckBox>
			</div>
			<div className={"flex flex-col gap-2 mt-6 mb-4"}>
				<h1 className={"text-header-bold-h8  text-shade-black "}>
					{__("OnboardingPage.BlokOwnerContact.Title")}
				</h1>
				<p className={"text-body-regular-b5 text-shade-black "}>
					{__("OnboardingPage.BlokOwnerContact.Description")}
				</p>
			</div>
			<div className={"w-full flex flex-col gap-6"}>
				<div>
					<TextBox.Label>
						{__('LandingCommon.ContactLayout.PhoneNumber')}
					</TextBox.Label>
					<PhoneInput
						defaultCountry={"tr"}
						name={'phoneNumber'}
						value={talentStore.state.form.gsmNumber}
						onChange={(value) => talentStore.set('gsmNumber', value)}
						placeholder={__('LandingCommon.ContactLayout.PhoneNumber')}
						className={'mt-[10px] flex items-center rounded-lg border focus-within:border-primary-500 transition ease-in-out duration-150 focus:ring-0 focus:outline-none border-lavender-web-2 p-3 w-full '}
					/>
					<CustomError
						name="gsmNumber"
						stateType={UIState.Error}
						error={talentStore.state.errors}
					/>
				</div>
				<TextBox name={"txt_LinkedInProfile"} isOptional>
					<TextBox.Label>
						{__("CreateTalentPage.LinkedInUrl")}
					</TextBox.Label>
					<TextBox.Input
						name={"linkedInUrl"}
						error={talentStore.state.errors}
						attr={{
							onChange: e => talentStore.set("linkedInUrl", e.target.value),
							name: "linkedInUrl",
							value: talentStore.state.form.linkedInUrl,
							maxLength: 200,
							placeholder: 'https://www.linkedin.com/in/username'
						}}
					/>
				</TextBox>
				<div className="flex justify-start">
					<TalentContract />
				</div>
				<Button
					name="startBtn"
					classes="w-full"
					attrs={{ onClick: handleSubmit }}
					isLoading={talentStore.state.isSaving}
					disabled={
						(!talentStore.state.form.isEmployed && !talentStore.state.form.lastWorkedCompany)
					}
				>
					{__("OnboardingPage.TalentLastStep.Button")}
				</Button>
			</div>
		</React.Fragment>
	)
}

function CompanyForm() {
	companyStore.initializeStore()

	const [searchParams] = useSearchParams()

	useQuery({
		queryKey: ["sectors"],
		queryFn: async () => {
			await sectorStore.fetchSectors()
			return true
		},
	})

	const handleSubmit = async () => {
		const redirect = searchParams.get('redirect')
		const slug = String(redirect?.split('/').pop())

		const response = await companyStore.createForEvent(slug)

		if (response?.data.result && redirect) {
			return window.location.href = redirect
		}
	}

	return (
		<div className="flex flex-col gap-6">
			<div>
				<h1 className="text-header-bold-h8 w-full text-shade-black mb-4">
					{__("OnboardingPage.CompanyName.Title")}
				</h1>
				<div className={"w-full flex flex-col gap-5"}>
					<TextBox name={"title"} classes={"w-full"}>
						<TextBox.Input
							name={"title"}
							error={companyStore.state.errors}
							attr={{
								name: "title",
								type: InputType.Text,
								value: companyStore.state.form.title,
								onChange: e => companyStore.set('title', e.target.value),
								placeholder: __('CompanyPage.CompanyNamePlaceholder')
							}}
						/>
					</TextBox>
				</div>
			</div>
			<div>
				<h1 className="text-header-bold-h8 w-full text-shade-black mb-4">
					{__("OnboardingPage.CompanyIndustry.Title")}
				</h1>
				<SelectBox
					maxLength={35}
					name={"sectorId"}
					inputName={"sectorId"}
					error={companyStore.state.errors}
					value={companyStore.state.form.sectorId}
					classes={'w-full'}
					canSearchable={true}
					onChange={e => companyStore.set('sectorId', e.value)}
					data={companyStore.getSectors()}
					placeholder={__('CompanyPage.SectorPlaceholder')}
				/>
			</div>
			{companyStore.state.form?.title && (
				<div>
					<h1 className="text-header-bold-h8 w-full text-shade-black mb-4">
						<Trans
							i18nKey={"OnboardingPage.OnboardingCompanyPosition.Title"}
							values={{ company_name: companyStore.state.form.title }}
						/>
					</h1>
					<div className={"w-full flex flex-col gap-5"}>
						<TextBox name={"position"} classes={"w-full"}>
							<TextBox.Label>
								{__('OnboardingPage.OnboardingCompanyPosition.SubTitle')}
							</TextBox.Label>
							<TextBox.Input
								name={"position"}
								error={companyStore.state.errors}
								attr={{
									name: "position",
									type: InputType.Text,
									value: companyStore.state.form.position,
									onChange: e => companyStore.set('position', e.target.value),
									placeholder: __('OnboardingPage.OnboardingCompanyPosition.PlaceHolder')
								}}
							/>
						</TextBox>
					</div>
				</div>
			)}
			<div>
				<div className={"flex flex-col gap-2  mb-4"}>
					<h1 className={"text-header-bold-h8  text-shade-black "}>
						{__("OnboardingPage.BlokOwnerContact.Title")}
					</h1>
					<p className={"text-body-regular-b5 text-shade-black "}>
						{__("OnboardingPage.BlokOwnerContact.Description")}
					</p>
				</div>
				<div className={"w-full flex flex-col gap-6"}>
					<div>
						<TextBox.Label>
							{__('LandingCommon.ContactLayout.PhoneNumber')}
						</TextBox.Label>
						<PhoneInput
							defaultCountry={"tr"}
							name={'phoneNumber'}
							value={companyStore.state.form.gsmNumber}
							onChange={(value) => companyStore.set('gsmNumber', value)}
							placeholder={__('LandingCommon.ContactLayout.PhoneNumber')}
							className={'mt-[10px] flex items-center rounded-lg border focus-within:border-primary-500 transition ease-in-out duration-150 focus:ring-0 focus:outline-none border-lavender-web-2 p-3 w-full '}
						/>
						<CustomError
							name="gsmNumber"
							stateType={UIState.Error}
							error={companyStore.state.errors}
						/>
					</div>
				</div>
			</div>
			<div className="flex justify-start">
				<CompanyContract />
			</div>
			<Button
				name="startBtn"
				classes="w-full"
				attrs={{ onClick: handleSubmit }}
				isLoading={companyStore.state.isSaving}
			>
				{__("OnboardingPage.CompanyLastStep.Button")}
			</Button>
		</div>
	)
}
