import { useOutletContext, useSearchParams } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import { __ } from "@/utils/Trans";
import OnboardingBottom from "../OnboardingBottom";
import OnboardingSlider from "../OnboardingSlider";
import companyStore from "@/js/modules/company/store/CompanyStore";
import { useQuery } from '@tanstack/react-query';
import employeeCountStore from "@/js/modules/employeeCounts/store/EmployeeCountStore";
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";

export default function OnboardingCompanySize() {
  const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>();
  const [searchParams] = useSearchParams()

  const handleOnContinueClick = () => {
    handleChangeStep();
  };

  useQuery({
    queryKey: ['employeeCounts'],
    queryFn: async () => {
      await employeeCountStore.fetchEmployeeCount()
      return true
    }
  })

  return (
    <div className="flex flex-col justify-center items-center ">
      <div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} max-w-2xl flex-col xl:justify-center items-center gap-10 inline-flex xl:p-0 py-10 px-4`}>
        <div className="text-shade-black text-header-bold-h5">
          {__("OnboardingPage.OnboardingCompanySize.Title")}
        </div>
        <div className={"flex flex-col w-full px-12 mt-[70px]"}>
          <OnboardingSlider
            tickCount={companyStore.getEmployeeCounts()}
            offSet={{ min: -25, max: -150 }}
            infoText={(label) => `👨🏼‍💻 ${label} Employees`}
            tooltipText={__("LandingSlider.SellServices")}
            onReturn={value => companyStore.set('employeeCountId', value)}
          />
        </div>
      </div>
      <OnboardingBottom onClick={handleOnContinueClick} />
    </div>
  );
}
