export default function CheckPurpleIcon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="16"
         height="16"
         fill="none"
         viewBox="0 0 16 16"
      >
         <g clipPath="url(#clip0_5657_23515)">
            <path
               fill="#D3C2FA"
               d="M15.628 2.615a1.269 1.269 0 00-1.794 0l-8.077 8.077-3.59-3.59A1.27 1.27 0 10.372 8.897l4.487 4.487a1.265 1.265 0 001.795 0l8.974-8.974a1.27 1.27 0 000-1.795z"
            ></path>
         </g>
         <defs>
            <clipPath id="clip0_5657_23515">
               <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
         </defs>
      </svg>
   )
}