import { useOutletContext } from "react-router-dom"
import { OnboardingOutletContextType } from "../../constants/onbardingConstants"
import { UISize, UIState } from "@/utils/Enums"
import Badge from "@/js/components/Badge/Badge"
import OnboardingBottom from "../OnboardingBottom"
import SelectBox from "@/js/components/SelectBox/SelectBox"
import blokStore from "@/js/modules/bloks/store/BlokStore"
import { __ } from "@/utils/Trans"
import { useQuery } from "@tanstack/react-query"
import locationStore from "@/js/modules/locations/store/LocationStore"
import regionStore from "@/js/modules/regions/store/RegionStore"
import CheckBox from "@/js/components/CheckBox/CheckBox"
import Tooltip from "@/js/components/Tooltip/Tooltip"
import { TooltipPlacement } from "@/js/components/Tooltip/TooltipTypes"
import { Icons } from "@/utils/Icons"
import Icon from "@/js/components/Icon/Icon"

export default function OnboardingBlokOwnerLocation() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()

	const handleContinueClick = () => {
		if (blokStore.state.form.isGlobal) {
			blokStore.set('locations', [])
			blokStore.set('regions', [])
		}

		handleChangeStep()
	}

	useQuery({
		queryKey: ['locations', 'regions'],
		queryFn: async () => {
			await Promise.all([
				locationStore.fetchLocations(),
				regionStore.fetchRegions()
			])
			return true
		},
	})

	const locations = blokStore.locations()

	return (
		<div className="flex flex-col justify-center items-center">
			<div className={"onboarding-full-height py-10 px-4 flex flex-col justify-start items-center gap-7 max-w-2xl w-full xl:gap-10 xl:p-0 xl:justify-center"}>
				<div className={"flex flex-col gap-4"}>
					<div className={'w-full flex items-start flex-col gap-[14px] md:items-center'}>
						<Badge type={UIState.Success} size={UISize.Sm}>
							{__("Optional")}
						</Badge>
						<h1 className={"text-header-bold-h8 md:text-header-bold-h5 text-shade-black self-start md:text-center"}>
							{__("OnboardingPage.BlokOwnerSelectLocation.Title")}
						</h1>
					</div>
					<p className={"text-body-regular-b5 text-shade-black text-start md:text-center"}>
						{__("OnboardingPage.BlokOwnerSelectLocation.Description")}
					</p>
				</div>
				<div className={"w-full flex flex-col gap-4"}>
					<div className={'flex items-center gap-2 mb-2'}>
						<CheckBox>
							<CheckBox.Input
								name={'isGlobal'}
								id={'isGlobal'}
								attrs={{
									name: 'isGlobal',
									checked: blokStore.state.form.isGlobal,
									onChange: (e) => blokStore.set('isGlobal', e.target.checked),
								}}
							/>
							<CheckBox.Label htmlFor={'isGlobal'}>
								{__("CreateBlokStepForm.GlobalBlok")}
							</CheckBox.Label>
						</CheckBox>
						<Tooltip
							placement={TooltipPlacement.Right}
							classes={"info-tooltip"}
							content={__("CreateBlokStepForm.GlobalBlokDescription")}
							name={"rewardInfo"}
						>
							<Icon name={Icons.Info} />
						</Tooltip>
					</div>
					<SelectBox
						canBeMultiple
						classes={'w-full'}
						name={'regions'}
						inputName={'regions'}
						data={blokStore.regions()}
						error={blokStore.state.errors}
						isDisabled={blokStore.state.form.isGlobal}
						onChange={e => blokStore.set('regions', e)}
						value={blokStore.getSelectBoxValues('regions')}
						label={__("CreateBlokStepForm.Region")}
						placeholder={__("CreateBlokStepForm.RegionPlaceholder")}
					/>
					<SelectBox
						canBeMultiple
						classes={'w-full'}
						name={'locations'}
						inputName={'locations'}
						data={!!locations.length && locations}
						error={blokStore.state.errors}
						isDisabled={blokStore.state.form.isGlobal}
						onChange={e => blokStore.set('locations', e)}
						value={blokStore.getSelectBoxValues('locations')}
						label={__("CreateBlokStepForm.TypeRegionCountryOrState")}
						placeholder={__("CreateBlokStepForm.TypeRegionCountryOrStatePlaceholder")}
					/>

				</div>
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={
					!blokStore.state.form.isGlobal &&
					(!blokStore.state.form.locations.length && !blokStore.state.form.regions.length)
				}
			/>
		</div>
	)
}
