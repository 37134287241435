import { useOutletContext } from "react-router-dom";
import OnboardingBottom from "../OnboardingBottom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import Badge from "@/js/components/Badge/Badge";
import { UISize, UIState } from "@/utils/Enums";
import OnboardingSlider from "../OnboardingSlider";
import { __ } from "@/utils/Trans";
import blokStore from "@/js/modules/bloks/store/BlokStore";

export default function OnboardingBlokOwnerNetworkReach() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()

	const handleContinueClick = () => {
		handleChangeStep()
	}

	return (
		<div className="flex flex-col justify-center items-center">
			<div className={"onboarding-full-height py-10 px-4 flex flex-col justify-start items-center gap-24 max-w-2xl w-full xl:gap-28 xl:p-0 xl:justify-center"}>
				<div className={"flex flex-col gap-4"}>
					<div className={'w-full flex items-start flex-col gap-[14px] md:items-center'}>
						<Badge type={UIState.Success} size={UISize.Sm}>
							{__("Optional")}
						</Badge>
						<h1 className={"text-header-bold-h8 md:text-header-bold-h5 text-shade-black self-start md:self-auto md:text-center"}>
							{__("OnboardingPage.BlokOwnerNetworkReach.Title")}
						</h1>
					</div>
					<p className={"md:text-center text-body-regular-b5 text-shade-black text-start"}>
						{__("OnboardingPage.BlokOwnerNetworkReach.Description")}
					</p>
				</div>
				<div className={"w-full px-12"}>
					<OnboardingSlider
						value={blokStore.state.form.outreach}
						tickCount={blokStore.getCommunitySize()}
						offSet={{ min: -15, max: -45 }}
						infoText={label => `🚀 ${label}`}
						onReturn={value => blokStore.set('outreach', value)}
					/>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={!blokStore.state.form.outreach}
			/>
		</div>
	)
}
