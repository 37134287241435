import BaseStore from "@/js/core/BaseStore";
import { IEmployeeCountStore } from "./EmployeeCountTypes";
import EmployeeCountService from "../services/EmployeeCountService";

const employeeCountStore: IEmployeeCountStore = {
    employeeCounts: []
}

class EmployeeCountStore extends BaseStore<IEmployeeCountStore, EmployeeCountService> {
    async fetchEmployeeCount() {
        const { data } = await this.service.fetchEmployeeCounts()
        this.state.employeeCounts = data.result;
    }
}

export default new EmployeeCountStore(employeeCountStore, EmployeeCountService) as EmployeeCountStore;
