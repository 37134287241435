import BaseService from "@/js/core/BaseService";
import { CompanyEndpoints } from "./CompanyEndpoints";

export default class JobLevelService extends BaseService {
	async fetchCompany() {
		return this.api().get(CompanyEndpoints.FetchCompany);
	}
	async createCompany(payload: unknown) {
		return await this.api().post(CompanyEndpoints.Company, payload);
	}
	async fetchWithRelations(id: string) {
		return await this.api().get(CompanyEndpoints.FetchCompanyWithRelations.replace(':id', id));
	}
	async updateCompany(id: string, payload: unknown) {
		return await this.api().put(CompanyEndpoints.UpdateCompany.replace(':id', id), payload);
	}
	async checkIfCompanyCanCreateJobPost(companyId: string) {
		return await this.api().get(CompanyEndpoints.CheckJobPostPermission.replace(':id', companyId));
	}
	async getCompanyByAuthUser() {
		return await this.api().get(CompanyEndpoints.FetchCompanyByAuthUser);
	}
	async createForEvent(payload: unknown) {
		return await this.api().post(CompanyEndpoints.CreateForEvent, payload);
	}
	async updateCompanState(id: string, payload: unknown) {
		return await this.api().put(CompanyEndpoints.UpdateCompanyState.replace(':id', id), payload);
	}
	async updateImportedCompanyData(id: string, payload: unknown) {
		return await this.api().put(CompanyEndpoints.UpdateImportedCompany.replace(':id', id), payload);
	}

}
