import React from 'react'
import { Navigate, Route } from "react-router-dom"
import { PageRoutes } from "@/utils/PageRoutes";
import AdminLayout from "@/js/layouts/admin/AdminLayout";

const AdminCompaniesListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminCompaniesListPage'));
const AdminJobPostCandidatesListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminJobPostCandidatesListPage'));
const AdminBlokCompaniesListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminBlokCompaniesListPage'));
const AdminUserListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminUserListPage'));
const AdminBlokTalentListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminBlokTalentListPage'));
const AdminBlokListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminBlokListPage'));
const AdminJobPostListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminJobPostListPage'));
const AdminJobPostBlokListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminJobPostBlokListPage'));
const AdminEditTranslationsPage = React.lazy(() => import('@/js/modules/admin/pages/AdminEditTranslationsPage'));
const AdminBlogPostsListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminBlogPostsListPage'));
const AdminCreateBlogPostPage = React.lazy(() => import('@/js/modules/admin/pages/AdminCreateBlogPostPage'));
const AdminServiceListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminServiceListPage'));
const AdminCreateServicePage = React.lazy(() => import('@/js/modules/admin/pages/AdminCreateServicePage'));
const AdminPaymentListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminPaymentListPage'));
const AdminPerkListPage = React.lazy(() => import('@/js/modules/admin/pages/AdminPerkListPage'));
const AdminPerkCreatePage = React.lazy(() => import('@/js/modules/admin/pages/AdminCreatePerkPage.tsx'));

const AdminRoutes = (
   <Route element={<AdminLayout />} path={PageRoutes.Admin}>
      <Route index element={<Navigate to={PageRoutes.AdminBlokListPage.replace(":state", 'approved')} />} />
      <Route path={PageRoutes.AdminBlokListPage} element={<AdminBlokListPage />} />
		  <Route path={PageRoutes.AdminPerkListPage} element={<AdminPerkListPage/>}/>
      <Route path={PageRoutes.AdminEditTransaltionsPage} element={<AdminEditTranslationsPage />} />
      <Route path={PageRoutes.AdminJobPostListPage} element={<AdminJobPostListPage />} />
      <Route path={PageRoutes.AdminJobPostBloksListPage} element={<AdminJobPostBlokListPage />} />
      <Route path={PageRoutes.AdminUserListPage} element={<AdminUserListPage />} />
      <Route path={PageRoutes.AdminBlogPostsListPage} element={<AdminBlogPostsListPage />} />
      <Route path={PageRoutes.AdminBlogPostCreatePage} element={<AdminCreateBlogPostPage />} />
      <Route path={PageRoutes.AdminBlogPostEditPage} element={<AdminCreateBlogPostPage />} />
      <Route path={PageRoutes.AdminServiceListPage} element={<AdminServiceListPage />} />
      <Route path={PageRoutes.AdminServiceCreatePage} element={<AdminCreateServicePage />} />
      <Route path={PageRoutes.AdminServiceEditPage} element={<AdminCreateServicePage />} />
      <Route path={PageRoutes.AdminPaymentListPage} element={<AdminPaymentListPage />} />
      <Route path={PageRoutes.AdminBlokTalentsListPage} element={<AdminBlokTalentListPage />} />
      <Route path={PageRoutes.AdminBlokCompaniesListPage} element={<AdminBlokCompaniesListPage />} />
      <Route path={PageRoutes.AdminJobPostCandidatesListPage} element={<AdminJobPostCandidatesListPage />} />
      <Route path={PageRoutes.AdminCompaniesListPage} element={<AdminCompaniesListPage />} />
		  <Route path={PageRoutes.AdminCreatePerkPage} element={<AdminPerkCreatePage/>}/>
		 	<Route path={PageRoutes.AdminUpdatePerkPage} element={<AdminPerkCreatePage/>}/>
   </Route>
)

export default AdminRoutes
