import { tailwindColors } from "@/plugins/tailwind";
import React from "react";

function OtherServiceIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none">
      <circle cx="17.5" cy="17.5" r="17.5" fill={tailwindColors['secondary']['400']}></circle>
      <path
        fill="#fff"
        d="M14.257 16.9a1.205 1.205 0 100-2.41 1.205 1.205 0 000 2.41z"
      ></path>
      <path
        fill="#fff"
        d="M27.4 22.267V12.19A2.193 2.193 0 0025.21 10H11.19A2.193 2.193 0 009 12.19v10.077a2.193 2.193 0 002.19 2.19h.823a1.315 1.315 0 011.275.995l.138.552a1.314 1.314 0 002.264.547l1.44-1.645a1.312 1.312 0 01.989-.449h7.09a2.194 2.194 0 002.191-2.19zm-10.507-1.872a.545.545 0 01-.445.229.545.545 0 01-.54-.458l-.15-.9a1.52 1.52 0 00-3 0l-.15.9a.544.544 0 01-.634.45.547.547 0 01-.447-.627l.15-.901a2.6 2.6 0 011.05-1.685 2.3 2.3 0 113.057 0c.56.4.938 1.007 1.051 1.685l.15.9a.544.544 0 01-.092.407zm5.25-.21h-3.505a.548.548 0 010-1.095h3.505a.548.548 0 010 1.095zm2.19-2.628h-5.695a.548.548 0 010-1.095h5.695a.548.548 0 110 1.095zm0-2.629h-5.695a.548.548 0 010-1.095h5.695a.548.548 0 110 1.095z"
      ></path>
    </svg>
  );
}

export default OtherServiceIcon;
