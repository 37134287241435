import BaseService from "@/js/core/BaseService";
import { LanguageEndpoints } from "./LanguageEndpoints";

export default class LanguageService extends BaseService {
  async fetchLanguages() {
    return this.api().get(LanguageEndpoints.FetchLanguages);
  }
  async fetchLanguageLevels() {
    return this.api().get(LanguageEndpoints.FetchLanguageLevels);
  }
}
