import React from 'react';
import Cropper from 'react-easy-crop';
import Button from '../Buttons/Button';
import {Images} from '@/utils/Images';
import {Point, Area} from 'react-easy-crop';
import {getCroppedImage} from './canvasUtils';
import {__} from '@/utils/Trans';

interface IImageCropper {
    cropSize?: boolean;
    aspectRatio?: number;
    roundedAspect?: boolean;
    base64String: string;
    onReturn(file: File | null): void;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    onClose?: () => void;
}

const ImageCropper = (props: IImageCropper) => {
    const {base64String, onReturn, setIsEditing, aspectRatio, roundedAspect = false} = props;

    const [zoom, setZoom] = React.useState(1);
    const [crop, setCrop] = React.useState<Point>({x: 0, y: 0});
    const [rotation, setRotation] = React.useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area | null>(null);

    const handleClose = React.useCallback(() => {
        props.onClose?.();
        setIsEditing(false);
        setZoom(1);
        setRotation(0);
        setCrop({x: 0, y: 0});
        setCroppedAreaPixels(null);
    }, [setIsEditing]);

    const handleOnCropComplete = React.useCallback((_: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleGetEditedImage = React.useCallback(async () => {
        const editedImage = await getCroppedImage(base64String, croppedAreaPixels, rotation);
        onReturn(editedImage);
        handleClose();
    }, [base64String, croppedAreaPixels, rotation, handleClose, onReturn]);

    return (
        <>
            <div className="h-[374px] lg:h-80 relative mt-2">
                <Cropper
                    image={base64String || Images.EmptyProfileLogo}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={aspectRatio ? aspectRatio : 1 / 1}
                    cropShape={roundedAspect ? 'round' : 'rect'}
                    onZoomChange={setZoom}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={handleOnCropComplete}
                    style={{
                        containerStyle: {
                            height: '320px',
                            borderRadius: '4px',
                        },
                    }}
                />
            </div>
            <div className="lg:h-40 h-56 flex flex-col justify-between mt-4">
                <div className="flex flex-col gap-2">
                    <label htmlFor="rotation-slider">{__('ImageCropper.Rotation')}</label>
                    <input
                        id="rotation-slider"
                        min="1"
                        max="360"
                        type="range"
                        value={rotation}
                        className="w-full h-1 bg-gray-200 rounded-lg cursor-pointer "
                        onChange={(e) => setRotation(+e.target.value)}
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label htmlFor="zoom-slider">{__('ImageCropper.ZoomRatio')}</label>
                    <input
                        id="zoom-slider"
                        min="1"
                        max="150"
                        type="range"
                        value={zoom}
                        className="w-full h-1 bg-gray-200 rounded-lg cursor-pointer "
                        onChange={(e) => setZoom(+e.target.value)}
                    />
                </div>
                <div className="flex-col-reverse lg:flex-row lg:self-end flex gap-2 mt-2">
                    <Button attrs={{onClick: handleClose}} outlined name="CropCancel">
                        {__('ImageCropper.Cancel')}
                    </Button>
                    <Button attrs={{onClick: handleGetEditedImage}} name="CropSave">
                        {__('ImageCropper.Save')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ImageCropper;
