import BaseService from "@/js/core/BaseService";
import { JobEndpoints } from "./JobEndpoints";

export default class JobService extends BaseService {
	async fetchJobPost(id) {
		return this.api().get(`${JobEndpoints.FetchJobPost}/${id}`);
	}

	async fetchJobPostForPublic(id) {
		return this.api().get(JobEndpoints.FetchJobPostForPublic.replace(':id', id));
	}

	async fetchJobPostsByCompany(companyId) {
		return this.api().get(`${JobEndpoints.FetchJobPostsByCompany}/${companyId}`);
	}

	async fetchRewardJobPosts(companyId) {
		return this.api().get(JobEndpoints.FetchRewardJobPosts.replace(':id', companyId));
	}

	async fetchAllRewardJobPosts() {
		return this.api().get(JobEndpoints.FetchAllRewardsJobPosts);
	}

	async fetchBlokJobPosts(companyId) {
		return this.api().get(JobEndpoints.FetchBlokJobPosts.replace(':id', companyId));
	}

	async createJobPost(payload) {
		return this.api().post(JobEndpoints.CreateJobSummary, payload);
	}

	async updateJobPost(id, payload) {
		return this.api().put(`${JobEndpoints.UpdateJobPost}/${id}`, { ...payload, id });
	}

	async updateJobPostDetails(id, payload) {
		return this.api().put(`/job-posts/${id}/details`, payload);
	}

	async updateJobPostReward(id, payload) {
		return this.api().put(`/job-posts/${id}/reward`, payload);
	}

	async updateSettings(id, payload) {
		return this.api().put(`/job-posts/${id}/settings`, payload);
	}

	async updateApplicationType(id, payload) {
		return this.api().put(`/job-posts/${id}/applicationType`, payload);
	}

	async createPayment(payload) {
		return this.api().post(JobEndpoints.CreatePayment, payload);
	}

	async unpublishJobPost(id, payload) {
		return this.api().put(JobEndpoints.UnpublishJobPost.replace(':id', id), payload);
	}

	async getJobPostState(id: string) {
		return this.api().get(JobEndpoints.GetJobPostState.replace(':id', id));
	}
}
