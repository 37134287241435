import { useOutletContext } from "react-router-dom";
import OnboardingBottom from "../OnboardingBottom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import locationTypeStore from "@/js/modules/locationTypes/store/LocationTypeStore";
import jobTypeStore from "@/js/modules/jobTypes/store/JobTypeStore";
import SelectionBox from "../SelectionBox";
import { useToggleOptions } from "../../hooks/useToggleOptions/useToggleOptions";
import { useQuery } from "@tanstack/react-query";
import talentStore from "@/js/modules/talent/store/TalentStore";
import React from "react";
import LocalStorage from "@/js/helpers/localStorage";
import { __ } from "@/utils/Trans";
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";
import { useSearchParams } from 'react-router-dom';

const handleOnClickOption = (value: string, storeKey: string, toggleOption: (value: string) => void) => {
	const currentIds = talentStore.state.form[storeKey];

	if (currentIds.includes(value)) {
		talentStore.set(storeKey, currentIds.filter(item => item !== value));
		toggleOption(value);
	} else {
		talentStore.set(storeKey, Array.from(new Set([...currentIds, value])));
		toggleOption(value);
	}
};

export default function OnboardingTalentWorkPreferences() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()
	const { selectedOptions: locationOptions, toggleOption: toggleLocationOption } = useToggleOptions<string>([], { selectCount: 3 });
	const { selectedOptions: jobTypeOptions, toggleOption: toggleJobTypeOption } = useToggleOptions<string>([], { selectCount: 4 });
  const [searchParams] = useSearchParams()

	const handleContinueClick = () => {
		handleChangeStep()
	}

	const handleOnClickLocationType = (value: string) => {
		handleOnClickOption(value, "talentLocationTypeIds", toggleLocationOption)
	}

	const handleOnClickJobType = (value: string) => {
		handleOnClickOption(value, "talentJobTypeIds", toggleJobTypeOption)
	}

	const handleSetLocalstorageData = React.useCallback(() => {
		const payload = LocalStorage.get('onboardingPayload') as { talentJobTypeIds: string[], talentLocationTypeIds: string[] }

		const jobType = payload?.talentJobTypeIds
		const locationType = payload?.talentLocationTypeIds

		if (!!jobType.length) {
			jobType.forEach(item => toggleJobTypeOption(item))
		}

		if (!!locationType) {
			locationType.forEach(item => toggleLocationOption(item))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useQuery({
		queryKey: ['jobTypes', 'locationTypes'],
		queryFn: async () => {
			await Promise.all([
				jobTypeStore.fetchJobTypes(),
				locationTypeStore.fetchLocationTypes()
			])
			return true
		}
	})

	React.useEffect(() => {
		handleSetLocalstorageData()
	}, [handleSetLocalstorageData])

	return (
		<div className="flex flex-col justify-center items-center">
			<div className={`px-4 flex flex-col justify-start items-center gap-7 max-w-[672px] w-full xl:gap-6 xl:p-0 xl:justify-center`}>
				<div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} no-scrollbar overflow-y-auto py-10 w-full`}>
					<div className={"flex flex-col gap-4 w-full items-start md:items-center"}>
						<h1 className={"text-header-bold-h8  text-shade-black text-center md:text-header-bold-h5"}>
							{__("OnboardingPage.TalentSelectWorkPreferences.Title")}
						</h1>
						<p className={"text-body-regular-b5 text-shade-black md:text-center"}>
							{__("OnboardingPage.TalentSelectWorkPreferences.Subtitle")}
						</p>
					</div>
					<div className={"flex flex-col gap-10 w-full"}>
						<div className={"flex flex-col gap-3"}>
							<span>
								{__("OnboardingPage.TalentSelectWorkPreferences.FirstOption")}
							</span>
							<div className="flex flex-col gap-[10px] md:flex-row">
								{locationTypeStore.mapOptionsForOnboarding().map(item => (
									<SelectionBox
										key={item.value}
										rounded={false}
										onClick={() => handleOnClickLocationType(item.value)}
										isSelected={locationOptions.includes(item.value)}
									>
										{item.name}
									</SelectionBox>
								))}
							</div>
						</div>
						<div className={"flex flex-col gap-3"}>
							<span>
								{__("OnboardingPage.TalentSelectWorkPreferences.SecondOption")}
							</span>
							<div className="flex flex-col gap-[10px] md:flex-row">
								{jobTypeStore.mapOptionsForOnboarding().map(item => (
									<SelectionBox
										key={item.value}
										rounded={false}
										onClick={() => handleOnClickJobType(item.value)}
										isSelected={jobTypeOptions.includes(item.value)}
									>
										{item.name}
									</SelectionBox>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={!(locationOptions.length && jobTypeOptions.length)}
			/>
		</div>
	)
}
