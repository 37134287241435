import React from 'react'
import userStore from "@/js/modules/users/store/v2/UserStore";

export const useNavigateByAccountTypeNavBar = () => {
   const getRedirectRoute = React.useCallback(async () => {
      await userStore.fetchAccountType()

      return userStore.getRedirectPagesByAccountTypeNavBar();
   }, [])


   return { getRedirectRoute }
}