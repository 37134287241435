export default function EnglishFlagIcon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         fill="none"
         viewBox="0 0 24 24"
      >
         <path fill="#F0F0F0" d="M24 4H0v16h24V4z"></path>
         <path
            fill="#D80027"
            d="M13.5 4h-3v6.5H0v3h10.5V20h3v-6.5H24v-3H13.5V4z"
         ></path>
         <path
            fill="#0052B4"
            d="M18.458 14.782l5.541 3.079v-3.079h-5.541zM14.608 14.782L24 20v-1.476l-6.736-3.742h-2.656zM21.498 20l-6.89-3.828V20h6.89z"
         ></path>
         <path
            fill="#F0F0F0"
            d="M14.608 14.782L24 20v-1.476l-6.736-3.742h-2.656z"
         ></path>
         <path
            fill="#D80027"
            d="M14.608 14.782L24 20v-1.476l-6.736-3.742h-2.656z"
         ></path>
         <path
            fill="#0052B4"
            d="M4.235 14.782L0 17.135v-2.353h4.235zM9.392 15.445V20H1.195l8.197-4.554z"
         ></path>
         <path
            fill="#D80027"
            d="M6.736 14.782L0 18.524V20l9.391-5.218H6.736z"
         ></path>
         <path
            fill="#0052B4"
            d="M5.541 9.217L0 6.14v3.078h5.541zM9.391 9.217L0 4v1.475l6.736 3.742H9.39zM2.502 4l6.89 3.828V4h-6.89z"
         ></path>
         <path fill="#F0F0F0" d="M9.391 9.217L0 4v1.475l6.736 3.742H9.39z"></path>
         <path fill="#D80027" d="M9.391 9.217L0 4v1.475l6.736 3.742H9.39z"></path>
         <path
            fill="#0052B4"
            d="M19.765 9.218l4.234-2.353v2.353h-4.234zM14.608 8.554V4h8.197l-8.197 4.554z"
         ></path>
         <path
            fill="#D80027"
            d="M17.264 9.217L24 5.475V4l-9.392 5.217h2.656z"
         ></path>
      </svg>
   )
}