import dayjs from "dayjs";
import { Trans } from "react-i18next";

interface IDateDiff {
  date: string;
}

const DateDiff = (props: IDateDiff) => {
  const getDiff = (date: string, type: dayjs.UnitType) => {
    const timezone = dayjs.tz.guess()
    return dayjs(dayjs().tz(timezone)).tz(timezone).diff(date, type)
  }

  const getDateDiff = () => {
    const days = getDiff(props.date, 'day');
    const hours = getDiff(props.date, 'hour');
    const minutes = getDiff(props.date, 'minute');
    const months = getDiff(props.date, 'month');
    const weeks = Math.floor(days / 7);

    if (days >= 30) {
      return { key: 'DateDiff.Months', value: months }
    } else if (days >= 7) {
      return { key: 'DateDiff.Weeks', value: weeks }
    } else if (days > 0) {
      return { key: 'DateDiff.Day', value: days }
    } else if (hours) {
      return { key: 'DateDiff.Hours', value: hours }
    }

    return { key: 'DateDiff.Minutes', value: minutes }
  }

  return (
    <Trans
      i18nKey={getDateDiff().key}
      values={{
        value: getDateDiff().value
      }}
    />
  )
};

export default DateDiff