import BaseService from "@/js/core/BaseService";
import { UserEndpoints } from "./UserEndpoints";

export default class UserService extends BaseService {
  async isEmailExists(email: string) {
    return this.api().get(UserEndpoints.IsEmailExists.replace(':email', email));
  }
  async verify(payload) {
    return this.api().post(UserEndpoints.UserEmailVerify, payload);
  }
  async fetchAccountType() {
    return this.api().get(UserEndpoints.FetchAccountType);
  }
  async me() {
    return this.api().get(UserEndpoints.Me);
  }
  async updateLanguage(payload: unknown) {
    return this.api().put(UserEndpoints.UpdateLanguage, payload);
  }
  async resendVerificationEmail(token: string) {
    return this.api().post(UserEndpoints.ResendVerificationEmail, { token });
  }
  async updatePassword(payload: unknown) {
    return this.api().post(UserEndpoints.UpdatePassword, payload);
  }

	async getUserInviteInfo(id: string) {
    return this.api().get(UserEndpoints.getUserInviteInfo + '/' + id);
  }

	async sendImportRequest(req: any) {
    return this.api().post(UserEndpoints.InsertUserInviteInfo, req);
  }

	async updateImportRequest(req: any) {
    return this.api().post(UserEndpoints.UpdateUserInviteInfo, req);
  }
}
