import { tailwindColors } from "@/plugins/tailwind"

const AllNoticesPackageIcon = () => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="35"
         height="36"
         fill="none"
         viewBox="0 0 35 36"
      >
         <circle cx="17.5" cy="18.285" r="17.5" fill={tailwindColors['primary']['700']}></circle>
         <g
            fill="#fff"
            fillRule="evenodd"
            clipPath="url(#clip0_403_1082)"
            clipRule="evenodd"
         >
            <path d="M26.667 14.785v5.833c1.305-.14 2.333-1.4 2.333-2.917.004-1.517-1.025-2.773-2.333-2.916zM25.517 12.029c0-.686-.577-1.244-1.286-1.244-.709 0-1.286.558-1.286 1.244v11.68c0 .685.577 1.244 1.286 1.244.71 0 1.286-.559 1.286-1.245v-11.68zM13.777 14.697v6.348c2.396.333 5.68 1.13 8.371 2.297V12.396c-2.69 1.167-5.98 1.964-8.37 2.3zM12.976 20.943h-1.797C9.431 20.943 8 19.558 8 17.866c0-1.691 1.431-3.076 3.18-3.076h1.796v6.153zM13.218 21.795h-2.039c-.321 0-.634-.038-.933-.107.466 1.662 1.092 3.861 1.444 5.143.29 1.044 1.405 1.667 2.484 1.39l.783-.205-1.739-6.221z"></path>
         </g>
         <defs>
            <clipPath id="clip0_403_1082">
               <path
                  fill="#fff"
                  d="M0 0H21V21H0z"
                  transform="translate(8 8.785)"
               ></path>
            </clipPath>
         </defs>
      </svg>
   )
}

export default AllNoticesPackageIcon