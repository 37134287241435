import React from "react";
import { useRanger } from "react-ranger";
import { tailwindColors } from "@/plugins/tailwind";

interface IOnboardingSlider {
	classes?: string;
	tooltipText?: string;
	value?: string;
	offSet?: { min: number, max: number };
	onReturn: (value: string) => void;
	infoText?: (label: string) => string;
	tickCount: { label: string, value: string }[];
}

export default function OnboardingSlider(props: IOnboardingSlider) {
	const [values, setValues] = React.useState([1]);

	const { getTrackProps, handles, segments } = useRanger({
		values,
		min: 1,
		stepSize: 1,
		max: props.tickCount.length,
		onDrag: ([value]) => {
			setValues([value]);
			props.onReturn(props.tickCount[value - 1].value);
		}
	});

	const isActiveStep = (step: number) => {
		return values[0] >= step
	};

	const steps = Array.from({ length: props.tickCount.length }, (_, i) => i + 1);

	const labels = props.tickCount.map(ec => props.infoText ? props.infoText(ec.label) : ec.label);

	const currentIndex = values[0] - 1;

	React.useEffect(() => {
		if (values[0] && props.tickCount && props.tickCount.length > 0 && !props.value) {
			const selectedIndex = values[0] - 1;

			if (selectedIndex >= 0 && selectedIndex < props.tickCount.length) {
				const selectedCountValue = props.tickCount[selectedIndex].value;
				props.onReturn(selectedCountValue);
			}
		}
	}, [values, props.tickCount, props]);

	React.useEffect(() => {
		if (props.value) {
			const selectedIndex = props.tickCount.findIndex(ec => ec.value === props.value);

			if (selectedIndex >= 0) {
				setValues([selectedIndex + 1]);
			}
		}
	}, [props.value, props.tickCount]);

	return (
		<div {...getTrackProps(trackStyles)}>
			{steps.map((step, index) => (
				<div
					className="mt-5"
					key={index}
					style={{
						...circleStyles,
						backgroundColor: isActiveStep(step)
							? tailwindColors["primary"]["500"]
							: tailwindColors["primary"]["50"],
						left: `${(index / (props.tickCount.length - 1)) * 100}%`,
					}}
				/>
			))}
			{segments.map(({ getSegmentProps }, index) => (
				<div
					{...getSegmentProps(getSegmentStyles(index))}
					key={`segment-${index}`}
				/>
			))}
			{handles.map(({ getHandleProps }, index) => (
				// eslint-disable-next-line react/jsx-key
				<div {...getHandleProps(handleStyles)} className="relative cursor-pointer sm:max-w-xl sm:mx-auto">
					<div
						className="cursor-pointer bg-shades-stroke whitespace-pre absolute rounded-lg border border-neutral-200 justify-center items-center bottom-5 mb-6 px-4 py-2"
						style={{
							left: calculateLeftOffset(
								values[0],
								props.tickCount.length,
								props.offSet?.min,
								props.offSet?.max
							)
						}}
					>
						<span className="text-body-regular-b6 md:text-body-regular-b5 text-shades-black">
							{labels[currentIndex]}
						</span>
					</div>
				</div>
			))}
		</div>
	);
}

const getSegmentStyles = (index: number): { style: React.CSSProperties } => ({
	style: {
		height: "10px",
		background:
			index === 1
				? tailwindColors["primary"]["50"]
				: tailwindColors["primary"]["500"],
	},
});

const trackStyles: { style: React.CSSProperties } = {
	style: {
		height: "16px",
		display: "flex ",
		alignItems: "center",
		justifyContent: "space-between",
	},
};

const handleStyles: { style: React.CSSProperties } = {
	style: {
		width: "30px",
		height: "30px",
		borderRadius: "100%",
		backgroundColor: "#FFF",
		border: "4px solid #8A47F6",
		zIndex: "1",
	},
};

const circleStyles: React.CSSProperties = {
	width: "20px",
	height: "20px",
	borderRadius: "100%",
	backgroundColor: tailwindColors["primary"]["50"],
	position: "absolute",
	transform: "translate(-50%, -50%)",
	zIndex: 0,
};

const calculateLeftOffset = (value: number, max: number, minOffset = -60, maxOffset = -140) => {
	const ratio = (value - 1) / (max - 1);
	const offset = minOffset + (maxOffset - minOffset) * ratio;

	return `${offset}px`;
};
