import TopNavBar from '@/js/layouts/topbar/TopNavBar'
import useUserStore from '@/js/modules/users/store/useUserStore'
import { __ } from '@/utils/Trans'
import { Trans } from 'react-i18next'
import Image from "@/js/components/Image/Image";
import { Images } from "@/utils/Images";

const NotFoundPage = () => {
    const user = useUserStore(state => state.user)

    // @Todo import this from config
    const email = 'support@digitalwisers.com'

    return (
        <>
            <TopNavBar useLong={true} useOnlyLogo={true} isSearchable={false} hasNotifications={false}>
                {user.id && <TopNavBar.AuthorSection />}
            </TopNavBar>
            <section className="flex items-center h-full -mt-20 lg:-mt-0 p-16 overflow-x-hidden">
                <div className="container flex flex-col items-center justify-center px-5 mx-auto">
										<Image
											className={"w-full max-h-96 my-12"}
											src={Images.NotFound}
										/>
                    <p className="text-2xl font-semibold leading-7 text-center text-gray-500">{__('NotFoundPage.SubText')}</p>
                    <p className="mb-8 text-base leading-5 text-center text-gray-700 mt-7">
                        <Trans
                            i18nKey="NotFoundPage.Description"
                            components={{
                                span: <a href={`mailto:${email}`} className="text-primary-500" />,
                                br: <br />
                            }}
                        />
                    </p>
                </div>
            </section>
        </>
    )
}

export default NotFoundPage
