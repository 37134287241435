import BaseStore from "@/js/core/BaseStore";
import JobService from "@/js/modules/jobs/services/JobService";
import companyStore from "@/js/modules/company/store/CompanyStore";
import blokCompanyStore from "@/js/modules/bloks/store/BlokCompanyStore";
import blokJobPostStore from "@/js/modules/bloks/store/BlokJobPostStore";
import jobStore from "@/js/modules/jobs/store/JobStore";
import { RowStates } from "@/js/core/enums/RowStates";

interface IBlokJobStore { }

const jobBlokStore = {
	bloks: [],
	selectedBloks: [],
	jobPost: {},
	state: {}
}

class JobBlokStore extends BaseStore<IBlokJobStore, JobService> {
	initializeStore() {
		return this.useStore();
	}

	async fetchForBlokSelect(paramsId) {
		this.state.isLoading = true;
		const company = await companyStore.fetchCompany()
		this.state.bloks = await blokCompanyStore.fetchBloksByCompany(company.id);
		const jobPost = await jobStore.fetchJobPost(paramsId);

		this.state.jobPost = jobPost.result;

		if (paramsId) {
			const bloks = await blokJobPostStore.fetchBloksByJobPost(paramsId);
			await jobStore.fetchJobPost(paramsId);
			this.state.selectedBloks = bloks.map(blok => blok.id);
		}

		this.state.isLoading = false;
	}

	isPublished() {
		return jobStore.isPublished(this.state.jobPost?.state);
	}

	selectBlok(isSelected, blok) {
		if (isSelected) return this.state.selectedBloks[0] = blok.id;

		this.state.selectedBloks = this.state.selectedBloks.filter(selectedBlok => selectedBlok !== blok.id);
	}
	async updateBloksByJobPostId(jobPostId) {
		this.state.isSaving = true;
		const result = await blokJobPostStore.updateBloksByJobPostId(jobPostId, { bloks: this.state.selectedBloks });
		this.state.isSaving = false;

		return result;
	}
	async updateBloksByFreeJobPostId(jobPostId) {
		this.state.isSaving = true;
		const result = await blokJobPostStore.updateBloksByJobPostId(jobPostId, { bloks: this.state.selectedBloks, state: RowStates.Pending });
		this.state.isSaving = false;

		return result;
	}
}

export default new JobBlokStore(jobBlokStore, JobService) as JobBlokStore;
