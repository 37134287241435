export enum PaymentStatus {
  Success = 'success',
  Failed = 'failed',
  Failure = 'failure',
  Pending = 'pending',
  Refunded = 'refunded',
  Active = 'active',
  Passive = 'passive',
  Canceled = 'canceled',
  Unpaid = 'unpaid',
  Upgraded = 'upgraded',
  Downgraded = 'downgraded',
  Expired = 'expired',
}
