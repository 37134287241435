import Icon from "@/js/components/Icon/Icon"
import { Icons } from "@/utils/Icons"

interface ITopBarMobileMenuButton extends React.PropsWithChildren {
    toggleMobileMenu: () => void
    isMobileMenuOpen: boolean
}

const TopBarMobileMenuButton = (props: ITopBarMobileMenuButton) => {
    return (
        <div onClick={props.toggleMobileMenu} className={"flex items-center h-full xl:hidden ml-3"}>
            <button
                type={"button"}
                className={"inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-300"}
                aria-controls={"mobile-menu"}
                aria-expanded={"false"}
            >
                <div className={"block"}>
                    {props.isMobileMenuOpen ? <Icon name={Icons.Close} /> : <Icon name={Icons.Hamburger} />}
                </div>
            </button>
        </div>
    )
}

export default TopBarMobileMenuButton
