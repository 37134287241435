export default function ReplyIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" fill="none">
			<path
				fill="#D64751"
				fillRule="evenodd"
				d="M8.092 18.043a1.05 1.05 0 000-1.485L5.685 14.15h5.865A7.35 7.35 0 0018.9 6.8V4.7a1.05 1.05 0 10-2.1 0v2.1c0 2.9-2.35 5.25-5.25 5.25H5.685l2.407-2.407a1.05 1.05 0 10-1.484-1.485l-4.2 4.2a1.05 1.05 0 000 1.485l4.2 4.2c.41.41 1.074.41 1.484 0z"
				clipRule="evenodd"
			></path>
		</svg>
	)
}
