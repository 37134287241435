import ChevronUpV2 from '@/assets/ChevronUpV2'
import CheckPurpleIcon from '@/assets/icons/CheckPurpleIcon'
import ChevronDownV2 from '@/assets/icons/ChevronDownV2'
import GlobeIconV2 from '@/assets/icons/GlobeIconV2'
import EnglishFlagIcon from '@/assets/icons/flags/EnglishFlagIcon'
import TurkishFlagIcon from '@/assets/icons/flags/TurkishFlagIcon'
import cookies from '@/js/helpers/cookies'
import { PageTranslation } from '@/utils/Enums'
import { __ } from '@/utils/Trans'
import { Menu, Transition } from '@headlessui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import userStore from '../../users/store/v2/UserStore'
import { useSearchParams } from 'react-router-dom'

const items = [
   { icon: <TurkishFlagIcon />, translationKey: "LandingNavbarLangSelector.Turkish", value: PageTranslation.TR },
   { icon: <EnglishFlagIcon />, translationKey: "LandingNavbarLangSelector.English", value: PageTranslation.EN }
]

export default function LandingNavbarLangSelector() {
   const { i18n } = useTranslation()

   const [searchParams, setSearchParams] = useSearchParams()

   const searchLang = searchParams.get('lang')

   const handleOnChangeLanguage = (translation?: PageTranslation) => {
      const accessToken = localStorage.getItem('accessToken')

      translation = searchLang ? searchLang as PageTranslation : translation

      if (searchLang) {
         searchParams.delete('lang')
         setSearchParams(searchParams)
      }

      if ((accessToken && accessToken !== 'null') || cookies.getCookie('accessToken')) {
         userStore.updateLanguage({ language: translation })
            .then((result) => {
               i18n.changeLanguage(result.language)
               window.location.reload()
            })
      } else {
         i18n.changeLanguage(translation)
         window.location.reload()
      }
   }

   React.useEffect(() => {
      const searchLang = searchParams.get('lang') as PageTranslation

      if (searchLang) {
         handleOnChangeLanguage(searchLang)
         searchParams.delete('lang')
         setSearchParams(searchParams)
      }
   }, [searchParams.get('lang')])


   return (
      <Menu as="div" className="relative inline-block text-left">
         {({ open }) => (
            <>
               <div>
                  <Menu.Button className={`${open && 'bg-shade-background'} inline-flex w-full gap-[6px] justify-center items-center p-[6px] text-sm font-medium text-primary-700 rounded-full hover:bg-shade-background focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}>
                     <GlobeIconV2 />
                     {i18n.language.toUpperCase()}
                     {open ? <ChevronUpV2 /> : <ChevronDownV2 />}
                  </Menu.Button>
               </div>
               <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
               >
                  <Menu.Items className="absolute right-0 z-50 mt-2 origin-top-right py-4 px-2 rounded-20 bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                     <div className="flex flex-col">
                        {items.map((item, index) => (
                           <Menu.Item
                              key={index}
                              as={'button'}
                              onClick={() => handleOnChangeLanguage(item.value)}
                              className={"flex items-center py-2 px-3 gap-2 hover:bg-shade-background rounded-xl"}
                           >
                              {item.icon}
                              {__(item.translationKey)}
                              {i18n.language === item.value && <span className={"ml-2"}><CheckPurpleIcon /></span>}
                           </Menu.Item>
                        ))}
                     </div>
                  </Menu.Items>
               </Transition>
            </>
         )}
      </Menu>
   )
}