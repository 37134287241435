import TopNavBar from "@/js/layouts/topbar/TopNavBar";

export default function AuthLayoutHeader() {
	return (
		<header>
			<TopNavBar useLong isSearchable={false} hasNotifications={false} >
				<TopNavBar.MenuSection menuItems={[]} />
				<TopNavBar.AuthorSection dropdownItems={[]} />
			</TopNavBar>
		</header>
	);
}
