import { useOutletContext, useSearchParams } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import { __ } from "@/utils/Trans";
import OnboardingBottom from "../OnboardingBottom";
import TextBox from "@/js/components/TextBox/TextBox";
import companyStore from "@/js/modules/company/store/CompanyStore";
import { InputType } from "@/js/components/Input/InputType";
import { Trans } from 'react-i18next';
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";

export default function OnboardingCompanyPosition() {
  const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>();
  const [searchParams] = useSearchParams()

  const handleOnContinueClick = () => {
    handleChangeStep();
  };

  return (
    <div className="flex flex-col justify-center items-center ">
      <div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} max-w-2xl w-full flex-col justify-start items-center gap-8 xl:gap-10 inline-flex md:justify-center xl:p-0 py-10 px-4`}>
        <h1 className="text-header-bold-h8 w-full text-shade-black md:text-header-bold-h5 md:w-auto md:text-center">
          <Trans
            i18nKey={"OnboardingPage.OnboardingCompanyPosition.Title"}
            values={{ company_name: companyStore.state.form.title }}
          />
        </h1>
        <div className={"w-full flex flex-col gap-5"}>
          <TextBox name={"position"} classes={"w-full"}>
            <TextBox.Label>
              {__('OnboardingPage.OnboardingCompanyPosition.SubTitle')}
            </TextBox.Label>
            <TextBox.Input
              name={"position"}
              error={companyStore.state.errors}
              attr={{
                name: "position",
                type: InputType.Text,
                value: companyStore.state.form.position,
                onChange: e => companyStore.set('position', e.target.value),
                placeholder: __('OnboardingPage.OnboardingCompanyPosition.PlaceHolder')
              }}
            />
          </TextBox>
        </div>
      </div>
      <OnboardingBottom
        onClick={handleOnContinueClick}
        disabled={!companyStore.state.form.position}
      />
    </div>
  );
}
