import BaseStore from '@/js/core/BaseStore';
import { IBlokOwnerStore } from '@/js/modules/bloks/store/BlokTypes';
import BlokOwnerService from '@/js/modules/bloks/services/BlokOwnerService';
import socialAccountStore from '@/js/modules/socialMedias/store/SocialAccountStore';
import { errorHandler } from '@/js/helpers/errorHandler';
import blokPublicStore from './BlokPublicStore';

const blokOwnerStore = {
	form: {
		avatarUrl: '',
		username: '',
		title: '',
		bio: '',
		gsmNumber: "",
		services: [],
		isBlokOwnerVisible: true,
		ownerSocialAccounts: []
	},
	hideblokComplete: true,
};

class BlokOwnerStore extends BaseStore<IBlokOwnerStore, BlokOwnerService> {
	initializeStore() {
		return this.useStore();
	}

	providers() {
		return socialAccountStore.state.socialAccounts;
	}

	resetSocialMediasForm() {
		this.state.form.socialAccounts = [];
	}

	async fetchForBlokOwnerStep() {
		this.state.isLoading = true;

		try {
			const response = await this.service.fetchBlokOwner();

			this.setFormData(response.data.result);

			return response.data.result;
		} catch (error) {
			this.goToNotFound();
		} finally {
			this.state.isLoading = false;
		}
	}

	async updateBlokOwner() {
		this.state.isSaving = true;
		try {
			const response = await this.service.updateBlokOwner(this.state.form);

			return response
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	setFormData(data) {
		this.state.form = {
			avatarUrl: data.avatarUrl,
			username: data.username,
			title: data.title,
			bio: data.bio,
			isBlokOwnerVisible: data.isBlokOwnerVisible || blokPublicStore.state.info.isBlokOwnerVisible,
			socialAccounts: data?.socialAccounts,
			ownerSocialAccounts: data?.ownerSocialAccounts
		}
	}


	public resetSocialMediaErrors() {
		const errors = {};
		Object.keys(this.state.errors).map((key) => {
			if (!key.includes('url')) {
				errors[key] = this.state.errors[key]
			}
		})

		this.state.errors = errors;
	}
}

export default new BlokOwnerStore(blokOwnerStore, BlokOwnerService) as BlokOwnerStore;
