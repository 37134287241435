import LocalStorage from "@/js/helpers/localStorage";
import useUserStore from "@/js/modules/users/store/useUserStore";
import { PageRoutes } from "@/utils/PageRoutes";
import { useCallback } from "react";
import { resetAmplitude } from "@/plugins/amplitude";
import cookies from "@/js/helpers/cookies";
import { setSentryUserId } from "@/plugins/sentry";

export const useLogout = () => {
  const reset = useUserStore(state => state.reset);

  const logout = useCallback((redirectPage?: PageRoutes | string, redirectToSignIn = true) => {
    LocalStorage.remove("accessToken");
    LocalStorage.remove("user");
    cookies.deleteCookie("accessToken");

    reset();
    resetAmplitude();
    setSentryUserId('')

    if (redirectPage) {
      window.location.href = redirectPage;
    } else if (redirectToSignIn) {
      window.location.href = PageRoutes.SignIn;
    }

  }, [reset]);

  return logout;
};
