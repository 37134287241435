import BaseStore from "@/js/core/BaseStore";
import BlokCompanyService from "@/js/modules/bloks/services/BlokCompanyService";
import blokStore from '@/js/modules/bloks/store/BlokStore';

interface IBlokCompanyStore {}

const blokCompanyStore = {
  bloks: [],
  jobPosts: [],
}

class BlokCompanyStore extends BaseStore<IBlokCompanyStore, BlokCompanyService> {
  initializeStore() {
    return this.useStore();
  }

  async fetchBloksByCompany(companyId) {
    try {
      this.state.isLoading = true;
      const { data } = await this.service.fetchBloksByCompany(companyId);
      this.state.bloks = data.result;
      for(let i=0; i<  this.state.bloks.length; i++){
        const blok = await blokStore.fetchBlokPackages(this.state.bloks[i].id);
        for(let j=0; j<blok.length; j++){
            if(blok[j].slug.startsWith('publish-job-post')){
              this.state.bloks[i].advertPrice = blok[j].price;
            }
        }
      }

      return  this.state.bloks;
    } finally {
      this.state.isLoading = false;
    }
  }
}

export default new BlokCompanyStore(blokCompanyStore, BlokCompanyService) as BlokCompanyStore;
