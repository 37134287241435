import BaseService from "@/js/core/BaseService";
import { AdminBlogEndpoints } from "./AdminBlogEndpoints";

export default class AdminBlogService extends BaseService {
   async fetchForForm(id: string) {
      return await this.api().get(AdminBlogEndpoints.ById.replace(':id', id));
   }
   async createBlogPost(payload: unknown) {
      return await this.api().post(AdminBlogEndpoints.Create, payload);
   }
   async updateBlogPost(id: string, payload: unknown) {
      return await this.api().put(AdminBlogEndpoints.ById.replace(':id', id), payload);
   }
   async fetchAllBlogPostsForAdmin() {
      return this.api().get(AdminBlogEndpoints.AllBlogPosts);
   }
}