import BaseStore from "@/js/core/BaseStore";
import { ILanguageStore } from "@/js/modules/languages/store/LanguageTypes";
import LanguageService from "@/js/modules/languages/services/LanguageService";

const languageStore = {
  languages: [],
  languageLevels: [],
}

class LanguagesStore extends BaseStore<ILanguageStore, LanguageService> {
  async fetchLanguages() {
    const { data } = await this.service.fetchLanguages();
    this.state.languages = data.result;
  }
  async fetchLanguageLevels() {
    const { data } = await this.service.fetchLanguageLevels();
    this.state.languageLevels = data.result;
  }
  optionsMapper(options) {
    return options.map(option => { return { label: option.name, value: option.id } });
  }
}

export default new LanguagesStore(languageStore, LanguageService);
