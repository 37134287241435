export enum TalentEditTabs {
  Profile = "profile",
  Education ="education",
  WorkPreferences = "workPreferences",
}

export enum LookingForJobTypes {
  Open = "open",
  Maybe = "maybe",
  Close = "close"
}


export interface ITalentStore {

}

export interface ITalentProfileStore {

}

export interface ITalent {
  user: {
    firstName: string;
    lastName: string;
    avatarUrl: string;
  };
  talentLanguages: [];
}
