import TrashIconV2 from "@/assets/icons/TrashIconV2"
import UploadIcon from "@/assets/icons/UploadIcon"
import FormLogo from "@/js/modules/onboarding/components/company/FormLogo"
import clsx from "clsx"
import Skeleton from "react-loading-skeleton"

interface IMediaUploader {
	title?: string
	label: string
	mediaInfo?: string
	imageSrc: string
	isRounded?: boolean
	isUploadingImage: boolean
	onDeleteClick?: () => void
	onChange: (url: string) => void
	setIsUploadingImage: (isLoading: boolean) => void
	canUpload?: boolean
	uploadAiPhoto?: boolean
}

export default function MediaUploader(props: IMediaUploader) {
	const {
		imageSrc,
		label,
		isUploadingImage,
		title,
		mediaInfo,
		onDeleteClick,
		onChange,
		setIsUploadingImage,
		isRounded = false,
		canUpload = false,
		uploadAiPhoto = false,
	} = props

	return (
		<>
			<label htmlFor={label} className="flex items-center gap-6 cursor-pointer">
				<div
					onClick={onDeleteClick}
					className={
						clsx(`w-20 h-20 bg-neutral-50 flex justify-center items-center rounded-[14px] p-3`, {
							"border border-shade-stroke": imageSrc,
							"rounded-full": isRounded,
						})
					}
					style={{
						backgroundSize: 'cover',
						backgroundImage: `url(${imageSrc ? imageSrc : 'https://www.thecolorapi.com/id?format=svg&named=false&hex=F3F5F6'})`,
					}}
				>
					{isUploadingImage
						? <Skeleton className={"w-20 h-20 rounded-[14px]"} />
						: (
							<span className={clsx({ 'w-10 h-10 rounded-full p-2 flex items-center justify-center bg-neutral-50 opacity-80': imageSrc })}>
								{imageSrc ? <TrashIconV2 /> : <UploadIcon />}
							</span>
						)}
				</div>
				{(title && mediaInfo) && (
					<div className="flex flex-col">
						<p className={"text-primary-500 text-body-semibold-b5"}>
							{title}
						</p>
						<span className={"text-neutral-500 text-body-regular-b6"}>
							{mediaInfo}
						</span>
					</div>
				)}
			</label>
			<FormLogo
				isEditable
				hidePlaceholder
				htmlFor={label}
				inputName={label}
				canUpload={canUpload || !imageSrc}
				uploadAiPhoto={uploadAiPhoto}
				onChange={url => onChange(url)}
				imageLoading={isLoading => setIsUploadingImage(isLoading)}
			/>
		</>
	)
}
