import { Outlet } from "react-router-dom";
import PublicNavbar from "@/js/modules/landing/components/PublicNavbar";

interface IAuthLayoutForPublic { }

const AuthLayoutForPublic: React.FC<IAuthLayoutForPublic> = () => {
	return (
		<>
			<header>
				<PublicNavbar hideAll />
			</header>
			<main>
				<Outlet />
			</main>
		</>
	);
}

export default AuthLayoutForPublic;
