import PriceIcon from "@/assets/icons/PriceIcon";
import BlokIcon from "@/assets/icons/BlokIcon";
import { Link, useLocation } from "react-router-dom";
import { PageRoutes } from "@/utils/PageRoutes";
import { __ } from "@/utils/Trans";

export default function JobsPageNavbarMenu() {
	const location = useLocation();

	const isActive = (route: PageRoutes) => location.pathname === route;

	return (
		<div className={"flex items-center justify-start px-4 -mt-4 bg-white gap-4 sm:justify-end sm:px-8 sm:gap-7"}>
			<Link to={PageRoutes.DashboardRewardJobs} className={`flex items-center gap-2 pt-5 pb-4 border-b-3 ${isActive(PageRoutes.DashboardRewardJobs) ? 'text-primary-500 border-primary-500' : 'text-gray-500 border-transparent'}`}>
				<PriceIcon />
				<p className={"text-gray-700"}>{__('JobsPageNavbarMenu.Rewards')}</p>
			</Link>
			<Link to={PageRoutes.DashboardBlokJobs} className={`flex items-center gap-2 pt-5 pb-4 border-b-3 ${isActive(PageRoutes.DashboardBlokJobs) ? 'text-primary-500 border-b-2 border-primary-500' : 'text-gray-500 border-transparent'}`}>
				<BlokIcon />
				<p className={"text-gray-700"}>{__('JobsPageNavbarMenu.Bloks')}</p>
			</Link>
		</div>
	)
}

