import { isJsonString } from '@/js/helpers/isJsonString';

const LocalStorage = {
    has(key: string) {
        return localStorage.hasOwnProperty(key);
    },
    set<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    remove(key: string) {
        localStorage.removeItem(key);
    },
    get<T>(key: string): T | string | null | unknown {
        const item = localStorage.getItem(key);

        if (isJsonString(item)) {
            return item ? JSON.parse(item) : '';
        }

        return item;
    },
};

export default LocalStorage;
