import dayjs from "dayjs";
import i18n from 'i18next';
import utc from 'dayjs/plugin/utc'
import updateLocale from 'dayjs/plugin/updateLocale'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone';
import { PageTranslation } from "@/utils/Enums";
import tr from 'dayjs/locale/tr';
import en from 'dayjs/locale/en';

dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.extend(timezone);
dayjs.extend(customParseFormat)

dayjs.updateLocale(PageTranslation.EN, {
   months: [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
   ]
})

dayjs.updateLocale(PageTranslation.TR, {
   months: [
      "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz",
      "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
   ]
})

i18n.on('languageChanged', (lang) => {
   switch (lang) {
      case PageTranslation.TR:
         dayjs.locale(tr);
         break;
      case PageTranslation.EN:
         dayjs.locale(en);
         break;
      default:
         dayjs.locale(tr);
   }
})