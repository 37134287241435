import BaseStore from "@/js/core/BaseStore";
import { BlogPostCategory, PageTranslation } from "@/utils/Enums";
import { errorHandler } from "@/js/helpers/errorHandler";
import AdminBlogService from "../services/AdminBlogService";
import { IAdminBlogStore } from "./AdminBlogStoreTypes";
import adminStore from "./AdminStore";
import { IBlogStore } from "../../blog/store/BlogStoreTypes";
import slugify from "@/js/helpers/slugify";
import { __ } from "@/utils/Trans";
import { RowStates } from "@/js/core/enums/RowStates";
import { getFixedT } from "i18next";

const adminBlogStore = {
   form: {
      id: '',
      category: '',
      state: '',
      imageUrl: '',
      languages: [{
         [PageTranslation.EN]: {
            title: '',
            body: '',
            category: ''
         },
         [PageTranslation.TR]: {
            title: '',
            body: '',
            category: ''
         }
      }]
   }
}

class AdminBlogStore extends BaseStore<IAdminBlogStore, AdminBlogService> {
   initializeStore() {
      return this.useStore();
   }

   searchInBlogPosts(blogPost: IBlogStore['blogPosts'][0], value: string) {
      return blogPost.id.includes(slugify(value)) || slugify(blogPost.title).includes(slugify(value)) || slugify(blogPost.category).includes(slugify(value));
   }

   filterByBlogPostState(data: IBlogStore['blogPosts'], selectedTab: string | undefined, searchValue: string) {
      if (selectedTab) {
         return data?.filter(d => d.state === selectedTab && this.searchInBlogPosts(d, searchValue));
      }

      return data?.filter(d => this.searchInBlogPosts(d, searchValue));
   }

   async fetchAllBlogPostsForAdmin() {
      try {
         this.state.isLoading = true;

         const { data } = await this.service.fetchAllBlogPostsForAdmin();

         this.state.blogPosts = data.result;

         return data.result

      } catch (error) {
         if (this.goToForbidden(error)) {
            return this.goToForbidden(error);
         }

         return this.goToNotFound();
      } finally {
         this.state.isLoading = false;
      }
   }


   async fetchForForm(id: string) {
      try {
         this.state.isLoading = true;

         const { data } = await this.service.fetchForForm(id);
         this.setFormData(data.result);

         return data.result
      } catch (error) {
         if (this.goToForbidden(error)) {
            return this.goToForbidden(error);
         }

         return this.goToNotFound();
      } finally {
         this.state.isLoading = false;
      }
   }

   async updateBlogPost(id: string) {
      try {
         this.state.isSaving = true;

         const { data } = await this.service.updateBlogPost(id, this.getPayload());

         return data.result
      } catch (error) {
         const errorMessages = errorHandler(error);

         if (Object.keys(errorMessages!).length) {
            this.state.errors = errorMessages;
         }
      } finally {
         this.state.isSaving = false;
      }
   }

   async createBlogPost() {
      try {
         this.state.isSaving = true;

         const { data } = await this.service.createBlogPost(this.getPayload());

         return data
      } catch (error) {
         const errorMessages = errorHandler(error);

         if (Object.keys(errorMessages!).length) {
            this.state.errors = errorMessages;
         }
      } finally {
         this.state.isSaving = false;
      }
   }

   setValueByLanguage(language: PageTranslation, fieldName: string, value: string) {
      if ((language && fieldName) || value) {
         this.state.errors['languages'] = ''
         this.state.form.languages[0][language][fieldName] = value
      }
   }

   getValueByLanguage(language: PageTranslation, fieldName: string) {
      return this.state.form.languages?.[0]?.[language]?.[fieldName]
   }

   getOptionsArrayFromEnum(enumObject, isLabelUpperCase = false) {
      return Object.entries(enumObject).map(([key, value]) => {
         return { value, label: isLabelUpperCase ? String(value).toUpperCase() : value }
      }) as { value: string, label: string }[]
   }

   getCategories() {
      return [
         { value: BlogPostCategory.ForBlokOwners, label: __("BlogPosts.ForBlokOwners") },
         { value: BlogPostCategory.ForTalents, label: __("BlogPosts.ForTalents") },
         { value: BlogPostCategory.ForCompanies, label: __("BlogPosts.ForCompanies") },
      ]
   }

   getStates() {
      return [
         { value: RowStates.Published, label: __('AdminCreateBlogPostPage.Publish') },
         { value: RowStates.Draft, label: __('AdminCreateBlogPostPage.AddToDrafts') },
         { value: RowStates.Unpublished, label: __('AdminCreateBlogPostPage.Unpublish') },
      ]
   }

   getPageTranslations() {
      return this.getOptionsArrayFromEnum(PageTranslation, true)
   }

   setFormData(data: IAdminBlogStore['form']) {
      this.state.form = data
   }

   getCategoryTranslation(category: BlogPostCategory, lang: PageTranslation) {
      const t = (key: string) => {
         const translate = getFixedT(lang, null, key)
         return () => translate(key)
      }

      switch (category) {
         case BlogPostCategory.ForBlokOwners:
            return t("BlogPosts.ForBlokOwners")()
         case BlogPostCategory.ForCompanies:
            return t("BlogPosts.ForCompanies")()
         case BlogPostCategory.ForTalents:
            return t("BlogPosts.ForTalents")()
         default:
            return ''
      }
   }

   getDateByFormat(date: Date) {
      return adminStore.getDateByFormat(date)
   }

   getPayload() {
      const tr = this.state.form?.languages[0][PageTranslation.TR]
      const en = this.state.form?.languages[0][PageTranslation.EN]

      return {
         ...this.state.form,
         languages: [{
            [PageTranslation.EN]: {
               ...en,
               category: this.getCategoryTranslation(this.state.form.category, PageTranslation.EN),
            },
            [PageTranslation.TR]: {
               ...tr,
               category: this.getCategoryTranslation(this.state.form.category, PageTranslation.TR)
            }
         }]
      }
   }

   resetFormState() {
      this.state.form = {
         id: '',
         category: '',
         state: '',
         imageUrl: '',
         languages: [{
            [PageTranslation.EN]: {
               title: '',
               body: '',
               category: ''
            },
            [PageTranslation.TR]: {
               title: '',
               body: '',
               category: ''
            }
         }]
      }
   }
}

export default new AdminBlogStore(adminBlogStore, AdminBlogService) as AdminBlogStore;