import React from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { OnboardingOutletContextType } from '../../constants/onbardingConstants';
import { __ } from "@/utils/Trans";
import { Trans } from "react-i18next"
import OnboardingBottom from '../OnboardingBottom';
import SelectionBox from '../SelectionBox';
import { useToggleOptions } from '../../hooks/useToggleOptions/useToggleOptions';
import companyStore from '@/js/modules/company/store/CompanyStore';
import { CompanyInterests } from '@/utils/Enums';
import { isJoinBlokFlow } from '../../utils/OnboardingHelper';


const companyOptionsData = [
	{ id: CompanyInterests.GetTalentSuggestionFromBlokOwners, label: "OnboardingPage.CompanySelectInterest.FirstOption" },
	{ id: CompanyInterests.PublishJobs, label: "OnboardingPage.CompanySelectInterest.SecondOption" },
	{ id: CompanyInterests.SortJobApplicationsByDwsScore, label: "OnboardingPage.CompanySelectInterest.ThirdOption" },
	{ id: CompanyInterests.BuyService, label: "OnboardingPage.CompanySelectInterest.FourthOption" },
	{ id: CompanyInterests.GetInfoFromCommunities, label: "OnboardingPage.CompanySelectInterest.FifthOption" }
];

export default function OnboardingCompanyInterest() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()
	const { selectedOptions, toggleOption } = useToggleOptions<string>([], { selectCount: 2 });
  const [searchParams] = useSearchParams()

	const handleOnContinueClick = () => {
		companyStore.set("interests", selectedOptions)
		handleChangeStep()
	}

	const handleSetLocalstorageData = React.useCallback(() => {
		const interests = companyStore.state.form.interests;

		if (interests) {
			Object.values<string>(interests)
				.filter(item => typeof item === 'string' && item.trim() !== '')
				.forEach(item => {
					toggleOption(item);
				});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		handleSetLocalstorageData()
	}, [handleSetLocalstorageData])

	return (
		<div className="flex flex-col justify-center items-center ">
			<div className={`px-4 flex flex-col justify-start items-center  max-w-2xl w-full  xl:p-0 xl:justify-center`}>
				<div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} w-full flex flex-col`}>
					<div className="no-scrollbar overflow-y-auto py-10 w-full">
						<div className="flex flex-col justify-center items-center gap-4">
							<div className="inline-flex flex-col justify-center items-center gap-[10px] xl:gap-4">
								<div className="self-stretch flex flex-col justify-start items-center gap-4">
									<h1 className="text-header-bold-h8 w-full text-shade-black xl:text-header-bold-h5 xl:w-auto xl:text-center">
										<Trans
											i18nKey={"OnboardingPage.CompanySelectInterest.Title"}
											components={{
												bold: <span className={"text-violet-500 text-body-bold-b5"} />,
											}}
										/>
									</h1>
								</div>
								<div className="text-center text-body-regular-b5">
									<Trans
										i18nKey={"OnboardingPage.CompanySelectInterest.Subtitle"}
										components={{
											bold: <span className={"text-violet-500 text-body-bold-b5"} />,
										}}
									/>
								</div>
							</div>
						</div>
						<div className="flex flex-wrap md:justify-center md:items-center justify-items-start items-start gap-4 pt-8 xl:pt-10">
							{companyOptionsData.map((option, index) => (
								<SelectionBox
									rounded
									key={option.id}
									onClick={() => toggleOption(option.id)}
									isSelected={selectedOptions.includes(option.id)}
									classes={`!p-4 !inline-flex !items-center !gap-2.5 !w-auto ${index === 0 && "!whitespace-normal sm:!whitespace-nowrap"}`}
								>
									{__(option.label)}
								</SelectionBox>
							))}
						</div>
					</div>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleOnContinueClick}
				disabled={selectedOptions.length < 2}
			/>
		</div>
	);
}
