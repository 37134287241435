import { useOutletContext } from "react-router-dom";
import OnboardingBottom from "../OnboardingBottom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import SelectBox from "@/js/components/SelectBox/SelectBox";
import { __ } from "@/utils/Trans";
import { BlokOwnerServices } from "@/utils/Enums";
import SelectionBox from "../SelectionBox";
import React from 'react'
import { useToggleOptions } from "../../hooks/useToggleOptions/useToggleOptions";
import blokOwnerStore from "@/js/modules/bloks/store/BlokOwnerStore";

export default function OnboardingBlokOwnerServices() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()
	const { selectedOptions, toggleOption, setSelectedOptions } = useToggleOptions<string>([], { selectCount: 7 });

	const handleContinueClick = () => {
		handleChangeStep()
	}

	const blokOwnerOptionsData = [
		{ value: BlokOwnerServices.Training, label: __("OnboardingPage.BlokOwnerServices.Training") },
		{ value: BlokOwnerServices.Consultancy, label: __("OnboardingPage.BlokOwnerServices.Consultancy") },
		{ value: BlokOwnerServices.TechnicalAssessment, label: __("OnboardingPage.BlokOwnerServices.TechnicalAssessment") },
		{ value: BlokOwnerServices.FindTalents, label: __("OnboardingPage.BlokOwnerServices.FindTalents") },
		{ value: BlokOwnerServices.Sponsorship, label: __("OnboardingPage.BlokOwnerServices.Sponsorship") },
		{ value: BlokOwnerServices.JobBoost, label: __("OnboardingPage.BlokOwnerServices.JobBoost") },
		{ value: BlokOwnerServices.NotInterested, label: __("OnboardingPage.BlokOwnerServices.NotInterested") }
	];

	const handleOnChangeServices = (services: { label: string, value: string }[]) => {
		setSelectedOptions(services.map(service => service.value))

		const payload = blokOwnerOptionsData.filter(option => {
			return services.map(service => service.value).includes(option.value)
		});

		blokOwnerStore.set("services", payload);
	};

	const handleSelectionBoxClick = (value: string) => {
		toggleOption(value)

		setSelectedOptions(prevSelected => {
			const notInList = prevSelected.filter(option => {
				return !blokOwnerOptionsData.map(option => option.value)
					.includes(option as BlokOwnerServices)
			});

			notInList.forEach(item => toggleOption(item));

			const payload = blokOwnerOptionsData.filter(option => prevSelected.includes(option.value));
			blokOwnerStore.set("services", payload);

			return prevSelected
		})
	}

	React.useEffect(() => {
		if (selectedOptions.includes(BlokOwnerServices.NotInterested)) {
			selectedOptions.forEach(item => toggleOption(item));
			toggleOption(BlokOwnerServices.NotInterested);

			const option = blokOwnerOptionsData.find(option => {
				return option.value === BlokOwnerServices.NotInterested
			})

			blokOwnerStore.set("services", [option]);
		}
	}, [selectedOptions.length])

	return (
		<div className="flex flex-col justify-center items-center">
			<div className={"px-4 flex flex-col justify-start items-center gap-7 max-w-[640px] w-full xl:gap-10 xl:p-0 xl:justify-center"}>
				<div className="onboarding-full-height no-scrollbar overflow-y-auto py-10 w-full">
					<div className={"flex flex-col gap-4"}>
						<h1 className={"text-header-bold-h8 md:text-header-bold-h5 text-shade-black self-start md:self-auto md:text-center text-start"}>
							{__("OnboardingPage.BlokOwnerServices.Title")}
						</h1>
						<p className={"md:text-center text-body-regular-b5 text-shade-black text-start"}>
							{__("OnboardingPage.BlokOwnerServices.SubTitle")}
						</p>
					</div>
					<div className={"w-full"}>
						<SelectBox
							canBeMultiple
							name={'services'}
							classes={'w-full min-h-[60px]'}
							error={blokOwnerStore.state.errors}
							onChange={handleOnChangeServices}
							data={blokOwnerOptionsData}
							value={blokOwnerStore.getSelectBoxValues("services")}
							placeholder={__("OnboardingPage.BlokOwnerServices.ChooseService")}
						/>
						<div className="flex flex-wrap md:justify-center md:items-center justify-items-start items-start gap-6 pt-6">
							{blokOwnerOptionsData.map(option => (
								<SelectionBox
									rounded
									key={option.value}
									onClick={() => handleSelectionBoxClick(option.value)}
									classes={"!p-4 !inline-flex !items-center !gap-2.5 !w-auto"}
									disabled={option.value !== BlokOwnerServices.NotInterested && selectedOptions.includes(BlokOwnerServices.NotInterested)}
									isSelected={
										selectedOptions.includes(option.value) ||
										blokOwnerStore.state.form.services?.map(service => service.value).includes(option.value)
									}
								>
									{option.label}
								</SelectionBox>
							))}
						</div>
					</div>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={blokOwnerStore.state.form.services?.length === 0}
			/>
		</div>
	)
}
