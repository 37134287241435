import CloseIcon from "@/assets/icons/CloseIcon"
import YellowWarningIcon from "@/assets/icons/YellowWarningIcon"
import { useIsomorphicLayoutEffect } from "@/js/hooks/useIsomorphicLayoutEffect/useIsomorphicLayoutEffect"
import { useScrollPosition } from "@/js/hooks/useScrollPosition/useScrollPosition"
import { UIState } from "@/utils/Enums"
import clsx from "clsx"
import React from 'react'
import { useNavigate } from "react-router-dom"

interface ILineNotifier extends React.PropsWithChildren {
	state: UIState
	show: boolean
	isSticky?: boolean
	onClose?: () => void
	withButton?: boolean
	buttonText?: string
	buttonLink?: string
	hasWarningIcon?: boolean
	customNavigateFunction?: () => void;
}

export default function LineNotifier(props: ILineNotifier) {
	const [stickyClasses, setStickyClasses] = React.useState('')
	const scrollPosition = useScrollPosition()
	const navigate = useNavigate();

	const getBackgroundColor = () => {
		switch (props.state) {
			case UIState.Success:
				return 'bg-narvik-green';
			case UIState.Error:
				return 'bg-error-50';
			case UIState.Info:
				return 'bg-shade-background'
			default:
				break;
		}
	}

	const handleButtonClick = () => {
		if (props.customNavigateFunction) {
		  props.customNavigateFunction();
		} else if (props.buttonLink) {
		  navigate(props.buttonLink);
		}
	}

	useIsomorphicLayoutEffect(() => {
		if (props.isSticky) {
			if (scrollPosition !== 0) {
				setStickyClasses('shadow sticky inset-x-0 top-0 z-[99999]');
			} else {
				setStickyClasses('');
			}
		}
	}, [scrollPosition])

	return !props.show
		? <React.Fragment />
		: (
			<div
				className={clsx(
					`relative flex items-center justify-center min-h-[75px] w-full p-3 ${getBackgroundColor()} xl:px-0 xl:py-6`,
					{ [stickyClasses]: props.isSticky }
				)}
			>
				<div className={clsx("flex flex-col md:flex-row items-center", {
					"mt-4 md:mt-0": props.onClose
				})}>
					{props.hasWarningIcon ? (
						<div className="flex md:items-center md:gap-2">
							<div className="mt-1.5 md:mt-0">
								<YellowWarningIcon />
							</div>
							{props.children}
						</div>
					) : (
						<div>
							{props.children}
						</div>
					)}
					{props.withButton && props.buttonText && (
						<button
							className="bg-primary-500 text-sm hover:bg-primary-300 text-white py-1.5 px-9 rounded-full mt-2 ml-0 md:ml-4 md:mt-0"
							onClick={handleButtonClick}
						>
							{props.buttonText}
						</button>
					)}
				</div>
				{props.onClose && (
					<button className="absolute top-1.5 right-1.5 md:right-4 md:top-7 text-gray-500" onClick={props.onClose}>
						<CloseIcon />
					</button>
				)}
			</div>
		)
}
