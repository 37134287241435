export enum TrackerEvents {
  SignUp = 'Sign Up',
  SignIn = 'Sign In',
  SignInWithProviders = 'Sign In With Providers',
  VerifyAccount = 'Verify Account',
  OnboardingCard = 'Onboarding Card',
  CreateTalent = 'Create Talent',
  UpdateTalentInfo = 'Update Talent Info',
  UpdateTalentWorkPreferences = 'Update Talent Work Preferences',
  CreateBlok = 'Create Blok',
  CreateCompany = 'Create Company',
  UpdateCompany = 'Update Company',
  BlokCompanyApprove = 'Blok Company Approve',
  BlokCompanyDecline = 'Blok Company Decline',
  BlokJobPostApprove = 'Blok Job Post Approve',
  BlokJobPostDecline = 'Blok Job Post Decline',
  BlokTalentDecline = 'Blok Talent Decline',
  BlokTalentReview = 'Blok Talent Review',
  BlokTalentApprove = 'Blok Talent Approve',
  SubscribeToBlok = 'Subscribe To Blok',
  CreateBlokOwner = 'Create Blok Owner',
  UpdateBlokOwner = 'Update Blok Owner',
  UpdateBlok = 'Update Blok',
  DWSScoreForaJobPost = 'DWS Score For a Job Post',
  JobPostApplication = 'Job Post Application',
  JobPostSetReward = 'Job Post Set Reward',
  UpdateJobPostReward = 'Update Job Post Reward',
  TalentSuggestion = 'Talent Suggestion',
  UpdateJobDetails = 'Update Job Details',
  UpdateJobPost = 'Update Job Post',
  CreateJobPost = 'Create Job Post',
  UpdateJobPostApplicationType = 'Update Job Post Application Type',
  JobPostSelectBlok = 'Job Post Select Blok',
  JobPostCreatePayment = 'Job Post Create Payment',
  CreatePaymentInvoice = 'Create Payment Invoice',
  PaymentSuccess = 'Payment Success',
  PaymentFailed = 'Payment Failed',
  BlokInvite = 'Blok Invite',
  UnpublishJobPostButtonClick = 'Unpublish Job Post Button Click',
  CloseUnpublishModal = 'Close Unpublish Modal',
  UnpublishJobPost = 'Unpublish Job Post',
  DisplayPackagesPage = 'Display Packages Page',
  ClickBuyButton = 'Display Buy Button',
  DisplayInvoicePage = 'Display Invoice Page',
  ClickPurchaseButton = 'Click Purchase Button',
  CompleteRegister = 'Complete Register',
}
