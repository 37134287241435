import BaseStore from "@/js/core/BaseStore";
import { IBenefitStore } from "@/js/modules/benefits/store/BenefitTypes";
import BenefitService from "@/js/modules/benefits/services/BenefitService";

const benefitStore = {
  benefits: [],
}

class BenefitsStore extends BaseStore<IBenefitStore, BenefitService> {
  async fetchBenefits() {
    const { data } = await this.service.fetchBenefits();
    this.state.benefits = data.result;
  }
}

export default new BenefitsStore(benefitStore, BenefitService);
