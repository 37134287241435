import companyStore from '@/js/modules/company/store/CompanyStore';
import SelectBox, { ISelectBoxResult } from "@/js/components/SelectBox/SelectBox";
import { __ } from "@/utils/Trans";
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { OnboardingOutletContextType } from '../../constants/onbardingConstants';
import OnboardingBottom from '../OnboardingBottom';
import { useQuery } from '@tanstack/react-query';
import locationStore from '@/js/modules/locations/store/LocationStore';
import { isJoinBlokFlow } from '../../utils/OnboardingHelper';

export default function OnboardingCompanyLocation() {
  const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()
  const [searchParams] = useSearchParams()

  const handleOnContinueClick = () => {
    handleChangeStep()
  }

  const handleLocationChange = (e: ISelectBoxResult) => {
    companyStore.set("locationId", e.value)
  }

  useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      await locationStore.fetchLocations()
      return true
    }
  })

  return (
    <div className="flex flex-col justify-center items-center">
      <div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} max-w-[950px] w-full`}>
        <div className='flex flex-col h-full justify-start items-start gap-6 w-full px-4 py-10 md:justify-center md:gap-10 md:p-0 md:items-center'>
          <h2 className="text-header-bold-h8 md:text-header-bold-h5 ">
            {__('OnboardingPage.CompanySelectLocation.Title')}
          </h2>
          <SelectBox
            inputName={"locationId"}
            name={"locationId"}
            classes={'w-full max-w-[750px]'}
            data={companyStore.getLocations()}
            onChange={e => handleLocationChange(e)}
            error={companyStore.state.errors}
            value={companyStore.state.form.locationId}
            placeholder={__('OnboardingPage.TalentSelectLocation.SearchPlaceholder')}
          />
        </div>
      </div>
      <OnboardingBottom
        onClick={handleOnContinueClick}
        disabled={!companyStore.state.form.locationId}
      />
    </div>
  );
}
