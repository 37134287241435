import Button from "@/js/components/Buttons/Button"
import Modal from "@/js/components/Modal/Modal"
import { UISize } from "@/utils/Enums"
import { __ } from '@/utils/Trans';
import { Trans } from 'react-i18next';

interface IRemoveCompanyFromBlokModal extends React.PropsWithChildren {
   blok: {name: string}
   onClose: () => void
   onClick: () => void
}

const RemoveCompanyFromBlokModal = (props: IRemoveCompanyFromBlokModal) => {
   return (
      <Modal size={UISize.Lg} name={"DiscardFromBlokModal"} onClose={() => props.onClose()}>
         <div className={"flex flex-col justify-center w-full gap-5"}>
            <h1 className={"text-gray-700 leading-7 text-2xl font-semibold text-center"}>
               {__("UnsubscribeFromBlokModal.RemoveFromBlok")}
            </h1>
            <div className={"flex flex-col justify-center items-center"}>
               <p className={"text-center"}>
                  <Trans i18nKey={'UnsubscribeFromBlokModal.Body'} values={{ blokName: props.blok.name }} />
               </p>
            </div>
            <div className={"w-full flex flex-col sm:flex-row justify-center gap-3 sm:gap-16"}>
               <Button
                  outlined
                  name={"CancelButton"}
                  attrs={{ onClick: () => props.onClose() }}
               >
                  {__("UnsubscribeFromBlokModal.Cancel")}
               </Button>
               <Button attrs={{ onClick: () => props.onClick() }} name={"DiscardButton"}>
                  {__("UnsubscribeFromBlokModal.Unsubscribe")}
               </Button>
            </div>

         </div>
      </Modal>
   )
}

export default RemoveCompanyFromBlokModal
