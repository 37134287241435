import BaseStore from "@/js/core/BaseStore";
import { IJobLevelStore } from "@/js/modules/jobLevels/store/JobLevelTypes";
import JobLevelService from "@/js/modules/jobLevels/services/JobLevelService";

const jobLevelStore = {
  jobLevels: [],
}

class JobLevelsStore extends BaseStore<IJobLevelStore, JobLevelService> {
  async fetchJobLevels() {
    const { data } = await this.service.fetchJobLevels();
    this.state.jobLevels = data.result;
  }
}

export default new JobLevelsStore(jobLevelStore, JobLevelService);
