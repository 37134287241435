import BaseStore from "@/js/core/BaseStore";
import { ILocationStore } from "@/js/modules/locations/store/LocationTypes";
import SkillExperienceService from "../services/SkillExperienceService";

const skillExperienceStore = {
  skillExperiences: [],
}

class SkillExperienceStore extends BaseStore<ILocationStore, SkillExperienceService> {
  async fetchSkillExperiences() {
    const { data } = await this.service.fetchSkillExperiences();
    this.state.skillExperiences = data.result;
  }

  optionsMapper() {
    return this.state.skillExperiences.map((skillExperience: any) => ({
      value: skillExperience.id,
      label: skillExperience.name,
    }));
  }
}

export default new SkillExperienceStore(skillExperienceStore, SkillExperienceService);
