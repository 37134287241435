export enum InputType {
  Text = 'text',
  Password = 'password',
  Email = 'email',
  Number = 'number',
  Date = 'date',
  Time = 'time',
  DateTime = 'datetime',
  CheckBox = 'checkbox',
  File = 'file',
  Search = 'search',
  Radio = 'radio',
}
