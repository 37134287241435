import React from "react";
import { __ } from "@/utils/Trans";
import { useQuery } from "@tanstack/react-query";
import { useToggleOptions } from "../../onboarding/hooks/useToggleOptions/useToggleOptions";
import { ContractRoutes } from "../../contracts/services/ContractRoutes";
import { PriceSymbols, UIState } from '@/utils/Enums';
import { LookingForJobTypes } from "../store/TalentTypes";
import talentStore from "../store/TalentStore";
import locationTypeStore from "@/js/modules/locationTypes/store/LocationTypeStore";
import jobTypeStore from "@/js/modules/jobTypes/store/JobTypeStore";
import CustomError from '@/js/components/CustomError/CustomError';
import CheckBox from "@/js/components/CheckBox/CheckBox";
import SelectBox from "@/js/components/SelectBox/SelectBox";
import SelectionBox from "../../onboarding/components/SelectionBox";
import ContractCheckbox from "../../contracts/components/ContractCheckbox/ContractCheckbox";
import SelectMenuV2 from "@/js/components/SelectMenu/SelectMenuV2";
import PriceInput from "@/js/components/PriceInput/PriceInput";
import SearchIcon from "@/assets/icons/SearchIcon";
import OpenToOpportunitiesIcon from "@/assets/icons/OpenToOpportunitiesIcon";
import CloseIcon from "@/assets/icons/CloseIcon";

export const selectMenuOptions = [
  {
    icon: <SearchIcon />,
    title: "TalentWorkPreferences.JobSearchStatus.OpenTitle",
    description: "TalentWorkPreferences.JobSearchStatus.OpenDescription",
    value: LookingForJobTypes.Open
  },
  {
    icon: <OpenToOpportunitiesIcon />,
    title: "TalentWorkPreferences.JobSearchStatus.MaybeTitle",
    description: "TalentWorkPreferences.JobSearchStatus.MaybeDescription",
    value: LookingForJobTypes.Maybe
  },
  {
    icon: <div className="text-red-500"><CloseIcon /></div>,
    title: "TalentWorkPreferences.JobSearchStatus.CloseTitle",
    description: "TalentWorkPreferences.JobSearchStatus.CloseDescription",
    value: LookingForJobTypes.Close
  }
];

export default function TalentEditFormWorkPreferences() {
  const [hasAgreement] = React.useState(talentStore?.state?.form?.hasAgreement || false);
  const { selectedOptions: locationOptions, toggleOption: toggleLocationOption } = useToggleOptions<string>([], { selectCount: 3 });
	const { selectedOptions: jobTypeOptions, toggleOption: toggleJobTypeOption } = useToggleOptions<string>([], { selectCount: 4 });

	const handleOnClickOption = (value: string, storeKey: string, toggleOption: (value: string) => void) => {

		const currentIds = talentStore.state.form[storeKey];

		if (currentIds.includes(value)) {
			talentStore.set(storeKey, currentIds.filter(item => item !== value));
			toggleOption(value);
		} else {
			talentStore.set(storeKey, Array.from(new Set([...currentIds, value])));
			toggleOption(value);
		}
	};

  const handleOnClickLocationType = (value: string) => {
		handleOnClickOption(value, "talentLocationTypeIds", toggleLocationOption)
	}

  const handleOnClickJobType = (value: string) => {
		handleOnClickOption(value, "talentJobTypeIds", toggleJobTypeOption)
	}

  useQuery({
		queryKey: ['jobTypes', 'locationTypes'],
		queryFn: async () => {
			await Promise.all([
				jobTypeStore.fetchJobTypes(),
				locationTypeStore.fetchLocationTypes()
			])
			return true
		}
	})

	React.useEffect(() => {
		const jobType = talentStore.state.form.talentJobTypeIds
		const locationType = talentStore.state.form.talentLocationTypeIds

		if (!!jobType) {
			jobType.forEach(item => toggleJobTypeOption(item.value))
		}

		if (!!locationType) {
			locationType.forEach(item => toggleLocationOption(item.value))
		}
	}, [])

	React.useEffect(() => {
		talentStore.state.form.talentJobTypeIds = jobTypeOptions
		talentStore.state.form.talentLocationTypeIds = locationOptions
	}, [locationOptions, jobTypeOptions])

  return (
    <div className="flex flex-col" style={{ gap: '47px' }}>
      <div className={"w-full"}>
        <SelectMenuV2
          label={__("TalentEditWorkPreferences.JobSearchStatus")}
          value={talentStore.getLookingForJobType()}
          onChange={e => talentStore.state.form.lookingForJobType = e.value}
          data={selectMenuOptions}
        />
      </div>
      <div className={"w-full"}>
        <SelectBox
          maxLength={50}
          error={talentStore.state.errors}
          data={talentStore.positions()}
          label={__("TalentEditWorkPreferences.InterestedPositions")}
          placeholder={__("TalentEditWorkPreferences.InterestedPositionsPlaceholder")}
          canBeMultiple
          canSearchable
          name={"talentPositionIds"}
          value={talentStore.getSelectBoxValues("talentPositionIds")}
          onChange={e => talentStore.state.form.talentPositionIds = e}
          inputName={"talentPositionIds"}
        />
      </div>
	    <div className={"flex flex-col gap-3"}>
        <span className={"text-shade-black text-body-semibold-b5"}>
          {__("TalentEditWorkPreferences.LocationTypes.Title")}
        </span>
        <div className="flex flex-col gap-[10px] md:flex-row">
          {locationTypeStore.mapOptionsForOnboarding().map(item => (
            <SelectionBox
              key={item.value}
              rounded={false}
              onClick={() => handleOnClickLocationType(item.value)}
              isSelected={locationOptions.includes(item.value)}
            >
              {item.name}
            </SelectionBox>
          ))}
        </div>
      </div>
      <div className={"flex flex-col gap-3"}>
        <span className={"text-shade-black text-body-semibold-b5"}>
          {__("TalentEditWorkPreferences.JobTypes.Title")}
        </span>
        <div className="flex flex-col gap-[10px] md:flex-row">
          {jobTypeStore.mapOptionsForOnboarding().map(item => (
            <SelectionBox
              key={item.value}
              rounded={false}
              onClick={() => handleOnClickJobType(item.value)}
              isSelected={jobTypeOptions.includes(item.value)}
            >
              {item.name}
            </SelectionBox>
          ))}
        </div>
      </div>
        <div className="flex flex-col gap-1 items-start">
          <span className={"text-shade-black mb-2 text-body-semibold-b5"}>
            {__("TalentEditWorkPreferences.MinSalaryExpectation")}
          </span>
          <div className={"mb-2 w-full"}>
            <PriceInput
              name={"minimumSalary"}
              inputName={"minimumSalary"}
              prefix={PriceSymbols.USD}
              value={talentStore.state.form.minimumSalary}
              onChange={(value) => talentStore.set("minimumSalary", value)}
              placeholder={__('TalentEditWorkPreferences.MinSalaryExpectationPlaceholder')}
              maxLength={10}
              error={talentStore.state.errors}
            />
          </div>
          <CheckBox className={"flex-row-reverse pt-4"}>
            <CheckBox.Label htmlFor={"isSalaryVisible"}>
              <span className={"!text-black !font-normal"}>
                {__("TalentEditWorkPreferences.HideSalaryInfo")}
              </span>
            </CheckBox.Label>
            <CheckBox.Input
              name={"isSalaryVisible"}
              id={"isSalaryVisible"}
              attrs={{
                onChange: e => talentStore.state.form.isSalaryVisible = !e.target.checked,
                name: "isSalaryVisible",
                checked: !talentStore.state.form.isSalaryVisible
              }}
            />
          </CheckBox>
        </div>
      {!hasAgreement && (
        <div className={"flex mt-10 flex-row-reverse justify-end"}>
          <ContractCheckbox
            to={ContractRoutes.TalentMemberShipAgreement}
            label={"CreateTalentPage.TalentAgreement"}
            name={"hasAgreement"}
            checked={talentStore.state.form.hasAgreement}
            error={talentStore.state.errors}
            onChecked={e => talentStore.state.form.hasAgreement = e.target.checked}
          />
          <div className={"mt-8"}>
            <CustomError
              name="hasAgreement"
              stateType={UIState.Error}
              error={talentStore.state.errors}
            />
          </div>
        </div>
      )}
    </div>
  );
}
