import { proxy, useSnapshot } from "valtio";
import BaseService from "@/js/core/BaseService";
import { PageRoutes } from '@/utils/PageRoutes';

export default class BaseStore<TStore extends object, TService extends BaseService> {
  public state: any = null;
  private baseState = {
    isLoading: false,
    isSaving: false,
    hasError: false,
    errors: {},
  }

  public set(fieldName: string, value: any, options?: { multiple: boolean, clearError: boolean }) {
    this.state.form[fieldName] = value;

    if (options?.multiple && options?.clearError) {
      this.state.errors[fieldName] = ''
    } else if (!options?.multiple) {
      this.state.errors[fieldName] = ''
    }
  }

  public getSelectBoxValues(fieldName: string) {
    return this.state.form[fieldName]?.map((item: { value: string }) => item.value);
  }

	public getSelectBoxValue(fieldName: string) {
		return this.state.form[fieldName] ? [this.state.form[fieldName]] : []
  }

	public getSelectBoxStringValue(fieldName: string) {
		return this.state.form[fieldName] ? this.state.form[fieldName] : ""
  }

  constructor(private _store: any, private _service: new () => TService) {
    this.state = proxy<TStore>({ ...this.baseState, ..._store });
  }

  getServiceInstance() {
    return new this._service();
  }

  protected service: TService = this.getServiceInstance();

  public useStore = (state?): void => {
    return useSnapshot(state ? state : this.state, { sync: true });
  }

  public goToForbidden(error) {
    if (error?.response?.code === 403 || error?.response?.status === 403 || error === true) {
      window.history.replaceState(null, '', PageRoutes.Forbidden);
      window.location.href = PageRoutes.Forbidden;

      return true;
    }
  }

  public goToNotFound() {
    window.history.replaceState(null, '', PageRoutes.NotFound);
    window.location.href = PageRoutes.NotFound;

    return;
  }

  public resetErrors() {
    this.state.errors = {};
  }
}
