import * as Sentry from "@sentry/react";

const isProduction = process.env.NODE_ENV === "production";

const initSentry = () => {
	if (isProduction) {
		Sentry.init({
			dsn:  process.env.VITE_SENTRY_DSN,
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration({
					maskAllText: false,
					blockAllMedia: false,
				}),
			],
			tracesSampleRate: 1.0,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		});
	}
};

const setSentryUserId = (userId?: string | undefined) => {
	if (isProduction) {
		Sentry.setUser(userId ? { userId } : null);
	}
}

export { initSentry, setSentryUserId };