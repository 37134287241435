export enum ContractRoutes {
   EmployerUserAgreement = 'employer-user-agreement',
   RewardNoticeContract = 'reward-notice-contract',
   TalentMemberShipAgreement = 'member-membership-agreement',
   CandidateClarificationText = 'candidate-clarification-text',
   CookiePolicy = 'cookie-policy',
   ProtectionOfPersonalData = 'protection-of-personal-data',
   ManagerMembershipAgreement = 'manager-membership-agreement',
   ExplicitConsentText = 'explicit-consent-text',
   PrivacyPolicy = 'privacy-policy',
   PlatformTermsOfUse = 'platform-terms-of-use',
   CancellationAndRefundPolicy = 'cancellation-and-refund-policy',
   DistanceSalesAgreement = 'distance-sales-agreement'
}
