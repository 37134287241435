import CloseIcon from "@/assets/icons/CloseIcon";
import OpenToOpportunitiesIcon from "@/assets/icons/OpenToOpportunitiesIcon";
import SearchIcon from "@/assets/icons/SearchIcon";
import { LookingForJobTypes } from "@/js/modules/talent/store/TalentTypes";
import OnboardingBottom from "../OnboardingBottom";
import { useOutletContext } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import { __ } from "@/utils/Trans";
import SelectionBox from "../SelectionBox";
import { useToggleOptions } from "../../hooks/useToggleOptions/useToggleOptions";
import PriceInput from "@/js/components/PriceInput/PriceInput";
import { PriceSymbols } from "@/utils/Enums";
import talentStore from "@/js/modules/talent/store/TalentStore";
import React from 'react'
import CheckBox from "@/js/components/CheckBox/CheckBox";
import LocalStorage from "@/js/helpers/localStorage";
import { useSearchParams } from 'react-router-dom';
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";

export const options = [
	{
		icon: <SearchIcon />,
		title: "TalentWorkPreferences.JobSearchStatus.OpenTitle",
		value: LookingForJobTypes.Open
	},
	{
		icon: <OpenToOpportunitiesIcon />,
		title: "TalentWorkPreferences.JobSearchStatus.MaybeTitle",
		value: LookingForJobTypes.Maybe
	},
	{
		icon: <span className="text-red-500"><CloseIcon /></span>,
		title: "TalentWorkPreferences.JobSearchStatus.CloseTitle",
		value: LookingForJobTypes.Close
	}
];

export default function OnboardingTalentSalaryExpectation() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()
	const { selectedOptions, setSelectedOptions: toggleWorkTypeOption } = useToggleOptions<string>([], { selectCount: 1 });
  const [searchParams] = useSearchParams()

	const handleContinueClick = () => {
		handleChangeStep()
	}

	const handleOnClickJobSearchStatus = (value: string) => {
		toggleWorkTypeOption(prevSelected => prevSelected.includes(value) ? [] : [value]);
		talentStore.set("lookingForJobType", value)
	}

	React.useEffect(() => {
		const payload = LocalStorage.get('onboardingPayload') as { lookingForJobType: LookingForJobTypes }
		const jobSearchStatus = payload?.lookingForJobType

		if (jobSearchStatus) {
			toggleWorkTypeOption(prevSelected =>
				prevSelected.includes(jobSearchStatus) ? [] : [jobSearchStatus]
			);
		}
	}, [toggleWorkTypeOption])

	return (
		<div className="flex flex-col justify-center items-center">
			<div className={`px-4 flex flex-col justify-start items-center gap-7 max-w-[672px] w-full xl:gap-6 xl:p-0 xl:justify-center`}>
				<div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} no-scrollbar overflow-y-auto py-10 w-full`}>
					<h1 className={"text-header-bold-h8 md:text-header-bold-h5 text-shade-black self-start md:text-center"}>
						{__("OnboardingPage.TalentSalary.Title")}
					</h1>
					<div className={"flex flex-col gap-10 w-full"}>
						<div className={"flex flex-col gap-3"}>
							<span>
								{__("OnboardingPage.TalentSalary.FirstOption")}
							</span>
							<div className="flex flex-col gap-[10px] md:flex-row">
								{options.map(item => (
									<SelectionBox
										rounded={false}
										key={item.value}
										isSelected={selectedOptions.includes(item.value)}
										onClick={() => handleOnClickJobSearchStatus(item.value)}
									>
										{item.icon} {__(item.title)}
									</SelectionBox>
								))}
							</div>
						</div>
						<div className={"flex flex-col gap-3"}>
							<span>
								{__("OnboardingPage.TalentSalary.SecondOption")}
							</span>
							<div className={"flex  flex-col gap-[10px]"}>
								<PriceInput
									maxLength={10}
									placeholder={'0'}
									name={"minimumSalary"}
									inputName={"minimumSalary"}
									prefix={PriceSymbols.USD}
									error={talentStore.state.errors}
									value={talentStore.state.form.minimumSalary}
									onChange={value => talentStore.set("minimumSalary", value)}
								/>
								<div className={"self-start"}>
									<CheckBox className={"flex-row-reverse"}>
										<CheckBox.Label htmlFor={"isSalaryVisible"}>
											<span className={"text-body-regular-b5 text-shade-black"}>
												{__("TalentEditWorkPreferences.HideSalaryInfo")}
											</span>
										</CheckBox.Label>
										<CheckBox.Input
											id={"isSalaryVisible"}
											name={"isSalaryVisible"}
											attrs={{
												name: "isSalaryVisible",
												checked: !talentStore.state.form.isSalaryVisible,
												onChange: e => talentStore.state.form.isSalaryVisible = !e.target.checked,
											}}
										/>
									</CheckBox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={!selectedOptions.length || !talentStore.state.form.minimumSalary}
			/>
		</div>
	)
}
