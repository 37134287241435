const FilterIcon = () => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="21"
         height="21"
         fill="none"
         viewBox="0 0 21 21"
      >
         <path
            fill="#7F8B9F"
            fillRule="evenodd"
            d="M3.15 3.15c0-.58.47-1.05 1.05-1.05h12.6c.58 0 1.05.47 1.05 1.05V6.3c0 .278-.11.546-.308.742L12.6 11.985v3.765c0 .279-.11.546-.308.742l-2.1 2.1A1.05 1.05 0 018.4 17.85v-5.865L3.457 7.042A1.05 1.05 0 013.15 6.3V3.15z"
            clipRule="evenodd"
         ></path>
      </svg>
   );
}
export default FilterIcon
