import { Outlet, useSearchParams } from "react-router-dom";
import AuthLayoutHeader from "../auth/AuthLayoutHeader";
import LineNotifier from "@/js/components/LineNotifier/LineNotifier";
import { AccountTypes, UIState } from "@/utils/Enums";
import React from 'react'
import { track } from "@/plugins/amplitude";
import { TrackerEvents } from "@/utils/TrackerEvents";
import userStore from "@/js/modules/users/store/v2/UserStore";
import LocalStorage from "@/js/helpers/localStorage";
import OnboardingProgresBar from "@/js/modules/onboarding/components/OnboardingProgresBar";
import { __ } from "@/utils/Trans";
import talentStore from "@/js/modules/talent/store/TalentStore";
import companyStore from "@/js/modules/company/store/CompanyStore";
import { useTranslation } from "react-i18next";
import blokStore from "@/js/modules/bloks/store/BlokStore";
import blokOwnerStore from "@/js/modules/bloks/store/BlokOwnerStore";
import { VerificationErrors } from "@/js/modules/auth/constants/VerificationErrors";
import { OnboardingOutletContextType, OnboardingSteps, onboardingSteps, resetOnboardingLocalStorage } from "@/js/modules/onboarding/constants/onbardingConstants";
import OnboardingBlokInfo from "@/js/modules/onboarding/components/OnboardingBlokInfo";

export default function OnboardingLayout() {
	blokStore.initializeStore()
	talentStore.initializeStore()
	companyStore.initializeStore();
	blokStore.initializeStore();
	blokOwnerStore.initializeStore();

	const [mailActivationSucces, setMailActivationSucces] = React.useState(false)

	const [stepName, setStepName] = React.useState<OnboardingSteps>(OnboardingSteps.SelectAccountType)
	const [currentStep, setCurrentStep] = React.useState<JSX.Element>(onboardingSteps[stepName]);

	const { i18n } = useTranslation()
	const [searchParams] = useSearchParams();

	const handleChangeStep = (isBack?: boolean) => {
		const steps = LocalStorage.get('onboardingSteps') as OnboardingSteps[]
		const currentStepIndex = steps.findIndex(step => step === stepName)

		if ((isBack && currentStepIndex === 0) || (!isBack && currentStepIndex === steps.length - 1)) {
			return;
		}

		const step = isBack ? steps[currentStepIndex - 1] : steps[currentStepIndex + 1];

		setStepName(step)
		setCurrentStep(onboardingSteps[step]);

		handleSetPayloadToLocalStorageByAccountType()

		LocalStorage.set('lastOnboardingStep', step)
	}

	const handleSetPayloadForStore = () => {
		const accountType = LocalStorage.get('onboardingSelectedAccountType') as AccountTypes
		const payload = LocalStorage.get('onboardingPayload')

		switch (accountType) {
			case AccountTypes.Talent:
				return talentStore.setCreatePayload(payload)
			case AccountTypes.BlokOwner:
				return (blokStore.setFormData(payload), blokOwnerStore.setFormData(payload))
			case AccountTypes.CompanyOwner:
				return companyStore.setCreatePayload(payload)
			default:
				break;
		}
	}

	const handleSetPayloadToLocalStorageByAccountType = () => {
		const accountType = LocalStorage.get('onboardingSelectedAccountType') as AccountTypes

		switch (accountType) {
			case AccountTypes.Talent:
				LocalStorage.set('onboardingPayload', talentStore.getCreatePayload(talentStore.state.form))
				break;
			case AccountTypes.BlokOwner:
				LocalStorage.set('onboardingPayload', blokStore.getCreatePayload())
				break;
			case AccountTypes.CompanyOwner:
				LocalStorage.set('onboardingPayload', companyStore.getCreatePayload(companyStore.state.form))
				break;
			default:
				break;
		}
	}

	React.useEffect(() => {
		(async () => {
			await userStore.updateLanguage({ language: i18n.language })

			if (searchParams.has('token')) {
				resetOnboardingLocalStorage()

				const token = String(searchParams.get('token'))
				track(TrackerEvents.VerifyAccount, { token });

				if (await userStore.verify(token) || userStore.state.errors.token === VerificationErrors.emailAlreadyVerified) {
					setMailActivationSucces(true)
				}
			}

			if (LocalStorage.has('lastOnboardingStep')) {
				const lastOnboardingStep = LocalStorage.get('lastOnboardingStep') as OnboardingSteps

				setStepName(lastOnboardingStep)
				setCurrentStep(onboardingSteps[lastOnboardingStep])
			}

			if (LocalStorage.has('onboardingPayload')) {
				handleSetPayloadForStore()
			}
		})()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const outletContext: OnboardingOutletContextType = {
		currentStep,
		setCurrentStep,
		handleChangeStep,
		setMailActivationSucces,
	}

	return (
		<React.Fragment>
			<AuthLayoutHeader />

			{!searchParams.has('flow') && <OnboardingProgresBar />}
			<LineNotifier show={mailActivationSucces} state={UIState.Success}>
				<p className={"text-success-500 text-body-bold-b4 text-center"}>
					{__("OnboardingPage.EmailVerified")}
				</p>
			</LineNotifier>

			{currentStep !== onboardingSteps[OnboardingSteps.SelectAccountType] &&
				<OnboardingBlokInfo/>
			}
			<Outlet context={outletContext} />
		</React.Fragment>
	);
}
