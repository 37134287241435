import BaseStore from "@/js/core/BaseStore";
import { ILocationTypeStore } from "@/js/modules/locationTypes/store/LocationTypeTypes";
import LocationTypeService from "@/js/modules/locationTypes/services/LocationTypeService";
import { LocationTypes } from "@/utils/Enums";

const locationTypeStore = {
  locationTypes: [],
}

class LocationTypesStore extends BaseStore<ILocationTypeStore, LocationTypeService> {
  async fetchLocationTypes() {
    const { data } = await this.service.fetchLocationTypes()
    this.state.locationTypes = data.result;
  }

  mapOptionsForOnboarding(): { name: string, value: string }[] {
    return this.state?.locationTypes.map(item => {
      switch (item.languages[0].en) {
        case LocationTypes.Hybrid:
          return { name: `🔁 ${item.name}`, value: item.id }
        case LocationTypes.Office:
          return { name: `🏢 ${item.name}`, value: item.id }
        case LocationTypes.Remote:
          return { name: `🏠 ${item.name}`, value: item.id }
        default:
          return { name: item.name, value: item.id }
      }
    })
  }
}

export default new LocationTypesStore(locationTypeStore, LocationTypeService);