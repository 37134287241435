import React from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { OnboardingOutletContextType, OnboardingSteps, talentOnboardingSteps } from '../../constants/onbardingConstants';
import { __ } from "@/utils/Trans";
import { Trans } from "react-i18next"
import OnboardingBottom from '../OnboardingBottom';
import SelectionBox from '../SelectionBox';
import { useToggleOptions } from '../../hooks/useToggleOptions/useToggleOptions';
import LocalStorage from '@/js/helpers/localStorage';
import { TalentInterests } from '@/utils/Enums';
import talentStore from '@/js/modules/talent/store/TalentStore';
import { isJoinBlokFlow } from '../../utils/OnboardingHelper';

const talentOptionsData = [
	{ id: TalentInterests.ApplyToJobs, label: "OnboardingPage.TalentSelectInterest.FirstOption" },
	{ id: TalentInterests.GetInfoFromCommunities, label: "OnboardingPage.TalentSelectInterest.SecondOption" },
	{ id: TalentInterests.ExpandNetwork, label: "OnboardingPage.TalentSelectInterest.ThirdOption" },
	{ id: TalentInterests.GetHelpFromCommunityLeaders, label: "OnboardingPage.TalentSelectInterest.FourthOption" }
];

export default function OnboardingTalentInterest() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()
	const { selectedOptions, toggleOption } = useToggleOptions<string>([], { selectCount: 2 });
  const [searchParams] = useSearchParams()

	const handleOnContinueClick = () => {
		if (!selectedOptions.includes(talentOptionsData[0].id)) {
			const stepsToRemove = [
				OnboardingSteps.TalentBreath,
				OnboardingSteps.TalentWorkPreferences,
				OnboardingSteps.TalentSalaryExpectation,
			]

			const updatedSteps = talentOnboardingSteps.filter(step => !stepsToRemove.includes(step))

			LocalStorage.set('onboardingSteps', updatedSteps)
		} else {
			LocalStorage.set('onboardingSteps', talentOnboardingSteps)
		}

		talentStore.set('interests', selectedOptions)
		handleChangeStep()
	}

	const handleSetLocalstorageData = React.useCallback(() => {
		const interests = talentStore.state.form.interests;

		if (interests) {
			Object.values<string>(interests)
				.filter(item => typeof item === 'string' && item.trim() !== '')
				.forEach(item => {
					toggleOption(item);
				});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		handleSetLocalstorageData()
	}, [handleSetLocalstorageData])

	return (
		<div className="flex flex-col justify-center items-center ">
			<div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} py-10 px-4 flex flex-col justify-start items-center gap-8 max-w-2xl w-full xl:gap-10 xl:p-0 xl:justify-center`}>
				<div className="flex flex-col items-start w-full gap-[10px] md:gap-4 md:items-center">
					<h1 className={"text-header-bold-h8 text-shade-black w-full md:text-center md:text-header-bold-h5"}>
						{__('OnboardingPage.TalentSelectInterest.Title')}
					</h1>
					<p className="text-center text-body-regular-b5">
						<Trans
							i18nKey={'OnboardingPage.TalentSelectInterest.Subtitle'}
							components={{
								bold: <span className={"text-violet-500 text-body-bold-b5"} />,
							}}
						/>
					</p>
				</div>
				<div className="flex flex-wrap md:justify-center md:items-center justify-items-start items-start gap-4">
					{talentOptionsData.map(option => (
						<SelectionBox
							rounded
							key={option.id}
							onClick={() => toggleOption(option.id)}
							isSelected={selectedOptions.includes(option.id)}
							classes={"!p-4 !inline-flex !items-center !gap-2.5 !w-auto"}
						>
							{__(option.label)}
						</SelectionBox>
					))}
				</div>
			</div>
			<OnboardingBottom
				onClick={handleOnContinueClick}
				disabled={selectedOptions.length < 2}
			/>
		</div>

	);
}
