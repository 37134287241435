import BaseService from "@/js/core/BaseService";
import { ConversationEndpoints } from "../../store/ConversationStore/ConversationEndpoints";
import { ConversationFilterTypes } from "../../components/ConversationFilter";

export default class ConversationService extends BaseService {
   async fetchConversationsByUser(filter: ConversationFilterTypes) {
      return await this.api().get(ConversationEndpoints.FetchThreadsByUser.replace(':filter', filter))
   }
   async fetchExistingConversationBetweenTwoUsers(receiverId: string) {
      return await this.api().get(ConversationEndpoints.FetchExistingBetweenTwoUsers.replace(':receiverId', receiverId))
   }
   async updateArchivedDate(threadId: string) {
      return await this.api().put(ConversationEndpoints.UpdateArchivedDate.replace(':threadId', threadId))
   }
   async updateConversationLastMessageReadDate(threadId: string) {
      return await this.api().put(ConversationEndpoints.UpdateLastMessageReadDate.replace(':threadId', threadId))
   }
   async fetchForNotifications() {
      return await this.api().get(ConversationEndpoints.FetchForNotifications)
   }
}
