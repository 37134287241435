import { Images } from "@/utils/Images";
import Image from "../Image/Image";
import { Link } from "react-router-dom"
import { PageRoutes } from "@/utils/PageRoutes";
import { useNavigateByAccountType } from '@/js/hooks/useNavigateByAccountType/useNavigateByAccountType';
import React from "react";
import DWSLetterLogo from "@/assets/icons/DWSLetterLogo";
import { useNavigateByAccountTypeNavBar } from "@/js/hooks/useNavigateByAccountType/useNavigateByAccountTypeNavBar";

interface IDWSLogo {
    useLong: boolean
    forceRedirect?: boolean
}

const DWSLogo = ({ useLong = false, forceRedirect }: IDWSLogo) => {
    const [route, setRoute] = React.useState(PageRoutes.Index)
    const { getRedirectRoute } = useNavigateByAccountTypeNavBar()

    const handleNavigateRoute = React.useCallback(async () => {
        setRoute(await getRedirectRoute())
    }, [])

    React.useEffect(() => {
        handleNavigateRoute()
    }, [])

    const isBlogPage = location.href.includes(String(process.env.VITE_BLOG_POST_PAGE).replace(':slug', ''))
    // const isLandingPage = landingRoutes.some(route => route.includes(pathname as PageRoutes))
    // const isContractPage = location.href.includes(PageRoutes.Contracts)

    return <>
        {
            useLong && (
                <Link
                    className={"flex items-center flex-shrink-0"}
                    reloadDocument={forceRedirect}
                    to={isBlogPage ? String(process.env.VITE_FE_URL) : PageRoutes.Index}
                >
                    <Image
                        width={160}
                        height={40}
                        alt={"DigitalWisers Logo"}
                        src={Images.DWSLogo}
                    />
                </Link>
            )
        }
        {
            !useLong && (
                <Link to={route}>
										<DWSLetterLogo />
                </Link>
            )
        }
    </>
}

export default DWSLogo;
