export default function Bars3Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        fill="#060010"
        d="M21.75 6.5H2.25a1.5 1.5 0 010-3h19.5a1.5 1.5 0 010 3zM21.75 21.5H2.25a1.5 1.5 0 010-3h19.5a1.5 1.5 0 010 3zM21.75 14H2.25a1.5 1.5 0 010-3h19.5a1.5 1.5 0 010 3z"
      ></path>
    </svg>
  );
}
