import ReactDOM from 'react-dom/client';
import Router from './js/router/Router';
import modules from './modules';
import { BrowserRouter } from 'react-router-dom'

import './plugins/axios';
import './plugins/dayjs';
import './plugins/i18n';
import { initSentry } from './plugins/sentry';

import 'react-loading-skeleton/dist/skeleton.css'
import 'react-circular-progressbar/dist/styles.css';
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/flicking-plugins/dist/flicking-plugins.css";
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'react-international-phone/style.css';
import './assets/css/app.css';

import '@/js/helpers/injectChatBot'
import '@/js/helpers/injectNewRelic'
import '@/js/helpers/injectAmplitude'

const element = document.getElementById('app') as HTMLElement;

const selectedComponent = element?.dataset?.component as string
const props = JSON.parse(element?.dataset?.props || '{}');

const Component = modules[selectedComponent] || Router;
const root = ReactDOM.createRoot(element);

const moduleElement = document.getElementById('module') as HTMLElement

let moduleRoot: ReactDOM.Root | null = null;

if (moduleElement) moduleRoot = ReactDOM.createRoot(moduleElement)

const selectedModule = moduleElement?.dataset?.module as string
const moduleProps = JSON.parse(moduleElement?.dataset?.props || '{}');
const Module = modules[selectedModule]

initSentry()

if (!selectedComponent) {
	root.render(<Component {...props} />)
} else {
	root.render(
		<BrowserRouter>
			<Component {...props} />
		</BrowserRouter>
	)
	if (moduleRoot) {
		moduleRoot.render(<Module {...moduleProps} />)
	}
}
