import React from 'react';

interface IMessageIconProps {
    hasMessages?: boolean;
  }  

const MessageIcon: React.FC<IMessageIconProps> = ({ hasMessages = false }) => {
  return (
    <div className="relative w-[44px] h-[44px]">
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 17.011C28.8841 17.2955 29.5 18.1392 29.5 19.1082V23.3938C29.5 24.5304 28.6526 25.4943 27.5197 25.5867C27.1808 25.6144 26.8409 25.6386 26.5 25.6592V28.75L23.5 25.75C22.1463 25.75 20.8056 25.6948 19.4802 25.5866C19.1819 25.5623 18.9034 25.4775 18.6549 25.3451M28 17.011C27.8486 16.9623 27.6893 16.93 27.5239 16.9163C26.1972 16.8062 24.8551 16.75 23.5 16.75C22.1448 16.75 20.8028 16.8062 19.4761 16.9163C18.345 17.0101 17.5 17.9732 17.5 19.1082V23.3937C17.5 24.231 17.9599 24.9746 18.6549 25.3451M28 17.011V15.1373C28 13.5159 26.8483 12.1106 25.2403 11.9019C23.1978 11.6368 21.115 11.5 19.0003 11.5C16.8853 11.5 14.8023 11.6368 12.7596 11.902C11.1517 12.1107 10 13.516 10 15.1374V21.3626C10 22.984 11.1517 24.3893 12.7596 24.598C13.3366 24.6729 13.9168 24.7376 14.5 24.7918V29.5L18.6549 25.3451"
        stroke="#7F8B9F"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="20.5" cy="21.25" r="0.75" fill="#7F8B9F" />
      <circle cx="23.5" cy="21.25" r="0.75" fill="#7F8B9F" />
      <circle cx="26.5" cy="21.25" r="0.75" fill="#7F8B9F" />
      {hasMessages && (
        <>
          <circle cx="29.5" cy="10" r="5.5" fill="#8A47F6" />
        </>
      )}
    </svg>
  </div>
  );
};

export default MessageIcon;
