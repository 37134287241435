const slugify = (str: string) => {
   if (!str) return '';


   str = str.trim();
   str = str.toLowerCase();

   // remove accents, swap ñ for n, etc
   const from = 'åàáãäâèéëêğìıíïîòóöôùúüûñçşŞ·/_,:;';
   const to = 'aaaaaaeeeegiiiiioooouuuuncsS------';

   for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
   }

   return str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes
      .replace(/^-+/, '') // trim - from start of text
      .replace(/-+$/, '') // trim - from end of text
      .replace(/-/g, '-');
};

export default slugify;
