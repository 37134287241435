import Badge from "@/js/components/Badge/Badge";
import { AccountTypes, UIState } from '@/utils/Enums';
import BaseTable from "@/js/components/BaseTable/BaseTable";
import React from "react";
import { __ } from "@/utils/Trans";
import adminStore from "@/js/modules/admin/store/AdminStore";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { PageRoutes } from "@/utils/PageRoutes";
import TextBox from '@/js/components/TextBox/TextBox';
import { RowStates } from "@/js/core/enums/RowStates";

export const AdminUserListFilter = { ...AccountTypes, ...RowStates, All: 'all', } as const

const AdminUserListPage = () => {
  adminStore.initializeStore();

  const params = useParams<{ accountType: string, state: string }>();
  const navigate = useNavigate();

  const [search, setSearch] = React.useState('')
  const [selectedState, setSelectedState] = React.useState(params.state)
  const [selectedAccountType, setSelectedAccountType] = React.useState(params.accountType)

  const columns = [
    { name: __('AdminUserListPage.Table.Id') },
    { name: __('AdminUserListPage.Table.Name') },
    { name: __("AdminUserListPage.Table.Surname") },
    { name: __('AdminUserListPage.Table.Email') },
    { name: __('AdminUserListPage.Table.CreatedDate') },
    { name: __('AdminUserListPage.Table.LastLoginDate') },
    { name: __('AdminUserListPage.Table.CommunicationPermission') },
    { name: __('AdminUserListPage.Table.UserType') },
    { name: __('AdminUserListPage.Table.Status') },
    { name: __('AdminUserListPage.Table.BlokName') },
    { name: __('AdminUserListPage.Table.CompanyName') },
  ];

  const accountTypeBadges = [
    { type: AdminUserListFilter.All, name: __('AdminUserListPage.All') },
    { type: AdminUserListFilter.BlokOwner, name: __('AdminUserListPage.BlokOwner') },
    { type: AdminUserListFilter.CompanyOwner, name: __('AdminUserListPage.CompanyOwner') },
    { type: AdminUserListFilter.Talent, name: __('AdminUserListPage.Talent') },
  ]

  const filterBadges = [
    { type: AdminUserListFilter.All, name: __('AdminUserListPage.All') },
    { type: AdminUserListFilter.Approved, name: __('AdminUserListPage.Table.Status.Approved') },
    { type: AdminUserListFilter.Declined, name: __('AdminUserListPage.Table.Status.Rejected') },
    { type: AdminUserListFilter.Pending, name: __('AdminUserListPage.Table.Status.Pending') },
  ]

  const handleGetUserStatus = (user) => {
    if (user.accountType === AccountTypes.Talent) {
      return <span className={"text-white"}>-</span>;
    }

    if (user.accountType === AccountTypes.CompanyOwner) {
      return user.companyState === RowStates.Approved
        ? (
          <Badge type={UIState.Success}>
            {__('AdminUserListPage.Table.Status.Approved')}
          </Badge>
        ) : (
          <Badge type={UIState.Warning}>
            {__('AdminUserListPage.Table.Status.Restricted')}
          </Badge>
        )
    }

    if (user.accountType === AccountTypes.BlokOwner) {
      switch (user.blokState) {
        case RowStates.Approved:
          return (
            <Badge type={UIState.Success}>
              {__('AdminUserListPage.Table.Status.Approved')}
            </Badge>
          )
        case RowStates.Declined:
          return (
            <Badge type={UIState.Danger}>
              {__("AdminUserListPage.Table.Status.Rejected")}
            </Badge>
          )
        case RowStates.Pending:
          return (
            <Badge type={UIState.Warning}>
              {__('AdminUserListPage.Table.Status.Pending')}
            </Badge>
          )
        default:
          return (
            <Badge type={UIState.Default}>
              -
            </Badge>
          )
      }
    }
  }

  React.useEffect(() => {
    setSelectedAccountType(params.accountType);
    setSelectedState(params.state as any);

    adminStore.fetchUsers(params.accountType!, params.state!);
  }, [params]);

  return (
    <div className={"ml-8 mt-5 bg-gray-900"}>
      <h1 className={"text-3xl font-semibold text-white"}>{__('AdminUserListPage.Title')}</h1>
      {adminStore.state.isLoading ? null : <>
        <div className={"flex gap-8 mt-8 md:flex-row flex-col"}>
          <div className={"flex items-center flex-wrap gap-4"}>
            {accountTypeBadges.map((badge, index) => (
              <Badge
                key={index}
                isClickable
                type={adminStore.getTabType(badge.type, selectedAccountType)}
                onClick={() => navigate(PageRoutes.AdminUserListPage.replace(':accountType', badge.type).replace(':state', selectedState as any))}
              >
                {badge.name}
              </Badge>
            ))}
            <span className="text-white">&#124;</span>
            {filterBadges.map((badge, index) => (
              <Badge
                key={index}
                isClickable
                type={adminStore.getTabType(badge.type, selectedState)}
                onClick={() => navigate(PageRoutes.AdminUserListPage.replace(':accountType', selectedAccountType as any).replace(':state', badge.type))}
              >

                {badge.name}
              </Badge>
            ))}
          </div>
          <div>
            <TextBox name={"title"} classes={"w-96"}>
              <TextBox.Input
                name={"search"}
                classes={"pl-2"}
                attr={{
                  onChange: e => setSearch(e.target.value),
                  name: "search",
                  placeholder: __('AdminUserListPage.SearchText')
                }}
              />
            </TextBox>
          </div>
        </div>

        <div>
          <BaseTable columns={columns} classes={"max-w-screen-xl"}>
            {adminStore.filterByAccountTypeAndSearch(adminStore.state.users, selectedAccountType === AdminUserListFilter.All ? '' : selectedAccountType, search).map((user, index) =>
              <BaseTable.Row key={index}>
                <BaseTable.Row.Cell>
                  <p className={"text-white"}>{user.id}</p>
                </BaseTable.Row.Cell>
                <BaseTable.Row.Cell>
                  {user.accountType === AdminUserListFilter.Talent && (
                    <Link target={"_blank"} to={PageRoutes.TalentProfilePage.replace(":id", user.id)}>
                      <p className={"text-white hover:text-gray-100"}>{user.firstName}</p>
                    </Link>
                  )}
                  {user.blokId && user.accountType === AdminUserListFilter.BlokOwner && (
                    <Link target={"_blank"} to={PageRoutes.BlokPublicPage.replace(":slug", user.blokSlug).replace(':tab?', '')}>
                      <p className={"text-white hover:text-gray-100"}>{user.firstName}</p>
                    </Link>
                  )}

                  {user.companyId && user.accountType === AdminUserListFilter.CompanyOwner && (
                    <Link target={"_blank"} to={PageRoutes.CompanyPublicPage.replace(":id", user.companyId)}>
                      <p className={"text-white hover:text-gray-100"}>{user.firstName}</p>
                    </Link>
                  )}

                  {
                    ((user.accountType === AdminUserListFilter.CompanyOwner && !user.companyId) ||
                      (user.accountType === AdminUserListFilter.BlokOwner && !user.blokId) ||
                      !user.accountType) && <p className={"text-white"}>{user.firstName}</p>
                  }

                </BaseTable.Row.Cell>
                <BaseTable.Row.Cell>
                  <p className={"text-white"}>{user.lastName}</p>
                </BaseTable.Row.Cell>
                <BaseTable.Row.Cell>
                  <p className={"text-white"}>{user.email}</p>
                </BaseTable.Row.Cell>
                <BaseTable.Row.Cell>
                  <p className={"text-white"}>
                    {adminStore.getDateByFormat(user.created)}
                  </p>
                </BaseTable.Row.Cell>
                <BaseTable.Row.Cell>
                  <p className={"text-white"}>
                    {adminStore.getDateByFormat(user.lastLoginDate)}
                  </p>
                </BaseTable.Row.Cell>
                <BaseTable.Row.Cell>
                  <p className={"text-white"}>
                    {user.hasNotificationPermission ? __('AdminUserListPage.Yes') : __('AdminUserListPage.No')}
                  </p>
                </BaseTable.Row.Cell>
                <BaseTable.Row.Cell>
                  <p className={"text-white"}>
                    <Badge type={adminStore.getAccountType(user.accountType).type}>
                      {adminStore.getAccountType(user.accountType).text}
                    </Badge>
                  </p>
                </BaseTable.Row.Cell>
                <BaseTable.Row.Cell>
                  {handleGetUserStatus(user)}
                </BaseTable.Row.Cell>
                <BaseTable.Row.Cell>
                  <p className={"text-white"}>
                    {user.blokName || ' - '}
                  </p>
                </BaseTable.Row.Cell>
                <BaseTable.Row.Cell>
                  <p className={"text-white"}>
                    {user.companyName || ' - '}
                  </p>
                </BaseTable.Row.Cell>

              </BaseTable.Row>
            )}
          </BaseTable>
        </div>
      </>}
    </div >
  )
}

export default AdminUserListPage;
