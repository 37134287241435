import { useOutletContext, useSearchParams } from "react-router-dom";
import OnboardingBottom from "../OnboardingBottom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import locationTypeStore from "@/js/modules/locationTypes/store/LocationTypeStore";
import SelectionBox from "../SelectionBox";
import { useToggleOptions } from "../../hooks/useToggleOptions/useToggleOptions";
import { useQuery } from "@tanstack/react-query";
import companyStore from "@/js/modules/company/store/CompanyStore";
import LocalStorage from "@/js/helpers/localStorage";
import React from "react";
import { Trans } from "react-i18next";
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";
export default function OnboardingCompanyWorkType() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>();
	const { selectedOptions: locationOptions, setSelectedOptions: toggleWorkTypeOption } = useToggleOptions<string>([], { selectCount: 1 });
  const [searchParams] = useSearchParams()

	const handleContinueClick = () => {
		handleChangeStep();
	};

	const handleOnClickLocationType = (value: string) => {
		toggleWorkTypeOption(prevSelected => prevSelected.includes(value) ? [] : [value]);
		companyStore.set("locationTypeId", value);
	};

	const handleSetLocalstorageData = React.useCallback(() => {
		const payload = LocalStorage.get('onboardingPayload') as { locationTypes: string }
		const locationType = payload?.locationTypes

		if (locationType) {
			toggleWorkTypeOption(prevSelected =>
				prevSelected.includes(locationType) ? [] : [locationType]
			);
		}
	}, [toggleWorkTypeOption])

	useQuery({
		queryKey: ["locationTypes"],
		queryFn: async () => {
			await locationTypeStore.fetchLocationTypes();
			return true;
		},
	});

	React.useEffect(() => {
		handleSetLocalstorageData()
	}, [handleSetLocalstorageData])

	return (
		<div className="flex flex-col justify-center items-center">
			<div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} w-full py-10 px-4 flex flex-col justify-start items-center gap-[30px] max-w-2xl xl:gap-10 xl:p-0 xl:justify-center`}>
				<h1 className={"text-header-bold-h8 md:text-header-bold-h5 text-shade-black w-full text-left lg:text-center"}>
					<Trans
						i18nKey={"OnboardingPage.OnboardingCompanyWorkType.Title"}
						components={{
							br: <br className="sm:hidden" />,
						}}
					/>
				</h1>
				<div className={"w-full"}>
					<div className="flex flex-col gap-[10px] md:flex-row">
						{locationTypeStore.mapOptionsForOnboarding().map(item => (
							<SelectionBox
								key={item.value}
								rounded={false}
								onClick={() => handleOnClickLocationType(item.value)}
								isSelected={locationOptions.includes(item.value)}
							>
								{item.name}
							</SelectionBox>
						))}
					</div>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={!locationOptions.length}
			/>
		</div>
	);
}
