export default function MagnifyGlassIcon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="25"
         fill="none"
         viewBox="0 0 24 25"
      >
         <path
            fill="#7F8B9F"
            d="M20.45 20.243l-3.907-3.906a7.439 7.439 0 001.86-4.933 7.45 7.45 0 00-2.196-5.303 7.45 7.45 0 00-5.303-2.197A7.45 7.45 0 005.6 6.101a7.45 7.45 0 00-2.197 5.303A7.45 7.45 0 005.6 16.707a7.45 7.45 0 005.303 2.197 7.439 7.439 0 004.932-1.86l3.907 3.906a.498.498 0 00.707 0 .5.5 0 000-.707zM6.308 16.001a6.458 6.458 0 01-1.904-4.597c0-1.736.676-3.368 1.904-4.596a6.458 6.458 0 014.596-1.904c1.736 0 3.368.677 4.596 1.904a6.459 6.459 0 011.904 4.596A6.459 6.459 0 0115.5 16a6.459 6.459 0 01-4.596 1.904A6.459 6.459 0 016.308 16z"
         ></path>
      </svg>
   )
}