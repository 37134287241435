import SuccessIconGray from "@/assets/icons/SuccesIconGray"
import SuccessIconFilled from "@/assets/icons/SuccessIconFilled"
import Image from "@/js/components/Image/Image"
import { useWindowSize } from "@/js/hooks/useWindowSize/useWindowSize"
import { ScreenSizes } from "@/utils/ScreenSizes"
import { __ } from "@/utils/Trans"
import { useSearchParams } from "react-router-dom"
import OnboardingBottom from "../OnboardingBottom"
import { setIdentify, track } from "@/plugins/amplitude"
import LocalStorage from "@/js/helpers/localStorage"
import { resetOnboardingLocalStorage } from "../../constants/onbardingConstants"
import { TrackerEvents } from "@/utils/TrackerEvents"
import { AccountTypes, UIState } from "@/utils/Enums"
import { Images } from "@/utils/Images"
import blokStore from "@/js/modules/bloks/store/BlokStore"
import { ContractRoutes } from "@/js/modules/contracts/services/ContractRoutes"
import ContractCheckbox from "@/js/modules/contracts/components/ContractCheckbox/ContractCheckbox"
import CustomError from "@/js/components/CustomError/CustomError"
import { useNavigateByAccountType } from "@/js/hooks/useNavigateByAccountType/useNavigateByAccountType"

const items = [
	{ icon: <SuccessIconFilled />, text: "OnboardingPage.BlokOwnerLastStep.Step1" },
	{ icon: <SuccessIconGray />, text: "OnboardingPage.BlokOwnerLastStep.Step2" },
	{ icon: <SuccessIconGray />, text: "OnboardingPage.BlokOwnerLastStep.Step3" },
	{ icon: <SuccessIconGray />, text: "OnboardingPage.BlokOwnerLastStep.Step4" },
	{ icon: <SuccessIconGray />, text: "OnboardingPage.BlokOwnerLastStep.Step5" },
]

export default function OnboardingBlokOwnerLastStep() {
	blokStore.initializeStore(blokStore.state)

	const { width } = useWindowSize()
	const [searchParams] = useSearchParams()

	const { getRedirectRoute } = useNavigateByAccountType()

	const isMobile = width <= ScreenSizes.XSmall

	const handleSubmitClick = async () => {
		LocalStorage.set('onboardingPayload', blokStore.getCreatePayload())

		const payload = LocalStorage.get('onboardingPayload')
		const response = await blokStore.create(true);

		track(TrackerEvents.CreateBlok, payload);
		track(TrackerEvents.CreateBlokOwner, payload);
		setIdentify('accountType', AccountTypes.BlokOwner);

		if (response && response.data.isSuccess) {
			const redirect = searchParams.get('redirect');

			resetOnboardingLocalStorage()

			if (redirect) {
				return window.location.href = redirect
			}

			return window.location.pathname = await getRedirectRoute()
		}
	}

	return (
		<div className={"flex flex-col justify-between items-center"}>
			<div className={`${!isMobile && 'xl:h-[calc(100vh-200px)]'}  mx-auto w-full flex flex-col-reverse px-4 py-10 gap-7 items-center justify-center xl:gap-0 xl:p-0 xl:flex-row xl:items-stretch xl:justify-between xl:max-w-[70%]`}>
				<div className="flex items-center self-start justify-center xs:self-auto xl:w-[45%]">
					<div className={"flex flex-col gap-4 xl:gap-10"}>
						<h1 className="text-header-bold-h8 md:text-header-bold-h5 text-shade-black">
							{__("OnboardingPage.BlokOwnerLastStep.Title")}
						</h1>
						<ul className={"flex flex-col gap-3"}>
								{items.map((item, index) => (
									<li key={index} className={"flex items-center"}>
										{item.icon}
										<span className={"p-2.5 text-body-regular-b6 md:text-body-regular-b5 text-shade-black"}>
											{__(item.text)}
										</span>
									</li>
								))}
								<li className="pt-2.5 flex">
									<BlokOwnerAgreement />
								</li>
							</ul>
					</div>
				</div>
				<div className="xl:w-[45%] flex justify-center items-center">
					<Image
						src={Images.BlokOwnerOnboardingLastStep}
						alt={"OnboardingTalentLastStep"}
						className={"w-full object-cover h-full max-w-md xl:max-w-none"}
					/>
				</div>
			</div>
			<OnboardingBottom
				hasBackButton={false}
				onClick={handleSubmitClick}
				isLoading={blokStore.state.isSaving}
				disabled={!blokStore.state.form.hasAgreement}
				buttonText={__("OnboardingPage.TalentLastStep.Button")}
				customElement={undefined}
			/>
		</div>
	)
}

const BlokOwnerAgreement = () => {
	return (
		<div className={`flex  flex-row-reverse justify-end`}>
			<ContractCheckbox
				to={ContractRoutes.ManagerMembershipAgreement}
				label={"CreateBlokPage.BlokOwnerAgreement"}
				name={"hasAgreement"}
				onChecked={e => blokStore.set('hasAgreement', e.target.checked)}
				error={blokStore.state.errors}
				checked={blokStore.state.form.hasAgreement}
			/>
			<div className={"mt-8"}>
				<CustomError
					name="hasAgreement"
					stateType={UIState.Error}
					error={blokStore.state.errors}
				/>
			</div>
		</div>
	)
}
