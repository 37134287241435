export default function XMarkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <g clipPath="url(#clip0_5657_11165)">
        <path
          fill="#060010"
          d="M11.832 10.517l7.788-7.788A1.294 1.294 0 1017.788.897L10 8.684 2.212.897A1.294 1.294 0 10.38 2.729l7.788 7.788L.38 18.303a1.294 1.294 0 00.916 2.212c.332 0 .664-.127.916-.38L10 12.35l7.788 7.788a1.292 1.292 0 001.832 0 1.294 1.294 0 000-1.833l-7.788-7.787z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5657_11165">
          <path
            fill="#fff"
            d="M0 0H20V20H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
