import { NotificationEvent } from '@/js/hooks/useNotificationBar/useNotificationBar.types';
import { useEffect, useRef, useState } from 'react';

export default function NotificationBar() {
  const hasComponentMounted = useRef(false);

  const [hasTimedOut, setHasTimedOut] = useState(true);
  const [notification, setNotification] = useState({} as NotificationEvent);
  const timeout = useRef<null | NodeJS.Timeout>(null);

  function clearNotifications() {
    clearTimeout(timeout.current as any);
    setHasTimedOut(true);
  }

  const pushNotification = useRef((e: NotificationEvent) => {
    clearNotifications();
    (() =>
      setNotification((not) => {
        return e;
      }))();
  });

  useEffect(() => {
    if (hasComponentMounted.current) return;
    window.addEventListener('notification', pushNotification.current as any);
    hasComponentMounted.current = true;
  }, []);

  useEffect(() => {
    if (notification.message) {
      window.scrollTo(0, 0);
      setHasTimedOut(false);
      timeout.current = setTimeout(() => {
        clearNotifications();
      }, 5000);
    }

    return () => {
      clearNotifications();
    };
  }, [notification, notification.timeStamp]);

  if (hasTimedOut) return null;

  return (
    <div className="bg-narvik-green p-4 text-center">
      <div className="font-bold text-lg text-success-500">{notification.message}</div>
    </div>
  );
}
