import BaseStore from "@/js/core/BaseStore";
import { IEducationDegreeStore } from "@/js/modules/educationDegrees/store/EducationDegreeTypes";
import EducationDegreeService from "@/js/modules/educationDegrees/services/EducationDegreeService";

const educationDegreeStore = {
  educationDegrees: [],
}

class EducationDegreesStore extends BaseStore<IEducationDegreeStore, EducationDegreeService> {
  async fetchEducationDegrees() {
    const { data } = await this.service.fetchEducationDegrees();
    this.state.educationDegrees = data.result;
  }
  optionsMapper(options) {
    return options.map(option => { return { label: option.name, value: option.id } });
  }
}

export default new EducationDegreesStore(educationDegreeStore, EducationDegreeService);
