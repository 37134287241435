import { tailwindColors } from "@/plugins/tailwind";
import React from "react";

function InterviewServiceIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none">
      <circle cx="17.5" cy="17.5" r="17.5" fill={tailwindColors['primary']['400']}></circle>
      <path
        fill="#fff"
        d="M9.25 20.368c0 1.306 1.01 2.369 2.25 2.369s2.25-1.063 2.25-2.369c0-1.305-1.01-2.368-2.25-2.368s-2.25 1.063-2.25 2.368zM15.5 28h.5v-.526c0-2.031-1.57-3.684-3.5-3.684h-2c-1.93 0-3.5 1.653-3.5 3.684V28h8.5zm5.75-7.632c0 1.306 1.01 2.369 2.25 2.369s2.25-1.063 2.25-2.369c0-1.305-1.01-2.368-2.25-2.368s-2.25 1.063-2.25 2.368zM27.5 28h.5v-.526c0-2.031-1.57-3.684-3.5-3.684h-2c-1.93 0-3.5 1.653-3.5 3.684V28h8.5z"
      ></path>
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M21.009 6a6.536 6.536 0 00-2.817.623l-.247.168h.197a2.033 2.033 0 011.383.56c.368.354.581.832.594 1.335v4.628h.667l1.804 1.822a.678.678 0 00.94 0 .638.638 0 00.197-.455v-1.895a4.706 4.706 0 001.507-1.127A3.042 3.042 0 0026 9.693a3.31 3.31 0 00-.46-1.455 3.424 3.424 0 00-1.072-1.111A5.974 5.974 0 0021.008 6zM9.642 8.062a.652.652 0 00-.454.183.614.614 0 00-.188.44v6.667c0 .166.068.324.188.441.12.117.284.183.454.183a.432.432 0 00.297 0l2.47-1.2h5.733a.678.678 0 00.472-.189.638.638 0 00.196-.458V8.686a.64.64 0 00-.204-.443.678.678 0 00-.464-.18h-8.5z"
        ></path>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M9 6h17v10H9z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default InterviewServiceIcon;
