import Image from "@/js/components/Image/Image";
import { Images } from "@/utils/Images";
import OnboardingBottom from "../OnboardingBottom";
import { useWindowSize } from "@/js/hooks/useWindowSize/useWindowSize";
import { ScreenSizes } from "@/utils/ScreenSizes";
import ContractCheckbox from "@/js/modules/contracts/components/ContractCheckbox/ContractCheckbox";
import { ContractRoutes } from "@/js/modules/contracts/services/ContractRoutes";
import talentStore from "@/js/modules/talent/store/TalentStore";
import CustomError from "@/js/components/CustomError/CustomError";
import { AccountTypes, UIState } from "@/utils/Enums";
import LocalStorage from "@/js/helpers/localStorage";
import { setIdentify, track } from "@/plugins/amplitude";
import { TrackerEvents } from "@/utils/TrackerEvents";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageRoutes } from "@/utils/PageRoutes";
import SuccessIconFilled from "@/assets/icons/SuccessIconFilled";
import SuccessIconGray from "@/assets/icons/SuccesIconGray";
import { useNavigateByAccountType } from "@/js/hooks/useNavigateByAccountType/useNavigateByAccountType";
import { OnboardingSteps, resetOnboardingLocalStorage } from "../../constants/onbardingConstants";
import { __ } from "@/utils/Trans";
import blokPublicStore from "@/js/modules/bloks/store/BlokPublicStore";

export default function OnboardingTalentLastStep() {
	talentStore.initializeStore(talentStore.state)
	blokPublicStore.initializeStore()

	const navigate = useNavigate()
	const { width } = useWindowSize()
	const [searchParams] = useSearchParams()
	const isMobile = width <= ScreenSizes.XSmall

	const { getRedirectRoute } = useNavigateByAccountType()

	const onboardingSteps = LocalStorage.get('onboardingSteps') as OnboardingSteps[]

	const handleSubmitClick = async () => {
		LocalStorage.set('onboardingPayload', talentStore.getCreatePayload(talentStore.state.form))

		const payload = LocalStorage.get('onboardingPayload')
		const response = await talentStore.createTalent(payload);

		track(TrackerEvents.CreateTalent, payload);
		setIdentify('accountType', AccountTypes.Talent);

		if (response?.data.isSuccess) {
			const redirect = searchParams.get('redirect');
			const subscribe = searchParams.get('subscribe');

			//Subscribes to the selected blok
			if (searchParams.has('redirect')) {
				const redirect = String(searchParams.get('redirect'))
				if (redirect &&  redirect.includes('bloks')) {
					const blokId = redirect.replace("/bloks/", "").replace("/","")
					if(blokId && blokId !== 'null'){
						track(TrackerEvents.SubscribeToBlok, { blokId: blokId });
						await blokPublicStore.subscribe(blokId as string, AccountTypes.Talent);
					}
				}
			}

			resetOnboardingLocalStorage()

			if (subscribe && redirect) {
				return window.location.href = `${redirect}?subscribe=${subscribe}`
			}

			if (redirect) {
				return window.location.href = redirect;
			}

			if (onboardingSteps.includes(OnboardingSteps.TalentBreath)) {
				return navigate({ pathname: await getRedirectRoute() });
			} else {
				return navigate({ pathname: PageRoutes.DiscoverBloksPage })
			}
		}
	}

	const items = [
		{ icon: <SuccessIconFilled />, text: "OnboardingPage.TalentLastStep.Step1", },
		{ icon: <SuccessIconGray />, text: "OnboardingPage.TalentLastStep.Step2", },
		{ icon: <SuccessIconGray />, text: "OnboardingPage.TalentLastStep.Step3", },
		{
			icon: <SuccessIconGray />,
			text: onboardingSteps.includes(OnboardingSteps.TalentBreath)
				? "OnboardingPage.TalentLastStep.Step4"
				: "OnboardingPage.TalentLastStep.Step5",
		},
	]

	return (
		<div className={"flex flex-col justify-between items-center"}>
			<div className={`${!isMobile && 'xl:h-[calc(100vh-200px)]'} mx-auto w-full flex flex-col-reverse px-4 py-10 gap-7 items-center justify-center xl:gap-0 xl:p-0 xl:flex-row xl:items-stretch xl:justify-between xl:max-w-[70%]`}>
				<div className="flex items-center self-start justify-center xs:self-auto xl:w-[45%]">
					<div className={"flex flex-col gap-4 xl:gap-10"}>
						<h1 className="text-header-bold-h8 md:text-header-bold-h5 text-shade-black">
							{__("OnboardingPage.TalentLastStep.Title")}
						</h1>
						<ul className={"flex flex-col"}>
							{items.map(item => (
								<li key={item.text} className={"flex items-center"}>
									{item.icon}
									<span className={"p-2.5 text-body-regular-b6 md:text-body-regular-b5 text-shade-black"}>
										{__(item.text)}
									</span>
								</li>
							))}
							<li className="pt-2.5 flex">
								<TalentContract />
							</li>
						</ul>
					</div>

				</div>
				<div className="xl:w-[45%] flex justify-center items-center">
					<Image
						src={Images.TalentOnboardingLastStepGIF}
						alt={"OnboardingTalentLastStep"}
						className={"w-full h-full max-w-md xl:max-w-none"}
					/>
				</div>
			</div>
			<OnboardingBottom
				hasBackButton={false}
				onClick={handleSubmitClick}
				isLoading={talentStore.state.isSaving}
				disabled={!talentStore.state.form.hasAgreement}
				buttonText={__("OnboardingPage.TalentLastStep.Button")}
				customElement={undefined}
			/>
		</div>
	)
}

export const TalentContract = () => {
	return (
		<div className={"flex items-center flex-row-reverse"}>
			<ContractCheckbox
				checked={talentStore.state.form.hasAgreement}
				name={"hasAgreement"}
				error={talentStore.state.errors}
				label={"CreateTalentPage.TalentAgreement"}
				to={ContractRoutes.TalentMemberShipAgreement}
				labelClass={"text-shade-black text-body-regular-b7"}
				onChecked={e => talentStore.state.form.hasAgreement = e.target.checked}
			/>
			<div>
				<CustomError
					name="hasAgreement"
					stateType={UIState.Error}
					error={talentStore.state.errors}
				/>
			</div>
		</div>
	)
}
