
const VisaIcon = () => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="48"
         height="16"
         fill="none"
         viewBox="0 0 48 16"
      >
         <path
            fill="url(#paint0_linear_5957_1450)"
            fillRule="evenodd"
            d="M23.65.452l-3.075 14.475h-3.75L19.9.452h3.75zm15.825 9.3l1.95-5.475 1.125 5.475h-3.075zm4.2 5.175h3.45l-3-14.475H40.9c-.75 0-1.35.45-1.575 1.05l-5.7 13.425H37.6l.75-2.175h4.8l.525 2.175zm-9.825-4.725c0-3.825-5.325-4.05-5.25-5.775 0-.525.525-1.05 1.575-1.2.525-.075 2.025-.15 3.675.675l.675-3.075C33.625.452 32.5.152 31 .152c-3.75 0-6.3 1.95-6.375 4.8 0 2.1 1.875 3.225 3.3 3.975 1.5.675 1.95 1.2 1.95 1.8 0 .975-1.2 1.425-2.25 1.425-1.875 0-3-.525-3.825-.9l-.675 3.15c.9.375 2.475.75 4.2.75 3.9 0 6.525-1.95 6.525-4.95zM18.25.452l-6.075 14.475H8.2l-3-11.55c-.15-.75-.375-.975-.9-1.275-.9-.525-2.4-.975-3.675-1.2L.7.452h6.375c.825 0 1.575.525 1.725 1.5l1.575 8.4 3.9-9.9h3.975z"
            clipRule="evenodd"
         ></path>
         <defs>
            <linearGradient
               id="paint0_linear_5957_1450"
               x1="0.61"
               x2="4650.61"
               y1="750.077"
               y2="750.077"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#1D1C45"></stop>
               <stop offset="1" stopColor="#174489"></stop>
            </linearGradient>
         </defs>
      </svg>
   )
}

export default VisaIcon