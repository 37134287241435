import { tailwindColors } from "@/plugins/tailwind";
import React from "react";

function TalentServiceIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none">
      <circle cx="17.5" cy="17.5" r="17.5" fill={tailwindColors['primary']['200']}></circle>
      <path
        fill="#fff"
        d="M16.421 11.333a3.333 3.333 0 11-6.667 0 3.333 3.333 0 016.667 0zm-2.125 4.208h-2.458a3.518 3.518 0 00-3.5 3.125l-.333 2.875a.736.736 0 00.541.791c1.417.292 2.959.459 4.542.459.5 0 .958 0 1.458-.042l.333-2.666c.167-1.417.917-2.667 1.958-3.542-.708-.625-1.583-1-2.541-1zm8.415 1.667h-2.458a3.518 3.518 0 00-3.5 3.125l-.333 2.874a.73.73 0 00.584.792 22.64 22.64 0 004.54.458c1.584 0 3.084-.166 4.542-.458a.73.73 0 00.583-.792l-.333-2.874c-.333-1.75-1.833-3.125-3.625-3.125zM24.837 13a3.333 3.333 0 11-6.667 0 3.333 3.333 0 016.666 0z"
      ></path>
    </svg>
  );
}

export default TalentServiceIcon;
