export interface ISocialAccountStore {
    socialAccounts: []
}

export enum SocialAccountNames {
    Website = 'Websitesi',
    Dribbble = "Dribbble",
    Telegram = 'Telegram',
    Medium = 'Medium',
    Behance = "Behance",
    Instagram = 'Instagram',
    Reddit = 'Reddit',
    Twitter = 'Twitter',
    Whatsapp = 'Whatsapp',
    Other = 'Diğer',
    Gitlab = 'Gitlab',
    LinkedIn = 'LinkedIn',
    Youtube = 'Youtube',
    Spotify = 'Spotify',
    Slack = 'Slack',
    Discord = 'Discord',
    Github = 'Github',
    Facebook = 'Facebook',
    Twitch = 'Twitch',
    Email = 'Email',
}
