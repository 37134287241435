import React from 'react'
import { __ } from "@/utils/Trans";
import { isMobile } from "react-device-detect";
import SocialMediaShareModal from "@/js/components/SocialMediaShare/SocialMediaShareModal"
import CopyUrlIcon from '@/assets/icons/CopyUrlIcon';

interface IBlogPostShareModal {
	title: string;
	blogPostUrl: string;
}

const BlogPostShareModal = (props: IBlogPostShareModal) => {
	const [isShareModalOpen, setIsShareModalOpen] = React.useState(false);

	const handleGetSocialMediaContent = () => {
		return {
			shareText: __("SocialMediaShareModal.BlogPost.ShareContent")
				.replace('{{article_title}}', props.title)
				.replace('{{short_link}}', ''),
			shareTextWithUrl: __("SocialMediaShareModal.BlogPost.ShareContent")
				.replace('{{article_title}}', props.title)
				.replace('{{short_link}}', props.blogPostUrl)
		}
	}

	const handleShareButtonClick = () => {
		if (navigator.share && isMobile) {
			return navigator.share({
				text: handleGetSocialMediaContent().shareTextWithUrl,
			})
		}

		setIsShareModalOpen(true);
	}

	return (
		<>
			<button onClick={handleShareButtonClick} className={"flex items-center gap-2 mt-4 mb-5"}>
				<span className='text-gray-400'>
					<CopyUrlIcon />
				</span>
				<span className='text-gray-500'>
					{__('SocialMediaShareModal.BlogPost.Share')}
				</span>
			</button>
			{isShareModalOpen && (
				<SocialMediaShareModal
					url={props.blogPostUrl}
					type='share'
					modalTitle={__("SocialMediaShareModal.BlogPost.Title")}
					onClose={() => setIsShareModalOpen(false)}
					shareText={handleGetSocialMediaContent().shareText}
					shareTextWithUrl={handleGetSocialMediaContent().shareTextWithUrl}
				/>
			)}
		</>
	)

}
export default BlogPostShareModal
