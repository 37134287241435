import BaseStore from "@/js/core/BaseStore";
import ExperienceService from "../services/ExperienceService"
import { IExperienceStore } from "./ExperienceTypes";

const experienceStore: IExperienceStore = {
    experiences: []
}

class ExperienceStore extends BaseStore<IExperienceStore, ExperienceService> {
    async fetchExperiences() {
        const { data } = await this.service.fetchExperiences()
        this.state.experiences = data.result;
    }
}

export default new ExperienceStore(experienceStore, ExperienceService) as ExperienceStore;
