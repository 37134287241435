import BaseStore from "@/js/core/BaseStore";

import SocialAccountService from "../services/SocialAccountService";
import { ISocialAccountStore, SocialAccountNames } from "./SocialAccountTypes";

const socialAccountStore = {
    socialAccounts: [],
}

class SocialAccountStore extends BaseStore<ISocialAccountStore, SocialAccountService> {
    async fetchSocialAccounts() {
        const { data } = await this.service.fetchSocialAccounts();
        this.state.socialAccounts = data.result;
    }

    getFilteredSocialAccountProviders(providersToExclude: SocialAccountNames[]) {
        return this.state?.socialAccounts?.filter(socialAccount => {
            return !providersToExclude.includes(socialAccount.name);
        });
    }
}

export default new SocialAccountStore(socialAccountStore, SocialAccountService);
