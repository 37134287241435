const TelegramIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.299 4.70101 21 10.5 21Z" fill="url(#paint0_linear_2254_9855)"/>
      <path d="M15.6069 6.03887L13.7304 15.4924C13.7304 15.4924 13.4668 16.1494 12.7469 15.8347L8.4194 12.5183L6.84578 11.759L4.19816 10.866C4.19816 10.866 3.79295 10.7204 3.75361 10.4057C3.71427 10.091 4.2139 9.92179 4.2139 9.92179L14.7454 5.79103C14.7414 5.79103 15.6069 5.40943 15.6069 6.03887Z" fill="white"/>
      <path d="M8.08933 15.3901C8.08933 15.3901 7.96344 15.3783 7.80607 14.8787C7.64871 14.3791 6.8501 11.759 6.8501 11.759L13.2115 7.71871C13.2115 7.71871 13.5773 7.49447 13.5655 7.71871C13.5655 7.71871 13.6324 7.75805 13.4357 7.94295C13.239 8.12785 8.43946 12.4396 8.43946 12.4396" fill="#D3E6F2"/>
      <path d="M10.0831 13.7889L8.37179 15.3507C8.37179 15.3507 8.23803 15.453 8.09247 15.39L8.419 12.4906" fill="#B6D0E5"/>
      <defs>
        <linearGradient id="paint0_linear_2254_9855" x1="10.4993" y1="0" x2="10.4993" y2="20.9219" gradientUnits="userSpaceOnUse">
          <stop stopColor="#42BDE9"/>
          <stop offset="1" stopColor="#23A8DD"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default TelegramIcon;
