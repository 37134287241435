import BaseService from "@/js/core/BaseService";
import { JobEndpoints } from "./JobEndpoints";

export default class JobPostTalentService extends BaseService {
  async createJobPostTalent(payload) {
    return this.api().post(JobEndpoints.CreateJobPostTalent, payload);
  }
  async suggestionTalent(payload) {
    return this.api().post(JobEndpoints.SuggestionTalent, payload);
  }
  async fetchTalents({ jobPostId, blokId }, sortByScore?: boolean) {
    return this.api().get(JobEndpoints.FetchTalents.replace(':id', jobPostId).replace(':blokId', blokId), {
      params: {
        sortByScore
      }
    });
  }
  async fetchJobPostApplications(jobPostId) {
    return this.api().get(JobEndpoints.FetchJobPostApplications.replace(':id', jobPostId));
  }
  async updateState(id, payload) {
    return this.api().put(JobEndpoints.UpdateTalentApplicationState.replace(':id', id), payload);
  }
  async fetchTalentsByReferredId() {
    return this.api().get(JobEndpoints.fetchTalentsByReferredId);
  }
  async suggestTalentWithLinkedIn(payload: unknown) {
    return this.api().post(JobEndpoints.SuggestTalentWithLinkedIn, payload);
  }
  async updateJobPostTalentReferralStatus(id: string, payload: { hasTalentAcceptedReferral: boolean }) {
    return this.api().put(JobEndpoints.UpdateJobPostTalentReferralStatus.replace(':id', id), payload);
  }
  async getHasTalentAcceptedReferralStatus(id: string) {
    return this.api().get(JobEndpoints.UpdateJobPostTalentReferralStatus.replace(':id', id));
  }
}
