import React from 'react'

export const useGetChildByDisplayName = <T extends string | number | symbol,>(children: React.ReactNode, displayNames: T[]) => {

    if (!children) return {} as { [key in T]: React.ReactElement }

    const childs = React.Children.map(children, ((child: any) => {
        if (displayNames.includes(child.type.displayName)) return child;
        return null;
    }))

    return childs?.reduce((acc, curr) => {
        if (curr) acc[curr.type.displayName] = curr;
        return acc;
    }, {}) as { [key in T]: React.ReactElement }
}
