export enum CompanyEndpoints {
	FetchCompany = '/companies/by/user',
	Company = '/companies',
	FetchCompanyPublicPage = '/companies/public/:id',
	FetchCompanyWithRelations = '/companies/:id',
	UpdateCompany = '/companies/:id',
	UploadAvatarPhoto = '/companies/:id/updateLogo',
	CheckJobPostPermission = '/companies/:id/can-create-job-post',
	FetchCompanyByAuthUser = '/companies/company-by-auth-user',
	CreateForEvent = '/companies/for-event',
	UpdateCompanyState = '/companies/:id/updateState',
	UpdateImportedCompany = '/companies/:id/updateImportedCompany',
}
