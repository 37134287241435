const CheckIconV4 = () => {
	return (
		<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.5424 6.05754C17.9524 6.46759 17.9524 7.13241 17.5424 7.54246L9.14236 15.9425C8.73231 16.3525 8.06749 16.3525 7.65744 15.9425L3.45744 11.7425C3.04739 11.3324 3.04739 10.6676 3.45744 10.2575C3.86749 9.84749 4.53231 9.84749 4.94236 10.2575L8.3999 13.7151L16.0574 6.05754C16.4675 5.64749 17.1323 5.64749 17.5424 6.05754Z"
				fill="#2E9052"
			/>
		</svg>
	);
};

export default CheckIconV4;
