export default function ChevronDownV2() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         fill="none"
         viewBox="0 0 20 20"
      >
         <path
            fill="#3A0087"
            fillRule="evenodd"
            d="M3.087 6.837a.938.938 0 011.326 0L10 12.424l5.587-5.587a.938.938 0 011.326 1.326l-6.25 6.25a.938.938 0 01-1.326 0l-6.25-6.25a.938.938 0 010-1.326z"
            clipRule="evenodd"
         ></path>
      </svg>
   )
}