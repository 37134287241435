import TopNavBarMenuItem from "./TopNavBarMenuItem"

interface ITopBarNavigation extends React.PropsWithChildren {
    items: {
        to: string,
        name: string,
        translation: string
        openInNewTab?: boolean
        forceRedirect?: boolean
        element?: React.ReactNode | JSX.Element
    }[]
}


const TopNavBarMenu = (props: ITopBarNavigation) => {
    const activeClassName = `inline-flex h-12 items-center px-1 pt-1 text-sm font-bold border-b-2 text-primary-500 border-primary-500`
    const inActiveClassName = "inline-flex h-12 items-center px-1 pt-1 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-neutral-400"

    const getClassNames = (isActive: boolean) => {
        if (isActive) return activeClassName
        else return inActiveClassName
    }

    return (
        <div className={"items-center hidden md:flex md:space-x-5 ml-5"}>
            {props.items.map(item => (
                <TopNavBarMenuItem
                    item={item}
                    key={item.name}
                    getClassNames={getClassNames}
                />
            ))}
        </div>
    )
}

export default TopNavBarMenu