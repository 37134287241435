import { Images } from '@/utils/Images';
import { __ } from '@/utils/Trans';
import React from 'react';
import Icon from '@/js/components/Icon/Icon';
import { InputType } from '@/js/components/Input/InputType';
import { Icons } from '@/utils/Icons';
import Image from '@/js/components/Image/Image';
import { useFileUpload } from '@/js/hooks/useFileUpload/useFileUpload';
import Skeleton from 'react-loading-skeleton';
import { LogoVariants, LogoSizes, FileSizes, AccountTypes } from '@/utils/Enums';
import ImageCropper from '@/js/components/ImageCropper/ImageCropper';
import Modal from '@/js/components/Modal/Modal';
import { nanoid } from 'nanoid';
import { toast } from "react-hot-toast";
import { useAiImageFileUpload } from '@/js/hooks/useFileUpload/useAiImageFileUpload';

interface IFormLogo {
	inputName: string;
	onChange?(value: string, name: string): void;
	canUpload?: boolean;
	logoUrl?: string;
	variant?: LogoVariants | AccountTypes,
	size?: LogoSizes,
	classes?: string,
	isEditable?: boolean,
	roundedAspect?: boolean,
	isClickable?: boolean
	hidePlaceholder?: boolean
	htmlFor?: string
	imageLoading?: (value: boolean) => void
	alt?:string
	uploadAiPhoto?: boolean
}

const FormLogo = (props: IFormLogo) => {
	const {
		inputName,
		onChange,
		canUpload = false,
		logoUrl,
		variant,
		size = LogoSizes.L,
		classes = '',
		isEditable = false,
		isClickable = false,
		htmlFor = 'company-logo',
		hidePlaceholder = false,
		alt,
		uploadAiPhoto = false
	} = props

	const [file, setFile] = React.useState<string | null>(null);
	const [filePath, setFilePath] = React.useState('');
	const [base64Image, setBase64Image] = React.useState('');
	const [isEditing, setIsEditing] = React.useState(false);

	const { handleFileChange, isLoading } = uploadAiPhoto ? useAiImageFileUpload(): useFileUpload();

	const getSizeClasses = () => {
		if (size === LogoSizes.L) return 'w-24 h-24';
		if (size === LogoSizes.XL) return 'w-44 h-44';
	}

	const getSize = () => {
		if (size === LogoSizes.XS) return 44;
		if (size === LogoSizes.SM) return 52;
		if (size === LogoSizes.SM2) return 56;
		if (size === LogoSizes.MD) return 64;
		if (size === LogoSizes.MD2) return 72;
		if (size === LogoSizes.L) return 96;
		if (size === LogoSizes.XL) return 172;
	}

	const getVariant = () => {
		if (variant === LogoVariants.Talent) return Images.EmptyProfileLogo;
		if (variant === LogoVariants.Company) return Images.EmptyCompanyLogo;
		if (variant === LogoVariants.Blok) return Images.EmptyBlokLogo;

		return Images.EmptyCompanyLogo;
	}

	const getRoundedAspect = () => {
		if (variant === LogoVariants.Talent) return '!rounded-full';
		if (variant === LogoVariants.Company || variant === AccountTypes.CompanyOwner) return '!rounded-lg';
		if (variant === LogoVariants.Blok || variant === AccountTypes.BlokOwner) return '!rounded-20';

		return '';
	}

	const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;

		if (files && files[0].size > FileSizes.FiveMB) {
			toast.error(__('FormLogo.SizeError'))
			e.target.value = '';
			e.currentTarget.value = '';
			e.target.files = null;
			e.currentTarget.files = null;
			return;
		}

		if (isEditable) setIsEditing(true)

		if (isEditable && (files && files.length > 0)) {
			const reader = new FileReader()
			reader.readAsDataURL(files[0])
			reader.onload = () => {
				if (reader.readyState === 2) {
					setBase64Image(reader.result as string);
				}
			}
			return;
		}

		// if not edited
		const url = await handleFileChange(files?.item(0) as File) as unknown as string;
		setFile(url);
		setFilePath(url);
		if (onChange) onChange(url, inputName);
	}

	const getLoaderVariant = () => {
		switch (variant) {
			case LogoVariants.Talent:
				return 'rounded-full'
			default:
				return 'rounded-lg'
		}
	}


	React.useEffect(() => {
		props.imageLoading && props.imageLoading(isLoading)
	}, [isLoading, props])

	return (
		<>
			{
				isLoading
					? <Skeleton className={`${getLoaderVariant()} ${hidePlaceholder ? "!hidden" : ""} !w-24 h-24`} />
					: <div className={'relative'}>
						<label
							htmlFor={htmlFor ? htmlFor : 'company-logo'}
							className={`${canUpload ? 'cursor-pointer' : `${classes}`} ${getSizeClasses()} ${getRoundedAspect()} ${hidePlaceholder ? "!hidden" : ""} flex shrink-0 border-2 border-gray-100 items-center justify-center group bg-gray-50 relative overflow-hidden company-logo`}
						>
							{(file || logoUrl) && (
								<Image
									width={getSize()}
									height={getSize()}
									src={filePath || logoUrl}
									className={`object-cover h-full ${isClickable ? "cursor-pointer" : ""}`}
									alt={alt || 'company logo preview'}
								/>
							)}
							{canUpload && (
								<input
									key={'logo-input' + nanoid()}
									id={htmlFor ? htmlFor : 'company-logo'}
									onChange={handleUpload}
									name={inputName}
									type={InputType.File}
									className={'hidden'}
									accept={'.png, .jpg, .jpeg'}
								/>
							)}
							{((!file && !logoUrl)) && (
								<Image
									width={getSize()}
									height={getSize()}
									src={getVariant()}
									alt={__('Logo.EmptyProfile')}
								/>
							)}
							{canUpload && (
								<div className={'absolute top-9 w-full h-full bg-gradient-to-t from-black/40 group-hover:from-black/60 flex items-center justify-center'}>
									<div className={`${size === LogoSizes.MD ? "mb-7" : "mb-3"}`}>
										<Icon color='text-white' name={Icons.Pencil} />
									</div>
								</div>
							)}
						</label>
					</div>
			}
			{isEditing && (
				<Modal name='ImageCropperModal' onClose={() => setIsEditing(false)}>
					<ImageCropper
						roundedAspect={props.roundedAspect || false}
						setIsEditing={setIsEditing}
						base64String={base64Image}
						onReturn={edited => {
							handleFileChange(edited)
								.then(url => {
									setFile(url as string);
									setFilePath(url as string);
									if (onChange) onChange(url as string, inputName);
								})
						}}
					/>
				</Modal>
			)}
		</>
	);
}

export default FormLogo;
