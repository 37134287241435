import CopyButton from '@/js/components/CopyButton/CopyButton'
import TextBox from '@/js/components/TextBox/TextBox'
interface IInputWithCopy {
	label?: string
	value: string
}

const InputWithCopy = (props: IInputWithCopy) => {
	return (
		<div className='flex flex-col gap-1'>
			<TextBox name={'copy'}>
				<TextBox.Label>{props?.label}</TextBox.Label>
				<TextBox.Input
					name={'copyInput'}
					classes={"!rounded-full !text-shade-black !cursor-text !text-label-regular-l5 !px-6 !py-4 !bg-neutral-50 !border-0"}
					attr={{
						disabled: true,
						value: `${props.value.slice(0, 50)}...`,
					}}
					endIcon={
						<div className='mr-6'>
							<CopyButton value={props.value} />
						</div>
					}
				/>
			</TextBox>
		</div>
	)
}

export default InputWithCopy

