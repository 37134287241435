import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { __ } from "@/utils/Trans";
import adminStore from "@/js/modules/admin/store/AdminStore";
import { PageRoutes } from "@/utils/PageRoutes";
import HamburgerIcon from "@/assets/icons/HamburgerIcon";
import SearchIconV2 from "@/assets/icons/SearchIconV2";
import { RowStates } from "@/js/core/enums/RowStates";
import DWSLogo from "@/js/components/DWS/DWSLogo";
import { BlogStates } from "@/js/modules/admin/pages/AdminBlogPostsListPage";
import { BlokTalentStates } from "@/js/modules/admin/pages/AdminBlokTalentListPage";
import { BlokCompaniesStates } from "@/js/modules/admin/pages/AdminBlokCompaniesListPage";
import { AdminUserListFilter } from "@/js/modules/admin/pages/AdminUserListPage";
import './AdminLayout.css';

const teams = [
  { id: 1, name: "Planetaria", href: "#", initial: "P", current: false },
  { id: 2, name: "Protocol", href: "#", initial: "P", current: false },
  { id: 3, name: "Tailwind Labs", href: "#", initial: "T", current: false }
];

const secondaryNavigation = [
  { name: "Overview", href: "#", current: true },
  { name: "Activity", href: "#", current: false },
  { name: "Settings", href: "#", current: false },
  { name: "Collaborators", href: "#", current: false },
  { name: "Notifications", href: "#", current: false }
];

const stats = [
  { name: "Number of deploys", value: "405" },
  { name: "Average deploy time", value: "3.65", unit: "mins" },
  { name: "Number of servers", value: "3" },
  { name: "Success rate", value: "98.5%" }
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showPage, setShowPage] = useState(false);

  const location = useLocation();

  const navigation = [
    { name: "AdminSidebar.Users", href: PageRoutes.AdminUserListPage.replace(':accountType', AdminUserListFilter.All).replace(':state', AdminUserListFilter.All), current: location.pathname.includes("users") },
    { name: "AdminSidebar.Bloks", href: PageRoutes.AdminBlokListPage.replace(':state', RowStates.Approved), current: location.pathname.includes("blok-list") },
    { name: "AdminSidebar.Companies", href: PageRoutes.AdminCompaniesListPage, current: location.pathname.includes("companies") },
    { name: "AdminBlokTalentListPage.Title", href: PageRoutes.AdminBlokTalentsListPage.replace(':state', BlokTalentStates.All), current: location.pathname.includes("blok-talents") },
    { name: "AdminBlokCompaniesListPage.Title", href: PageRoutes.AdminBlokCompaniesListPage.replace(':state', BlokCompaniesStates.All), current: location.pathname.includes("blok-companies") },
    { name: "AdminSideBar.JobPostCandidates", href: PageRoutes.AdminJobPostCandidatesListPage, current: location.pathname.includes("job-post-candidates") },
    { name: "AdminSidebar.RewardsJobPosts", href: PageRoutes.AdminJobPostListPage.replace(':state', RowStates.Published), current: location.pathname.includes("job-post-list") },
    { name: "AdminSidebar.JobPostBloks", href: PageRoutes.AdminJobPostBloksListPage.replace(':state', RowStates.Published), current: location.pathname.includes("job-post-bloks-list") },
    { name: "AdminSidebar.Services", href: PageRoutes.AdminServiceListPage, current: location.pathname.includes("services") },
    { name: "AdminEditTranslationsPage.Translations", href: PageRoutes.AdminEditTransaltionsPage, current: location.pathname.includes("translations") },
    { name: "AdminSidebar.BlogPosts", href: PageRoutes.AdminBlogPostsListPage.replace(':state', BlogStates.All), current: location.pathname.includes("blog-posts") },
    { name: "AdminSideBar.NewBlogPost", href: PageRoutes.AdminBlogPostCreatePage, current: location.pathname.includes("blog-post-create"), reload: true },
		{ name: "AdminSideBar.PerkList", href: PageRoutes.AdminPerkListPage, current: location.pathname.includes("perks-list"), reload: true},
  ];

  React.useEffect(() => {
    (async () => {
      const isAdmin = await adminStore.checkIsAdmin();

      if (!isAdmin) {
        return window.location.href = PageRoutes.Forbidden;
      }else{
				setShowPage(true)
			}
    })()
  });

  return (
    <>
		{showPage &&
      <div className={"bg-gray-700 dark admin-layout"}>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 xl:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        {/*<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />*/}
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
                    <div className="flex shrink-0 items-center mt-4">
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  reloadDocument={item.reload}
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  {__(`${item.name}`)}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={classNames(
                                    team.current
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <span
                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col bg-gray-900">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5">
            <div className="flex h-16 shrink-0 items-center mt-4">
              <DWSLogo useLong={false} />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={item.href}
                          reloadDocument={item.reload}
                          className={classNames(
                            item.current
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          {__(`${item.name}`)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <span
                            className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="xl:pl-72">
          {/* Sticky search header */}
          <div
            className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-white/5 bg-gray-900 px-4 shadow-sm sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-white xl:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <HamburgerIcon />
            </button>

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className="flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500 flex items-center">
                    <SearchIconV2 />
                  </div>
                  <input
                    id="search-field"
                    className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-white focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    type="search"
                    name="search"
                  />
                </div>
              </form>
            </div>
          </div>

          <main>
            <header>
              {/* Secondary navigation */}
              <nav className="flex overflow-x-auto border-b border-white/10 py-4 bg-gray-900">
                <ul
                  role="list"
                  className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
                >
                  {secondaryNavigation.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className={item.current ? "text-indigo-400" : ""}>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>

              {/* Heading */}
              <div
                className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-700/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 bg-gray-900">
                <div>
                  <div className="flex items-center gap-x-3">
                    <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
                      <div className="h-2 w-2 rounded-full bg-current" />
                    </div>
                    <h1 className="flex gap-x-3 text-base leading-7">
                      <span className="font-semibold text-white">DigitalWisers</span>
                    </h1>
                  </div>
                  <p className="mt-2 text-xs leading-6 text-gray-400">Deploys from GitHub via main branch</p>
                </div>
                <div
                  className="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">
                  Production
                </div>
              </div>

              {/* Stats */}
              <div className="grid grid-cols-1 bg-gray-700/10 sm:grid-cols-2 lg:grid-cols-4 bg-gray-900">
                {stats.map((stat, statIdx) => (
                  <div
                    key={stat.name}
                    className={classNames(
                      statIdx % 2 === 1 ? "sm:border-l" : statIdx === 2 ? "lg:border-l" : "",
                      "border-t border-white/5 py-6 px-4 sm:px-6 lg:px-8"
                    )}
                  >
                    <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
                    <p className="mt-2 flex items-baseline gap-x-2">
                      <span className="text-4xl font-semibold tracking-tight text-white">{stat.value}</span>
                      {stat.unit ? <span className="text-sm text-gray-400">{stat.unit}</span> : null}
                    </p>
                  </div>
                ))}
              </div>
            </header>

            <div className="border-t border-white/10 pt-11 bg-gray-900">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
		}
    </>
  );
}
