export const tailwindColors = {
  gainsboro: '#DFDFDF',
  'lavender-web-2': '#E1E1F6',
  'floral-lavender': '#A674F7',
  'pixie-powder': '#340581',
  'blue-violet': '#7329EA',
  'pale-violet': '#C3A3F9',
  'old-silver': '#828282',
  'lavender-web': '#F0E0FF',
  'rich-black': '#071120',
  'light-silver': '#D8D8D8',
  primary: {
    50: '#E8DBFF',
    100: '#E1D1FC',
    200: '#B991FA',
    300: '#B78AFF',
    400: '#A69EE2',
    500: '#8A47F6',
    600: '#532B94',
    700: '#3A0087',
    800: '#1C0E31',
    900: '#0C001B',
  },
  secondary: {
    50: '#EAEEFF',
    100: '#DCE2FD',
    200: '#B9C5FB',
    300: '#96A8FA',
    400: '#738BF8',
    500: '#506EF6',
    600: '#4058C5',
    700: '#304294',
    800: '#202C62',
    900: '#101631',
  },
  neutral: {
    50: '#F3F5F6',
    100: '#EEF0F3',
    200: '#E5E8EC',
    300: '#E2E2E2',
    400: '#C7CDD4',
    500: '#7F8B9F',
    600: '#6B7B8A',
    700: '#333840',
    800: '#191C20',
    900: '#0F1113',
  },
  error: {
    50: '#F7DADC',
    100: '#EFB5B9',
    200: '#E69197',
    300: '#DE6C74',
    400: '#D64751',
    500: '#AB3941',
    600: '#802B31',
    700: '#561C20',
    800: '#2B0E10',
    900: '#220709',
  },
  success: {
    50: '#E8FFF0',
    100: '#D7F0E0',
    200: '#B0E1C2',
    300: '#88D2A3',
    400: '#61C385',
    500: '#39B466',
    600: '#2E9052',
    700: '#226C3D',
    800: '#174829',
    900: '#0B2414',
  },
  shade: {
    white: '#FFFFFF',
    black: '#060010',
    stroke: '#E2E2E2',
    background: '#F9F9FF',
  },
} as const;
