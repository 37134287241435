import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import tr from '../translations/tr/app.json';
import en from '../translations/en/app.json';

const order = ['localStorage', 'htmlTag', 'path', 'subdomain'];

const customLanguageDetector = {
  name: 'customDetector',
  lookup() {
    const navigatorLang = navigator.language;
    if (navigatorLang.startsWith('en')) {
      return 'en';
    }
    if (navigatorLang.startsWith('tr')) {
      return 'tr';
    }
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customLanguageDetector);

if (window.location.href.includes('blogposts')) {
  order.unshift('cookie');
}

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    fallbackLng: 'en',
    resources: {
      tr: { translation: tr },
      en: { translation: en },
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'customDetector', 'navigator', 'htmlTag'],
      caches: ['cookie', 'localStorage'],
      cookieOptions: {
        sameSite: 'lax',
        domain: process.env.VITE_APP_DOMAIN ? String(process.env.VITE_APP_DOMAIN) : undefined,
      }
    },
    interpolation: {
      escapeValue: false
    },
  });

  const originalChangeLanguage = i18n.changeLanguage.bind(i18n);
  i18n.changeLanguage = (lng = 'en', callback) => {
    if (lng) {
      localStorage.setItem('i18nextLng', lng);
      document.cookie = `i18next=${lng}; path=/; SameSite=Lax`;
    }
    return originalChangeLanguage(lng, callback);
  };

export default i18n;