import BaseService from "@/js/core/BaseService";
import { BlokEndpoints } from '@/js/modules/bloks/services/BlokEndpoints';

export default class BlokOwnerService extends BaseService {
  async fetchBlokOwner() {
    return this.api().get(BlokEndpoints.FetchBlokOwner);
  }

  async updateBlokOwner(data) {
    return this.api().put(BlokEndpoints.FetchBlokOwner, data);
  }
}
