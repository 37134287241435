export default function CopyIconV2() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
			<path
				stroke="#7F8B9F"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.75"
				d="M7 14H5.25a1.75 1.75 0 01-1.75-1.75v-7c0-.966.784-1.75 1.75-1.75h7c.966 0 1.75.784 1.75 1.75V7M8.75 17.5h7a1.75 1.75 0 001.75-1.75v-7A1.75 1.75 0 0015.75 7h-7A1.75 1.75 0 007 8.75v7c0 .966.784 1.75 1.75 1.75z"
			></path>
		</svg>
	)
}
