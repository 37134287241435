import { useOutletContext, useSearchParams } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import OnboardingBottom from "../OnboardingBottom";
import React from 'react'
import { useRanger } from "react-ranger";
import { tailwindColors } from "@/plugins/tailwind";
import { useWindowSize } from "@/js/hooks/useWindowSize/useWindowSize";
import { ScreenSizes } from "@/utils/ScreenSizes";
import { Trans } from "react-i18next";
import { __ } from "@/utils/Trans";
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";

export default function OnboardingTalentBreath() {
  const { width } = useWindowSize()
  const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>();
	const [searchParams] = useSearchParams()

   const isMobile = width <= ScreenSizes.Medium

   const { getTrackProps, segments, handles, ticks } = useRanger({
      min: 1,
      stepSize: 1,
      max: isMobile ? 3 : 8,
      values: isMobile ? [1, 2, 3] : [1, 2, 3, 4, 5, 6, 7, 8, 9],
      ticks: isMobile ? Array.from({ length: 3 }) : Array.from({ length: 8 })
   });

   const handleContinueClick = () => {
      handleChangeStep()
   }

   const getTextAndIconByIndex = (index: number) => {
      switch (index) {
         case 0:
            return {
               text: __("OnboardingPage.TalentBreath.Step1"),
               icon: '✏️',
            }
         case isMobile ? 1 : 5:
            return {
               text: __("OnboardingPage.TalentBreath.Step2"),
               icon: '🚀',
            }
         case isMobile ? 2 : 8:
            return {
               text: __("OnboardingPage.TalentBreath.Step3"),
               icon: '🏆',
            }
         default:
            return {};
      }
   }

   return (
      <div className="flex flex-col justify-center items-center">
         <div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} py-10 px-4 flex flex-col justify-start items-center max-w-3xl w-full  xl:p-0 xl:justify-center`}>
            <div className={"flex flex-col gap-4 mb-[100px]"}>
               <h1 className={"text-header-bold-h8 text-shade-black text-left md:text-center md:text-header-bold-h5"}>
                  {__("OnboardingPage.TalentBreath.Title")}
               </h1>
               <p className={"text-body-regular-b5 text-shade-black md:text-center"}>
                  <Trans
                     i18nKey="OnboardingPage.TalentBreath.Subtitle"
                     components={{ bold: <b className="text-primary-500" /> }}
                  />
               </p>
            </div>
            <div className="w-full max-w-[280px] md:max-w-none">
               <div {...getTrackProps(trackStyles)}>
                  {segments.map(({ getSegmentProps }, index) => (
                     // eslint-disable-next-line react/jsx-key
                     <div {...getSegmentProps(getSegmentStyles(index, isMobile))} />
                  ))}
                  {ticks.map(({ getTickProps }, index) => (
                     // eslint-disable-next-line react/jsx-key
                     <div {...getTickProps()} style={getTickStyles(index, isMobile)} />
                  ))}
                  {handles.map(({ getHandleProps }, index) => (
                     // eslint-disable-next-line react/jsx-key
                     <div {...getHandleProps()} className="!pointer-events-none relative sm:max-w-xl sm:mx-auto">
                        <div className="inline-block w-10">
                           <div className={`text-primary-500  ${index === handles.length - 1 ? "w-32" : "w-40"} text-center absolute z-10 bottom-full -left-14`}>
                              <span className={'text-header-extrabold-h6'}>
                                 {getTextAndIconByIndex(index)?.icon}
                              </span>
                           </div>
                           <div className={`text-primary-500 ${(isMobile && index === 0) && '!w-44'} ${(isMobile && index !== 0) && '!w-32'} ${index === handles.length - 1 ? "w-40 !text-shade-black" : "w-36"} text-center absolute z-10 top-full -left-14 mt-2`}>
                              <span className={'text-body-semibold-b7 md:text-body-semibold-b5'}>
                                 {getTextAndIconByIndex(index)?.text}
                              </span>
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
         <OnboardingBottom onClick={handleContinueClick} />
      </div>
   )
}

const ticks = Array.from({ length: 8 })

const trackStyles: { style: React.CSSProperties } = {
   style: {
      height: '7px',
      display: 'flex ',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
};

const getTickStyles = (index: number, isMobile: boolean) => ({
   zIndex: 1,
   width: isMobile ? "18px" : '15px',
   height: isMobile ? "18px" : '15px',
   borderRadius: '100%',
   background:
      isMobile
         ? index === 0 || index === 1
            ? tailwindColors.primary[500]
            : tailwindColors.primary[50]
         : index === ticks.length - 1 || index === ticks.length - 2
            ? tailwindColors.primary[50]
            : tailwindColors.primary[500]
})

const getSegmentStyles = (index: number, isMobile: boolean): { style: React.CSSProperties } => ({
   style: {
      borderRadius: '4px',
      height: isMobile ? '10px' : '7px',
      background:
         isMobile
            ? index === 1
               ? tailwindColors.primary[500]
               : tailwindColors.primary[50]
            : index === ticks.length - 1 || index === ticks.length - 2
               ? tailwindColors.primary[50]
               : tailwindColors.primary[500],
   },
});
