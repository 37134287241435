export default function CrossIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor">
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M5.008 5.007a1.05 1.05 0 011.485 0L11 9.514l4.508-4.507a1.05 1.05 0 011.485 1.485l-4.508 4.507 4.508 4.508a1.05 1.05 0 01-1.485 1.485L11 12.484l-4.507 4.508a1.05 1.05 0 01-1.485-1.485l4.507-4.508-4.507-4.507a1.05 1.05 0 010-1.485z"
				clipRule="evenodd"
			></path>
		</svg>
	)
}
