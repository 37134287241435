
const OpenToOpportunitiesIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
			<g fill="#7F8B9F" clipPath="url(#a)">
				<path d="M19.012 11.593c.838 0 1.518-.661 1.518-1.476 0-.816-.68-1.476-1.518-1.476s-1.518.66-1.518 1.476c0 .815.68 1.476 1.518 1.476zm-2.499 2.805a1.6 1.6 0 00-1.156.477c-.31.302-.49.711-.49 1.124 0 .434.161.8.49 1.124.3.292.706.455 1.142.455.431 0 .84-.16 1.159-.444l.007-.008c.615-.597.619-1.597.011-2.233-.281-.32-.695-.495-1.163-.495zm-6.016 5.693c.962 0 1.741-.758 1.741-1.693s-.78-1.693-1.741-1.693c-.962 0-1.741.758-1.741 1.693s.78 1.693 1.74 1.693zM4.48 14.15c-.472 0-.944.189-1.332.527l-.007.007-.008.007a1.806 1.806 0 00-.537 1.295c0 .49.197.96.537 1.294a1.91 1.91 0 001.332.523c.505 0 .987-.192 1.331-.523.344-.334.538-.803.538-1.294s-.198-.96-.538-1.295a1.905 1.905 0 00-1.317-.54zm-.498-4.032c0-1.07-.896-1.942-1.997-1.942-1.1 0-2.03.889-2.03 1.942 0 1.07.896 1.942 1.997 1.942s2.03-.89 2.03-1.942zm.498-3.786c1.174 0 2.126-.925 2.126-2.066 0-1.142-.952-2.067-2.125-2.067-1.174 0-2.126.925-2.126 2.067 0 1.141.952 2.066 2.126 2.066z"></path>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h21v21H0z"></path>
				</clipPath>
			</defs>
		</svg>
	)
}

export default OpenToOpportunitiesIcon
