export enum AdminEndpoints {
  fetchUsers = '/admin/users/:accountType',
  fetchBloks = '/admin/bloks/:state',
  fetchJobPosts = '/admin/job-posts/:state',
  UpdateBlokState = '/admin/bloks/:id',
  UpdateJobPostState = '/admin/job-posts/:id',
  UpdateJobPostBlokState = '/admin/job-posts-blok/:jobPostId/:blokId',
  CheckIsAdmin = '/admin/check-is-admin',
  FetchJobPostsBloks = '/admin/job-posts-bloks',
  BlokTalents = '/admin/blok-talents/:state',
  BlokCompanies = '/admin/blok-companies/:state',
  JobPostTalents = '/admin/job-post-talents',
  UpdateDwsStatus = '/admin/dws-status/:id',
  UpdateDwsNote = '/admin/dws-note/:id',
  FetchCompanies = '/admin/companies',
  UpdateCompanyCreateJobPostStatus = '/admin/company-create-job-post-status/:id',
	UpdateCompanyState = '/admin/company-state/:id',
	UpdateBlokCategory = '/blokcategories/:id',
	CreateBlokCategory = '/blokcategories/',
	UpdateBlokOwnertype = '/blokownertypes/:id',
	CreateBlokOwnertype = '/blokownertypes/',
}
