import { TrackerEvents } from '@/utils/TrackerEvents';
import * as amplitude from '@amplitude/analytics-browser';

export const initAmplitude = () => {
  const API_KEY = process.env.VITE_AMPLITUDE_API_KEY!;

  amplitude.init(API_KEY, {
    defaultTracking: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: true,
      fileDownloads: true,
    },
  });
}

export const track = (eventName: TrackerEvents, eventProps?) => {
  amplitude.track(eventName, eventProps);
}

export const setUserId = (userId: string) => {
  amplitude.setUserId(userId);
}

export const setIdentify = (key: string, value) => {
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set(key, value);
  amplitude.identify(identifyEvent);
}

export const resetAmplitude = () => {
  amplitude.reset();
}