import BaseStore from "@/js/core/BaseStore";
import { ILocationStore } from "@/js/modules/locations/store/LocationTypes";
import LocationService from "@/js/modules/locations/services/LocationService";

const locationStore = {
  locations: [],
}

class LocationsStore extends BaseStore<ILocationStore, LocationService> {
  async fetchLocations() {
    const { data } = await this.service.fetchLocations();
    this.state.locations = data.result;
  }
}

export default new LocationsStore(locationStore, LocationService);
