import { UISize } from "@/utils/Enums";
import { useOnClickOutside } from "@/js/hooks/useOnClickOutside/useOnClickOutside";
import React from "react";
import { BaseComponentProps } from "@/utils/BaseComponentProps";
import Button from "../Buttons/Button";
import CrossIcon from "@/assets/icons/CrossIcon";

interface IModal extends BaseComponentProps {
	children?: React.ReactNode;
	onClose: () => void;
	size?: UISize;
	classes?: string;
	closeClasses?: string;
}

export default function Modal({ size = UISize.Md, ...props }: IModal) {
	const ref = React.useRef<HTMLDivElement>(null);

	const getModalClassNames = () => {
		switch (size) {
			case UISize.Sm:
				return 'max-w-lg';
			case UISize.Md:
				return 'max-w-2xl';
			case UISize.Xl:
				return 'max-w-4xl';
			default:
				return 'max-w-3xl';
		}
	}

	useOnClickOutside(ref, () => props.onClose());

	return (
		<div className={"absolute z-50"} role={"dialog"}>
			<div className={"fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"}></div>
			<div className={"fixed inset-0 overflow-y-auto"}>
				<div className={"flex items-center justify-center min-h-full p-4 text-center"}>
					<div
						ref={ref}
						className={`${getModalClassNames()} w-full relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 p-[30px] ${props.classes}`}
					>
						<div className={`flex justify-end text-right dark:text-white mb-[30px] ${props.closeClasses}`}>
							<Button
								outlined
								minWidth={false}
								name={'editAboutBtn'}
								attrs={{ onClick: () => props.onClose() }}
								classes={'!p-0 !w-[36px] !h-[36px] !border-0 !bg-neutral-100 !text-shade-black'}
							>
								<CrossIcon />
							</Button>
						</div>
						<div>{props.children}</div>
					</div>
				</div>
			</div>
		</div>
	)
}
