import React, { useEffect } from "react";
import { __ } from "@/utils/Trans";
import blokStore from "@/js/modules/bloks/store/BlokStore";
import TextBox from "@/js/components/TextBox/TextBox";
import SelectBox from "@/js/components/SelectBox/SelectBox";
import PlusIcon from "@/assets/icons/PlusIcon";
import TrashIconV2 from "@/assets/icons/TrashIconV2";
import { Icons } from "@/utils/Icons";
import Tooltip from "@/js/components/Tooltip/Tooltip";
import Icon from "@/js/components/Icon/Icon";
import { TooltipPlacement } from "@/js/components/Tooltip/TooltipTypes";

interface IAddSocialMediaLinks {
  providers: { id: string; iconUrl: string }[];
  socialAccounts: { id: string; url: string }[];
  onChange: (data: { id: string; url: string }[]) => void;
  errors: { [key: string]: string };
  labelKey?: string;
  initialProvider?: string;
  initialLink?: string;
}

export default function AddSocialMediaLinkInfo(props: IAddSocialMediaLinks) {
  const { labelKey = "AddSocialMediaLinks.SocialMediaAccountsTitle", initialProvider, initialLink } = props;

  const [url, setUrl] = React.useState(initialLink || "");
  const [key, setKey] = React.useState(Number(new Date()));
  const [provider, setProvider] = React.useState(initialProvider || "");

  useEffect(() => {
    if (initialProvider) {
      setProvider(initialProvider);
    }
    if (initialLink) {
      setUrl(initialLink);
    }
  }, [initialProvider, initialLink]);

  const getProviderOptions = (options) => {
    const result = options.map((option) => {
      return {
        label: (
          <span className={"flex gap-2"}>
            <img className={"flex"} src={option.iconUrl} />
            {option.name}
          </span>
        ),
        value: option.id,
      };
    });

    return result;
  };

  const getSelectedProviderOptions = (options, selectedOption) => {
    const result = options.map((option) => {
      return {
        label: (
          <span className={"flex gap-2"}>
            <img className={"flex"} src={option.iconUrl} />
            {option.name}
          </span>
        ),
        value: option.id,
      };
    });

    return result.filter((social) => selectedOption.id === social.value);
  };

  const handleAddButton = () => {
    const data = props.socialAccounts;
    data.push({ id: provider, url });

    props.onChange(data);
    setProvider("");
    setUrl("");
    setKey(Number(new Date()));
  };

  const handleEdit = (url, index) => {
    const data = props.socialAccounts;
    data[index] = { id: data[index]["id"], url };

    props.onChange(data);
  };

  const handleRemoveButton = (index: number) => {
    const data = props.socialAccounts;
    data.splice(index, 1);
    props.onChange(data);
  };

  return (
    <>
      <div className="mb-[10px]">
        <span className="text-shade-black text-body-semibold-b5 flex items-center gap-2">
          {__(labelKey)}
        </span>
      </div>
			{/*List*/}
      {props.socialAccounts?.map((socialAccount, index) => (
        <div className={`flex w-full gap-[10px] items-center mt-[10px]`} key={index}>
          <SelectBox
            canSearchable={false}
            classes={'h-full w-[350px] rounded-lg border-0 bg-transparent self-start mt-1 py-0 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'}
            data={getSelectedProviderOptions(props.providers, socialAccount)}
            name={`provider-${index}`}
            placeholder={__("AddSocialMediaLinks.Account")}
            inputName={`provider-${index}`}
            onChange={(e) => blokStore.set("provider", e.value)}
            value={socialAccount.id}
            error={props.errors}
          />
          <TextBox name={"txt_title"} classes={"w-full"}>
            <TextBox.Input
              name={`url-${index}`}
              error={props.errors}
              classes={"!rounded-lg h-[54px] !rounded-lg px-4 py-2"}
              attr={{
                onChange: (e) => handleEdit(e.target.value, index),
                name: `url-${index}`,
                value: socialAccount.url,
                maxLength: 200,
                placeholder: __("AddSocialMediaLinks.EnterURL"),
              }}
            />
          </TextBox>
          <button
            className={`mt-1 ${Object.keys(props.errors).length ? "self-start mt-5" : ""}`}
            name={"ClearSocialAccount"}
            onClick={() => handleRemoveButton(index)}
          >
            <TrashIconV2 />
          </button>
        </div>
      ))}
			<div className="my-[10px] flex gap-1">
        <span className="text-shade-black text-body-semibold-b5 flex items-center gap-2">
          {__("AddSocialMediaLinkInfo.AddSocialMediaLink")}
        </span>
				<Tooltip
						classes={"info-tooltip"}
						name={'talent-contact-info-tooltip'}
						placement={TooltipPlacement.Right}
						content={__("AddSocialMediaLinkInfo.tooltip")}
					>
						<Icon name={Icons.Info} />
					</Tooltip>
      </div>

			{/*New Item*/}
			<div className={`flex w-full gap-[10px] items-center justify-center`}>
        <SelectBox
          key={key}
          canSearchable
          name={"provider"}
          inputName={"provider"}
          value={provider}
          error={props.errors}
          onChange={(e) => setProvider(e.value)}
          data={getProviderOptions(props.providers)}
          placeholder={__("AddSocialMediaLinks.Channel")}
					classes={'h-full w-[350px] rounded-lg border-0 bg-transparent self-start py-0 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'}
					/>
        <TextBox name={"txt_title"} classes={"w-full"}>
          <TextBox.Input
            name={"url"}
            error={props.errors}
            classes={"!rounded-lg h-[54px] !rounded-lg px-4 py-2 !-mt-1"}
            attr={{
              onChange: (e) => setUrl(e.target.value),
              name: "url",
              value: url,
              maxLength: 200,
              placeholder: __("AddSocialMediaLinks.EnterURL"),
            }}
          />
        </TextBox>
        <button
          onClick={() => handleAddButton()}
          disabled={!(provider && url)}
          name={"NewSocialAccount"}
          className={` justify-center items-center border-neutral-200 flex gap-1 ${!(provider && url) ? "cursor-not-allowed" : ""}`}
        >
          <PlusIcon />
        </button>
      </div>
    </>
  );
}
