import React from 'react'
import FilterIcon from "@/assets/icons/FilterIcon"
import { Menu, Transition } from "@headlessui/react"
import { __ } from '@/utils/Trans'

interface IConversationFilter {
   selectedFilter: string
   counts: {
      all: number
      unread: number
      read: number
      archived: number
   }
   setSelectedFilter: React.Dispatch<React.SetStateAction<ConversationFilterTypes>>
}

export enum ConversationFilterTypes {
   All = 'all',
   Read = 'read',
   Unread = 'unread',
   Archived = 'archived',
}

const ConversationFilter = ({ counts, selectedFilter, setSelectedFilter }: IConversationFilter) => {
   const filters = [
      { label: `${__("ConversationFilter.All")} (${counts?.all})`, value: ConversationFilterTypes.All },
      { label: `${__("ConversationFilter.Unread")} (${counts?.unread})`, value: ConversationFilterTypes.Unread },
      { label: `${__("ConversationFilter.Read")} (${counts?.read})`, value: ConversationFilterTypes.Read },
      { label: `${__("ConversationFilter.Archived")} (${counts?.archived})`, value: ConversationFilterTypes.Archived },
   ]

   return (
      <Menu as={"div"} className={"relative inline-block text-left"}>
         <Menu.Button className={"inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-gray-100"}>
            <FilterIcon />
         </Menu.Button>
         <Transition
            as={React.Fragment}
            enter={"transition ease-out duration-100"}
            enterFrom={"transform opacity-0 scale-95"}
            enterTo={"transform opacity-100 scale-100"}
            leave={"transition ease-in duration-75"}
            leaveFrom={"transform opacity-100 scale-100"}
            leaveTo={"transform opacity-0 scale-95"}
         >
            <Menu.Items className={"absolute right-0 mt-2 w-40 origin-top-right rounded bg-white shadow ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden"}>
               {filters.map(filter => (
                  <Menu.Item key={filter.value}>
                     {({ active }) => (
                        <button
                           onClick={() => setSelectedFilter(filter.value)}
                           className={`
                              block w-full text-left px-4 py-2 text-sm
                              ${active ? 'bg-gray-50' : 'text-gray-500'} 
                              ${selectedFilter === filter.value ? 'bg-gray-50 text-violet-500 font-semibold' : 'text-gray-700'}`}
                        >
                           {filter.label}
                        </button>
                     )}
                  </Menu.Item>
               ))}
            </Menu.Items>
         </Transition>
      </Menu>
   )
}

export default ConversationFilter