import useUserStore from "@/js/modules/users/store/useUserStore"
import { shallow } from "zustand/shallow";
import React from 'react'
import { useQuery } from "@tanstack/react-query";
interface TopBarUserInfoProps extends React.PropsWithChildren {
	classes?: string
}

const AuthorInfo = (props: TopBarUserInfoProps) => {
	const { user, me } = useUserStore(state => ({ user: state.user, me: state.me }), shallow);

	useQuery({
		queryKey: ['navbarAuthorInfo'],
		queryFn: async () => await me(),
	})

	return (
		<div className={`ml-3 ${props.classes}`}>
			{user && (
				<>
					<div className="text-base font-medium text-gray-800 break-words truncate w-64 sm:max-w-lg sm:w-full xl:w-44">
						{`${user.firstName} ${user.lastName}`}
					</div>
					<div className="text-sm font-medium text-gray-500">
						{user.email}
					</div>
				</>
			)}
		</div>
	)
}

export default AuthorInfo
