import BellIcon from '@/assets/icons/BellIcon'

const TopBarNotification = () => {
    return (
        <button type={"button"} className={"px-5 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none"}>
            <BellIcon />
        </button>
    )
}

export default TopBarNotification