import { useOutletContext } from "react-router-dom";
import { OnboardingOutletContextType } from "../constants/onbardingConstants";
import LeftIcon from "@/assets/icons/LeftIcon";
import Button from "@/js/components/Buttons/Button";
import { __ } from "@/utils/Trans";

interface IOnboardingBottom {
	disabled?: boolean;
	buttonText?: string;
	onClick: () => void;
	hasBackButton?: boolean;
	customElement?: JSX.Element;
	isLoading?: boolean;
}

export default function OnboardingBottom(props: IOnboardingBottom) {
	const { hasBackButton = true, isLoading = false } = props

	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()

	return (
		<div className={"h-20 w-full border-t-neutral-50 py-1 px-4 flex justify-center items-center  xl:px-0 border-t-2"}>
			<div className={`w-full flex justify-between items-center ${props.customElement ? 'max-w-5xl' : 'max-w-4xl'} gap-2 sm:gap-0`}>
				{(props.customElement && !hasBackButton) && props.customElement}
				{hasBackButton && (
					<div onClick={() => handleChangeStep(true)} className={"flex items-center gap-4 cursor-pointer"}>
						<span className={"bg-primary-50 text-primary-500 h-10 w-10 rounded-full flex justify-center items-center p-3"}>
							<LeftIcon />
						</span>
						<p className={"text-shade-black text-body-regular-b5"}>
							{__("OnboardingBottom.BackButton")}
						</p>
					</div>
				)}
				<Button
					name={"continueButton"}
					classes={`${!hasBackButton && "mx-auto w-full"} xl:w-auto`}
					disabled={props.disabled || false}
					isLoading={isLoading}
					attrs={{ onClick: props.onClick }}
				>
					{props.buttonText || __("OnboardingBottom.NextButton")}
				</Button>
			</div>
		</div>
	)
}
