import { useCallback, useState } from 'react';
import { IUseToggle } from './useToggle.types';

export const useToggle: IUseToggle = (initialValue) => {
    const [value, setValue] = useState(!!initialValue);

    const toggle = useCallback(() => setValue(value => !value), []);

    return [value, toggle, setValue];
};
