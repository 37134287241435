const WhatsAppIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2254_9846)">
        <path d="M21 10.5C21 16.2981 16.2969 21 10.5 21C4.70043 21 0 16.2955 0 10.5C0 4.70453 4.70043 0 10.5 0C16.2996 0 21 4.70189 21 10.5Z" fill="#0D9F16"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.577 9.0366C15.8927 6.19698 13.4487 4.26075 10.4947 4.22641C10.1063 4.23169 9.69942 4.26339 9.29516 4.34792C5.17601 5.18264 3.04114 9.74981 5.04919 13.4374C5.10203 13.5351 5.10467 13.617 5.07561 13.7174C4.80875 14.6894 4.54453 15.6615 4.27767 16.6309C4.2354 16.7894 4.2354 16.7894 4.39393 16.7498C5.40324 16.4857 6.40991 16.2241 7.41658 15.9574C7.50113 15.9362 7.56454 15.9441 7.64116 15.9838C8.93847 16.6468 10.3045 16.8423 11.7365 16.5649C15.1581 15.8966 17.3934 12.4204 16.577 9.0366ZM11.3666 15.5638C11.0866 15.6113 10.8012 15.6377 10.6532 15.6351C9.55674 15.6324 8.66368 15.3868 7.84197 14.8902C7.73628 14.8268 7.64645 14.8109 7.52226 14.8426C6.97005 14.9958 6.41255 15.1332 5.86034 15.2785C5.74408 15.3128 5.73351 15.2891 5.76258 15.1834C5.9079 14.6577 6.04793 14.1347 6.19853 13.6143C6.24081 13.4691 6.22496 13.3555 6.14305 13.226C5.00691 11.4008 5.05447 9.12377 6.32536 7.43584C7.64645 5.6766 9.44312 4.95547 11.5912 5.41509C13.7102 5.8615 15.0604 7.22716 15.5571 9.33509C16.2362 12.2143 14.2809 15.0777 11.3666 15.5638Z" fill="#FCFCFC"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.1276 13.4426C11.9057 13.4532 11.6943 13.4004 11.4803 13.3449C10.0509 12.9328 8.97549 12.0373 8.11414 10.8592C7.78915 10.4155 7.4853 9.95848 7.3849 9.4064C7.26336 8.74339 7.45624 8.18338 7.94768 7.72376C8.10357 7.58112 8.58181 7.51772 8.77468 7.61018C8.84602 7.64452 8.89094 7.70791 8.92 7.77659C9.09967 8.20716 9.27934 8.6298 9.45636 9.06037C9.49071 9.14225 9.47486 9.22414 9.43787 9.30603C9.34804 9.50414 9.20007 9.66263 9.05211 9.81848C8.94114 9.93206 8.93586 10.0377 9.01776 10.1751C9.52242 11.0177 10.2226 11.6279 11.1368 11.9951C11.2742 12.0506 11.3746 12.0294 11.4671 11.9158C11.6203 11.7283 11.7815 11.5407 11.9242 11.3453C12.0219 11.2079 12.1355 11.1894 12.2756 11.2528C12.5768 11.3902 12.8806 11.5328 13.1818 11.6755C13.2505 11.7045 13.3166 11.7389 13.3826 11.7706C13.6891 11.9238 13.6865 11.9264 13.6601 12.2645C13.6099 12.9249 13.1131 13.2498 12.5266 13.4109C12.3997 13.4479 12.2624 13.4506 12.1276 13.4426Z" fill="#FDFDFD"/>
      </g>
      <defs>
        <clipPath id="clip0_2254_9846">
          <rect width="21" height="21" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default WhatsAppIcon;
