export default function SuccessIconFilled() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="21"
         height="21"
         fill="none"
         viewBox="0 0 21 21"
      >
         <path
            fill="#39B466"
            d="M10.5 1.75a8.75 8.75 0 018.75 8.75 8.75 8.75 0 01-8.75 8.75 8.75 8.75 0 01-8.75-8.75 8.75 8.75 0 018.75-8.75zm2.818 6.099L9.405 11.76l-1.724-1.724a.656.656 0 00-.927.928l2.188 2.187a.656.656 0 00.927 0l4.375-4.375a.656.656 0 10-.927-.927z"
         ></path>
      </svg>
   )
}