import React from 'react';
import { __ } from '@/utils/Trans';

interface IReadMore {
   limit: number;
   children: React.ReactNode & string;
   isTalentProfile?: boolean;
}

const ReadMore = ({ limit, children, isTalentProfile = false }: IReadMore) => {
   const [isReadMore, setIsReadMore] = React.useState<boolean>(false);

   const handleToggleClick = () => setIsReadMore(!isReadMore);

   return (
      <>
         {isReadMore ? (
            <span className={"whitespace-break-spaces"}>{children}</span>
         ) : (
            <span className={`whitespace-break-spaces ${isTalentProfile ? "limited-two-line-block" : ""}`}>
               {children.slice(0, limit) + '...'}
            </span>
         )}
         <button onClick={handleToggleClick} className={'pl-1 text-primary-500 font-semibold text-sm'}>
            {isReadMore ? __("ReadMore.ReadLess") : __("ReadMore.ReadMore")}
         </button>
      </>
   );
}

export default ReadMore;
