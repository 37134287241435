import { PageRoutes } from "@/utils/PageRoutes"
import TopNavBar from "../topbar/TopNavBar"
import LandingTopNavButton from "@/js/modules/landing/components/LandingTopNavButton"

export const landingMenuItems = [
   {
      to: String(process.env.VITE_FE_URL),
      name: "For Employers",
      translation: "TopBar.Landing.ForEmployers",
      openInNewTab: false,
      reloadDocument: true,
   },
   {
      to: `${String(process.env.VITE_FE_URL)}${PageRoutes.LandingForCommunities}`,
      name: "For Communities",
      translation: "TopBar.Landing.ForCommunities",
      openInNewTab: false,
      reloadDocument: true,
   },
   {
      to: `${String(process.env.VITE_FE_URL)}${PageRoutes.DWSPlus}`,
      name: "DWS+",
      translation: "TopBar.Landing.DWS+",
      openInNewTab: false,
      reloadDocument: true,
   },
]

const BlogLayout = () => {
   return (
      <TopNavBar forceRedirect useLong isSearchable={false} hasNotifications={false} hasTranslation>
         <TopNavBar.MenuSection menuItems={landingMenuItems} />
         <TopNavBar.ActionSection>
            <LandingTopNavButton />
         </TopNavBar.ActionSection>
      </TopNavBar>
   )
}

export default BlogLayout