/* eslint-disable react/jsx-key */
import { useWindowSize } from "@/js/hooks/useWindowSize/useWindowSize";
import { useOutletContext } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import { ScreenSizes } from "@/utils/ScreenSizes";
import { useRanger } from "react-ranger";
import OnboardingBottom from "../OnboardingBottom";
import { tailwindColors } from "@/plugins/tailwind";
import { __ } from "@/utils/Trans";
import { Trans } from "react-i18next";

export default function OnboardingBlokOwnerBreath() {
	const { width } = useWindowSize()
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>();

	const isMobile = width <= ScreenSizes.Medium

	const { getTrackProps, segments, handles, ticks } = useRanger({
		min: 1,
		stepSize: 1,
		max: isMobile ? 3 : 7,
		values: isMobile ? [1, 2, 3] : [1, 2, 3, 4, 5, 6, 7, 8],
		ticks: isMobile ? Array.from({ length: 3 }) : Array.from({ length: 7 })
	});

	const handleContinueClick = () => {
		handleChangeStep()
	}

	const getTextAndIconByIndex = (index: number) => {
		switch (index) {
			case 0:
				return {
					text: "OnboardingPage.BlokOwnerBreath.Step1",
					icon: '✏️',
				}
			case isMobile ? 1 : 3:
				return {
					text: "OnboardingPage.BlokOwnerBreath.Step2",
					icon: '🚀',
				}
			case isMobile ? 2 : 7:
				return {
					text: "OnboardingPage.BlokOwnerBreath.Step3",
					icon: '🏆',
				}
			default:
				return {};
		}
	}

	return (
		<div className="flex flex-col justify-center items-center">
			<div className={"onboarding-full-height py-10 px-4 flex flex-col justify-start items-center max-w-2xl w-full  xl:p-0 xl:justify-center"}>
				<div className={"flex flex-col gap-4 mb-[100px]"}>
					<h1 className={"text-header-bold-h8 text-shade-black text-left md:text-center md:text-header-bold-h5"}>
						{__("OnboardingPage.BlokOwnerBreath.Title")}
					</h1>
					<p className={"text-body-regular-b5 text-shade-black md:text-center"}>
						{__("OnboardingPage.BlokOwnerBreath.Subtitle")}
					</p>
				</div>
				<div className="w-full max-w-[280px] md:max-w-none">
					<div {...getTrackProps(trackStyles)}>
						{segments.map(({ getSegmentProps }, index) => (
							<div {...getSegmentProps(getSegmentStyles(index, isMobile))} />
						))}
						{ticks.map(({ getTickProps }, index) => (
							<div {...getTickProps()} style={getTickStyles(index, isMobile)} />
						))}
						{handles.map(({ getHandleProps }, index) => (
							<div
								{...getHandleProps()}
								className="!pointer-events-none relative sm:max-w-xl sm:mx-auto"
							>
								<div className="inline-block w-10">
									<div
										className={`text-primary-500 ${index === handles.length - 1 ? "w-32" : "w-40"
											} text-center absolute z-10 bottom-full `}
										style={{
											left: calculateLeftOffset(index, handles.length, -56, -52),
										}}
									>
										<span className={"text-header-extrabold-h6"}>
											{getTextAndIconByIndex(index)?.icon}
										</span>
									</div>
									<div
										className={`text-primary-500 ${isMobile && index !== 0 && "!w-32"} ${index === handles.length - 1 ? "w-40 !text-shade-black" : "w-36"
											} text-center absolute z-10 top-full mt-2`}
										style={{
											left: calculateLeftOffset(index, handles.length, -50, -65),
										}}
									>
										<p className={"text-body-semibold-b7 md:text-body-semibold-b5"}>
											{getTextAndIconByIndex(index)?.text! && (
												<Trans
													i18nKey={getTextAndIconByIndex(index).text}
													components={{
														br: <br />,
													}}
												/>
											)}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<OnboardingBottom onClick={handleContinueClick} />
		</div>
	)
}

const ticks = Array.from({ length: 7 })

const trackStyles: { style: React.CSSProperties } = {
	style: {
		height: '7px',
		display: 'flex ',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
};

const getTickStyles = (index: number, isMobile: boolean) => ({
	zIndex: 1,
	width: isMobile ? "18px" : '15px',
	height: isMobile ? "18px" : '15px',
	borderRadius: '100%',
	background:
		isMobile
			? index === 0 || index === 1
				? tailwindColors.primary[500]
				: tailwindColors.primary[50]
			: index === ticks.length - 1 ||
				index === ticks.length - 2 ||
				index === ticks.length - 3
				? tailwindColors.primary[50]
				: tailwindColors.primary[500]
})

const getSegmentStyles = (index: number, isMobile: boolean): { style: React.CSSProperties } => ({
	style: {
		borderRadius: '4px',
		height: isMobile ? '10px' : '7px',
		background:
			isMobile
				? index === 1
					? tailwindColors.primary[500]
					: tailwindColors.primary[50]
				: index === ticks.length - 1 ||
					index === ticks.length - 2 ||
					index === ticks.length - 3
					? tailwindColors.primary[50]
					: tailwindColors.primary[500],
	},
})

const calculateLeftOffset = (value: number, max: number, minOffset = -60, maxOffset = -140) => {
	const ratio = (value - 1) / (max - 1);
	const offset = minOffset + (maxOffset - minOffset) * ratio;

	return `${offset}px`;
};
