import CheckBox from "@/js/components/CheckBox/CheckBox";
import SelectBox from "@/js/components/SelectBox/SelectBox";
import TextBox from "@/js/components/TextBox/TextBox";
import talentStore from "@/js/modules/talent/store/TalentStore";
import { UIState } from "@/utils/Enums";
import { __ } from "@/utils/Trans";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import OnboardingBottom from "../OnboardingBottom";
import { useQuery } from "@tanstack/react-query";
import educationDegreeStore from "@/js/modules/educationDegrees/store/EducationDegreeStore";
import { InputType } from "@/js/components/Input/InputType";
import React from 'react'
import { defaultTitleMaxLength } from "@/utils/Regex";
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";

export default function OnboardingTalentEducation() {
	const [errors, setErrors] = React.useState({})
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()
  const [searchParams] = useSearchParams()

	const handleContinueClick = () => {
		setErrors({})

		if (
			talentStore.state.form.lastEducationName.length > defaultTitleMaxLength ||
			talentStore.state.form.lastAcademicMajor.length > defaultTitleMaxLength
		) {
			setErrors({
				lastEducationName: talentStore.state.form.lastEducationName.length > defaultTitleMaxLength ? "Validations.DefaultTitleMaxLength" : "",
				lastAcademicMajor: talentStore.state.form.lastAcademicMajor.length > defaultTitleMaxLength ? "Validations.DefaultTitleMaxLength" : ""
			})
			return
		}

		handleChangeStep()
	}

	useQuery({
		queryKey: ['educationDegrees'],
		queryFn: async () => {
			await educationDegreeStore.fetchEducationDegrees()
			return true
		}
	})

	return (
		<div className="flex flex-col justify-center items-center">
			<div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} py-10 px-4 flex flex-col justify-start items-center gap-7 max-w-2xl w-full xl:gap-10 overflow-y-auto no-scrollbar`}>
				<h1 className={"text-header-bold-h8 text-left md:text-center text-shade-black self-start md:self-auto md:text-header-bold-h5"}>
					{__("OnboardingPage.TalentEducation.Title")}
				</h1>
				<div className={"w-full flex flex-col justify-center gap-6"}>
					<SelectBox
						data={talentStore.educationDegrees()}
						label={__("CreateTalentPage.EducationLevel")}
						placeholder={__("CreateTalentPage.EducationLevelPlaceholder")}
						name={"educationDegreeId"}
						error={talentStore.state.errors}
						onChange={e => talentStore.set("educationDegreeId", e.value)}
						value={talentStore.state.form.educationDegreeId}
						stateType={{} as UIState}
						inputName={"educationDegree"}
					/>
					<TextBox name={"txt_EducationInstitution"} >
						<TextBox.Label>
							{__("CreateTalentPage.School")}
						</TextBox.Label>
						<TextBox.Input
							name={"lastEducationName"}
							error={errors}
							attr={{
								type: InputType.Text,
								onChange: e => {
									setErrors(prev => ({ ...prev, lastEducationName: "" }))
									talentStore.set("lastEducationName", e.target.value)
								},
								name: "lastEducationName",
								value: talentStore.state.form.lastEducationName,
								maxLength: 200,
								placeholder: __("CreateTalentPage.SchoolPlaceholder")
							}}
						/>
					</TextBox>
					<TextBox name={"txt_Major"}>
						<TextBox.Label>
							{__("CreateTalentPage.Major")}
						</TextBox.Label>
						<TextBox.Input
							name={"lastAcademicMajor"}
							error={errors}
							attr={{
								onChange: e => {
									setErrors(prev => ({ ...prev, lastAcademicMajor: "" }))
									talentStore.set("lastAcademicMajor", e.target.value)
								},
								name: "lastAcademicMajor",
								value: talentStore.state.form.lastAcademicMajor,
								maxLength: 200,
								placeholder: __("CreateTalentPage.MajorPlaceholder")
							}}
						/>
					</TextBox>
				</div>
				<div className={"self-start"}>
					<CheckBox className={"flex-row-reverse"}>
						<CheckBox.Label htmlFor={"isStudent"}>
							<span className={"text-body-regular-b5 text-shade-black"}>
								{__("CreateTalentPage.StillStudent")}
							</span>
						</CheckBox.Label>
						<CheckBox.Input
							id={"isStudent"}
							name={"isStudent"}
							attrs={{
								type: "checkbox",
								name: "isStudent",
								checked: talentStore.state.form.isStudent,
								onChange: e => talentStore.set("isStudent", e.target.checked),
							}}
						/>
					</CheckBox>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={
					!talentStore.state.form.educationDegreeId ||
					!talentStore.state.form.lastEducationName ||
					!talentStore.state.form.lastAcademicMajor
				}
			/>
		</div>
	)
}
