export default function LinkedInIconV3() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="18"
         height="19"
         fill="none"
         viewBox="0 0 18 19"
      >
         <path
            fill="#0077B5"
            d="M17.996 18.151H18v-6.602c0-3.23-.695-5.717-4.47-5.717-1.815 0-3.033.996-3.53 1.94h-.053V6.134h-3.58V18.15h3.728V12.2c0-1.568.297-3.082 2.237-3.082 1.912 0 1.94 1.787 1.94 3.182v5.85h3.724zM.297 6.135h3.732v12.017H.297V6.135zM2.162.152A2.162 2.162 0 000 2.314c0 1.193.968 2.182 2.162 2.182 1.193 0 2.161-.989 2.161-2.182A2.163 2.163 0 002.161.152z"
         ></path>
      </svg>
   )
}