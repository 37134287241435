import React from 'react'

const DWSLetterLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" fill="none">
            <path
                fill="#08208F"
                d="M54 4.335c0 2.403-1.92 4.335-4.252 4.335-2.358 0-4.253-1.957-4.253-4.335 0-2.403 1.92-4.335 4.253-4.335C52.105 0 54 1.957 54 4.335z"
            ></path>
            <path
                fill="url(#a)"
                d="M8.528 16.398c0 2.403 1.92 4.335 4.253 4.335 2.357 0 4.253-1.957 4.253-4.335 0-2.403-1.92-4.335-4.253-4.335-2.334-.025-4.253 1.932-4.253 4.335z"
            ></path>
            <path
                fill="url(#b)"
                d="M26.512 11.395c0 2.403 1.92 4.335 4.253 4.335 2.358 0 4.253-1.957 4.253-4.335 0-2.403-1.92-4.335-4.253-4.335-2.333-.025-4.253 1.932-4.253 4.335z"
            ></path>
            <path
                fill="url(#c)"
                d="M26.175 26.43l-4.156 11.617 4.447 12.46c-.194-.817-.146-1.709.146-2.576l4.18-11.692L26.2 23.36a4.597 4.597 0 00-2.187-2.503c1.968 1.04 2.916 3.419 2.163 5.574z"
            ></path>
            <path
                fill="url(#d)"
                d="M2.914 20.56C.605 21.402-.56 24.003.265 26.33l8.773 24.573a4.423 4.423 0 003.986 2.973 3.87 3.87 0 01-1.288-.273c-2.309-.842-3.475-3.443-2.649-5.771l4.132-11.593-4.642-12.98c-.657-1.833-2.358-2.973-4.156-2.973-.486 0-.997.1-1.507.273z"
            ></path>
            <path
                fill="url(#e)"
                d="M17.836 23.334L13.22 36.239l4.131 11.593c.827 2.353-.34 4.93-2.649 5.772a4.99 4.99 0 01-1.288.272 4.424 4.424 0 003.986-2.972l4.593-12.881-4.131-11.593c-.827-2.353.34-4.93 2.649-5.772a4.242 4.242 0 013.305.124 1.47 1.47 0 00-.292-.124 4.727 4.727 0 00-1.507-.272 4.424 4.424 0 00-4.18 2.948z"
            ></path>
            <path
                fill="#940078"
                d="M24.01 20.88c-.025-.024-.073-.024-.098-.049-.024-.025-.073-.025-.097-.05a4.466 4.466 0 00-3.305-.123c-2.309.842-3.475 3.443-2.65 5.771l4.132 11.593 4.156-11.617c.778-2.13-.17-4.509-2.139-5.524z"
            ></path>
            <path
                fill="#E51C5C"
                d="M17.374 47.832L13.243 36.24 9.11 47.832c-.826 2.354.34 4.93 2.65 5.772.437.149.874.248 1.287.273h.389a3.87 3.87 0 001.288-.273c2.309-.842 3.476-3.418 2.65-5.772z"
            ></path>
            <path
                fill="#08208F"
                d="M38.101 15.704l-7.315 20.51 4.18 11.692c.316.867.34 1.734.146 2.576l11.325-31.707c.826-2.353-.34-4.93-2.65-5.772a4.727 4.727 0 00-1.506-.272c-1.822.025-3.524 1.14-4.18 2.973z"
            ></path>
            <path
                fill="#08208F"
                d="M34.97 47.931L30.79 36.24l-4.18 11.69a4.462 4.462 0 00-.145 2.577l.194.52A4.447 4.447 0 0030.84 54a4.39 4.39 0 004.107-2.972l.195-.52a4.907 4.907 0 00-.17-2.577z"
            ></path>
            <path
                fill="url(#f)"
                d="M34.97 47.931L30.79 36.24l-4.18 11.69a4.462 4.462 0 00-.145 2.577l.194.52A4.447 4.447 0 0030.84 54a4.39 4.39 0 004.107-2.972l.195-.52a4.907 4.907 0 00-.17-2.577z"
                opacity="0.75"
            ></path>
            <defs>
                <linearGradient
                    id="a"
                    x1="8.528"
                    x2="17.034"
                    y1="16.398"
                    y2="16.398"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EC6B59"></stop>
                    <stop offset="1" stopColor="#E41C5C"></stop>
                </linearGradient>
                <linearGradient
                    id="b"
                    x1="26.512"
                    x2="35.018"
                    y1="11.395"
                    y2="11.395"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#930078"></stop>
                    <stop offset="1" stopColor="#3B0086"></stop>
                </linearGradient>
                <linearGradient
                    id="c"
                    x1="22.019"
                    x2="30.792"
                    y1="35.682"
                    y2="35.682"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#930078"></stop>
                    <stop offset="1" stopColor="#3B0086"></stop>
                </linearGradient>
                <linearGradient
                    id="d"
                    x1="2.873"
                    x2="10.15"
                    y1="20.299"
                    y2="45.193"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EC6B59"></stop>
                    <stop offset="1" stopColor="#E41C5C"></stop>
                </linearGradient>
                <linearGradient
                    id="e"
                    x1="20.106"
                    x2="14.361"
                    y1="30.831"
                    y2="44.618"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#940078"></stop>
                    <stop offset="1" stopColor="#E41C5C"></stop>
                </linearGradient>
                <linearGradient
                    id="f"
                    x1="26.341"
                    x2="35.239"
                    y1="45.12"
                    y2="45.12"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#930078"></stop>
                    <stop offset="1" stopColor="#3B0086"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default DWSLetterLogo