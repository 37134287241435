import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectBox from '@/js/components/SelectBox/SelectBox'
import { PageTranslation, ScreenModes } from '@/utils/Enums'
import userStore from '@/js/modules/users/store/v2/UserStore';
import cookies from '@/js/helpers/cookies';
import { useSearchParams } from 'react-router-dom';

export const translations = [
   { label: "EN", value: PageTranslation.EN },
   { label: "TR", value: PageTranslation.TR }
]

interface ITopNavBarChangeLanguage {
   screenMode?: ScreenModes
}

const TopNavBarChangeLanguage = (props: ITopNavBarChangeLanguage) => {
   const { i18n } = useTranslation()

   const [searchParams, setSearchParams] = useSearchParams()
   const [selectedTranslation, setSelectedTranslation] = React.useState(i18n.language)

   const searchLang = searchParams.get('lang')

   const handleOnChangeLanguage = (translation?: PageTranslation) => {
      const accessToken = localStorage.getItem('accessToken')

      translation = searchLang ? searchLang as PageTranslation : translation

      if (searchLang) {
         searchParams.delete('lang')
         setSearchParams(searchParams)
      }

      if ((accessToken && accessToken !== 'null') || cookies.getCookie('accessToken')) {
         userStore.updateLanguage({ language: translation })
            .then((result) => {
               setSelectedTranslation(result.language)
               i18n.changeLanguage(result.language)
               window.location.reload()
            })
      } else {
         setSelectedTranslation(translation as PageTranslation)
         i18n.changeLanguage(translation)
         window.location.reload()
      }
   }

   React.useEffect(() => {
      const searchLang = searchParams.get('lang') as PageTranslation

      if (searchLang) {
         handleOnChangeLanguage(searchLang)
         searchParams.delete('lang')
         setSearchParams(searchParams)
      }
   }, [searchParams.get('lang')])

   return (
      <>
         {
            props.screenMode === ScreenModes.Desktop && (
               <div className={"hidden xl:flex"}>
                  <div className={"flex gap-1 font-semibold text-sm leading-4 text-blue-gray-500 ml-5 select-none items-center"}>
                     {translations.map((translation, idx) => (
                        <React.Fragment key={translation.value}>
                           <span
                              className={`cursor-pointer underline-offset-4 ${selectedTranslation === translation.value && "underline"}`}
                              onClick={() => handleOnChangeLanguage(translation.value)}
                           >
                              {translation.label}
                           </span>
                           {idx !== translations.length - 1 && "/"}
                        </React.Fragment>
                     ))}
                  </div>
               </div>
            )
         }
         {
            props.screenMode === ScreenModes.Mobile && (
               <SelectBox
                  canSearchable={false}
                  name={'language'}
                  value={selectedTranslation}
                  onChange={e => handleOnChangeLanguage(e.value as PageTranslation)}
                  data={translations}
               />
            )
         }
      </>
   )
}


export default TopNavBarChangeLanguage
