import BaseService from "@/js/core/BaseService";
import { BlokEndpoints } from '@/js/modules/bloks/services/BlokEndpoints';

export default class BlokService extends BaseService {
  async fetchBlokById(id: string) {
    return this.api().get(BlokEndpoints.FetchBlokById.replace(':id', id));
  }

  async fetchBlokBySlug(slug: string) {
    return this.api().get(BlokEndpoints.FetchBlokBySlug.replace(':slug', slug));
  }

  async fetchBlokByAuthUser() {
    return this.api().get(BlokEndpoints.FetchBlokByAuthUser);
  }

  async fetchBlokDetailByAuthUser() {
    return this.api().get(BlokEndpoints.FetchBlokDetailByAuthUser);
  }

  async fetchBlokState() {
    return this.api().get(BlokEndpoints.FetchBlokState);
  }

  async fetchBloks() {
    return this.api().get(BlokEndpoints.FetchBloks);
  }

	async fetchJoinedBloks() {
    return this.api().get(BlokEndpoints.FetchJoinedBloks);
  }

	async fetchFeaturedBloks() {
    return this.api().get(BlokEndpoints.FetchFeaturedBloks);
  }

	async fetchAllBlokNames() {
    return this.api().get(BlokEndpoints.FetchAllBlokNames);
  }

  async fetchJoinedBloksByUserId(talentId: string) {
    return this.api().get(BlokEndpoints.FetchJoinedBloksByUserId.replace(':talentId', talentId));
  }

	async IsUserMakeAiPhotos() {
    return this.api().get(BlokEndpoints.IsUserMakeAiPhotos);
  }

	async fetchJoinedAndPendingBloksByUserId(talentId: string) {
    return this.api().get(BlokEndpoints.FetchJoinedAndPendingBloksByUserId.replace(':talentId', talentId));
  }

  async fetchAllBloks(pageParam, query, filterQuery, sortBy) {
		return this.api().get(`${BlokEndpoints.FetchAllBloks}?page=${pageParam.toString()}${query ? `&search=${query.toString()}` : ''}${filterQuery ? `&filters=${filterQuery.toString()}` : ''}${sortBy ? `&sortBy=${sortBy.toString()}` : ''}`);
  }

  async fetchBloksForAdmin() {
    return this.api().get(BlokEndpoints.FetchBloksForAdmin);
  }

  async create(data) {
    return this.api().post(BlokEndpoints.CreateBlok, data);
  }

  async update(id: string, data) {
    return this.api().put(BlokEndpoints.UpdateBlok.replace(':id', id), data);
  }

  async fetchBlokPackages(ownerType, ownerId) {
    return this.api().get(BlokEndpoints.FetchBlokPackages.replace(':ownerType', ownerType).replace(':ownerId', ownerId));
  }

  async fetchBlokPaywall(ownerType, ownerId) {
    return this.api().get(BlokEndpoints.FetchBlokPaywall.replace(':ownerType', ownerType).replace(':ownerId', ownerId));
  }

  async purchase(data) {
    return this.api().post(BlokEndpoints.Purchase, data);
  }

  async sendInvite(data) {
    return this.api().post(BlokEndpoints.SendInvite, data);
  }

	async fetchBlokInfo(slug: string) {
    return this.api().get(BlokEndpoints.FetchBlokInfo.replace(':slug', slug));
  }

  async fetchBlokSlug(id: string): Promise<string> {
    const response = await this.api().get(BlokEndpoints.FetchBlokSlug.replace(':id', id));
    return response?.data?.slug || '';
  }

	async updateBlokVisibility(id: string, data: { isVisible : boolean}) {
    return this.api().put(BlokEndpoints.UpdateBlokVisibility.replace(':id', id), data);
  }

	async updateBlokPaymentRequirement(id: string, data: { requiresPayment : boolean}) {
    return this.api().put(BlokEndpoints.UpdateBlokPaymentRequirement.replace(':id', id), data);
  }
}
