import BaseService from "@/js/core/BaseService";
import { AdminEndpoints } from "./AdminEndpoints";
import { RowStates } from "@/js/core/enums/RowStates";
import { DwsStatus } from "@/utils/Enums";

export default class AdminService extends BaseService {
  async fetchUsers(accountType: string, state: string) {
    return this.api().put(AdminEndpoints.fetchUsers.replace(':accountType', accountType), { state });
  }

  async fetchBloks(state) {
    return this.api().get(AdminEndpoints.fetchBloks.replace(':state', state));
  }

  async fetchJobPosts(state) {
    return this.api().get(AdminEndpoints.fetchJobPosts.replace(':state', state));
  }

  async updateBlokState(id: string, payload) {
    return this.api().put(AdminEndpoints.UpdateBlokState.replace(':id', id), payload);
  }

  async updateJobPostState(id: string, payload) {
    return this.api().put(AdminEndpoints.UpdateJobPostState.replace(':id', id), payload);
  }

  async updateJobPostTalentDwsStatus(id: string, payload: { dwsStatus: DwsStatus }) {
    return this.api().put(AdminEndpoints.UpdateDwsStatus.replace(':id', id), payload);
  }

  async updateJobPostBlokState(jobPostId: string, blokId: string, payload) {
    return this.api().put(AdminEndpoints.UpdateJobPostBlokState.replace(':jobPostId', jobPostId).replace(":blokId", blokId), payload);
  }

	async updateBlokCategory(id, payload) {
    return this.api().put(AdminEndpoints.UpdateBlokCategory.replace(':id', id), payload);
  }

	async createBlokCategory(payload) {
    return this.api().post(AdminEndpoints.CreateBlokCategory, payload);
  }

	async updateBlokOwnerType(id, payload) {
    return this.api().put(AdminEndpoints.UpdateBlokOwnertype.replace(':id', id), payload);
  }

	async createBlokOwnerType(payload) {
    return this.api().post(AdminEndpoints.CreateBlokOwnertype, payload);
  }

  async checkIsAdmin() {
    return this.api().get(AdminEndpoints.CheckIsAdmin);
  }

  async fetchJobPostsBloks() {
    return this.api().get(AdminEndpoints.FetchJobPostsBloks);
  }

  async fetchBlokTalents(state: RowStates) {
    return this.api().get(AdminEndpoints.BlokTalents.replace(':state', state));
  }

  async fetchBlokCompanies(state: RowStates) {
    return this.api().get(AdminEndpoints.BlokCompanies.replace(':state', state));
  }

  async fetchJobPostTalents() {
    return this.api().get(AdminEndpoints.JobPostTalents);
  }

  async updateDwsNoteById(id: string, payload: { dwsNote: string }) {
    return this.api().put(AdminEndpoints.UpdateDwsNote.replace(':id', id), payload);
  }

  async fetchCompanies() {
    return this.api().get(AdminEndpoints.FetchCompanies);
  }

  async updateCompanyCreateJobPostStatus(id: string, payload: { canCreateJobPost: boolean }) {
    return this.api().put(AdminEndpoints.UpdateCompanyCreateJobPostStatus.replace(':id', id), payload);
  }

	async updateCompanyState(id: string, payload: { state: string }) {
    return this.api().put(AdminEndpoints.UpdateCompanyState.replace(':id', id), payload);
  }
}
