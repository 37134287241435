import React from "react";
import CookieConsent, { OPTIONS as CookieBannerPlacement } from "react-cookie-consent";
import { ContractRoutes } from "../../contracts/services/ContractRoutes";
import { useContract } from "../../contracts/hooks/useContract";
import { Trans } from "react-i18next";
import { __ } from "@/utils/Trans";
import { useIsomorphicLayoutEffect } from "@/js/hooks/useIsomorphicLayoutEffect/useIsomorphicLayoutEffect";
import { useWindowSize } from "@/js/hooks/useWindowSize/useWindowSize";
import { ScreenSizes } from "@/utils/ScreenSizes";

interface ICookieConsentBanner { }

const initialBannerStyles: React.CSSProperties = {
   left: 0,
   right: 0,
   display: "flex",
   margin: "12px",
   position: "fixed",
   borderRadius: "6px",
   textAlign: "justify",
   alignItems: "center",
   background: "#4E5C66",
}

const CookieConsentBanner = (props: ICookieConsentBanner) => {
   const bannerRef = React.useRef<HTMLDivElement>(null)
   const [bannerStyles, setBannerStyles] = React.useState<React.CSSProperties>(initialBannerStyles)

   const { width } = useWindowSize()
   const getLinkToContract = useContract()

   useIsomorphicLayoutEffect(() => {
      bannerRef?.current?.style?.setProperty("z-index", "4000000000", "important")

      if (width < ScreenSizes.Large) {
         setBannerStyles(prevStyles => ({
            ...prevStyles,
            gap: "20px",
            alignItems: "end",
            flexDirection: "column",
         }))
      } else setBannerStyles(initialBannerStyles)
   }, [width])

   return (
      <div ref={bannerRef} className={"absolute"}>
         <CookieConsent
            disableStyles
            enableDeclineButton
            disableButtonStyles
            style={bannerStyles}
            location={CookieBannerPlacement.BOTTOM}
            cookieName={"isCookieConsentAccepted"}
            containerClasses={"p-5"}
            buttonWrapperClasses={"flex gap-1"}
            contentClasses={"text-sm md:text-base leading-5 text-white w-full"}
            buttonClasses={"px-5 py-2 md:px-10 md:py-4 bg-white rounded-full text-blue-gray-500 font-semibold text-sm md:text-base w-max"}
            declineButtonClasses={"px-5 py-2 md:px-10 md:py-4 rounded-full text-blue-gray-500 font-semibold text-sm md:text-base text-white w-max"}
            buttonText={__("CookieConsentBanner.Accept")}
            declineButtonText={__("CookieConsentBanner.Decline")}
         >
            <Trans
               i18nKey={"CookieConsentBanner.Text"}
               components={{
                  a: <a href="https://www.digitalwisers.com" />,
                  Link: getLinkToContract(ContractRoutes.CookiePolicy, '!text-white font-semibold')
               }}
            />
         </CookieConsent >
      </div>
   )
}

export default CookieConsentBanner