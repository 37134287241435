import { Icons } from "@/utils/Icons";
import Avatar from "../../../components/Avatar/Avatar";
import TopBarMobileMenuDropdown from "./TopBarMobileMenuDropdown";
import TopBarMobileMenuNavigation from "./TopBarMobileMenuNavigation";
import TopBarNotification from "../TopNavBarNotification";
import TopBarUserInfo from "../author/AuthorInfo";
import NewNoticeButton from "../actions/NewNoticeButton";
import useUserStore from "@/js/modules/users/store/useUserStore";
import { shallow } from 'zustand/shallow';
import React from 'react';
import TopNavBarChangeLanguage from "../TopNavBarChangeLanguage";
import { ScreenModes } from "@/utils/Enums";
import LandingTopNavButton from "@/js/modules/landing/components/LandingTopNavButton";
import userStore from "@/js/modules/users/store/v2/UserStore";
import { useLocation } from "react-router-dom";
import { PageRoutes } from "@/utils/PageRoutes";
import { useQuery } from "@tanstack/react-query";

interface ITopBarMobileMenu extends React.PropsWithChildren {
	menuItems: {
		to: string,
		name: string,
		translation: string
	}[]
	dropdownItems: {
		to: string,
		name: string,
		translation: string,
		icon: Icons
	}[]
	hasNotifications: boolean
	actionButton: React.ReactNode
}

const TopBarMobileMenu = (props: ITopBarMobileMenu) => {
	const [user, me] = useUserStore(state => [state.user, state.me], shallow)
	const { pathname } = useLocation()

	useQuery({
		queryKey: ['navbarMobileMenu'],
		queryFn: async () => await me(),
	})

	return (
		<>
			<TopBarMobileMenuNavigation items={props.menuItems} />
			<div className={"pt-4 pb-3 border-t border-gray-200"}>
				<div className={"flex justify-center flex-col px-4 mb-5"}>
					{pathname.includes(PageRoutes.Landing) && props.actionButton}
					{!pathname.includes(PageRoutes.Landing) && userStore.isCompanyOwnerAccountType() && (
						user.id ? <NewNoticeButton hasBorder={false} /> : <LandingTopNavButton />
					)}
				</div>
				{
					user.id
						? <>
							<div className={"flex items-center px-4 sm:px-6"}>
								<div className={"flex-shrink-0"}>
									<Avatar
										width={"w-11"}
										height={"h-11"}
										isRounded={true}
										isLazyLoaded={true}
										attrs={{
											src: user.avatarUrl,
										}}
									/>
								</div>
								<span className={'w-screen break-all'}><TopBarUserInfo /> </span>
								{props.hasNotifications && <div className={"flex-shrink-0 p-1 ml-auto"}><TopBarNotification /></div>}
							</div>
							<TopBarMobileMenuDropdown items={props.dropdownItems} />
						</>
						: <div className="px-4 w-36 md:w-full">
							<TopNavBarChangeLanguage screenMode={ScreenModes.Mobile} />
						</div>
				}
			</div>
		</>
	)
}

export default TopBarMobileMenu
