const PriceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor">
      <path fill="currentColor"
            d="M21.447 3.773h-20.3c-.21 0-.35.176-.35.352v11.327c0 .21.14.352.35.352h20.3c.175 0 .35-.141.35-.352V4.125c0-.176-.175-.352-.35-.352ZM1.497 15.1v-1.83c.945.142 1.68.915 1.82 1.83h-1.82Zm0-8.794V4.477h1.82a2.159 2.159 0 0 1-1.82 1.83Zm9.8 6.332c-1.575 0-2.835-1.302-2.835-2.85a2.853 2.853 0 0 1 2.835-2.849 2.83 2.83 0 0 1 2.835 2.85 2.83 2.83 0 0 1-2.835 2.849Zm9.8 2.462h-1.82c.14-.95.875-1.688 1.82-1.83v1.83Zm0-8.794a2.159 2.159 0 0 1-1.82-1.829h1.82v1.83ZM1.147 16.718h20.3v1.055h-20.3v-1.055Z" />
    </svg>
  );
};

export default PriceIcon;
