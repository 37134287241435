import React from 'react'
import { __ } from '@/utils/Trans';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BaseTable from '@/js/components/BaseTable/BaseTable';
import Badge from '@/js/components/Badge/Badge';
import { BlogPostCategory, UIState } from '@/utils/Enums';
import TextBox from '@/js/components/TextBox/TextBox';
import { PageRoutes } from '@/utils/PageRoutes';
import { RowStates } from '@/js/core/enums/RowStates';
import { IBlogStore } from '../../blog/store/BlogStoreTypes';
import PencilIcon from '@/assets/icons/PencilIcon';
import adminBlogStore from '../store/AdminBlogStore';

interface IAdminBlogPostsListPage { }

export const BlogStates = { ...RowStates, All: 'all' } as const

const AdminBlogPostsListPage = (props: IAdminBlogPostsListPage) => {
	adminBlogStore.initializeStore();

	const navigate = useNavigate()
	const params = useParams<{ state: string }>()

	const [search, setSearch] = React.useState('')
	const [selectedTab, setSelectedTab] = React.useState(params.state)

	const columns = [
		{ name: __('AdminUserListPage.Table.Id') },
		{ name: __("AdminBlogPostsListPage.Title") },
		{ name: __("AdminBlogPostsListPage.Category") },
		{ name: __("AdminBlogPostsListPage.State") },
		{ name: __('AdminUserListPage.Table.CreatedDate') },
		{ name: '' }
	];

	const getCategoryColor = React.useCallback((category: BlogPostCategory) => {
		switch (category) {
			case BlogPostCategory.ForCompanies:
				return 'text-blue-500'
			case BlogPostCategory.ForBlokOwners:
				return 'text-violet-500'
			case BlogPostCategory.ForTalents:
				return 'text-pink-500'
		}
	}, [])

	const getStateTranslation = React.useCallback((state: RowStates) => {
		switch (state) {
			case RowStates.Published:
				return __('AdminUserListPage.Published')
			case RowStates.Draft:
				return __('AdminUserListPage.Draft')
			case RowStates.Unpublished:
				return __('AdminUserListPage.Unpublished')
		}
	}, [])

	const getTabType = React.useCallback((tab: string) => {
		return tab === selectedTab ? UIState.Active : UIState.Default;
	}, [selectedTab])

	React.useEffect(() => {
		setSelectedTab(params.state);
		adminBlogStore.fetchAllBlogPostsForAdmin()
	}, [params])

	return (
		<div className={"ml-8 mt-5 bg-gray-900"}>
			<h1 className={"text-3xl font-semibold text-white"}>
				{__("AdminSidebar.BlogPosts")}
			</h1>
			{adminBlogStore.state.isLoading ? null : (
				<>
					<div className={"flex gap-8 mt-8 md:flex-row flex-col"}>
						<div className={"flex items-center flex-wrap gap-4"}>
							<Badge
								isClickable
								type={getTabType(BlogStates.All)}
								onClick={() => navigate(PageRoutes.AdminBlogPostsListPage.replace(':state', BlogStates.All))}
							>
								{__('AdminUserListPage.All')}
							</Badge>
							<Badge
								isClickable
								type={getTabType(BlogStates.Published)}
								onClick={() => navigate(PageRoutes.AdminBlogPostsListPage.replace(':state', BlogStates.Published))}
							>
								{__("AdminUserListPage.PublishedOnes")}
							</Badge>
							<Badge
								isClickable
								type={getTabType(BlogStates.Draft)}
								onClick={() => navigate(PageRoutes.AdminBlogPostsListPage.replace(':state', BlogStates.Draft))}
							>
								{__("AdminUserListPage.DraftOnes")}
							</Badge>
							<Badge
								isClickable
								type={getTabType(BlogStates.Unpublished)}
								onClick={() => navigate(PageRoutes.AdminBlogPostsListPage.replace(':state', BlogStates.Unpublished))}
							>
								{__("AdminUserListPage.UnpublishedOnes")}
							</Badge>
						</div>
						<div>
							<TextBox name={"title"} classes={"w-96"}>
								<TextBox.Input
									name={"search"}
									classes={"pl-2"}
									attr={{
										name: "search",
										onChange: e => setSearch(e.target.value),
										placeholder: __('AdminUserListPage.SearchText')
									}}
								/>
							</TextBox>
						</div>
					</div>
					<div>
						<BaseTable columns={columns} classes={"max-w-screen-xl"}>
							{
								adminBlogStore
									?.filterByBlogPostState(
										adminBlogStore.state.blogPosts,
										selectedTab === BlogStates.All ? '' : selectedTab,
										search
									)
									?.map((post: IBlogStore['blogPosts'][0]) =>
										<BaseTable.Row key={post.id}>
											<BaseTable.Row.Cell>
												<p className={"text-blue-gray-400"}>{post.id}</p>
											</BaseTable.Row.Cell>
											<BaseTable.Row.Cell>
												<a
													target={"_blank"}
													className={"text-blue-gray-100"}
													href={`${process.env.VITE_APP_URL}/blogposts/${post.slug}-${post.shortId}`}
													rel="noreferrer"
												>
													{post.title || post.name || __("AdminBlogPostsListPage.TitleError")}
												</a>
											</BaseTable.Row.Cell>
											<BaseTable.Row.Cell>
												<p className={`${getCategoryColor(post.category)} font-semibold`}>
													{post.categoryTranslation || post.category}
												</p>
											</BaseTable.Row.Cell>
											<BaseTable.Row.Cell>
												<p className='text-blue-400 uppercase font-semibold'>
													{getStateTranslation(post.state)}
												</p>
											</BaseTable.Row.Cell>
											<BaseTable.Row.Cell>
												<p className={"text-white"}>
													{adminBlogStore.getDateByFormat(post.created)}
												</p>
											</BaseTable.Row.Cell>
											<BaseTable.Row.Cell>
												<Link
													className={'text-white'}
													to={PageRoutes.AdminBlogPostEditPage.replace(':id', post.id)}
												>
													<PencilIcon />
												</Link>
											</BaseTable.Row.Cell>
										</BaseTable.Row>
									)
							}
						</BaseTable>
					</div>
				</>
			)}
		</div>
	)
}

export default AdminBlogPostsListPage
