import BaseStore from "@/js/core/BaseStore";
import BlokJobPostService from "@/js/modules/bloks/services/BlokJobPostService";
import blokStore from '@/js/modules/bloks/store/BlokStore';

interface IBlokJobPostStore { }

const blokJobPostStore = {
	bloks: [],
}

class BlokJobPostStore extends BaseStore<IBlokJobPostStore, BlokJobPostService> {
	initializeStore() {
		return this.useStore();
	}

	async fetchBloksByJobPost(jobPostId) {
		try {
			this.state.isLoading = true;
			const { data } = await this.service.fetchBloksByJobPost(jobPostId);
			this.state.bloks = data.result;


			this.state.bloks.forEach(async (blok) => {
				const blokPackages = await blokStore.fetchBlokPackages(blok.id);

				blok.advertPrice = blokPackages.find((blokPackage) => {
					return blokPackage.slug.startsWith('publish-job-post')
				})?.price;

				blok.slug = blokPackages.find((blokPackage) => {
					return blokPackage.slug.startsWith('publish-job-post')
				})?.slug;
			})

			return this.state.bloks;
		} finally {
			this.state.isLoading = false;
		}
	}

	async updateBloksByJobPostId(jobPostId, payload) {
		return await this.service.updateBloksByJobPostId(jobPostId, payload)
	}
}

export default new BlokJobPostStore(blokJobPostStore, BlokJobPostService) as BlokJobPostStore;
