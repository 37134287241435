import React from 'react';
import axios from 'axios';

export enum AwsClientEndpoints {
	UploadAiImageFileUpload = '/awsclients/profile-picture/upload',
}

export const useAiImageFileUpload = () => {
	const [isLoading, setIsLoading] = React.useState(false);

	const uploadFile = React.useCallback(async (file: File) => {
		setIsLoading(true);

		const formData = new FormData();
		formData.append('file', file);

		const { data: url } = await axios.post(AwsClientEndpoints.UploadAiImageFileUpload, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})

		setIsLoading(false);

		return url
	}, [])

	const handleFileChange = React.useCallback(async (newFile: File | null) => {//
		if (!newFile) return null;
		return await uploadFile(newFile)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return {
		isLoading,
		uploadFile,
		handleFileChange,
	}
}

