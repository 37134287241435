import BaseStore from "@/js/core/BaseStore";
import PositionService from "../services/PositionService";
import { IPositionStore } from "./PositionTypes";

const positionStore = {
    positions: [],
}

class PositionStore extends BaseStore<IPositionStore, PositionService> {
    async fetchPositions() {
        const { data } = await this.service.fetchPositions();
        this.state.positions = data.result;
    }

    addPosition(position) {
        if (!this.state.positions.find(p => p.id === position.value)) {
            this.state.positions.push({
                id: position.value,
                name: position.label,
                isNew: position.__isNew__
            });
        }
    }
}

export default new PositionStore(positionStore, PositionService);
