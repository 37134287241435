import BaseStore from "@/js/core/BaseStore";
import { IDepartmentStore } from "@/js/modules/departments/store/DepartmentTypes";
import DepartmentService from "@/js/modules/departments/services/DepartmentService";

const departmentStore = {
  departments: [],
}

class DepartmentsStore extends BaseStore<IDepartmentStore, DepartmentService> {
  async fetchDepartments() {
    const { data } = await this.service.fetchDepartments();
    this.state.departments = data.result;
  }
}

export default new DepartmentsStore(departmentStore, DepartmentService);
