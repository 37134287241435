import Image from "@/js/components/Image/Image";
import { Images } from "@/utils/Images";
import OnboardingBottom from "../OnboardingBottom";
import { useWindowSize } from "@/js/hooks/useWindowSize/useWindowSize";
import { ScreenSizes } from "@/utils/ScreenSizes";
import ContractCheckbox from "@/js/modules/contracts/components/ContractCheckbox/ContractCheckbox";
import { ContractRoutes } from "@/js/modules/contracts/services/ContractRoutes";
import companyStore from "@/js/modules/company/store/CompanyStore";
import CustomError from "@/js/components/CustomError/CustomError";
import { AccountTypes, UIState } from "@/utils/Enums";
import LocalStorage from "@/js/helpers/localStorage";
import { setIdentify, track } from "@/plugins/amplitude";
import { TrackerEvents } from "@/utils/TrackerEvents";
import { useNavigate, useSearchParams } from "react-router-dom";
import SuccessIconFilled from "@/assets/icons/SuccessIconFilled";
import SuccessIconGray from "@/assets/icons/SuccesIconGray";
import { useNavigateByAccountType } from "@/js/hooks/useNavigateByAccountType/useNavigateByAccountType";
import { resetOnboardingLocalStorage } from "../../constants/onbardingConstants";
import { __ } from "@/utils/Trans";
import { useTranslation } from 'react-i18next';
import { PageTranslation } from '@/utils/Enums';
import blokPublicStore from "@/js/modules/bloks/store/BlokPublicStore";

const items = [
	{ key: 'step1', icon: <SuccessIconFilled />, text: "OnboardingPage.CompanyLastStep.Step1" },
	{ key: 'step2', icon: <SuccessIconGray />, text: "OnboardingPage.CompanyLastStep.Step2" },
	{ key: 'step3', icon: <SuccessIconGray />, text: "OnboardingPage.CompanyLastStep.Step3" },
	{ key: 'step4', icon: <SuccessIconGray />, text: "OnboardingPage.CompanyLastStep.Step4" },
]

export default function OnboardingCompanyLastStep() {
	companyStore.initializeStore(companyStore.state)
	blokPublicStore.initializeStore()

	const navigate = useNavigate()
	const { width } = useWindowSize()
	const [searchParams] = useSearchParams()
	const isMobile = width <= ScreenSizes.XSmall
	const { i18n } = useTranslation();
	const isEnglish = i18n.language === PageTranslation.EN;

	const { getRedirectRoute } = useNavigateByAccountType()

	const handleSubmitClick = async () => {
		LocalStorage.set('onboardingPayload', companyStore.getCreatePayload(companyStore.state.form))

		const payload = LocalStorage.get('onboardingPayload')
		const response = await companyStore.createCompany();

		track(TrackerEvents.CreateCompany, payload);
		setIdentify('accountType', AccountTypes.CompanyOwner);

		if (response?.data.isSuccess) {

			//Subscribes to the selected blok
			if (searchParams.has('redirect')) {
				const redirect = String(searchParams.get('redirect'))
				if (redirect &&  redirect.includes('bloks')) {
					const blokId = redirect.replace("/bloks/", "").replace("/","")
					if(blokId && blokId !== 'null'){
						track(TrackerEvents.SubscribeToBlok, { blokId: blokId });
						await blokPublicStore.subscribe(blokId as string, AccountTypes.CompanyOwner);
					}
				}
			}

			resetOnboardingLocalStorage()

			const redirect = searchParams.get('redirect');
			const subscribe = searchParams.get('subscribe');

			if (subscribe && redirect) {
				return window.location.href = `${redirect}?subscribe=${subscribe}`
			}

			if (redirect) {
				return window.location.href = redirect;
			}

			return navigate({ pathname: await getRedirectRoute() });
		}
	}

	return (
		<div className={"flex flex-col justify-between items-center"}>
			<div className={`${!isMobile && 'xl:h-[calc(100vh-200px)]'} mx-auto w-full flex flex-col-reverse px-4 py-10 gap-7 items-center justify-center xl:gap-0 xl:p-0 xl:flex-row xl:items-stretch xl:justify-between xl:max-w-[70%]`}>
				<div className="flex items-center self-start justify-center xs:self-auto xl:w-45%]">
					<div className={"flex flex-col gap-4 xl:gap-10"}>
						<h1 className="text-header-bold-h8 md:text-header-bold-h5 text-shade-black">
							{__("OnboardingPage.CompanyLastStep.Title")}
						</h1>
						<ul className={"flex flex-col"}>
							{items.map(item => (
								<li key={item.key} className={"flex items-center"}>
									{item.icon}
									<span className={"p-2.5 text-body-regular-b6 md:text-body-regular-b5 text-shade-black"}>
										{__(item.text)}
									</span>
								</li>
							))}
							<li className="pt-2.5 flex">
								<CompanyContract />
							</li>
						</ul>
					</div>
				</div>
				<div className="xl:w-[45%] flex justify-center items-center">
					<Image
						src={isEnglish ? Images.CompanyOnboardingLastStepEN : Images.CompanyOnboardingLastStepTR}
						alt={"OnboardingCompanyLastStep"}
						className={"w-full h-full max-w-md xl:max-w-none"}
					/>
				</div>
			</div>
			<OnboardingBottom
				hasBackButton={false}
				onClick={handleSubmitClick}
				isLoading={companyStore.state.isSaving}
				disabled={!companyStore.state.form.hasEmployerAgreement}
				buttonText={__("OnboardingPage.CompanyLastStep.Button")}
				customElement={undefined}
			/>
		</div>
	)
}

export const CompanyContract = () => {
	return (
		<div className={"flex items-center flex-row-reverse"}>
			<ContractCheckbox
				checked={companyStore.state.form.hasEmployerAgreement}
				name={"hasEmployerAgreement"}
				error={companyStore.state.errors}
				label={"CreateCompanyPage.EmployerUserAgreement"}
				to={ContractRoutes.EmployerUserAgreement}
				labelClass={"text-shade-black text-body-regular-b7"}
				onChecked={e => companyStore.state.form.hasEmployerAgreement = e.target.checked}
			/>
			<div>
				<CustomError
					name="hasEmployerAgreement"
					stateType={UIState.Error}
					error={companyStore.state.errors}
				/>
			</div>
		</div>
	)
}
