import { useOutletContext, useSearchParams } from "react-router-dom";
import OnboardingBottom from "../OnboardingBottom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import TextBox from "@/js/components/TextBox/TextBox";
import { __ } from "@/utils/Trans";
import talentStore from "@/js/modules/talent/store/TalentStore";
import MedalDoodle from "@/assets/icons/MedalDoodle";
import { UIState } from "@/utils/Enums";
import SelectBox from "@/js/components/SelectBox/SelectBox";
import { useQuery } from "@tanstack/react-query";
import experienceStore from "@/js/modules/experience/store/ExperienceStore";
import { validLinkedinUrlArray } from "@/utils/Regex";
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";

export default function OnboardingTalentExperience() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()
  const [searchParams] = useSearchParams()

	const handleContinueClick = () => {
		talentStore.resetErrors()

		const linkedInUrl = talentStore.state.form.linkedInUrl

		if (linkedInUrl && !validLinkedinUrlArray.some(url => linkedInUrl.startsWith(url))) {
			talentStore.state.errors = { 'linkedInUrl': "Validations.Matches" }
			return;
		}

		handleChangeStep()
	}

	useQuery({
		queryKey: ['experiences'],
		queryFn: async () => {
			await experienceStore.fetchExperiences()
			return true
		}
	})

	return (
		<div className="flex flex-col justify-center items-center">
			<div className={"px-4 flex flex-col justify-start items-center max-w-2xl w-full xl:gap-6 xl:p-0 xl:justify-center"}>
				<div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} w-full flex flex-col justify-center items-center`}>
					<div className="no-scrollbar overflow-y-auto lg:overflow-y-visible  py-10 w-full flex flex-col justify-center items-center">
						<div className={"flex gap-3 items-start p-4 max-w-md w-full bg-blond rounded-20 mb-4"}>
							<span className="flex-shrink-0 lg:self-center">
								<MedalDoodle />
							</span>
							<p className={"text-body-regular-b8 text-gamboge-orange"}>
								{__("OnboardingPage.TalentExperience.Disclaimer")}
							</p>
						</div>
						<h1 className={"text-header-bold-h8 xl:text-header-bold-h5 text-shade-black text-left md:text-center pt-7"}>
							{__("OnboardingPage.TalentExperience.Title")}
						</h1>
						<div className={"flex flex-col w-full max-w-xl gap-10 pt-7"}>
							<SelectBox
								data={talentStore.experiences()}
								name={"experienceId"}
								placeholder={__('CreateTalentPage.TotalExperiencePlaceholder')}
								error={talentStore.state.errors}
								stateType={{} as UIState}
								onChange={e => talentStore.set("experienceId", e.value)}
								value={talentStore.state.form.experienceId}
								inputName={"experienceId"}
							/>
							<TextBox name={"txt_LinkedInProfile"} isOptional>
								<TextBox.Label>
									{__("CreateTalentPage.LinkedInUrl")}
								</TextBox.Label>
								<TextBox.Input
									name={"linkedInUrl"}
									error={talentStore.state.errors}
									attr={{
										onChange: e => talentStore.set("linkedInUrl", e.target.value),
										name: "linkedInUrl",
										value: talentStore.state.form.linkedInUrl,
										maxLength: 200,
										placeholder: 'https://www.linkedin.com/in/username'
									}}
								/>
							</TextBox>
						</div>
					</div>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={!talentStore.state.form.experienceId}
			/>
		</div>
	)
}
